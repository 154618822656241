import * as types from './actionTypes';
import { EDIT_ACCOUNT_SUCCESS } from '../base/editAccount/actionTypes';
import {adiaLiveSettings} from '../base/util/adiaLiveSettings';

export const initialState = {
    reLoginChecked: false,

    publicServiceInfo: undefined,
    publicServiceIsLoading: false,
    publicServiceErrorMessage: undefined,

    isLoggingIn: false,
    authenticated: false,
    user: undefined,
    service: undefined,
    serviceSettings: adiaLiveSettings.flagsToSettings(0),
    captcha: undefined,
    errorMessage: undefined,

    initFailed: false,
    initErrorId: undefined,
    initErrorNo: undefined,
    initErrorData: undefined,
};

/**
 *
 * @param state
 * @param action
 * @returns new state
 */
export default function auth(state = initialState, action) {
    switch (action.type) {
        case types.SELECT_SERVICE:
            return {
                ...state,
                publicServiceErrorMessage: undefined,
                errorMessage: undefined,
            };
        case types.LOGIN_REQUEST:
            return {
                ...state,
                isLoggingIn: true,
                errorMessage: undefined,
            };
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                authenticated: true,
                isLoggingIn: false,
                captcha: undefined,
                user: { ...action.user },
                service: action.serviceId,
            };
        case types.LOGIN_FAILURE:
            return {
                ...state,
                authenticated: false,
                isLoggingIn: false,
                errorMessage: action.errorMessage,
                captcha: action.captcha,
            };
        case types.RE_LOGIN_CHECKED:
            return {
                ...state,
                reLoginChecked: true,
            };
        case types.RE_LOGIN_REQUEST:
            return {
                ...state,
                isLoggingIn: true,
                errorMessage: undefined,
            };
        case types.RE_LOGIN_SUCCESS:
            return {
                ...state,
                authenticated: true,
                reLoginChecked: true,
                isLoggingIn: false,
                captcha: undefined,
                user: { ...action.user },
                service: action.serviceId,
            };
        case types.RE_LOGIN_FAILURE:
            return {
                ...state,
                authenticated: false,
                reLoginChecked: true,
                isLoggingIn: false,
                errorMessage: action.errorMessage,
                captcha: undefined,
            };
        case types.FORCE_LOGOUT:
            return {
                ...state,
                errorMessage: action.errorMessage,
            };
        case types.LOGOUT_SUCCESS:
            return {
                ...state,
                authenticated: false,
                user: undefined,
                service: undefined,
            };
        case types.PUBLIC_SERVICE_INFO_REQUEST:
            return {
                ...state,
                publicServiceIsLoading: true,
                publicServiceErrorMessage: undefined,
            };
        case types.PUBLIC_SERVICE_INFO_SUCCESS:
            return {
                ...state,
                publicServiceIsLoading: false,
                publicServiceInfo: action.publicServiceInfo,
                serviceSettings: adiaLiveSettings.flagsToSettings(action.publicServiceInfo.flags)
            };
        case types.PUBLIC_SERVICE_INFO_FAILURE:
            return {
                ...state,
                publicServiceIsLoading: false,
                publicServiceErrorMessage: action.errorMessage,
            };
        case EDIT_ACCOUNT_SUCCESS: {
            const newUser = Object.assign({}, state.user, action.user);
            if (state.publicServiceInfo.authMethod === 'keycloak') {
                newUser._id = newUser.keycloakId;
                delete newUser.keycloakId;
            }
            return {
                ...state,
                user: newUser,
            };
        }
        case types.INITIALIZE_FAILURE: {
            return {
                ...state,
                initFailed: true,
                initErrorId: action.errorId,
                initErrorNo: action.errorNo,
                initErrorData: action.errorData,
            };
        }
        default:
            return state;
    }
}
