export const REQUEST_MEETINGS = 'REQUEST_MEETINGS';
export const REQUEST_MEETINGS_SUCCESS = 'REQUEST_MEETINGS_SUCCESS';
export const REQUEST_MEETINGS_FAILURE = 'REQUEST_MEETINGS_FAILURE';

export const OPEN_EDIT_MEETING = 'OPEN_EDIT_MEETING';
export const GO_TO_MEETING = 'GO_TO_MEETING';

export const MANAGER_IFRAME_NEW_REF = 'MANAGER_IFRAME_NEW_REF';
export const MANAGER_IFRAME_LOAD = 'MANAGER_IFRAME_LOAD';
export const MANAGER_IFRAME_READY = 'MANAGER_IFRAME_READY';
export const MANAGER_IFRAME_SEND_CHECK_COMPLETED =
    'MANAGER_IFRAME_SEND_CHECK_COMPLETED';
export const MANAGER_IFRAME_SEND_OPEN_EDIT = 'MANAGER_IFRAME_SEND_OPEN_EDIT';
