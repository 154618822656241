import React from 'react';
import {connect} from 'react-redux';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import TranslatedString from '../base/i18n/TranslatedString';
import {getTranslatedString} from '../base/i18n/translations';

import {hideRecordingConfirmationDialog, startRecording, stopRecording} from './actions';
import {RECORDING_CONFIRMATION_STATE} from "../../constants/constants";

export function RecordingConfirmationDialog(props) {

    let canStartRecording = true;
    let canRestartConfirmation = false;

    let participantItems = [];

    props.meetingParticipants.forEach(item => {
        if (!item.standalone && !item.isAdviser) {
            let statusIcon;
            if (item.recordingConfirmationState === RECORDING_CONFIRMATION_STATE.CONFIRMED) {
                statusIcon = (
                    <i
                        className="pi-md-check"
                        style={{fontSize: '30px', color: 'green'}}
                    />
                );
            } else if (item.recordingConfirmationState === RECORDING_CONFIRMATION_STATE.DECLINED) {
                statusIcon = <i
                    className="pi-md-close"
                    style={{fontSize: '30px', color: 'red'}}
                />
                canStartRecording = false;
                canRestartConfirmation = true;
            } else if (item.recordingConfirmationState === RECORDING_CONFIRMATION_STATE.PENDING) {
                if (props.recordingConfirmationInProgress) {
                    statusIcon = (
                        <div className="circle-container circle-container-dialog" style={{height: '40px'}}>
                            <div className="circle-inner"/>
                            <div className="circle-outer"/>
                        </div>
                    );
                } else {
                    canRestartConfirmation = true;
                }
                canStartRecording = false;
            }
            participantItems.push(
                <tr key={item.id}>
                    <td>{item.info.name}</td>
                    <td>
                        {statusIcon}
                    </td>
                </tr>
            );
        }
    });

    const footer = (
        <div>
            <Button
                label={getTranslatedString(
                    props.language,
                    'cancel'
                )}
                className="p-button-secondary"
                onClick={canStartRecording ? props.hideRecordingConfirmationDialog : () => {
                    props.stopRecording();
                    props.hideRecordingConfirmationDialog();
                }}
            />
            { canRestartConfirmation ? (
                <Button
                    label={getTranslatedString(props.language, 'restartConfirmation')}
                    className="p-hightlight"
                    icon="pi pi-md-refresh"
                    onClick={props.startRecording}
                />
            ) : (
                <Button
                    label={getTranslatedString(props.language, 'startRecording')}
                    className="p-button-primary"
                    disabled={!canStartRecording}
                    onClick={() => {
                        props.startRecording();
                        props.hideRecordingConfirmationDialog();
                    }}
                />
            )}

        </div>
    );

    return (
        <Dialog
            className="recording-confirmation-dialog"
            header={<TranslatedString id={'confirmRecordingTitle'}/>}
            footer={footer}
            visible={props.recordingConfirmationDialogVisible}
            onHide={() => {
            }}
            closable={false}
            baseZIndex={999999}
            focusOnShow={false}
        >
            <div className="p-grid form-group">
                <div className="p-col-12">
                    <TranslatedString id="confirmRecordingMessage"/>
                </div>
                <div className="p-col-12">
                    {participantItems.length > 0 ? (
                        <table>
                            <tbody>
                            {participantItems}
                            </tbody>
                        </table>
                    ) : (
                        <TranslatedString id="noParticipants"/>
                    )}
                </div>
            </div>
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
        recordingConfirmationDialogVisible: state.meetings.recordingConfirmationDialogVisible,
        recordingConfirmationInProgress: state.meetings.meetingInfo.recordingConfirmationInProgress,
        clientInfo: state.meetings.clientInfo,
        meetingParticipants: state.meetings.meetingParticipants,
        language: state.base.i18n.language
    };
};

const mapDispatchToProps = {
    startRecording,
    stopRecording,
    hideRecordingConfirmationDialog
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecordingConfirmationDialog);
