import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import TranslatedString from '../../features/base/i18n/TranslatedString';
import { readFromLocalStorage } from '../base/util/helpers';
import { selectService } from './actions';

import { getTranslatedString } from '../base/i18n/translations';
import {Config} from "../../config/Config";

class ServiceIdSelector extends Component {
    constructor(props) {
        super(props);

        const serviceId = readFromLocalStorage('serviceId') || '';

        this.state = {
            serviceId: serviceId,
            invalidServiceId: false
        };
    }

    handleOnChange = () => e => {
        this.setState({ serviceId: e.target.value });
    };

    handleNextClick = () => e => {
        e.preventDefault();

        const serviceId = this.state.serviceId;
        if (serviceId !== '') {
            this.props.selectService(serviceId);
        } else {
            this.setState({ invalidServiceId: true });
        }
    };

    errorMessageFromError = error => {
        if (error) {
            if (error.errorNo === 40400) {
                return 'serviceNotFound';
            } else {
                if (error.internalError) {
                    return 'connectionError';
                }
                return 'serverError';
            }
        }
    };

    render() {
        return (
            <div>
                <div className="login-body">
                    <div className={'login-panel p-fluid'}>
                        <div className="login-panel-header">
                            <img src={Config.loginLogo} alt="Logo" />
                        </div>
                        <form
                            className="login-panel-content"
                            onSubmit={this.handleNextClick()}
                        >
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <h1>
                                        <TranslatedString
                                            id={'applicationName'}
                                        />
                                    </h1>
                                </div>

                                {this.props.error && (
                                    <div className="p-col-12">
                                        <Message
                                            severity="warn"
                                            text={getTranslatedString(
                                                this.props.language,
                                                this.errorMessageFromError(
                                                    this.props.error
                                                ) || ''
                                            )}
                                        />
                                    </div>
                                )}

                                <div className="p-col-12">
                                    <span className="md-inputfield">
                                        <InputText
                                            className={
                                                this.state.invalidServiceId
                                                    ? 'p-error'
                                                    : ''
                                            }
                                            value={this.state.serviceId}
                                            onChange={this.handleOnChange()}
                                        />
                                        <label>
                                            <TranslatedString
                                                id={'serviceId'}
                                            />
                                        </label>
                                    </span>
                                </div>

                                <div className="p-col-12">
                                    <Button
                                        label={'NEXT'}
                                        icon="pi-md-person"
                                        type="submit"
                                        className="p-highlight"
                                        onClick={this.handleNextClick()}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.auth.publicServiceErrorMessage,
        language: state.base.i18n.language
    };
};

const mapDispatchToProps = {
    selectService
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceIdSelector);
