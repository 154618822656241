import React from 'react';
import { connect } from 'react-redux';
import TranslatedString from '../base/i18n/TranslatedString';

export function OnAirStateIndicator(props) {
    return (
        <div className="onair-state-indicator">
            {props.isOnline ? (
                <span className="onair-state-icon online">
                    <TranslatedString id={'broadcastOnline'} />
                </span>
            ) : (
                <span className="onair-state-icon offline">
                    <TranslatedString id={'broadcastOffline'} />
                </span>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.meetings.meetingInfo.isOnline,
    };
};

export default connect(mapStateToProps, undefined)(OnAirStateIndicator);
