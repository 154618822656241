import { take, put, all, select } from 'redux-saga/effects';
import { push, LOCATION_CHANGE } from 'connected-react-router';

import { meetingTypeToRoute, routeWithServiceId } from '../base/util/helpers';
import { MANAGER_IFRAME_READY, OPEN_EDIT_MEETING } from './actionTypes';
import { managerIFrameSendOpenEdit } from './actions';

const getServiceId = (state) => state.auth.service;
const getApplicationsUrl = (state) => {
    let applicationsUrl = null;
    if (
        state.auth.publicServiceInfo &&
        state.auth.publicServiceInfo.meetingsSettings
    ) {
        applicationsUrl =
            state.auth.publicServiceInfo.meetingsSettings.applicationsUrl;
    }
    return applicationsUrl;
};

function* openEdit() {
    while (true) {
        const { meetingId, meetingType } = yield take(OPEN_EDIT_MEETING);
        const serviceId = yield select(getServiceId);
        const applicationsUrl = yield select(getApplicationsUrl);
        // redirect to meetings/webinar manager page
        const destination = routeWithServiceId(
            meetingTypeToRoute(meetingType),
            serviceId
        );
        yield put(push(destination));

        if (meetingId && applicationsUrl) {
            // if meeting manager is set, send openEdit message
            while (true) {
                const action = yield take([
                    MANAGER_IFRAME_READY,
                    LOCATION_CHANGE,
                ]);
                if (action.type === LOCATION_CHANGE) {
                    if (
                        action.payload.location.pathname !==
                        routeWithServiceId(
                            meetingTypeToRoute(meetingType),
                            serviceId
                        )
                    ) {
                        break;
                    }
                } else {
                    yield put(managerIFrameSendOpenEdit(meetingId));
                    break;
                }
            }
        }
    }
}
export function* meetingsManagerSagas() {
    yield all([openEdit()]);
}
