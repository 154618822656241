import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import classNames from 'classnames';

import MeetingMainVideo from './MeetingMainVideo';
import MeetingVideoList from './MeetingVideoList';
import MeetingTopbar from './MeetingTopbar';
import MeetingIndicators from './MeetingIndicators';
import MeetingToolbar from './MeetingToolbar';
import SharedApplicationsPanel from './SharedApplicationsPanel';
import SidePanel from './SidePanel';
import ParticipantActionsDialog from './ParticipantActionsDialog';
import RecordingConfirmationDialog from './RecordingConfirmationDialog';
import { LoadingScreen } from '../base/loadingScreen/LoadingScreen';

import {
    joinMeeting,
    leaveMeeting,
    hideCompleteMeetingDialog,
    hideLeaveMeetingDialog,
    alcCompleteMeeting,
    fullscreenNewRef,
    confirmJoinDialog,
    cancelJoinDialog,
} from './actions';
import {
    MEETING_TYPES,
    RESPONSIVE_MODES,
    SPEAKER_PERMISSION_STATE,
} from '../../constants/constants';
import { getTranslatedString } from '../base/i18n/translations';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import TranslatedString from '../base/i18n/TranslatedString';
import Routes from '../../constants/routes';
import AddAdviserDialog from './AddAdviserDialog';
import TransferCallDialog from './TransferCallDialog';

export class Meeting extends Component {
    constructor(props) {
        super(props);

        this.meetingRef = React.createRef();
        this.handleCompleteMeeting = this.handleCompleteMeeting.bind(this);
    }

    componentDidMount() {
        this.props.fullscreenNewRef(this.meetingRef);
        if (!this.props.alcMeetingJoined) {
            const meetingId = this.props.match.params.id;
            this.props.joinMeeting(meetingId);
        }
    }

    componentWillUnmount() {
        if (this.props.alcMeetingJoined) {
            this.props.leaveMeeting();
        }
        this.props.fullscreenNewRef(null);
    }

    handleCompleteMeeting() {
        this.props.hideCompleteMeetingDialog();
        this.props.hideLeaveMeetingDialog();
        this.props.alcCompleteMeeting();
    }

    renderCompleteMeetingDialog() {
        let completeMeetingDialog = null;
        if (this.props.completeMeetingDialogVisible) {
            const footer = (
                <div>
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'cancel'
                        )}
                        className="p-button-secondary"
                        onClick={this.props.hideCompleteMeetingDialog}
                    />
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'confirm'
                        )}
                        className="p-highlight"
                        onClick={this.handleCompleteMeeting}
                    />
                </div>
            );

            completeMeetingDialog = (
                <Dialog
                    header={<TranslatedString id={'completeMeeting'} />}
                    footer={footer}
                    visible={this.props.completeMeetingDialogVisible}
                    onHide={this.props.hideCompleteMeetingDialog}
                    baseZIndex={999999}
                    focusOnShow={false}
                >
                    <TranslatedString id="completeMeetingConfirmation" />
                </Dialog>
            );
        }

        return completeMeetingDialog;
    }

    renderLeaveMeetingDialog() {
        // only used to prompt user to complete meeting on leaving (if possible)
        // could be extended to general leave confirmation
        let leaveMeetingDialog = null;
        if (this.props.leaveMeetingDialogVisible) {
            const footer = (
                <div>
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'leaveMeeting'
                        )}
                        className="p-button-secondary"
                        onClick={() => {
                            if (
                                this.props.meetingType === MEETING_TYPES.WEBINAR
                            ) {
                                this.props.leaveMeeting(Routes.WEBINARS);
                            } else if (
                                this.props.meetingType ===
                                MEETING_TYPES.PHONE_CONSULTING
                            ) {
                                this.props.leaveMeeting(
                                    Routes.PHONE_CONSULTATION
                                );
                            } else if (
                                this.props.meetingType === MEETING_TYPES.PODIUM
                            ) {
                                this.props.leaveMeeting(Routes.PODIUMS);
                            } else {
                                this.props.leaveMeeting(Routes.MEETINGS);
                            }
                        }}
                    />
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'completeMeeting'
                        )}
                        className="p-highlight"
                        onClick={this.handleCompleteMeeting}
                    />
                </div>
            );

            leaveMeetingDialog = (
                <Dialog
                    header={<TranslatedString id={'completeMeeting'} />}
                    footer={footer}
                    visible={this.props.leaveMeetingDialogVisible}
                    onHide={this.props.hideLeaveMeetingDialog}
                    baseZIndex={999999}
                    focusOnShow={false}
                >
                    <TranslatedString id="completeMeetingConfirmation" />
                </Dialog>
            );
        }

        return leaveMeetingDialog;
    }

    // TODO: temporary workaround for no user gesture issue
    renderConfirmJoinDialog() {
        if (this.props.confirmJoinDialogVisible) {
            const footer = (
                <div>
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'cancel'
                        )}
                        className="p-button-secondary"
                        onClick={this.props.cancelJoinDialog}
                    />
                    <Button
                        label={getTranslatedString(this.props.language, 'join')}
                        className="p-highlight"
                        onClick={this.props.confirmJoinDialog}
                    />
                </div>
            );

            return (
                <Dialog
                    header={
                        <React.Fragment>
                            <TranslatedString id={'meeting'} />
                            {': '}
                            {this.props.meetingInfo.title}
                        </React.Fragment>
                    }
                    footer={footer}
                    visible={this.props.confirmJoinDialogVisible}
                    onHide={this.props.cancelJoinDialog}
                    baseZIndex={999999}
                    focusOnShow={false}
                >
                    {}
                </Dialog>
            );
        } else {
            return null;
        }
    }

    render() {
        if (this.props.alcMeetingJoined && this.props.webRtcMeetingJoined) {
            const { responsiveMode } = this.props;

            //const mobileLayout = responsiveMode < RESPONSIVE_MODES.FULL;
            const mobileLayout = false; // todo: is only quick fix for Mobile-View

            const sidePanelOverlayed = responsiveMode < RESPONSIVE_MODES.MEDIUM;

            const completeMeetingDialog = this.renderCompleteMeetingDialog();
            const leaveMeetingDialog = this.renderLeaveMeetingDialog();
            return (
                <div
                    className={classNames('meeting-wrapper', {
                        'not-mirror-own':
                            this.props.ownScreenShareActive ||
                            this.props.notMirrorOwn,
                    })}
                    ref={this.meetingRef}
                >
                    <div
                        className={classNames('meeting-panel-wrapper', {
                            'side-panel-active':
                                !!this.props.activeSidePanelApplicationId,
                            mobile: mobileLayout,
                        })}
                    >
                        <MeetingPanel
                            {...this.props}
                            completeMeetingDialog={completeMeetingDialog}
                            leaveMeetingDialog={leaveMeetingDialog}
                        />
                    </div>
                    <MeetingToolbar />
                    <div
                        className={classNames('side-panel-wrapper', {
                            hidden:
                                !this.props.activeSidePanelApplicationId &&
                                !mobileLayout,
                            mobile: mobileLayout,
                            overlayed: sidePanelOverlayed,
                        })}
                    >
                        <SidePanel />
                    </div>
                </div>
            );
        }
        return (
            <React.Fragment>
                <LoadingScreen />
                {this.renderConfirmJoinDialog()}
            </React.Fragment>
        );
    }
}

class MeetingPanel extends Component {
    render() {
        return (
            <div
                className={classNames('meeting', {
                    'videolist-active':
                        this.props.responsiveMode > RESPONSIVE_MODES.SMALL,

                    'responsive-mode-small':
                        this.props.responsiveMode === RESPONSIVE_MODES.SMALL,

                    'responsive-mode-medium':
                        this.props.responsiveMode === RESPONSIVE_MODES.MEDIUM ||
                        this.props.responsiveMode === RESPONSIVE_MODES.BIG ||
                        (this.props.responsiveMode === RESPONSIVE_MODES.FULL &&
                            this.props.visibleParticipantCount > 7),
                    'responsive-mode-full':
                        this.props.responsiveMode === RESPONSIVE_MODES.FULL &&
                        this.props.visibleParticipantCount <= 7,
                })}
            >
                <MeetingMainVideo />
                <MeetingIndicators />
                <MeetingTopbar />

                {this.props.responsiveMode > RESPONSIVE_MODES.SMALL && (
                    <MeetingVideoList />
                )}

                {this.props.applicationsUrl && (
                    <SharedApplicationsPanel standalone={false} />
                )}

                <ParticipantActionsDialog />
                <RecordingConfirmationDialog />
                <AddAdviserDialog />
                <TransferCallDialog />

                {this.props.completeMeetingDialog}
                {this.props.leaveMeetingDialog}

                <Prompt
                    message={getTranslatedString(
                        this.props.language,
                        'msgReallyLeaveMeeting'
                    )}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const meetingType =
        state.meetings.meetingInfo && state.meetings.meetingInfo.type;

    let visibleParticipantCount = 0;
    if (
        state.meetings.meetingParticipants &&
        state.meetings.meetingParticipants.length > 0
    ) {
        visibleParticipantCount = state.meetings.meetingParticipants.reduce(
            (acc, participant) => {
                if (
                    !participant.standalone &&
                    !participant.recorder &&
                    (meetingType !== MEETING_TYPES.WEBINAR ||
                        participant.isAdviser ||
                        participant.speakerPermissionState ===
                            SPEAKER_PERMISSION_STATE.GRANTED)
                ) {
                    return acc + 1;
                } else {
                    return acc;
                }
            },
            0
        );
    }

    return {
        alcMeetingJoined: state.meetings.alcMeetingJoined,
        webRtcMeetingJoined: state.meetings.webRtcMeetingJoined,
        applicationsUrl:
            state.auth.publicServiceInfo.meetingsSettings.applicationsUrl,
        responsiveMode: state.base.common.responsiveMode,
        visibleParticipantCount,
        completeMeetingDialogVisible:
            state.meetings.completeMeetingDialogVisible,
        leaveMeetingDialogVisible: state.meetings.leaveMeetingDialogVisible,
        language: state.base.i18n.language,
        meetingType,
        meetingInfo: state.meetings.meetingInfo,
        activeSidePanelApplicationId:
            state.meetings.activeSidePanelApplicationId,
        ownScreenShareActive: !!(
            state.meetings.ownScreenShareView &&
            state.meetings.ownScreenShareView.view
        ),
        // TODO: temporary workaround for no user gesture issue
        confirmJoinDialogVisible: state.meetings.confirmJoinDialogVisible,
        notMirrorOwn: state.deviceSettings.notMirrorOwn,
    };
};

const mapDispatchToProps = {
    joinMeeting,
    leaveMeeting,
    fullscreenNewRef,
    hideCompleteMeetingDialog,
    hideLeaveMeetingDialog,
    alcCompleteMeeting,
    confirmJoinDialog,
    cancelJoinDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(Meeting);
