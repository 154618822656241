export const AUTH_MIN_TOKEN_VALIDITY = 150; //in seconds
export const AUTH_MAX_INACTIVITY_TIME = 900; //in seconds
export const AUTH_REFRESH_TOKEN_INTERVAL = 60; //in seconds
export const MAX_LOCAL_BG_IMAGE_SIZE = 10485760; // 10 MB

export const SA_NAMESPACE = 'adiaLive';

export const SA_IDENTIFIERS = {
    SHARED_APPLICATIONS: 'adiaLive.sharedApplications',
    MANAGER: 'adiaLive.preparation',
    DOC_MANAGER: 'adiaLive.doc-manager',
    SIDE_PANEL: 'adiaLive.streamChat',
};

export const ADVISER_STATE_NAMES = {
    NONE: 'none',
    CONNECTING: 'connecting',
    CONNECTED: 'connected',
    RECONNECTING: 'reconnecting',
    BUSY: 'busy',
    READY: 'ready',
    JOINED_MEETING: 'joinedMeeting',
    LEFT_MEETING: 'leftMeeting',
};

export const SA_APPLICATIONS = {
    AGENDA: 'agenda',
    SHOWBOARD: 'showboard',
    MINUTES: 'minutes',
    DOCUMENTS: 'documents',
    CHAT: 'chat',
    STREAM_CHAT: 'streamChat',
    SURVEY_MANAGER: 'surveyManager',
    DOCUMENTS_MANAGER: 'documentsManager',
    STREAM_DOCUMENTS: 'streamDocuments',
    STREAM_SURVEYS: 'streamSurveys',
};

export const AL_APPLICATIONS = {
    ADIA_PAD: 'adiaPad',
    PARTICIPANTS: 'participants',
};

export const KELDANO_APPLICATIONS = {
    SHARED_APPLICATIONS: 'sharedApplications',
    MEETING_MANAGER: 'meetings',
    WEBINAR_MANAGER: 'webinars',
    PHONE_CONSULTING_MANAGER: 'phone-consulting',
    PODIUM_MANAGER: 'podiums',
    CALL_MANAGER: 'callmanager',
    DEFAULT_DOCUMENTS: 'admin/manager',
    DEFAULT_AGENDA: '/default/agenda',
    DEFAULT_MINUTES: '/default/minutes',
    SIGNATURE_TEMPLATES: 'nepatec/business-case-types',
};

export const IFRAME_COMMANDS = {
    INIT: 'init',
    INIT_CONFIRMED: 'initConfirmed',
    SHOW: 'show',
    HIDE: 'hide',
    READY: 'ready',
    SHOW_CONFIRMED: 'showConfirmed',
    CHAT_NEW_MESSAGE: 'newMessage',
    DOCUMENTS_NEW_UPLOAD: 'newDocument',
    NEW_SURVEY: 'newSurvey',
    JOIN_MEETING: 'joinMeeting',
    CHECK_COMPLETED: 'checkCompleted',
    OPEN_EDIT: 'openEdit',
    GO_TO_OVERVIEW: 'goToOverview',
    WINDOW_EVENT: 'windowEvent',
    REQUEST_SIGNATURE: 'requestSignature',
    REQUEST_SIGNATURE_STATE: 'signatures',
    NEW_PARTICIPANT_JOINED: 'newParticipantJoined',
    MAINTENANCE_WINDOWS: 'maintenanceWindows',
    CURRENT_APP: 'currentApp',
};

export const IFRAME_INIT_INTERVAL = 500;

export const RESOLUTION_LIST = [
    '480x270',
    '640x360',
    '1280x720',
    '1920x1080',
    '607x1080',
    '720x1280',
];

export const FPS_LIST = ['25', '30', '50', '60'];

export const DEFAULT_RESOLUTION = '1280x720';

export const COMMUNICATION_MODES = {
    NONE: 'none',
    AUDIO: 'audio',
    VIDEO: 'video',
    BOTH: 'both',
};

export const WEBRTC_MODES = {
    LIVESWITCH_SFU: 'liveSwitchSfu',
    LIVESWITCH_P2P: 'liveSwitchPeer',
    ADIA_P2P: 'adiaP2P',
};

export const BACKGROUND_EFFECTS = {
    NONE: 'none',
    BLUR: 'blur',
    STRONG_BLUR: 'strongBlur',
    IMAGE: 'image',
    LOCAL_IMAGE: 'localImage',
};

export const RESPONSIVE_MODES = {
    SMALL: 0,
    MEDIUM: 760,
    BIG: 1024,
    FULL: 1260,
};

export const CONNECTION_QUALITY_THRESHOLDS = {
    GOOD: 80,
    MEDIUM: 60,
};

export const TOOLTIP_TOUCH_DELAY = 500;

export const ERRORS = {
    SERVER_UNREACHABLE_ERROR: {
        internalError: true,
        errorNo: 0,
        message: 'could not reach server',
    },
    PARSING_ERROR: {
        internalError: true,
        errorNo: 1,
        message: 'could not parse response',
    },
    INVALID_ROLE_ERROR: {
        internalError: true,
        errorNo: 2,
        message: 'user must have adviser role',
    },
};

export const MEETING_TYPES = {
    MEETING: 1,
    WEBINAR: 2,
    PHONE_CONSULTING: 4,
    PODIUM: 8,
    OUTLOOK_MEETING: 16,
    CALL_MANAGER: 32,
};

export const RECORDING_CONFIRMATION_STATE = {
    PENDING: 0,
    CONFIRMED: 1,
    DECLINED: 2,
};

export const SPEAKER_PERMISSION_STATE = {
    NONE: 0,
    REQUESTING: 1,
    GRANTED_PREPARING: 2,
    GRANTED: 4,
    DECLINED: 8,
    FAILED: 16,
};

export const SHOWBOARD_TOOLBAR_HEIGHT = 64;

export const VIEW_MODES = {
    MAIN_ONLY: 'mainOnly',
    VIDEO_LIST: 'videoList',
};

export const SCREEN_SHARING_MODES = {
    VIDEO: 'video',
    COMBI: 'combi',
    SCREEN: 'screen',
};

export const ADIAPAD_TILES_CATEGORIES = {
    CONTROL: 'control',
    CONTENT: 'content',
    CUSTOM: 'custom',
    SEPARATOR: 'separator',
};

export const ADMITTANCE_STATE = {
    REQUESTED: 0,
    GRANTED: 1,
};
