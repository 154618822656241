import React from 'react';
import Tippy from "@tippyjs/react";
import {TOOLTIP_TOUCH_DELAY} from "../../constants/constants";
import classNames from "classnames";

function MeetingToolbarButton({label, toggled, onToggle, icon, disabled, badge, appId, btnRef}) {
    const isFontIcon = typeof icon === 'string';

    const clickHandler = () => {
        if (!disabled){
            if(toggled){
                onToggle(null);
            } else {
                onToggle(appId);
            }
        }
    }

    if (isFontIcon){
        return (
            <div aria-label={label} className="toolbox-button" ref={btnRef}>
                <div className="toolbox-icon">
                    <Tippy
                        content={label}
                        arrow
                        touch={['hold', TOOLTIP_TOUCH_DELAY]}
                    >
                        <i
                            className={classNames(icon, {toggled, disabled})}
                            onClick={clickHandler}
                        />
                    </Tippy>
                </div>
                {badge && (
                    <span className="toolbox-badge">
                        <span className="badge-round">{badge}</span>
                    </span>
                )}
            </div>
        )
    }

    return (
        <div aria-label={label} className="toolbox-button" ref={btnRef}>
            <div className="toolbox-icon">
                <Tippy
                    content={label}
                    arrow
                    touch={['hold', TOOLTIP_TOUCH_DELAY]}
                >
                    <div onClick={clickHandler} className={classNames('svg-icon','meeting-toolbar-icon', {toggled, disabled})}>
                        {icon}
                    </div>
                </Tippy>
            </div>
            {badge && (
                <span className="toolbox-badge">
                    <span className="badge-round">{badge}</span>
                </span>
            )}
        </div>
    )
}

export default MeetingToolbarButton;
