import { defaultConfig } from './Config_default';

/*
import Config_CONFIGNAME.js
if no CONFIGNAME is defined, use Config_local.js
  */
let config = null;

if (process.env.REACT_APP_CONFIG === 'al5-app04_default') {
    config = require('./Config_al5-app04_default').Config;
} else if (process.env.REACT_APP_CONFIG === 'al5-app07_default') {
    config = require('./Config_al5-app07_default').Config;
} else if (process.env.REACT_APP_CONFIG === 'al5-app08_default') {
    config = require('./Config_al5-app08_default').Config;
} else if (process.env.REACT_APP_CONFIG === 'al5-app10_default') {
    config = require('./Config_al5-app10_default').Config;
} else if (process.env.REACT_APP_CONFIG === 'al5-app11_default') {
    config = require('./Config_al5-app11_default').Config;
} else if (process.env.REACT_APP_CONFIG === 'al5-app14_default') {
    config = require('./Config_al5-app14_default').Config;
} else if (process.env.REACT_APP_CONFIG === 'VZ') {
    config = require('./Config_VZ').Config;
} else if (process.env.REACT_APP_CONFIG === 'CIC-PROD') {
    config = require('./Config_CIC-PROD').Config;
} else if (process.env.REACT_APP_CONFIG === 'CIC-DEV') {
    config = require('./Config_CIC-DEV').Config;
} else if (process.env.REACT_APP_CONFIG === 'al5-dev01_default') {
    config = require('./Config_al5-dev01_default').Config;
} else if (process.env.REACT_APP_CONFIG === 'al5-dev02_default') {
    config = require('./Config_al5-dev02_default').Config;
} else if (process.env.NODE_ENV === 'development') {
    try {
        config = require('./Config_local').Config;
    } catch (e) {
        throw new Error("Can't find config file Config_local.js!");
    }
}

if (!config) {
    throw new Error('No Config loaded!');
}

export const Config = Object.assign(defaultConfig, config);
