import authHelper from '../features/base/util/authHelper';

import { push } from 'connected-react-router';
import Routes from '../constants/routes';

import {
    FORCE_LOGOUT,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT,
    PUBLIC_SERVICE_INFO_SUCCESS,
    RE_LOGIN_CHECK,
    RE_LOGIN_REQUEST,
    RE_LOGIN_SUCCESS,
    SELECT_SERVICE
} from '../features/auth/actionTypes';
import {
    checkReLoginDone,
    keycloakLogin,
    keycloakReLogin,
    loginFailure,
    logoutSuccess,
    reLogin,
    reLoginService,
    serviceLogin,
    updateToken
} from '../features/auth/actions';
import {routeWithServiceId, topicsHandler} from '../features/base/util/helpers';
import {CHANGE_LANGUAGE} from "../features/base/i18n/actionTypes";
import {getTranslatedString, hasLanguage} from "../features/base/i18n/translations";
import {changeLanguage} from "../features/base/i18n/actions";
import {setAdiaPadSettings} from "../features/meetings/actions";

export const createAuthMiddleware = () => {
    return ({ getState, dispatch }) => next => action => {
        switch (action.type) {
            case LOGIN_REQUEST: {
                //login callbacks for different login methods
                const onLoginCallbacks = {
                    keycloakLogin: {
                        // code to execute when the login with keycloak succeeds
                        success: async (user, serviceId) => {
                            dispatch(keycloakLogin(user, serviceId));
                        },
                        // code to execute when the login with keycloak fails
                        failure: () => {
                            dispatch(loginFailure('Keycloak Login failed'));
                        }
                    },
                    defaultLogin: {
                        // code to execute for the default service login
                        service: () => {
                            dispatch(serviceLogin(action.loginData));
                        }
                    }
                };

                //logoutHandlers for different logout methods
                const onLogoutCallback = {
                    // code to execute for the logout with keycloak
                    keycloakLogout: keycloak => () => {
                        keycloak.logout({
                            redirectUri:
                                window.location.origin +
                                routeWithServiceId(
                                    Routes.SERVICE_LOGIN,
                                    action.loginData.serviceId
                                )
                        });
                    },
                    defaultLogout: {
                        // code to execute for the default service logout
                        service: () => {
                            setTimeout(() => {
                                dispatch(logoutSuccess());
                                dispatch(
                                    push(
                                        routeWithServiceId(
                                            Routes.SERVICE_LOGIN,
                                            action.loginData.serviceId
                                        )
                                    )
                                );
                            }, 0);
                        }
                    }
                };

                const onRefreshCallback = currentToken => {
                    dispatch(
                        updateToken(currentToken, action.loginData.serviceId)
                    );
                };

                // login
                authHelper.login(
                    action.loginData.loginType,
                    action.loginData.serviceId,
                    (getState().auth.publicServiceInfo || {}).authMethod,
                    (getState().auth.publicServiceInfo || {}).keycloakSettings,
                    onLoginCallbacks,
                    onLogoutCallback,
                    onRefreshCallback
                );

                return next(action);
            }

            case PUBLIC_SERVICE_INFO_SUCCESS: {
                if (action.publicServiceInfo.hasOwnProperty('adiaPadTiles')){
                    dispatch(setAdiaPadSettings(action.publicServiceInfo.adiaPadTiles));
                }
                return next(action);
            }

            case RE_LOGIN_REQUEST: {
                // code to execute for the admin/service re-login
                let onReLoginCallback;
                // code to execute for the default admin/service logout
                let onLogoutCallback;
                // code to execute on token refresh
                let onRefreshCallback;

                if (action.keycloak) {
                    onReLoginCallback = () => {
                        setTimeout(() => {
                            dispatch(keycloakReLogin());
                        }, 0);
                    };
                } else {
                    onReLoginCallback = () => {
                        setTimeout(() => {
                            dispatch(
                                reLoginService(action.user, action.serviceId)
                            );
                        }, 0);
                    };
                    onLogoutCallback = () => {
                        setTimeout(() => {
                            dispatch(logoutSuccess());
                            dispatch(
                                push(
                                    routeWithServiceId(
                                        Routes.SERVICE_LOGIN,
                                        action.serviceId
                                    )
                                )
                            );
                        }, 0);
                    };
                    onRefreshCallback = currentToken => {
                        dispatch(
                            updateToken(
                                'service',
                                currentToken,
                                action.serviceId
                            )
                        );
                    };
                }

                // re-login
                authHelper.reLogin(
                    onReLoginCallback,
                    onLogoutCallback,
                    onRefreshCallback
                );
                return next(action);
            }

            case CHANGE_LANGUAGE: {
                topicsHandler.setAllTopicsName(getTranslatedString(action.language, 'allTopicsName'));
                return next(action);
            }

            case LOGIN_SUCCESS:
            case RE_LOGIN_SUCCESS:
                authHelper.startTokenRefreshInterval();
                if(hasLanguage(action.user.locale)){
                    dispatch(changeLanguage(action.user.locale))
                }
                return next(action);

            case LOGOUT:
            case FORCE_LOGOUT:
                // (forced) logout
                authHelper.logout();
                return next(action);

            case RE_LOGIN_CHECK: {
                // code to execute if the re-login is possible/not possible
                const onReLoginCheck = {
                    possible: (user, serviceId, keycloak) => {
                        setTimeout(() => {
                            dispatch(reLogin(user, serviceId, keycloak));
                        });
                    },
                    notPossible: () => {
                        setTimeout(() => {
                            dispatch(checkReLoginDone());
                        }, 0);
                    }
                };

                const path = getState().router.location.pathname;

                // check if re-login is possible (i.e. necessary data has to be in local/session storage and path has to match)
                authHelper.reLoginPossible(onReLoginCheck, path);
                return next(action);
            }

            case SELECT_SERVICE: {
                // redirect when a service is entered in the ServiceInputPage
                dispatch(push(Routes.HOME + action.serviceId));
                return next(action);
            }

            default:
                return next(action);
        }
    };
};
