import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';

import { requestMeetings, goToMeeting } from './actions';
import { MEETING_TYPES } from '../../constants/constants';

const ROWS = 25;

export class MeetingsManagerStandard extends Component {
    componentDidMount() {
        this.props.requestMeetings();
    }

    render() {
        let filteredMeetings;
        if (this.props.type === MEETING_TYPES.MEETING) {
            filteredMeetings = this.props.meetings.filter(
                (meeting) =>
                    meeting.type === undefined ||
                    meeting.type === MEETING_TYPES.MEETING
            );
        } else if (this.props.type === MEETING_TYPES.WEBINAR) {
            filteredMeetings = this.props.meetings.filter(
                (meeting) => meeting.type === MEETING_TYPES.WEBINAR
            );
        } else if (this.props.type === MEETING_TYPES.PHONE_CONSULTING) {
            filteredMeetings = this.props.meetings.filter(
                (meeting) => meeting.type === MEETING_TYPES.PHONE_CONSULTING
            );
        } else if (this.props.type === MEETING_TYPES.PODIUM) {
            filteredMeetings = this.props.meetings.filter(
                (meeting) => meeting.type === MEETING_TYPES.PODIUM
            );
        }

        return (
            <div className="layout-content">
                <div className="p-grid meetings-manager-standard">
                    <div className="p-col-12">
                        <MeetingsTable
                            isLoading={this.props.isLoading}
                            meetings={filteredMeetings}
                            language={this.props.language}
                            onJoinClick={this.props.goToMeeting}
                            type={this.props.type}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

class MeetingsTable extends Component {
    render() {
        const meetingTableEntries = this.props.meetings.map((item, key) => {
            const meeting = { ...item };

            // convert date object to desired format (DD.MM.YYYY, hh:mm)
            const day = meeting.date.getDate();
            const month = meeting.date.getMonth() + 1;
            const year = meeting.date.getFullYear();
            const hours = meeting.date.getHours();
            const minutes = meeting.date.getMinutes();
            meeting.date =
                (day < 10 ? '0' + day : day) +
                '.' +
                (month < 10 ? '0' + month : month) +
                '.' +
                year +
                ', ' +
                (hours < 10 ? '0' + hours : hours) +
                ':' +
                (minutes < 10 ? '0' + minutes : minutes);

            meeting.numberOfParticipants = meeting.participants.reduce(
                (acc) => acc + 1,
                0
            );

            meeting.joinMeetingButton = (
                <Button
                    label={getTranslatedString(this.props.language, 'join')}
                    icon="pi-md-video-library"
                    onClick={() => this.props.onJoinClick(meeting._id)}
                />
            );

            return meeting;
        });

        let cardTitle = getTranslatedString(this.props.language, 'meetings');
        if (this.props.type === MEETING_TYPES.WEBINAR) {
            cardTitle = getTranslatedString(this.props.language, 'webinars');
        } else if (this.props.type === MEETING_TYPES.PHONE_CONSULTING) {
            cardTitle = getTranslatedString(
                this.props.language,
                'phoneConsulting'
            );
        } else if (this.props.type === MEETING_TYPES.PODIUM) {
            cardTitle = getTranslatedString(this.props.language, 'podiums');
        }

        return (
            <Card title={cardTitle}>
                <DataTable
                    autoLayout={true}
                    responsive={true}
                    value={meetingTableEntries}
                    rows={ROWS}
                    paginator={true}
                    paginatorPosition={'top'}
                    alwaysShowPaginator={true}
                >
                    <Column
                        className="column date-col"
                        field="date"
                        header={<TranslatedString id={'meetingTableDate'} />}
                    />
                    <Column
                        className="column title-col"
                        field="title"
                        header={<TranslatedString id={'meetingTableTitle'} />}
                    />
                    <Column
                        className="column description-col"
                        field="description"
                        header={
                            <TranslatedString id={'meetingTableDescription'} />
                        }
                    />
                    <Column
                        className="column participants-col"
                        field="numberOfParticipants"
                        header={
                            <TranslatedString id={'meetingTableParticipants'} />
                        }
                    />
                    <Column
                        className="column join-button-col"
                        field="joinMeetingButton"
                    />
                </DataTable>
            </Card>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isLoading: state.meetingsManager.isLoading,
        meetings: state.meetingsManager.meetings,
        language: state.base.i18n.language,
        type: ownProps.type,
    };
};

const mapDispatchToProps = {
    requestMeetings,
    goToMeeting,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MeetingsManagerStandard);
