import React from 'react';
import { connect } from 'react-redux';
import { WEBRTC_MODES } from '../../constants/constants';

export function RecordingIndicator(props) {
    if (props.isRecording) {
        return (
            <div className="recording-indicator blink">
                <i className="icon-rec" />
            </div>
        );
    }

    return null;
}

const mapStateToProps = (state) => {
    let isRecording = false;
    if (
        state.auth.publicServiceInfo.webRtcMode ===
            WEBRTC_MODES.LIVESWITCH_P2P ||
        state.auth.publicServiceInfo.webRtcMode === WEBRTC_MODES.ADIA_P2P
    ) {
        state.meetings.meetingParticipants.forEach((participant) => {
            if (participant.isRecording) {
                isRecording = true;
            }
        });
    } else {
        isRecording = state.meetings.meetingInfo
            ? state.meetings.meetingInfo.isRecording
            : false;
    }

    return {
        isRecording,
    };
};

export default connect(mapStateToProps, undefined)(RecordingIndicator);
