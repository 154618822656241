import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { getTranslatedString } from '../base/i18n/translations';
import { alcAcceptCall, alcRejectCall } from './actions';
import { LoadingScreen } from '../base/loadingScreen/LoadingScreen';
import TranslatedString from '../base/i18n/TranslatedString';

export class AcceptCallDialog extends React.Component {
    render() {
        if (
            this.props.adviserState === 'connecting' ||
            this.props.adviserState === 'connected'
        ) {
            const footer =
                this.props.adviserState === 'connecting' ? (
                    <div>
                        <Button
                            label={getTranslatedString(
                                this.props.language,
                                'reject'
                            )}
                            className="p-button-secondary"
                            onClick={this.props.alcRejectCall}
                        />
                        <Button
                            label={getTranslatedString(
                                this.props.language,
                                'connect'
                            )}
                            className="p-highlight"
                            icon="pi pi-check"
                            onClick={this.props.alcAcceptCall}
                        />
                    </div>
                ) : null;

            const header =
                this.props.adviserState === 'connecting'
                    ? getTranslatedString(this.props.language, 'incomingCall')
                    : getTranslatedString(this.props.language, 'joiningCall');

            return (
                <Dialog
                    className="accept-call-dialog"
                    header={header}
                    footer={footer}
                    visible={true}
                    onHide={() => {}}
                    closable={false}
                    baseZIndex={999999}
                    focusOnShow={false}
                >
                    {this.props.connectingClientInfo &&
                        !this.props.isInvitation && (
                            <table className="accept-call-dialog-clientInfoTable">
                                <tbody>
                                    <tr>
                                        <td className="clientInfo-label">
                                            <TranslatedString id="name" />:
                                        </td>
                                        <td className="clientInfo-value">
                                            {
                                                this.props.connectingClientInfo
                                                    .info.name
                                            }
                                        </td>
                                    </tr>
                                    {this.props.connectingClientInfo.info
                                        .email && (
                                        <tr>
                                            <td className="clientInfo-label">
                                                <TranslatedString id="email" />:
                                            </td>
                                            <td className="clientInfo-value">
                                                {
                                                    this.props
                                                        .connectingClientInfo
                                                        .info.email
                                                }
                                            </td>
                                        </tr>
                                    )}
                                    {this.props.connectingClientInfo.info
                                        ._topicsNamesFormated && (
                                        <tr>
                                            <td className="clientInfo-label">
                                                <TranslatedString id="topics" />
                                                :
                                            </td>
                                            <td className="clientInfo-value">
                                                {
                                                    this.props
                                                        .connectingClientInfo
                                                        .info
                                                        ._topicsNamesFormated
                                                }
                                            </td>
                                        </tr>
                                    )}
                                    {this.props.connectingClientInfo.info
                                        .transferInfo && (
                                        <React.Fragment>
                                            <tr>
                                                <td colSpan="2">
                                                    <b>
                                                        <TranslatedString
                                                            id="adviserTransferMessage"
                                                            replacements={{
                                                                adviserName:
                                                                    this.props
                                                                        .connectingClientInfo
                                                                        .info
                                                                        .transferInfo
                                                                        .transferredBy,
                                                            }}
                                                        />
                                                    </b>
                                                </td>
                                            </tr>
                                            {this.props.connectingClientInfo
                                                .info.transferInfo.message && (
                                                <tr>
                                                    <td
                                                        className="clientInfo-label"
                                                        style={{
                                                            paddingTop: 0,
                                                        }}
                                                    >
                                                        <TranslatedString id="comment" />
                                                        :
                                                    </td>
                                                    <td
                                                        className="clientInfo-value"
                                                        style={{
                                                            paddingTop: 0,
                                                        }}
                                                    >
                                                        {
                                                            this.props
                                                                .connectingClientInfo
                                                                .info
                                                                .transferInfo
                                                                .message
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </table>
                        )}
                    {this.props.invitationInfo && this.props.isInvitation && (
                        <React.Fragment>
                            <b>
                                <TranslatedString
                                    id="adviserInvitationMessage"
                                    replacements={{
                                        adviserName:
                                            this.props.invitationInfo
                                                .fromAdviser.info.name,
                                        meetingTitle:
                                            this.props.invitationInfo
                                                .meetingTitle,
                                    }}
                                />
                            </b>
                            {this.props.invitationInfo.comment && (
                                <div style={{ marginTop: 5 }}>
                                    <b>
                                        <TranslatedString id="invitationComment" />
                                    </b>
                                    :<br />
                                    {this.props.invitationInfo.comment}
                                </div>
                            )}
                        </React.Fragment>
                    )}
                    {this.props.adviserState === 'connected' && (
                        <div className="accept-call-loading-wrapper">
                            <LoadingScreen />
                        </div>
                    )}
                </Dialog>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.base.i18n.language,
        adviserState:
            state.meetings.clientInfo && state.meetings.clientInfo.state.name,
        isInvitation:
            state.meetings.clientInfo &&
            state.meetings.clientInfo.state.value.indexOf('invite:') === 0,
        connectingClientInfo: state.callManager.connectingClientInfo,
        invitationInfo: state.callManager.invitationInfo,
    };
};

const mapDispatchToProps = {
    alcAcceptCall,
    alcRejectCall,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptCallDialog);
