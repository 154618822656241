import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { getTranslatedString } from '../base/i18n/translations';
import { alcAcceptCall, alcOpenCallsUpdate, alcRejectCall } from './actions';
import { goToMeeting } from '../meetingsManager/actions';

export class OpenCallsDialog extends React.Component {
    render() {
        const openCallsArray = Object.entries(this.props.openCalls);
        if (
            openCallsArray.length > 0 &&
            this.props.adviserState !== 'joinedMeeting' &&
            this.props.adviserState !== 'connecting' &&
            this.props.adviserState !== 'connected'
        ) {
            const openCallsElements = [];
            openCallsArray.forEach(([key, value]) => {
                openCallsElements.push(
                    <tr key={value._id}>
                        <td>{value.title}</td>
                        <td style={{ paddingLeft: '15px' }}>
                            <Button
                                label={getTranslatedString(
                                    this.props.language,
                                    'join'
                                )}
                                className="p-button-primary"
                                icon="pi pi-check"
                                onClick={() => {
                                    this.props.alcOpenCallsUpdate({});
                                    this.props.goToMeeting(value._id);
                                }}
                            />
                        </td>
                    </tr>
                );
            });

            const footer = (
                <Button
                    label={getTranslatedString(this.props.language, 'close')}
                    className="p-button-primary"
                    onClick={() => this.props.alcOpenCallsUpdate({})}
                />
            );

            return (
                <Dialog
                    className="open-calls-dialog"
                    header={getTranslatedString(
                        this.props.language,
                        'openCallsTitle'
                    )}
                    footer={footer}
                    visible={true}
                    onHide={() => {}}
                    closable={false}
                    baseZIndex={999999}
                    focusOnShow={false}
                >
                    {getTranslatedString(
                        this.props.language,
                        'openCallsMessage'
                    )}
                    :
                    <br />
                    <br />
                    <table>
                        <tbody>{openCallsElements}</tbody>
                    </table>
                </Dialog>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.base.i18n.language,
        adviserState:
            state.meetings.clientInfo && state.meetings.clientInfo.state.name,
        openCalls: state.callManager.openCalls,
    };
};

const mapDispatchToProps = {
    alcAcceptCall,
    alcRejectCall,
    alcOpenCallsUpdate,
    goToMeeting,
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenCallsDialog);
