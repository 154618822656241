import * as types from './actionTypes';

export function alcInit(serviceId) {
    return { type: types.ALC_INIT, serviceId };
}

export function alcJoinMeeting(meetingId, communicationMode) {
    return { type: types.ALC_JOIN_MEETING, meetingId, communicationMode };
}

export function alcJoinMeetingStandalone(meetingId) {
    return { type: types.ALC_JOIN_MEETING_STANDALONE, meetingId };
}

export function alcLeaveMeeting() {
    return { type: types.ALC_LEAVE_MEETING };
}

export function alcLeaveMeetingSuccess() {
    return { type: types.ALC_LEAVE_MEETING_SUCCESS };
}

export function alcKicked() {
    return { type: types.ALC_KICKED };
}

export function alcTransferred() {
    return { type: types.ALC_TRANSFERRED };
}

export function alcInitialized() {
    return { type: types.ALC_INITIALIZED };
}

export function alcReconnecting() {
    return { type: types.ALC_RECONNECTING };
}

export function alcSessionRestored() {
    return { type: types.ALC_SESSION_RESTORED };
}

export function alcInviteAdviser(adviserClientId, comment) {
    return { type: types.ALC_INVITE_ADVISER, adviserClientId, comment };
}

export function alcCancelAdviserInvitation(invitationId) {
    return { type: types.ALC_CANCEL_ADVISER_INVITATION, invitationId };
}

export function alcTransferCall(clientId, topicId, message) {
    return { type: types.ALC_TRANSFER_CALL, clientId, topicId, message };
}

export function alcTransferCallSuccess() {
    return { type: types.ALC_TRANSFER_CALL_SUCCESS };
}

export function alcTransferCallFailed(error) {
    return { type: types.ALC_TRANSFER_CALL_FAILED, error };
}

export function alcJoinMeetingSuccess(
    joinOptions,
    meetingInfo,
    saAuth,
    responsiveMode
) {
    return {
        type: types.ALC_JOIN_MEETING_SUCCESS,
        joinOptions,
        meetingInfo,
        saAuth,
        responsiveMode,
    };
}

export function alcClientInfoUpdate(clientInfo) {
    return { type: types.ALC_CLIENT_INFO_UPDATE, clientInfo };
}

export function alcInvitationUpdate(invitationInfo) {
    return { type: types.ALC_INVITATION_UPDATE, invitationInfo };
}

export function alcMeetingInfoUpdate(meetingInfo) {
    return { type: types.ALC_MEETING_INFO_UPDATE, meetingInfo };
}

export function alcMeetingParticipantsUpdate(meetingParticipants) {
    return { type: types.ALC_MEETING_PARTICIPANTS_UPDATE, meetingParticipants };
}

export function alcPodiumParticipantsUpdate(podiumParticipants) {
    return { type: types.ALC_PODIUM_PARTICIPANTS_UPDATE, podiumParticipants };
}

export function alcWaitingRoomUpdate(waitingRoom) {
    return { type: types.ALC_WAITING_ROOM_UPDATE, waitingRoom };
}
export function alcAdviserAuth(authToken) {
    return { type: types.ALC_ADVISER_AUTH, authToken };
}

export function alcSetCurrentApp(sharedApplicationId) {
    return { type: types.ALC_SET_CURRENT_APP, sharedApplicationId };
}

export function alcSetEditPermissions(userId, editPermissions) {
    return { type: types.ALC_SET_EDIT_PERMISSIONS, userId, editPermissions };
}

export function alcChangeActiveUser(userId) {
    return { type: types.ALC_CHANGE_ACTIVE_USER, userId };
}

export function alcCompleteMeeting() {
    return { type: types.ALC_COMPLETE_MEETING };
}

export function alcMeetingCompleted(initiatedBySelf, meetingType) {
    return { type: types.ALC_MEETING_COMPLETED, initiatedBySelf, meetingType };
}

export function alcClose() {
    return { type: types.ALC_CLOSE };
}

export function alcError(context, error) {
    return { type: types.ALC_ERROR, context, error };
}

export function alcUpdateServerTimeDiff(serverTimeDiff) {
    return { type: types.ALC_UPDATE_SERVERTIME_DIFF, serverTimeDiff };
}

export function alcSetHandRaised(clientId, handRaised) {
    return { type: types.ALC_SET_HAND_RAISED, clientId, handRaised };
}

export function alcSetSpeakerPermission(userId, permission) {
    return { type: types.ALC_SET_SPEAKER_PERMISSION, userId, permission };
}

export function alcSetSpeakerPermissionSuccess() {
    return { type: types.ALC_SET_SPEAKER_PERMISSION_SUCCESS };
}

export function alcSetSpeakerPermissionFailure() {
    return { type: types.ALC_SET_SPEAKER_PERMISSION_FAILURE };
}

export function alcSetUserVisibility(userId, isVisible) {
    return { type: types.ALC_SET_USER_VISIBILITY, userId, isVisible };
}

export function alcSetAdmittance(clientId, admitted) {
    return { type: types.ALC_SET_ADMITTANCE, clientId, admitted };
}

export function webRtcInit(
    webRtcMode,
    webRtcMaxAudioBitrate,
    webRtcMaxVideoBitrate,
    useSimulcast
) {
    return {
        type: types.WEBRTC_INIT,
        webRtcMode,
        webRtcMaxAudioBitrate,
        webRtcMaxVideoBitrate,
        useSimulcast: !(useSimulcast === false),
    };
}

export function webRtcInitSuccess(
    webRtcFlags,
    communicationMode,
    backgroundEffectsSupported,
    backgroundEffect,
    notMirrorOwn,
    localBgImageName
) {
    return {
        type: types.WEBRTC_INIT_SUCCESS,
        webRtcFlags,
        communicationMode,
        backgroundEffectsSupported,
        backgroundEffect,
        notMirrorOwn,
        localBgImageName,
    };
}

export function webRtcInitFailure(error) {
    return { type: types.WEBRTC_INIT_FAILURE, error };
}

export function webRtcStartLocalMedia() {
    return { type: types.WEBRTC_START_LOCAL_MEDIA };
}

export function webRtcStartLocalMediaSuccess(
    communicationMode,
    currentSettings,
    backgroundEffect
) {
    return {
        type: types.WEBRTC_START_LOCAL_MEDIA_SUCCESS,
        communicationMode,
        currentSettings,
        backgroundEffect,
    };
}

export function webRtcStartLocalMediaFailure(error) {
    return { type: types.WEBRTC_START_LOCAL_MEDIA_FAILURE, error };
}

export function webRtcStopLocalMedia() {
    return { type: types.WEBRTC_STOP_LOCAL_MEDIA };
}

export function webRtcJoin(joinOptions, meetingInfo) {
    return { type: types.WEBRTC_JOIN, joinOptions, meetingInfo };
}

export function webRtcJoinSuccess(
    ownVideoView,
    communicationMode,
    currentSettings,
    backgroundEffect
) {
    return {
        type: types.WEBRTC_JOIN_SUCCESS,
        ownVideoView,
        communicationMode,
        currentSettings,
        backgroundEffect,
    };
}

export function webRtcJoinFailure(error) {
    return { type: types.WEBRTC_JOIN_FAILURE, error };
}

export function webRtcLeave() {
    return { type: types.WEBRTC_LEAVE };
}

export function webRtcUserJoined(videoView) {
    return { type: types.WEBRTC_USERJOINED, videoView };
}

export function webRtcUserLeft(userId, userType) {
    return { type: types.WEBRTC_USERLEFT, userId, userType };
}

export function webRtcSpeakerChanged(userId) {
    return { type: types.WEBRTC_SPEAKER_CHANGED, userId };
}

export function webRtcStartScreenShare() {
    return { type: types.WEBRTC_START_SCREENSHARE };
}

export function webRtcStartScreenShareSuccess(screenShareView) {
    return { type: types.WEBRTC_START_SCREENSHARE_SUCCESS, screenShareView };
}

export function webRtcStopScreenShare() {
    return { type: types.WEBRTC_STOP_SCREENSHARE };
}

export function webRtcStopScreenShareSuccess() {
    return { type: types.WEBRTC_STOP_SCREENSHARE_SUCCESS };
}

export function alcMuteParticipant(userId) {
    return { type: types.ALC_MUTE_PARTICIPANT, userId };
}

export function alcKickParticipant(userId) {
    return { type: types.ALC_KICK_PARTICIPANT, userId };
}

export function alcUpdateUserMode(mode) {
    return { type: types.ALC_UPDATE_USER_MODE, mode };
}

export function webRtcClose() {
    return { type: types.WEBRTC_CLOSE };
}

export function webRtcReconnecting() {
    return { type: types.WEBRTC_RECONNECTING };
}

export function webRtcReconnectingSuccess() {
    return { type: types.WEBRTC_RECONNECTING_SUCCESS };
}

export function webRtcReconnectingFailure() {
    return { type: types.WEBRTC_RECONNECTING_FAILURE };
}

export function joinMeeting(meetingId) {
    return { type: types.JOIN_MEETING, meetingId };
}

export function joinMeetingStandalone(meetingId) {
    return { type: types.JOIN_MEETING_STANDALONE, meetingId };
}

export function joinMeetingSuccess() {
    return { type: types.JOIN_MEETING_SUCCESS };
}

export function joinMeetingFailure() {
    return { type: types.JOIN_MEETING_FAILURE };
}

export function leaveMeeting(redirectTo) {
    return { type: types.LEAVE_MEETING, redirectTo };
}

export function showSharedApplications() {
    return { type: types.SA_SHOW };
}

export function switchOwnSharedApplication(sharedApplicationId) {
    return { type: types.SA_SWITCH_OWN, sharedApplicationId };
}

export function hideSharedApplications() {
    return { type: types.SA_HIDE };
}

export function updateRequestSignatureState(requestSignatureAvailable) {
    return {
        type: types.SA_REQUEST_SIGNATURE_STATE,
        requestSignatureAvailable,
    };
}

export function requestSignature() {
    return { type: types.SA_REQUEST_SIGNATURE };
}

export function sharedApplicationsIFrameLoad() {
    return { type: types.SA_IFRAME_LOAD };
}

export function sharedApplicationsIFrameReady() {
    return { type: types.SA_IFRAME_READY };
}

export function sharedApplicationsIFrameInitConfirmed() {
    return { type: types.SA_IFRAME_INIT_CONFIRMED };
}

export function sharedApplicationsIFrameNewRef(iFrameRef) {
    return { type: types.SA_IFRAME_NEW_REF, iFrameRef };
}

export function documentsNewUpload() {
    return { type: types.DOCUMENTS_NEW_UPLOAD };
}

export function chatNewMessage() {
    return { type: types.CHAT_NEW_MESSAGE };
}

export function sidePanelIFrameLoad() {
    return { type: types.SIDE_PANEL_IFRAME_LOAD };
}

export function sidePanelIFrameReady() {
    return { type: types.SIDE_PANEL_IFRAME_READY };
}

export function sidePanelIFrameInitConfirmed() {
    return { type: types.SIDE_PANEL_IFRAME_INIT_CONFIRMED };
}

export function sidePanelIFrameNewRef(iFrameRef) {
    return { type: types.SIDE_PANEL_IFRAME_NEW_REF, iFrameRef };
}

export function sidePanelShowApplication(applicationId) {
    return { type: types.SIDE_PANEL_SHOW_APPLICATION, applicationId };
}

export function streamChatNewMessage() {
    return { type: types.STREAM_CHAT_NEW_MESSAGE };
}

export function streamChatNewDocument() {
    return { type: types.STREAM_CHAT_NEW_DOCUMENT };
}

export function streamChatNewSurvey() {
    return { type: types.STREAM_CHAT_NEW_SURVEY };
}

export function showControls() {
    return { type: types.CONTROLS_SHOW };
}

export function hideControls() {
    return { type: types.CONTROLS_HIDE };
}

export function showMenu() {
    return { type: types.MENU_SHOW };
}

export function hideMenu() {
    return { type: types.MENU_HIDE };
}

export function showSidePanelMenu() {
    return { type: types.SIDE_PANEL_MENU_SHOW };
}

export function hideSidePanelMenu() {
    return { type: types.SIDE_PANEL_MENU_HIDE };
}

export function showInfoPanel() {
    return { type: types.INFO_PANEL_SHOW };
}

export function hideInfoPanel() {
    return { type: types.INFO_PANEL_HIDE };
}

export function showParticipantActions() {
    return { type: types.PARTICIPANT_ACTIONS_SHOW };
}

export function showAddAdviserDialog() {
    return { type: types.ADD_ADVISER_DIALOG_SHOW };
}

export function hideAddAdviserDialog() {
    return { type: types.ADD_ADVISER_DIALOG_HIDE };
}

export function showTransferCallDialog() {
    return { type: types.TRANSFER_CALL_DIALOG_SHOW };
}

export function hideTransferCallDialog() {
    return { type: types.TRANSFER_CALL_DIALOG_HIDE };
}

export function hideParticipantActions() {
    return { type: types.PARTICIPANT_ACTIONS_HIDE };
}

export function showCompleteMeetingDialog() {
    return { type: types.COMPLETE_MEETING_DIALOG_SHOW };
}

export function hideCompleteMeetingDialog() {
    return { type: types.COMPLETE_MEETING_DIALOG_HIDE };
}

export function showLeaveMeetingDialog() {
    return { type: types.LEAVE_MEETING_DIALOG_SHOW };
}

export function hideLeaveMeetingDialog() {
    return { type: types.LEAVE_MEETING_DIALOG_HIDE };
}

// TODO: temporary workaround for no user gesture issue
export function showConfirmJoinDialog() {
    return { type: types.CONFIRM_JOIN_DIALOG_SHOW };
}

export function confirmJoinDialog() {
    return { type: types.CONFIRM_JOIN_DIALOG_CONFIRM };
}

export function cancelJoinDialog() {
    return { type: types.CONFIRM_JOIN_DIALOG_CANCEL };
}

export function fullscreenNewRef(fullscreenRef) {
    return { type: types.FULLSCREEN_NEW_REF, fullscreenRef };
}

export function fullscreenToggle() {
    return { type: types.FULLSCREEN_TOGGLE };
}

export function fullscreenChange(fullscreenActive) {
    return { type: types.FULLSCREEN_CHANGE, fullscreenActive };
}

export function startRecording() {
    return { type: types.START_RECORDING };
}

export function startRecordingSuccess() {
    return { type: types.START_RECORDING_SUCCESS };
}

export function startRecordingFailure() {
    return { type: types.START_RECORDING_FAILURE };
}

export function stopRecording() {
    return { type: types.STOP_RECORDING };
}

export function webRtcStartRecording() {
    return { type: types.WEBRTC_START_RECORDING };
}

export function webRtcStartRecordingSuccess() {
    return { type: types.WEBRTC_START_RECORDING_SUCCESS };
}

export function webRtcStartRecordingFailure(error) {
    return { type: types.WEBRTC_START_RECORDING_FAILURE, error };
}

export function webRtcStopRecording() {
    return { type: types.WEBRTC_STOP_RECORDING };
}

export function alcStartRecording() {
    return { type: types.ALC_START_RECORDING };
}

export function alcStopRecording() {
    return { type: types.ALC_STOP_RECORDING };
}

export function alcStartRecordingSuccess() {
    return { type: types.ALC_START_RECORDING_SUCCESS };
}

export function showRecordingConfirmationDialog() {
    return { type: types.RECORDING_CONFIRMATION_DIALOG_SHOW };
}

export function hideRecordingConfirmationDialog() {
    return { type: types.RECORDING_CONFIRMATION_DIALOG_HIDE };
}

export function startCapturingClient() {
    return { type: types.ALC_START_CAPTURING_CLIENT };
}

export function stopCapturingClient() {
    return { type: types.ALC_STOP_CAPTURING_CLIENT };
}

export function startBroadcasting() {
    return { type: types.ALC_START_BROADCASTING };
}

export function stopBroadcasting() {
    return { type: types.ALC_STOP_BROADCASTING };
}

export function setAdiaPadSettings(tiles) {
    return { type: types.SET_ADIA_PAD_SETTINGS, tiles };
}
