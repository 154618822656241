import * as types from './actionTypes';

export function docManagerIFrameLoad() {
    return { type: types.DOC_MANAGER_IFRAME_LOAD };
}

export function docManagerIFrameReady() {
    return { type: types.DOC_MANAGER_IFRAME_READY };
}

export function docManagerIFrameNewRef(iFrameRef) {
    return { type: types.DOC_MANAGER_IFRAME_NEW_REF, iFrameRef };
}
