import Connector from '@adialive/connector';
import { Config } from '../config/Config';

import {
    alcInitialized,
    alcJoinMeetingSuccess,
    alcLeaveMeetingSuccess,
    alcClientInfoUpdate,
    alcMeetingParticipantsUpdate,
    alcMeetingInfoUpdate,
    alcError,
    alcKicked,
    alcUpdateServerTimeDiff,
    alcReconnecting,
    alcSessionRestored,
    alcMeetingCompleted,
    alcStartRecordingSuccess,
    alcPodiumParticipantsUpdate,
    alcSetSpeakerPermissionFailure,
    alcSetSpeakerPermissionSuccess,
    alcInvitationUpdate,
    alcTransferCallSuccess,
    alcTransferCallFailed,
    alcTransferred,
    alcWaitingRoomUpdate,
    sidePanelShowApplication,
    switchOwnSharedApplication,
} from '../features/meetings/actions';
import {
    alcServiceStateUpdate,
    alcQueueListUpdate,
    alcConnectingClientInfo,
    alcOpenCallsUpdate,
    alcInvitationInfo,
} from '../features/callManager/actions';
import { alcAdviserListUpdate } from '../features/dashboard/actions';
import { webRtcMuteAudio } from '../features/deviceSettings/actions';
import { showMessage } from '../features/base/messages/actions';

import {
    ALC_INIT,
    ALC_JOIN_MEETING,
    ALC_JOIN_MEETING_STANDALONE,
    ALC_ADVISER_AUTH,
    ALC_SET_CURRENT_APP,
    ALC_SET_EDIT_PERMISSIONS,
    ALC_CHANGE_ACTIVE_USER,
    ALC_LEAVE_MEETING,
    ALC_CLOSE,
    WEBRTC_START_SCREENSHARE_SUCCESS,
    WEBRTC_STOP_SCREENSHARE_SUCCESS,
    ALC_MUTE_PARTICIPANT,
    ALC_KICK_PARTICIPANT,
    ALC_COMPLETE_MEETING,
    ALC_START_RECORDING,
    ALC_STOP_RECORDING,
    ALC_SET_SPEAKER_PERMISSION,
    ALC_START_BROADCASTING,
    ALC_STOP_BROADCASTING,
    ALC_STOP_CAPTURING_CLIENT,
    ALC_START_CAPTURING_CLIENT,
    ALC_UPDATE_USER_MODE,
    ALC_INVITE_ADVISER,
    ALC_CANCEL_ADVISER_INVITATION,
    ALC_SET_USER_VISIBILITY,
    ALC_TRANSFER_CALL,
    ALC_SET_HAND_RAISED,
    ALC_SET_ADMITTANCE,
} from '../features/meetings/actionTypes';
import {
    WEBRTC_APPLY_RECOVERY_SETTINGS_SUCCESS,
    WEBRTC_CHANGE_CAM_SUCCESS,
    WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS,
    WEBRTC_CHANGE_MIC_SUCCESS,
    WEBRTC_CHANGE_RES_SUCCESS,
    WEBRTC_MUTE_AUDIO,
    WEBRTC_MUTE_VIDEO,
} from '../features/deviceSettings/actionTypes';
import {ADMITTANCE_STATE, COMMUNICATION_MODES} from '../constants/constants';
import {
    getActiveSharedApplications,
    getActiveSidePanelApplications,
    log,
} from '../features/base/util/helpers';
import {
    ALC_ACCEPT_CALL,
    ALC_REJECT_CALL,
    ALC_SET_BUSY,
    ALC_SET_READY,
} from '../features/callManager/actionTypes';
import adiaLiveFx from '../features/base/util/adiaLiveFx';
import { adiaLiveSettings } from '../features/base/util/adiaLiveSettings';
import { topicsHandler } from '../features/base/util/helpers';

const injectTopicNameToServiceState = (serviceState) => {
    if (serviceState.topics && serviceState.topics.length > 0) {
        for (let i = 0; i < serviceState.topics.length; i++) {
            const topic = serviceState.topics[i];
            topic.topicName = topicsHandler.idToName(topic.topicId);
        }
    }
};

export const createAlcMiddleware = () => {
    function calculateDeviceInfo(
        communicationMode,
        audioMuted,
        videoMuted,
        screenShareActive
    ) {
        const deviceInfo = {
            audio: false,
            video: false,
            screen: false,
        };
        switch (communicationMode) {
            case COMMUNICATION_MODES.AUDIO: {
                deviceInfo.audio = true;
                break;
            }
            case COMMUNICATION_MODES.VIDEO: {
                deviceInfo.video = true;
                break;
            }
            case COMMUNICATION_MODES.BOTH: {
                deviceInfo.audio = true;
                deviceInfo.video = true;
                break;
            }
            default: {
                break;
            }
        }
        if (audioMuted) {
            deviceInfo.audio = false;
        }
        if (videoMuted) {
            deviceInfo.video = false;
        }
        if (screenShareActive) {
            deviceInfo.screen = true;
        }
        return deviceInfo;
    }

    let alc;

    let completeMeetingInitiated = false;

    return (store) => (next) => (action) => {
        const alcMeetingJoined = store.getState().meetings.alcMeetingJoined;

        switch (action.type) {
            case ALC_INIT: {
                if (alc) {
                    alc.close();
                }

                const serviceUrl = Config.serviceUrl;
                const serviceId = action.serviceId;

                const flags =
                    store.getState().auth.publicServiceInfo &&
                    store.getState().auth.publicServiceInfo.flags;
                const callManagerActive = flags
                    ? adiaLiveSettings.flagsToSettings(flags).callManager
                    : false;
                const subscriptions = callManagerActive
                    ? ['serviceStateUpdate']
                    : [];

                const notificationHandler = function (msg) {
                    log.debug(msg);
                    if (msg.hasOwnProperty('serverTime')) {
                        store.dispatch(
                            alcUpdateServerTimeDiff(msg.serverTime - Date.now())
                        );
                    }
                    switch (msg.type) {
                        case 'initialize':
                            store.dispatch(alcInitialized());
                            break;
                        case 'reconnecting':
                            store.dispatch(alcReconnecting());
                            break;
                        case 'sessionRestored':
                            store.dispatch(alcSessionRestored());
                            break;
                        case 'clientInfoUpdate':
                            const previousClientInfo =
                                store.getState().meetings.clientInfo;
                            const isConnecting =
                                previousClientInfo &&
                                previousClientInfo.state.name === 'connecting';
                            if (
                                msg.data.state.name === 'connecting' &&
                                !isConnecting
                            ) {
                                adiaLiveFx.startRinging();
                            } else if (isConnecting) {
                                adiaLiveFx.stopRinging();
                            }
                            store.dispatch(alcClientInfoUpdate(msg.data));
                            break;
                        case 'joinedMeeting':
                            store.dispatch(
                                alcJoinMeetingSuccess(
                                    msg.data.joinOptions,
                                    msg.data.meetingInfo,
                                    msg.data.saAuth,
                                    store.getState().base.common.responsiveMode
                                )
                            );
                            break;
                        case 'leftMeeting': {
                            switch (msg.data) {
                                case 'kicked':
                                    store.dispatch(alcKicked());
                                    break;

                                case 'completed':
                                    const meetingType =
                                        store.getState().meetings.meetingInfo &&
                                        store.getState().meetings.meetingInfo
                                            .type;
                                    store.dispatch(
                                        alcMeetingCompleted(
                                            completeMeetingInitiated,
                                            meetingType
                                        )
                                    );
                                    completeMeetingInitiated = false;
                                    break;

                                case 'transferred':
                                    store.dispatch(alcTransferred());
                                    break;

                                default:
                                    store.dispatch(alcLeaveMeetingSuccess());
                                    break;
                            }
                            break;
                        }
                        case 'meetingInfoUpdate':
                            const state = store.getState();
                            // check if active sidePanelApplication was disabled
                            if (
                                state.meetings.activeSidePanelApplicationId !==
                                null
                            ) {
                                const activeSidePanelApplications =
                                    getActiveSidePanelApplications(
                                        msg.data,
                                        state.auth.publicServiceInfo,
                                        state.meetings.adiaPadEnabled
                                    );
                                if (
                                    !activeSidePanelApplications.includes(
                                        state.meetings
                                            .activeSidePanelApplicationId
                                    )
                                ) {
                                    const newApplicationId =
                                        activeSidePanelApplications.length > 0
                                            ? activeSidePanelApplications[0]
                                            : null;
                                    store.dispatch(
                                        sidePanelShowApplication(
                                            newApplicationId
                                        )
                                    );
                                }
                            }
                            // check if active standalone sharedApplication was disabled
                            if (state.meetings.ownApplicationId !== null) {
                                const activeSharedApplications =
                                    getActiveSharedApplications(
                                        msg.data,
                                        state.auth.publicServiceInfo,
                                        state.meetings.clientInfo.standalone
                                    );
                                if (
                                    !activeSharedApplications.includes(
                                        state.meetings.ownApplicationId
                                    )
                                ) {
                                    const newApplicationId =
                                        activeSharedApplications.length > 0
                                            ? activeSharedApplications[0]
                                            : null;
                                    store.dispatch(
                                        switchOwnSharedApplication(
                                            newApplicationId
                                        )
                                    );
                                }
                            }
                            store.dispatch(alcMeetingInfoUpdate(msg.data));
                            break;
                        case 'meetingParticipantsUpdate':
                            store.dispatch(
                                alcMeetingParticipantsUpdate(msg.data)
                            );
                            break;
                        case 'podiumParticipantsUpdate':
                            store.dispatch(
                                alcPodiumParticipantsUpdate(msg.data)
                            );
                            break;
                        case 'waitingRoomUpdate':
                            if (msg.data && msg.data.length > 0) {
                                let displayToast = false;
                                const state = store.getState();
                                msg.data.forEach((newWaitingUser) => {
                                    if (newWaitingUser.admittanceState === ADMITTANCE_STATE.REQUESTED) {
                                        const existingWaitingUser = state.meetings.waitingRoom.find(el => el.clientId === newWaitingUser.clientId);
                                        if (!existingWaitingUser || existingWaitingUser.admittanceState !== ADMITTANCE_STATE.REQUESTED) {
                                            displayToast = true;
                                        }
                                    }
                                });
                                if (displayToast) {
                                    store.dispatch(showMessage({
                                        contentId: 'msgNewWaitingUser',
                                        type: 'info',
                                    }))
                                }
                            }
                            store.dispatch(alcWaitingRoomUpdate(msg.data));
                            break;
                        case 'changeDeviceUsage':
                            if (msg.data.audio === false) {
                                store.dispatch(webRtcMuteAudio(true));
                                store.dispatch(
                                    showMessage({
                                        contentId: 'msgRemoteMute',
                                        type: 'info',
                                    })
                                );
                            }
                            break;
                        case 'serviceError':
                            switch (msg.data.context) {
                                case 'completeMeeting':
                                    completeMeetingInitiated = false;
                                    break;
                                case 'setSpeakerPermission':
                                    store.dispatch(
                                        alcSetSpeakerPermissionFailure()
                                    );
                                    break;
                                case 'transferCall':
                                    store.dispatch(
                                        alcTransferCallFailed(msg.data.error)
                                    );
                                    break;
                                default:
                                    break;
                            }
                            store.dispatch(
                                alcError(msg.data.context, msg.data.error)
                            );
                            break;
                        case 'adviserListUpdate':
                            for (let props in msg.data) {
                                const adviser = msg.data[props];
                                if (adviser.topics) {
                                    adviser._topicsNamesFormated =
                                        topicsHandler.toTopicNameString(
                                            adviser.topics
                                        );
                                }
                            }
                            store.dispatch(alcAdviserListUpdate(msg.data));
                            break;
                        case 'serviceStateUpdate': {
                            injectTopicNameToServiceState(msg.data);
                            store.dispatch(alcServiceStateUpdate(msg.data));
                            break;
                        }
                        case 'queueListUpdate': {
                            for (let i = 0; i < msg.data.length; i++) {
                                const user = msg.data[i];
                                if (user.info.topicId) {
                                    user.info._topicsNamesFormated =
                                        topicsHandler.toTopicNameString(
                                            user.info.topicId.split(',')
                                        );
                                } else {
                                    user.info._topicsNamesFormated = '';
                                }
                            }
                            store.dispatch(alcQueueListUpdate(msg.data));
                            break;
                        }
                        case 'topicsUpdate': {
                            topicsHandler.setTopics(msg.data);
                            break;
                        }
                        case 'connectingClientInfo': {
                            if (msg.data.info.topicId) {
                                msg.data.info._topicsNamesFormated =
                                    topicsHandler.toTopicNameString(
                                        msg.data.info.topicId.split(',')
                                    );
                            } else {
                                msg.data.info._topicsNamesFormated = '';
                            }

                            store.dispatch(alcConnectingClientInfo(msg.data));
                            break;
                        }
                        case 'invitationInfo': {
                            store.dispatch(alcInvitationInfo(msg.data));
                            break;
                        }
                        case 'shouldBeInCalls': {
                            store.dispatch(alcOpenCallsUpdate(msg.data));
                            break;
                        }
                        case 'actionSuccess':
                            switch (msg.data) {
                                case 'startRecording':
                                    store.dispatch(alcStartRecordingSuccess());
                                    break;
                                case 'setSpeakerPermission':
                                    store.dispatch(
                                        alcSetSpeakerPermissionSuccess()
                                    );
                                    break;
                                case 'transferCall':
                                    store.dispatch(alcTransferCallSuccess());
                                    break;
                                default:
                                    break;
                            }
                            break;

                        case 'invitationUpdate':
                            store.dispatch(alcInvitationUpdate(msg.data));
                            break;
                        default:
                            break;
                    }
                };

                alc = new Connector({
                    serviceUrl,
                    serviceId,
                    notificationHandler,
                    subscriptions,
                });

                alc.init();

                return next(action);
            }
            case ALC_JOIN_MEETING: {
                const deviceInfo = calculateDeviceInfo(
                    action.communicationMode,
                    false,
                    false,
                    false
                );
                alc.joinMeeting(
                    action.meetingId,
                    undefined,
                    deviceInfo,
                    undefined
                );
                return next(action);
            }
            case ALC_JOIN_MEETING_STANDALONE: {
                alc.joinMeetingStandalone(action.meetingId);
                return next(action);
            }
            case ALC_LEAVE_MEETING: {
                if (alcMeetingJoined) {
                    alc.leaveMeeting();
                }
                return next(action);
            }
            case ALC_ADVISER_AUTH: {
                alc.adviserAuth(action.authToken);
                return next(action);
            }
            case ALC_SET_CURRENT_APP: {
                if (alcMeetingJoined) {
                    alc.setCurrentApp(action.sharedApplicationId);
                }
                return next(action);
            }
            case ALC_SET_EDIT_PERMISSIONS: {
                if (alcMeetingJoined) {
                    alc.setEditPermissions(
                        action.userId,
                        action.editPermissions
                    );
                }
                return next(action);
            }
            case ALC_CHANGE_ACTIVE_USER: {
                if (alcMeetingJoined) {
                    alc.setCurrentActiveUser(action.userId);
                }
                return next(action);
            }
            case ALC_SET_ADMITTANCE: {
                if (alcMeetingJoined) {
                    alc.setAdmittance(action.clientId, action.admitted);
                }
                return next(action);
            }
            case ALC_START_RECORDING: {
                if (alcMeetingJoined) {
                    alc.startRecording();
                }
                return next(action);
            }
            case ALC_STOP_RECORDING: {
                if (alcMeetingJoined) {
                    alc.stopRecording();
                }
                return next(action);
            }
            case ALC_START_CAPTURING_CLIENT: {
                if (alcMeetingJoined) {
                    alc.startCapturingClient();
                }
                return next(action);
            }
            case ALC_STOP_CAPTURING_CLIENT: {
                if (alcMeetingJoined) {
                    alc.stopCapturingClient();
                }
                return next(action);
            }
            case ALC_START_BROADCASTING: {
                if (alcMeetingJoined) {
                    alc.startBroadcasting();
                }
                return next(action);
            }
            case ALC_STOP_BROADCASTING: {
                if (alcMeetingJoined) {
                    alc.stopBroadcasting();
                }
                return next(action);
            }
            case ALC_MUTE_PARTICIPANT: {
                if (alcMeetingJoined) {
                    alc.setDeviceUsage(action.userId, { audio: false });
                }
                return next(action);
            }
            case ALC_KICK_PARTICIPANT: {
                if (alcMeetingJoined) {
                    alc.kickUser(action.userId);
                }
                return next(action);
            }
            case ALC_SET_HAND_RAISED: {
                if (alcMeetingJoined) {
                    alc.setHandRaised(action.clientId, action.handRaised);
                }
                return next(action);
            }
            case ALC_SET_SPEAKER_PERMISSION: {
                if (alcMeetingJoined) {
                    alc.setSpeakerPermission(action.userId, action.permission);
                }
                return next(action);
            }
            case ALC_UPDATE_USER_MODE: {
                if (alcMeetingJoined) {
                    alc.updateUserMode(action.mode);
                }
                return next(action);
            }
            case ALC_SET_USER_VISIBILITY: {
                if (alcMeetingJoined) {
                    alc.setUserVisibility(action.userId, action.isVisible);
                }
                return next(action);
            }
            case ALC_COMPLETE_MEETING: {
                if (alcMeetingJoined) {
                    completeMeetingInitiated = true;
                    alc.completeMeeting();
                }
                return next(action);
            }
            case ALC_SET_READY: {
                alc.setReady(action.clientId);
                return next(action);
            }
            case ALC_SET_BUSY: {
                alc.setBusy();
                return next(action);
            }
            case ALC_ACCEPT_CALL: {
                const deviceInfo = calculateDeviceInfo(
                    store.getState().deviceSettings.communicationMode,
                    false,
                    false,
                    false
                );
                alc.acceptCall(deviceInfo);
                return next(action);
            }
            case ALC_REJECT_CALL: {
                alc.hangUp();
                return next(action);
            }
            case ALC_INVITE_ADVISER: {
                alc.inviteAdviser(action.adviserClientId, action.comment);
                return next(action);
            }
            case ALC_CANCEL_ADVISER_INVITATION: {
                alc.cancelAdviserInvitation(action.invitationId);
                return next(action);
            }
            case ALC_CLOSE: {
                if (alc) {
                    alc.close();
                }
                return next(action);
            }
            case ALC_TRANSFER_CALL: {
                alc.transferCall(
                    action.clientId,
                    action.topicId,
                    action.message
                );
                return next(action);
            }
            case WEBRTC_MUTE_AUDIO: {
                if (alcMeetingJoined) {
                    const meetingsState = store.getState().meetings;
                    const deviceSettingsState = store.getState().deviceSettings;
                    const deviceInfo = calculateDeviceInfo(
                        deviceSettingsState.communicationMode,
                        action.shouldMute,
                        deviceSettingsState.currentSettings.videoMuted,
                        !!meetingsState.ownScreenShareView
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case WEBRTC_MUTE_VIDEO: {
                if (alcMeetingJoined) {
                    const meetingsState = store.getState().meetings;
                    const deviceSettingsState = store.getState().deviceSettings;
                    const deviceInfo = calculateDeviceInfo(
                        deviceSettingsState.communicationMode,
                        deviceSettingsState.currentSettings.audioMuted,
                        action.shouldMute,
                        !!meetingsState.ownScreenShareView
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case WEBRTC_START_SCREENSHARE_SUCCESS: {
                if (alcMeetingJoined) {
                    const deviceSettingsState = store.getState().deviceSettings;
                    const deviceInfo = calculateDeviceInfo(
                        deviceSettingsState.communicationMode,
                        deviceSettingsState.currentSettings.audioMuted,
                        deviceSettingsState.currentSettings.videoMuted,
                        true
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case WEBRTC_STOP_SCREENSHARE_SUCCESS: {
                if (alcMeetingJoined) {
                    const deviceSettingsState = store.getState().deviceSettings;
                    const deviceInfo = calculateDeviceInfo(
                        deviceSettingsState.communicationMode,
                        deviceSettingsState.currentSettings.audioMuted,
                        deviceSettingsState.currentSettings.videoMuted,
                        false
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case WEBRTC_CHANGE_CAM_SUCCESS:
            case WEBRTC_CHANGE_MIC_SUCCESS:
            case WEBRTC_CHANGE_RES_SUCCESS: {
                if (alcMeetingJoined) {
                    const deviceInfo = calculateDeviceInfo(
                        store.getState().deviceSettings.communicationMode,
                        action.newSettings.audioMuted,
                        action.newSettings.videoMuted,
                        !!store.getState().meetings.ownScreenShareView
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            case WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS:
            case WEBRTC_APPLY_RECOVERY_SETTINGS_SUCCESS: {
                if (alcMeetingJoined) {
                    const deviceInfo = calculateDeviceInfo(
                        action.communicationMode,
                        action.currentSettings.audioMuted,
                        action.currentSettings.videoMuted,
                        !!store.getState().meetings.ownScreenShareView
                    );
                    alc.updateDeviceInfo(deviceInfo);
                }
                return next(action);
            }
            default:
                return next(action);
        }
    };
};
