import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './features/auth/reducer';
import dashboard from './features/dashboard/reducer';
import meetings from './features/meetings/reducer';
import meetingsManager from './features/meetingsManager/reducer';
import deviceSettings from './features/deviceSettings/reducer';
import recordings from './features/recordings/reducer';
import callManager from './features/callManager/reducer';

import common from './features/base/common/reducer';
import i18n from './features/base/i18n/reducer';
import messages from './features/base/messages/reducer';
import editAccount from './features/base/editAccount/reducer';

const base = combineReducers({ common, i18n, messages, editAccount });

const rootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        auth,
        base,
        dashboard,
        meetings,
        meetingsManager,
        deviceSettings,
        recordings,
        callManager,
    });

export default rootReducer;
