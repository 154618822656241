import '../css/themes/Theme_default.scss';

let serviceUrl = 'https://al5-app10.adialive.ch';

if (window.location.href.includes('host-del.adialive.ch')) {
    serviceUrl = 'https://al5-app10-del.adialive.ch';
} else if (window.location.href.includes('host-uc.adialive.ch')) {
    serviceUrl = 'https://al5-app10-uc.adialive.ch';
} else if (window.location.href.includes('host-ipeak.adialive.ch')) {
    serviceUrl = 'https://al5-app10-ipeak.adialive.ch';
}
export const Config = {
    serviceUrl,
};
