import React, { Component } from 'react';
import { connect } from 'react-redux';

import MainVideoInitial from './MainVideoInitial';
import VideoContainer from './VideoContainer';
import { SCREEN_SHARING_MODES } from '../../constants/constants';
import CombiModeView from './CombiModeView';
import classNames from "classnames";

export class MeetingMainVideo extends Component {
    render() {
        if (
            this.props.screenShareView &&
            this.props.userMode &&
            this.props.userMode.screenSharing !== SCREEN_SHARING_MODES.VIDEO
        ) {
            if (
                this.props.userMode.screenSharing ===
                    SCREEN_SHARING_MODES.COMBI &&
                this.props.mainVideoView &&
                this.props.mainVideoView.view
            ) {
                return (
                    <div className={classNames('meeting-main-video', 'screenshare', {controlsVisible:this.props.controlsVisible})}>
                        <CombiModeView
                            videoView={this.props.mainVideoView}
                            screenShareView={this.props.screenShareView}
                        />
                    </div>
                );
            } else {
                return (
                    <div className={classNames('meeting-main-video', 'screenshare', {controlsVisible:this.props.controlsVisible})}>
                        <VideoContainer
                            videoView={this.props.screenShareView}
                        />
                    </div>
                );
            }
        }
        let mainVideoElement = null;

        if (
            (!this.props.mainVideoView || !this.props.mainVideoView.view) &&
            this.props.mainVideoInitial
        ) {
            mainVideoElement = (
                <MainVideoInitial
                    userId={this.props.mainVideoId}
                    initial={this.props.mainVideoInitial}
                />
            );
        } else {
            mainVideoElement = (
                <VideoContainer videoView={this.props.mainVideoView} />
            );
        }

        return <div className={classNames("meeting-main-video", {controlsVisible:this.props.controlsVisible})}>{mainVideoElement}</div>;
    }
}

const mapStateToProps = (state) => {
    let mainVideoView;
    let mainVideoInitial;
    let screenShareView;
    let userMode;
    if (state.meetings.mainVideoId === state.meetings.ownVideoView.userId) {
        userMode = state.meetings.clientInfo.userMode;
        if (
            state.meetings.clientInfo.deviceInfo.screen &&
            state.meetings.ownScreenShareView
        ) {
            screenShareView = state.meetings.ownScreenShareView;
        }
        if (
            state.meetings.clientInfo.deviceInfo.video &&
            !state.deviceSettings.settingsPanelShown
        ) {
            mainVideoView = state.meetings.ownVideoView;
        }
        if (state.meetings.clientInfo.info.name) {
            let nameParts = state.meetings.clientInfo.info.name.split(' ');
            if (nameParts.length > 1 && nameParts[1] !== '') {
                mainVideoInitial =
                    nameParts[0].substr(0, 1) + nameParts[1].substr(0, 1);
            } else {
                mainVideoInitial = nameParts[0].substr(0, 2);
            }
        } else {
            mainVideoInitial = '';
        }
    } else {
        let screenShareOn = false;
        const meetingParticipant = state.meetings.meetingParticipants.find(
            (el) => el.id === state.meetings.mainVideoId
        );
        if (meetingParticipant) {
            userMode = meetingParticipant.userMode;
            if (meetingParticipant.info.name) {
                let nameParts = meetingParticipant.info.name.split(' ');
                if (nameParts.length > 1 && nameParts[1] !== '') {
                    mainVideoInitial =
                        nameParts[0].substr(0, 1) + nameParts[1].substr(0, 1);
                } else {
                    mainVideoInitial = nameParts[0].substr(0, 2);
                }
            } else {
                mainVideoInitial = '';
            }
            screenShareOn = meetingParticipant.deviceInfo.screen;
        }
        if (screenShareOn) {
            screenShareView = state.meetings.screenShareViews.find(
                (el) => el.userId === state.meetings.mainVideoId
            );
        }
        if (meetingParticipant && meetingParticipant.deviceInfo.video) {
            mainVideoView = state.meetings.videoViews.find(
                (el) => el.userId === state.meetings.mainVideoId
            );
        }
    }

    return {
        mainVideoView,
        screenShareView,
        mainVideoInitial,
        controlsVisible: state.meetings.controlsVisible,
        mainVideoId: state.meetings.mainVideoId,
        userMode,
    };
};

export default connect(mapStateToProps, undefined)(MeetingMainVideo);
