export const EDIT_ACCOUNT_SHOW = 'EDIT_ACCOUNT_SHOW';
export const EDIT_ACCOUNT_HIDE = 'EDIT_ACCOUNT_HIDE';
export const EDIT_ACCOUNT_REQUEST = 'EDIT_ACCOUNT_REQUEST';
export const EDIT_ACCOUNT_SUCCESS = 'EDIT_ACCOUNT_SUCCESS';
export const EDIT_ACCOUNT_FAILURE = 'EDIT_ACCOUNT_FAILURE';
export const EDIT_PASSWORD_REQUEST = 'EDIT_PASSWORD_REQUEST';
export const EDIT_PASSWORD_SUCCESS = 'EDIT_PASSWORD_SUCCESS';
export const EDIT_PASSWORD_FAILURE = 'EDIT_PASSWORD_FAILURE';
export const GET_NEPATEC_REQUEST = 'GET_NEPATEC_REQUEST';
export const GET_NEPATEC_SUCCESS = 'GET_NEPATEC_SUCCESS';
export const GET_NEPATEC_FAILURE = 'GET_NEPATEC_FAILURE';
export const EDIT_NEPATEC_REQUEST = 'EDIT_NEPATEC_REQUEST';
export const EDIT_NEPATEC_SUCCESS = 'EDIT_NEPATEC_SUCCESS';
export const EDIT_NEPATEC_FAILURE = 'EDIT_NEPATEC_FAILURE';
