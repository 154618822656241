export const ALC_ADVISER_LIST_UPDATE = 'ALC_ADVISER_LIST_UPDATE';

export const REQUEST_OWN_MEETINGS = 'REQUEST_OWN_MEETINGS';
export const REQUEST_OWN_MEETINGS_SUCCESS = 'REQUEST_OWN_MEETINGS_SUCCESS';
export const REQUEST_OWN_MEETINGS_FAILURE = 'REQUEST_OWN_MEETINGS_FAILURE';

export const GET_MAINTENANCE_WINDOWS = 'GET_MAINTENANCE_WINDOWS';
export const GET_MAINTENANCE_WINDOWS_SUCCESS =
    'GET_MAINTENANCE_WINDOWS_SUCCESS';
export const GET_MAINTENANCE_WINDOWS_FAILURE =
    'GET_MAINTENANCE_WINDOWS_FAILURE';
