import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Message } from 'primereact/message';
import { Checkbox } from 'primereact/checkbox';
import { Fieldset } from 'primereact/fieldset';
import classNames from 'classnames';

import {
    editAccount,
    editPassword,
    getNepatec,
    editNepatec,
    hideEditAccount,
} from './actions';

import TranslatedString from '../i18n/TranslatedString';
import { getTranslatedString } from '../i18n/translations';

const initialState = {
    activeTabIndex: 0,
    draftUser: {},
    draftNepatecCredentials: {
        active: true,
        username: '',
        newPassword: '',
        newPasswordConfirmation: '',
    },
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    blurredFieldsAccount: {
        firstName: false,
        name: false,
        email: false,
    },
    blurredFieldsPassword: {
        currentPassword: false,
        newPassword: false,
        newPasswordConfirmation: false,
    },
    blurredFieldsNepatec: {
        username: false,
        newPassword: false,
        newPasswordConfirmation: false,
    },
    invalidFields: {
        firstName: false,
        name: false,
        email: false,
        currentPassword: false,
        newPassword: false,
        newPasswordConfirmation: false,
        nepatecUsername: false,
        nepatecNewPassword: false,
        nepatecNewPasswordConfirmation: false,
    },
};

export class EditAccountDialog extends Component {
    constructor(props) {
        super(props);

        this.state = initialState;

        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePasswordInputChange = this.handlePasswordInputChange.bind(
            this
        );
        this.handleNepatecInputChange = this.handleNepatecInputChange.bind(
            this
        );
        this.handleSaveAccount = this.handleSaveAccount.bind(this);
        this.handleSavePassword = this.handleSavePassword.bind(this);
        this.handleSaveNepatec = this.handleSaveNepatec.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.handleBlurAccount = this.handleBlurAccount.bind(this);
        this.handleBlurPassword = this.handleBlurPassword.bind(this);
        this.handleBlurNepatec = this.handleBlurNepatec.bind(this);
    }

    componentDidMount() {
        this.validateFields();
    }

    handleCloseDialog() {
        this.setState(initialState);
        this.props.hideEditAccount();
    }

    handleTabChange(e) {
        this.setState({ activeTabIndex: e.index });
        if (e.index === 2) {
            this.props.getNepatec();
        }
    }

    validateFields() {
        const user = Object.assign({}, this.props.user, this.state.draftUser);
        const name = user.name.trim() === '';
        const firstName =
            this.props.firstNameCustomField &&
            this.props.firstNameCustomField.required &&
            user.firstName.trim() === '';
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const email =
            this.props.emailCustomField &&
            ((!this.props.emailCustomField.required &&
                user.email.trim() === '') ||
                !emailRegex.test(user.email));
        const currentPassword = this.state.currentPassword === '';
        const newPassword = this.state.newPassword.length < 8;
        const newPasswordConfirmation =
            this.state.newPasswordConfirmation.length < 8 ||
            this.state.newPassword !== this.state.newPasswordConfirmation;
        const nepatecUsername =
            this.state.draftNepatecCredentials.username.trim() === '' &&
            this.state.draftNepatecCredentials.active;
        const nepatecNewPassword =
            this.state.draftNepatecCredentials.newPassword.trim() === '' &&
            this.state.draftNepatecCredentials.active;
        const nepatecNewPasswordConfirmation =
            this.state.draftNepatecCredentials.newPasswordConfirmation !==
                this.state.draftNepatecCredentials.newPassword &&
            this.state.draftNepatecCredentials.active;

        this.setState({
            invalidFields: {
                firstName,
                name,
                email,
                currentPassword,
                newPassword,
                newPasswordConfirmation,
                nepatecUsername,
                nepatecNewPassword,
                nepatecNewPasswordConfirmation,
            },
        });
    }

    handleBlurAccount(field, e) {
        this.setState((prevState) => {
            return {
                blurredFieldsAccount: {
                    ...prevState.blurredFieldsAccount,
                    [field]: true,
                },
            };
        });
    }

    handleBlurPassword(field, e) {
        this.setState((prevState) => {
            return {
                blurredFieldsPassword: {
                    ...prevState.blurredFieldsPassword,
                    [field]: true,
                },
            };
        });
    }

    handleBlurNepatec(field, e) {
        this.setState((prevState) => {
            return {
                blurredFieldsNepatec: {
                    ...prevState.blurredFieldsNepatec,
                    [field]: true,
                },
            };
        });
    }

    handleInputChange(e) {
        this.setState(
            {
                draftUser: Object.assign({}, this.state.draftUser, {
                    [e.target.name]: e.target.value,
                }),
            },
            this.validateFields
        );
    }

    handlePasswordInputChange(e) {
        this.setState({ [e.target.name]: e.target.value }, this.validateFields);
    }

    handleNepatecInputChange(e) {
        this.setState(
            {
                draftNepatecCredentials: Object.assign(
                    {},
                    this.state.draftNepatecCredentials,
                    {
                        [e.target.name]: e.target.value,
                    }
                ),
            },
            this.validateFields
        );
    }

    handleSaveAccount() {
        this.props.editAccount(this.state.draftUser);
        this.setState(
            {
                draftUser: {},
                blurredFieldsAccount: {
                    firstName: false,
                    name: false,
                    email: false,
                },
            },
            this.validateFields
        );
    }

    handleSavePassword() {
        this.props.editPassword({
            currentPassword: this.state.currentPassword,
            newPassword: this.state.newPassword,
            newPasswordConfirmation: this.state.newPasswordConfirmation,
        });
        this.setState(
            {
                currentPassword: '',
                newPassword: '',
                newPasswordConfirmation: '',
                blurredFieldsPassword: {
                    currentPassword: false,
                    newPassword: false,
                    newPasswordConfirmation: false,
                },
            },
            this.validateFields
        );
    }

    handleSaveNepatec() {
        this.props.editNepatec({
            active: this.state.draftNepatecCredentials.active,
            username: this.state.draftNepatecCredentials.active
                ? this.state.draftNepatecCredentials.username
                : '',
            password: this.state.draftNepatecCredentials.active
                ? this.state.draftNepatecCredentials.newPassword
                : '',
        });
        this.setState(
            {
                draftNepatecCredentials: {
                    active: this.state.draftNepatecCredentials.active,
                    username: '',
                    newPassword: '',
                    newPasswordConfirmation: '',
                },
                blurredFieldsNepatec: {
                    username: false,
                    newPassword: false,
                    newPasswordConfirmation: false,
                },
            },
            this.validateFields
        );
    }

    render() {
        const footer = (
            <Button
                label={getTranslatedString(this.props.language, 'close')}
                className="p-button-secondary"
                onClick={this.handleCloseDialog}
                disabled={this.props.isLoading}
            />
        );

        let accordion;

        if (this.props.nepatecCredentialsActive) {
            accordion = (
                <Accordion
                    activeIndex={this.state.activeTabIndex}
                    onTabChange={this.handleTabChange}
                >
                    <AccordionTab header={<TranslatedString id="account" />}>
                        {this.renderAccountContent()}
                    </AccordionTab>
                    <AccordionTab header={<TranslatedString id="password" />}>
                        {this.renderPasswordContent()}
                    </AccordionTab>
                    <AccordionTab
                        header={<TranslatedString id="nepatecAccount" />}
                    >
                        {this.renderNepatecContent()}
                    </AccordionTab>
                </Accordion>
            );
        } else {
            accordion = (
                <Accordion
                    activeIndex={this.state.activeTabIndex}
                    onTabChange={this.handleTabChange}
                >
                    <AccordionTab header={<TranslatedString id="account" />}>
                        {this.renderAccountContent()}
                    </AccordionTab>
                    <AccordionTab header={<TranslatedString id="password" />}>
                        {this.renderPasswordContent()}
                    </AccordionTab>
                </Accordion>
            );
        }

        return (
            <Dialog
                className="edit-account-dialog"
                header={<TranslatedString id="editAccount" />}
                footer={footer}
                visible={this.props.editAccountVisible}
                onHide={this.handleCloseDialog}
                closable={!this.props.isLoading}
                baseZIndex={999999}
                focusOnShow={false}
            >
                {accordion}
            </Dialog>
        );
    }

    renderAccountContent() {
        const user = Object.assign({}, this.props.user, this.state.draftUser);

        return (
            <div className="p-grid form-group" style={{ paddingTop: '10px' }}>
                {this.props.accountMessage && (
                    <div className="p-col-12">
                        <Message
                            severity={this.props.accountMessage.type}
                            text={getTranslatedString(
                                this.props.language,
                                this.props.accountMessage.messageId
                            )}
                        />
                    </div>
                )}
                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className="form-input"
                            value={user.username}
                            disabled
                        />
                        <label>
                            <TranslatedString id={'username'} />
                        </label>
                    </span>
                </div>
                {this.props.firstNameCustomField && (
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        this.state.blurredFieldsAccount
                                            .firstName &&
                                        this.state.invalidFields.firstName,
                                })}
                                value={user.firstName}
                                name="firstName"
                                onChange={this.handleInputChange}
                                onBlur={this.handleBlurAccount.bind(
                                    this,
                                    'firstName'
                                )}
                                disabled={this.props.isLoading}
                            />
                            <label>
                                <TranslatedString id="firstName" />
                                {this.props.firstNameCustomField.required &&
                                    '*'}
                            </label>
                        </span>
                    </div>
                )}
                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames('form-input', {
                                'p-error':
                                    this.state.blurredFieldsAccount.name &&
                                    this.state.invalidFields.name,
                            })}
                            value={user.name}
                            name="name"
                            onChange={this.handleInputChange}
                            onBlur={this.handleBlurAccount.bind(this, 'name')}
                            disabled={this.props.isLoading}
                        />
                        <label>
                            <TranslatedString id="name" />*
                        </label>
                    </span>
                </div>
                {this.props.emailCustomField && (
                    <div className="p-col-12">
                        <span className="md-inputfield">
                            <InputText
                                className={classNames('form-input', {
                                    'p-error':
                                        this.state.blurredFieldsAccount.email &&
                                        this.state.invalidFields.email,
                                })}
                                value={user.email}
                                name="email"
                                onChange={this.handleInputChange}
                                onBlur={this.handleBlurAccount.bind(
                                    this,
                                    'email'
                                )}
                                disabled={this.props.isLoading}
                            />
                            <label>
                                <TranslatedString id="email" />
                                {this.props.emailCustomField.required && '*'}
                            </label>
                            {this.state.blurredFieldsAccount.email &&
                                this.state.invalidFields.email && (
                                    <span className="error-message">
                                        <TranslatedString id={'invalidEmail'} />
                                    </span>
                                )}
                        </span>
                    </div>
                )}
                <div className="p-col-12">
                    <Button
                        label={getTranslatedString(this.props.language, 'save')}
                        className="p-button"
                        icon="pi pi-check"
                        onClick={this.handleSaveAccount}
                        disabled={
                            this.props.isLoading ||
                            this.state.invalidFields.name ||
                            this.state.invalidFields.firstName ||
                            this.state.invalidFields.email
                        }
                    />
                </div>
            </div>
        );
    }

    renderPasswordContent() {
        return (
            <div className="p-grid form-group" style={{ paddingTop: '10px' }}>
                {this.props.passwordMessage && (
                    <div className="p-col-12">
                        <Message
                            severity={this.props.passwordMessage.type}
                            text={getTranslatedString(
                                this.props.language,
                                this.props.passwordMessage.messageId
                            )}
                        />
                    </div>
                )}
                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames('form-input', {
                                'p-error':
                                    this.state.blurredFieldsPassword
                                        .currentPassword &&
                                    this.state.invalidFields.currentPassword,
                            })}
                            value={this.state.currentPassword}
                            name="currentPassword"
                            type="password"
                            autoComplete="password"
                            onChange={this.handlePasswordInputChange}
                            onBlur={this.handleBlurPassword.bind(
                                this,
                                'currentPassword'
                            )}
                            disabled={this.props.isLoading}
                        />
                        <label>
                            <TranslatedString id="password" />
                        </label>
                    </span>
                </div>
                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames('form-input', {
                                'p-error':
                                    this.state.blurredFieldsPassword
                                        .newPassword &&
                                    this.state.invalidFields.newPassword,
                            })}
                            value={this.state.newPassword}
                            name="newPassword"
                            type="password"
                            autoComplete="new-password"
                            onChange={this.handlePasswordInputChange}
                            onBlur={this.handleBlurPassword.bind(
                                this,
                                'newPassword'
                            )}
                            disabled={this.props.isLoading}
                        />
                        <label>
                            <TranslatedString id="newPassword" />
                        </label>
                        {this.state.blurredFieldsPassword.newPassword &&
                            this.state.invalidFields.newPassword && (
                                <span className="error-message">
                                    <TranslatedString id={'invalidPassword'} />
                                </span>
                            )}
                    </span>
                </div>
                <div className="p-col-12">
                    <span className="md-inputfield">
                        <InputText
                            className={classNames('form-input', {
                                'p-error':
                                    this.state.blurredFieldsPassword
                                        .newPasswordConfirmation &&
                                    this.state.invalidFields
                                        .newPasswordConfirmation,
                            })}
                            value={this.state.newPasswordConfirmation}
                            name="newPasswordConfirmation"
                            type="password"
                            autoComplete="new-password"
                            onChange={this.handlePasswordInputChange}
                            onBlur={this.handleBlurPassword.bind(
                                this,
                                'newPasswordConfirmation'
                            )}
                            disabled={this.props.isLoading}
                        />
                        <label>
                            <TranslatedString id="newPasswordConfirmation" />
                        </label>
                        {this.state.blurredFieldsPassword
                            .newPasswordConfirmation &&
                            this.state.invalidFields
                                .newPasswordConfirmation && (
                                <span className="error-message">
                                    {this.state.newPassword !==
                                    this.state.newPasswordConfirmation ? (
                                        <TranslatedString
                                            id={'editPasswordDoesNotMatch'}
                                        />
                                    ) : (
                                        <TranslatedString
                                            id={'invalidPassword'}
                                        />
                                    )}
                                </span>
                            )}
                    </span>
                </div>
                <div className="p-col-12">
                    <Button
                        label={getTranslatedString(this.props.language, 'save')}
                        className="p-button"
                        icon="pi pi-check"
                        onClick={this.handleSavePassword}
                        disabled={
                            this.props.isLoading ||
                            this.state.invalidFields.currentPassword ||
                            this.state.invalidFields.newPassword ||
                            this.state.invalidFields.newPasswordConfirmation
                        }
                    />
                </div>
            </div>
        );
    }

    renderNepatecContent() {
        return (
            <div className="p-grid" style={{ paddingTop: '10px' }}>
                {this.props.nepatecMessage && (
                    <div className="p-col-12">
                        <Message
                            severity={this.props.nepatecMessage.type}
                            text={getTranslatedString(
                                this.props.language,
                                this.props.nepatecMessage.messageId
                            )}
                        />
                    </div>
                )}
                <div className="p-col-12">
                    <Fieldset legend={<TranslatedString id={'status'} />}>
                        <div className="p-col-12" style={{ fontSize: '20px' }}>
                            {this.props.nepatecCredentials.active ? (
                                <i
                                    className="pi-md-check-circle"
                                    style={{
                                        fontSize: '25px',
                                        position: 'relative',
                                        top: '5px',
                                        color: 'green',
                                    }}
                                />
                            ) : (
                                <i
                                    className="pi-md-cancel"
                                    style={{
                                        fontSize: '25px',
                                        position: 'relative',
                                        top: '5px',
                                        color: 'red',
                                    }}
                                />
                            )}
                            {'   '}
                            {this.props.nepatecCredentials.username}
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12">
                    <Fieldset legend={<TranslatedString id={'edit'} />}>
                        <div className="p-grid form-group">
                            <div
                                className="p-col-12"
                                style={{ paddingBottom: '18px' }}
                            >
                                <Checkbox
                                    value={
                                        !this.state.draftNepatecCredentials
                                            .active
                                    }
                                    checked={
                                        this.state.draftNepatecCredentials
                                            .active
                                    }
                                    name="active"
                                    onChange={this.handleNepatecInputChange}
                                    disabled={this.props.isLoading}
                                />
                                <label className="p-checkbox-label">
                                    <TranslatedString id="active" />
                                </label>
                            </div>
                            <div className="p-col-12">
                                <span className="md-inputfield">
                                    <InputText
                                        className={classNames('form-input', {
                                            'p-error':
                                                this.state.blurredFieldsNepatec
                                                    .username &&
                                                this.state.invalidFields
                                                    .nepatecUsername,
                                        })}
                                        value={
                                            this.state.draftNepatecCredentials
                                                .active
                                                ? this.state
                                                      .draftNepatecCredentials
                                                      .username
                                                : ''
                                        }
                                        name="username"
                                        type="text"
                                        autoComplete="username"
                                        onChange={this.handleNepatecInputChange}
                                        onBlur={this.handleBlurNepatec.bind(
                                            this,
                                            'username'
                                        )}
                                        disabled={
                                            this.props.isLoading ||
                                            !this.state.draftNepatecCredentials
                                                .active
                                        }
                                    />
                                    <label>
                                        <TranslatedString id="username" />
                                    </label>
                                </span>
                            </div>
                            <div className="p-col-12">
                                <span className="md-inputfield">
                                    <InputText
                                        className={classNames('form-input', {
                                            'p-error':
                                                this.state.blurredFieldsNepatec
                                                    .newPassword &&
                                                this.state.invalidFields
                                                    .nepatecNewPassword,
                                        })}
                                        value={
                                            this.state.draftNepatecCredentials
                                                .active
                                                ? this.state
                                                      .draftNepatecCredentials
                                                      .newPassword
                                                : ''
                                        }
                                        name="newPassword"
                                        type="password"
                                        autoComplete="new-password"
                                        onChange={this.handleNepatecInputChange}
                                        onBlur={this.handleBlurNepatec.bind(
                                            this,
                                            'newPassword'
                                        )}
                                        disabled={
                                            this.props.isLoading ||
                                            !this.state.draftNepatecCredentials
                                                .active
                                        }
                                    />
                                    <label>
                                        <TranslatedString id="password" />
                                    </label>
                                    {this.state.blurredFieldsNepatec
                                        .newPassword &&
                                        this.state.invalidFields
                                            .nepatecNewPassword && (
                                            <span className="error-message">
                                                <TranslatedString
                                                    id={'invalidPassword'}
                                                />
                                            </span>
                                        )}
                                </span>
                            </div>
                            <div className="p-col-12">
                                <span className="md-inputfield">
                                    <InputText
                                        className={classNames('form-input', {
                                            'p-error':
                                                this.state.blurredFieldsNepatec
                                                    .newPasswordConfirmation &&
                                                this.state.invalidFields
                                                    .nepatecNewPasswordConfirmation,
                                        })}
                                        value={
                                            this.state.draftNepatecCredentials
                                                .active
                                                ? this.state
                                                      .draftNepatecCredentials
                                                      .newPasswordConfirmation
                                                : ''
                                        }
                                        name="newPasswordConfirmation"
                                        type="password"
                                        autoComplete="new-password"
                                        onChange={this.handleNepatecInputChange}
                                        onBlur={this.handleBlurNepatec.bind(
                                            this,
                                            'newPasswordConfirmation'
                                        )}
                                        disabled={
                                            this.props.isLoading ||
                                            !this.state.draftNepatecCredentials
                                                .active
                                        }
                                    />
                                    <label>
                                        <TranslatedString id="newPasswordConfirmation" />
                                    </label>
                                    {this.state.blurredFieldsNepatec
                                        .newPasswordConfirmation &&
                                        this.state.invalidFields
                                            .nepatecNewPasswordConfirmation && (
                                            <span className="error-message">
                                                {this.state
                                                    .draftNepatecCredentials
                                                    .newPassword !==
                                                this.state
                                                    .draftNepatecCredentials
                                                    .newPasswordConfirmation ? (
                                                    <TranslatedString
                                                        id={
                                                            'editPasswordDoesNotMatch'
                                                        }
                                                    />
                                                ) : (
                                                    <TranslatedString
                                                        id={'invalidPassword'}
                                                    />
                                                )}
                                            </span>
                                        )}
                                </span>
                            </div>
                            <div className="p-col-12">
                                <Button
                                    label={getTranslatedString(
                                        this.props.language,
                                        'save'
                                    )}
                                    className="p-button"
                                    icon="pi pi-check"
                                    onClick={this.handleSaveNepatec}
                                    disabled={
                                        this.props.isLoading ||
                                        this.state.invalidFields
                                            .nepatecUsername ||
                                        this.state.invalidFields
                                            .nepatecNewPassword ||
                                        this.state.invalidFields
                                            .nepatecNewPasswordConfirmation
                                    }
                                />
                            </div>
                        </div>
                    </Fieldset>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const customFields =
        state.auth.publicServiceInfo &&
        state.auth.publicServiceInfo.adviserUserCustomFields
            ? state.auth.publicServiceInfo.adviserUserCustomFields
            : [];
    const firstNameCustomField = customFields.find(
        (el) => el._id === 'firstName'
    );
    const emailCustomField = customFields.find((el) => el._id === 'email');

    return {
        editAccountVisible: state.base.editAccount.editAccountVisible,
        isLoading: state.base.editAccount.isLoading,
        accountMessage: state.base.editAccount.accountMessage,
        passwordMessage: state.base.editAccount.passwordMessage,
        nepatecMessage: state.base.editAccount.nepatecMessage,
        user: state.auth.user,
        nepatecCredentials: state.base.editAccount.nepatecCredentials,
        language: state.base.i18n.language,
        firstNameCustomField,
        emailCustomField,
        nepatecCredentialsActive:
            state.auth.publicServiceInfo &&
            state.auth.publicServiceInfo.nepatecSettings &&
            state.auth.publicServiceInfo.nepatecSettings.active &&
            state.auth.publicServiceInfo.nepatecSettings.individualLogin,
    };
};

const mapDispatchToProps = {
    editAccount,
    editPassword,
    getNepatec,
    editNepatec,
    hideEditAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAccountDialog);
