import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { managerIFrameLoad, managerIFrameNewRef } from './actions';
import { Config } from '../../config/Config';

export class MeetingsManagerExternal extends Component {
    constructor(props) {
        super(props);
        this.iFrameRef = React.createRef();
    }

    componentDidMount() {
        this.props.managerIFrameNewRef(this.iFrameRef);
    }

    componentWillUnmount() {
        this.props.managerIFrameNewRef(null);
    }

    render() {
        return (
            <iframe
                className={classNames('meetings-manager-iframe', {
                    'no-breadcrumb': !Config.showBreadcrumb,
                })}
                title="Meetings Manager"
                src={this.props.managerUrl}
                allowFullScreen
                ref={this.iFrameRef}
                onLoad={this.props.managerIFrameLoad}
            />
        );
    }
}

const mapDispatchToProps = {
    managerIFrameLoad,
    managerIFrameNewRef,
};

export default connect(undefined, mapDispatchToProps)(MeetingsManagerExternal);
