import {
    ADVISER_STATE_NAMES,
    AL_APPLICATIONS,
    SA_APPLICATIONS,
} from '../../../../constants/constants';

export const italian = {
    // General
    language: 'Lingua',
    de: 'Tedesco',
    en: 'Inglese',
    fr: 'Francese',
    it: 'Italiano',

    applicationName: 'Unblu Meet - Adviser',

    save: 'Salva',
    cancel: 'Annulla',
    confirm: 'Conferma',
    close: 'Chiudi',
    join: 'Partecipa',
    edit: 'Modifica',
    refresh: 'Aggiorna',
    connect: 'Connetti',
    reject: 'Rifiuta',

    areYouSure: 'É sicuro?',

    username: 'Nome utente',
    password: 'Password',
    serviceId: 'Service-ID',
    captcha: 'Captcha',
    signIn: 'Login',
    signOut: 'Logout',

    dashboard: 'Dashboard',
    meetings: 'Meeting',
    phoneConsulting: 'Consulenza telefonica',
    templates: 'Modelli',
    defaultDocuments: 'Documenti Default',
    defaultAgenda: 'Agenda di default',
    defaultMinutes: 'Rapporto di default',
    signatureTemplates: 'Modelli con firma',

    reconnectingHeader: 'Connessione persa',
    reconnectingMessage:
        'Connessione al server persa. La connessione viene ripristinata...',

    pathDoesNotExist: 'Questa pagina non esiste!',

    // Account

    account: 'Account',
    nepatecAccount: 'Nepatec Account',
    editAccount: 'Modifica Account',
    name: 'Nome',
    lastName: 'Cognome',
    firstName: 'Nome',
    email: 'Email',
    newPassword: 'Nuova Password',
    newPasswordConfirmation: 'Conferma Password',
    status: 'Stato',
    active: 'Attivo',

    invalidEmail: 'Indirizzo email non valido',
    invalidPassword: 'La password deve contenere almeno 8 caratteri.',
    editAccountSuccess: 'Il tuo account è stato aggiornato.',
    editAccountFailure: 'Non è stato possibile aggiorare il suo profilo.',
    editPasswordSuccess: 'La tua password è stata aggiornata.',
    editPasswordFailure: 'Non è stato possibile aggiorare la sua password.',
    editPasswordCurrentWrong: 'Password esistente non valida.',
    editPasswordDoesNotMatch: 'La password di conferma non coincide.',
    userKeycloakConflict:
        'Un utente con questo nome utente e / o questa email esiste già.',
    getNepatecFailure:
        "Non è stato possibile recuperare l'utente o gli utenti.",

    // Dashboard

    adviserOnline: 'Consulente online',
    ownOpenMeetings: 'I suoi meeting aperti',

    // Device Settings

    deviceSettings: 'Impostazioni',
    cam: 'Videocamera',
    mic: 'Mircrofono',
    resolution: 'Risoluzione',
    comModeNone: 'Solo chat',
    comModeAudio: 'Audio',
    comModeBoth: 'Audio + video',
    backgroundEffect: 'Effetto sfondo del video',
    backgroundEffectNone: 'Nessuno',
    backgroundEffectBlur: 'Sfocatura',
    backgroundEffectStrongBlur: 'Sfocatura (forte)',
    backgroundEffectImage: 'Immagine',
    notMirrorOwn: 'Non riprodurre il proprio video in locale',
    localImage: 'Immagine locale',
    uploadImage: 'Carica immagine',
    advancedSettings: 'Impostazioni avanzate',
    hqAudio: 'Studio Audio',
    fps: 'FPS',

    // Meeting

    agenda: 'Ordine del giorno',
    showboard: 'Showboard',
    minutes: 'Rapporto',
    documents: 'Documenti',
    chat: 'Chat',
    surveys: 'Sondaggi',
    show: 'Mostra',
    editModePermissions: 'Modifica i permessi',

    startFullscreen: 'Apri schermo intero',
    stopFullscreen: 'Chiudi schermo intero',
    startScreenShare: 'Condividi lo schermo',
    stopScreenShare: 'Annulla condivisione schermo',
    leaveMeeting: 'Abbandona il meeting',
    muteMic: 'Disabilita il miscrofono',
    unmuteMic: 'Abilita il miscrofono',
    activateMic: 'Attiva il microfono',
    startCam: 'Abilita videocamera',
    stopCam: 'Disabilita videocamera',
    activateCam: 'Attiva videocamera',
    meetingInfo: 'Info meeting',
    sharedApplications: 'Shared applications',
    menu: 'Menu',

    startRecording: 'Inizia a registrare',
    stopRecording: 'Interrompere la registrazione',
    muteParticipant: 'Disabilita il miscrofono del partecipante',
    kickParticipant: 'Rimuovi partecipante',
    msgRemoteMute: 'Un consulente ha disabilitato il suo microfono.',
    msgKick: 'Un consulente vi ha rimosso dal meeting.',
    msgTransferred: 'La chiamata è stata trasferita',
    msgTransferCallError_input: 'Selezionare tema e partecipante.',
    msgTransferCallError_server: 'Impossibile trasferire la chiamata.',
    msgNewWaitingUser: 'Un utente ha richiesto di partecipare al meeting.',

    participantActions: 'Silenzia/rimuovi partecipanti',
    noParticipants: 'Nessun partecipante al meeting',
    mute: 'Silenzia',
    kick: 'Rimuovi',
    startCapturingClient: 'Inizia la registrazione del meeting',
    stopCapturingClient: 'Ferma la registrazione del meeting',
    startBroadcasting: 'Inizia la trassmissione',
    stopBroadcasting: 'Ferma la trassmissione',
    broadcastOnline: 'TRASMISSIONE LIVE',
    broadcastOffline: 'TRASMISSIONE OFFLINE',
    msgReallyLeaveMeeting: 'Vuole davver abbandonare il meeting?',
    completeMeeting: 'Terminare il meeting',
    completeMeetingConfirmation: 'Terminare davvero il meeting?',
    msgMeetingCompleted: 'Il meeting è stato terminato',

    share: 'Condividi',
    standaloneApplications: 'Standalone Applications',
    link: 'Link',
    participantLink: 'Link',

    currentActiveUser: 'Partecipante in scena',
    handRaised: 'Mano alzata',
    waitingRoom: "Sala d'attesa",

    bitrate: 'Bitrate',
    framerate: 'Framerate',
    packetLoss: 'Packet loss',

    // Meeting Manager Standard

    meetingTableDate: 'Data',
    meetingTableTitle: 'Titolo',
    meetingTableDescription: 'Descrizione',
    meetingTableParticipants: 'Partecipanti',

    // Webinars
    webinars: 'Webinars',
    participants: 'Partecipanti',
    participant: 'Partecipante',
    speakerPermissions: 'Permessi di parlare',
    grantedSpeakerPermission: 'Permessi di parlare approvati',
    requestedSpeakerPermission: 'Permesso per parlare richiesta',
    leaveWebinar: 'Abbandona il webinar',

    // Podium
    podiums: 'Podiums',

    // Call Manager
    callManager: 'Call Manager',

    // Recordings
    recordings: 'Registrazioni',
    download: 'Scarica',
    deleteRecording: 'Eliminare la registrazione',
    deleteRecordingConfirmation: 'Vuole davvero eliminare la registrazione?',
    unsavedRecordings:
        'Ci sono nuove registrazioni disponibile. Si prega di scaricarle ora e poi eliminarle dalla lista.',
    noRecordings: 'Nessuna registrazione trovata',
    recordingsNotice:
        'A causa dei limiti di archiviazione del browser, si prega di mantenere la lista il più breve possibile scaricando e poi eliminando le registrazioni.',
    markedForDelete: 'Verrà cancellato tra {{hours}} ore',
    markedForDelete1Hour: "Verrà cancellato tra meno di un'ora",
    needsDownload: 'Si prega di scaricare',
    confirmRecordingTitle: 'Attendere conferma di registrazione',
    confirmRecordingMessage:
        'Ai partecipanti viene chiesto il consenso per avviare la registrazione. Si prega di attendere la conferma da parte dei partecipanti.',
    confirmRecordingResultAsk: 'Richiesta di consenso',
    confirmRecordingResultRejected: 'Respinto',
    confirmRecordingResultConfirmed: 'Accettato',
    restartConfirmation: 'Richiedere nuovamente conferma',

    // adHoc
    meeting: 'Meeting',
    phoneConsultation: 'Consulenza telefonica',
    meetingId: 'ID meeting',
    consultationId: 'ID consulenza',
    newMeeting: 'Nuovo meeting',
    newPhoneConsultation: 'Nuova consulenza telefonica',

    callManagerQueueTitle: 'Coda',
    callManagerClosedCallsTitle: 'Chiamate terminate',
    callManagerAdvisersTitle: 'Consulenti',
    callManagerEmptyQueueMessage: 'Coda vuota',

    topics: 'Temi',
    topic: 'Tema',
    state: 'Stato',
    action: 'Azione',
    select: 'Seleziona',

    allTopicsName: 'Tutti i temi',

    incomingCall: 'Chiamata in entrata',
    joiningCall: 'Chiamata in collegamento',
    addAdviserBtn: 'Add',
    cancelInvitationBtn: 'Cancel invitation',
    noAdvisers: 'No advisers available',
    adviserInvitationMessage:
        '{{adviserName}} vorrebbe aggiungerti al meeting "{{meetingTitle}}".',
    adviserTransferMessage:
        'Questa chiamata è stata trasferita da "{{adviserName}}"',
    invitationComment: 'Messaggio',
    comment: 'Messaggio',

    ['appTitle-' + SA_APPLICATIONS.DOCUMENTS]: 'Documenti',
    ['appTitle-' + SA_APPLICATIONS.CHAT]: 'Chat',
    ['appTitle-' + SA_APPLICATIONS.DOCUMENTS_MANAGER]: 'Documenti',
    ['appTitle-' + SA_APPLICATIONS.STREAM_CHAT]: 'Chat',
    ['appTitle-' + SA_APPLICATIONS.SURVEY_MANAGER]: 'Sondaggi',
    ['appTitle-' + AL_APPLICATIONS.ADIA_PAD]: 'Pannello di controllo',
    ['appTitle-' + AL_APPLICATIONS.PARTICIPANTS]: 'Partecipanti',

    callManagerStateTitle: 'Stato Call Manager',

    ['callState-' + ADVISER_STATE_NAMES.BUSY]: 'Occupato',
    ['callState-' + ADVISER_STATE_NAMES.READY]: 'Pronto',
    ['callState-' + ADVISER_STATE_NAMES.CONNECTING]: 'Collegamento',
    ['callState-' + ADVISER_STATE_NAMES.CONNECTED]: 'Collegato',
    ['callState-' + ADVISER_STATE_NAMES.JOINED_MEETING]: 'Collegato',
    'callState-waiting': 'Aspetta',

    ['changeStateBtn-' + ADVISER_STATE_NAMES.BUSY]: 'Imposta occupato',
    ['changeStateBtn-' + ADVISER_STATE_NAMES.READY]: 'Imposta pronto',

    openCallsTitle: 'Chiamate aperte',
    openCallsMessage: 'Ci sono delle chiamate aperte',

    // adiaPad
    adiaPadName: 'Pad',
    adiaPadAddAdviser: 'Aggiungere consulenti',
    adiaPadTransferCall: 'Inoltrare la chiamata',
    adiaPadShowShowboard: 'Aprire Showboard',
    adiaPadShowAgenda: 'Aprire agenda',
    adiaPadShowMinutes: 'Aprire protocollo',
    adiaPadShowCamMicSettings: 'Impostazioni Camera/Mic',
    adiaPadMuteParticipant: 'Silenziare partecipanti',
    adiaPadStartScreenShare: 'Condividere schermo',
    adiaPadStopScreenShare: 'Annulla condivisione schermo',
    adiaPadRequestSignature: 'Richiedere una firma',
    adiaPadAddParticipant: 'Aggiungere partecipanti',
    adiaPadRemoveParticipant: 'Rimuovere partecipanti',
    adiaPadStartRecording: 'Avviare registrazione',
    adiaPadStopRecording: 'Interrompere registrazione',

    // Errors

    connectionError: 'La connessione al server non riuscita.',
    serverError: 'oops! Si è verificato un errore.',
    accessDenied: 'Accesso negato!',
    notAuthorized: 'Non autorizzato!',
    authInvalidIp: 'IP non valido.',
    authInvalidUser: 'Utente non valido.',
    authInvalidCaptcha: 'Captcha non valido.',
    authInvalidCredentials: 'Dati di accesso non validi.',
    serviceNotFound: 'Questo Service non esiste.',
    invalidRoleError:
        "Solo gli utenti con il ruolo 'adviser' possono utilizzare l'applicazione Adviser.",
    autoRecordingError:
        'Non è stato possibile avviare la registrazione automatica, non è autorizzato a partecipare a questo meeting senza avviare la registrazione.',
    invalidFileType: 'Formato del file invalido',
    fileSizeToBig: 'File troppo grande',
    meetingCreateError: 'Non è stato possibile aggingere il meeting.',
    meetingLimitError:
        'Al momento non sono possibili altri appuntamenti (capacità massima raggiunta). Per favore provare più tardi.',

    alcErrorDefault: 'Errore alc',
    alcErrorMeetingDoesNotExist:
        'Il Meeting già stato completato o non esiste.',
    alcErrorMeetingConnectionLost:
        'Connessione al Meeting-Server persa. Vi preghiamo di riprovare',
    alcErrorMeetingFull:
        'È stato superato il limite massimo di partecipanti al meeting.',
    alcErrorWebinarAdviserFull:
        'Numero massimo di consulenti raggiunto per questo webinar.',
    alcErrorAdviserIdMismatch:
        "L'invito che hai usato non è stato creato per il suo utente.",
    alcErrorExclusiveNotInvited:
        'Non siete invitati a questo meeting esclusivo',

    webRtcErrorDefault: 'Errore WebRTC',
    webRtcErrorNotFound:
        'Videocamera/microfono non trovati. Verificare che la videocamera o il microfono siano collegati, dopodiché cliccare Aggiorna.',
    webRtcErrorNotAllowed:
        "Consentire l'accesso alla microfono / videocamera nelle impostazioni del browser. Attivi successivamente l'audio o l'audio + video.",
    webRtcErrorDeviceInUse:
        'La videocamera è in uso in un’altra applicazione. Chiudere quell’applicazione o scegliere un’altra videocamera.',
    webRtcErrorFailedAllocateSource:
        'Non è stato possibile accedere alla videocamera.',
    webRtcErrorOverconstrained:
        'La sua videocamera salvata / Il suo microfono salvato non sono stati trovati. Verificare che la videocamera o il microfono siano collegati dopodichè cliccare Aggiorna o selezioni un altro dispositivo.',
    webRtcErrorConnectionTimeout:
        'Timeout nel tentativo di connettersi al meeting server',
    webRtcErrorRecordingStart: 'Impossibile avviare la registrazione.',
    webRtcErrorRecordingStream: 'Non è stato possibile registrare uno stream.',
    webRtcErrorGetRecordings: 'Impossibile recuperare una registrazione.',
    webRctErrorBackgroundEffect:
        "L'effetto di sfondo non può essere applicato.",

    webRtcBrowserNotSupported:
        'Il tuo browser non è supportato. Utilizza una versione recente di Chrome/Firefox o Safari su iOS.',
    webRtcBrowserOutOfDate:
        'La vostra versione di {{browser}} è obsoleta e non è più supportata. Per favore aggiorna il tuo browser.',
    webRtcBrowserNonSafariIos:
        'Sfortunatamente {{browser}} non è supportata su iOS. Per favore utilizzi una versione recente di Safari',
    webRtcBrowserLegacyEdge:
        'State usando Edge Legacy. Questa versione non è aggiornata e non è più supportata. Vi consigliamo di utilizzare la nuova versione Edge che potete scaricare {{link}}.',
    webRtcBrowserIe:
        'Internet Explorer non è supportato. Vi consigliamo di utilizzare la nuova versione di Edge che potete scaricare {{link}}.',
};
