import React from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';

const ROWS = 25;

export class AdviserList extends React.Component {
    render() {
        const adviserTableEntries = [];
        Object.entries(this.props.adviserList).forEach(([key, value]) => {
            if (!value.standalone) {
                adviserTableEntries.push({
                    name: value.info.name,
                    topicId: value._topicsNamesFormated,
                    state: getTranslatedString(
                        this.props.language,
                        'callState-' + value.state.name
                    ),
                });
            }
        });

        return (
            <DataTable
                autoLayout={true}
                responsive={true}
                value={adviserTableEntries}
                rows={ROWS}
                paginator={true}
                paginatorPosition={'top'}
                alwaysShowPaginator={false}
            >
                <Column
                    className="column date-col"
                    field="name"
                    header={<TranslatedString id={'name'} />}
                />
                <Column
                    className="column date-col"
                    field="topicId"
                    header={<TranslatedString id={'topics'} />}
                />
                <Column
                    className="column date-col"
                    field="state"
                    header={<TranslatedString id={'state'} />}
                />
            </DataTable>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        adviserList: state.dashboard.adviserList,
        language: state.base.i18n.language,
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdviserList);
