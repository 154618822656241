import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdiaPadTile from './AdiaPadTile';
import { getTranslatedString } from '../base/i18n/translations';
import {
    showParticipantActions,
    showSharedApplications,
    switchOwnSharedApplication,
    webRtcStartScreenShare,
    webRtcStopScreenShare,
    requestSignature,
    showInfoPanel,
    startRecording,
    stopRecording,
    showAddAdviserDialog,
    showTransferCallDialog,
} from '../meetings/actions';
import {
    SA_APPLICATIONS,
    ADIAPAD_TILES_CATEGORIES,
    MEETING_TYPES,
    WEBRTC_MODES,
} from '../../constants/constants';

import { ReactComponent as AgendaIcon } from '../../assets/icons/icon_agenda.svg';
import { ReactComponent as ShowboardIcon } from '../../assets/icons/icon_showboard.svg';
import { ReactComponent as ScreenShareIcon } from '../../assets/icons/icon_screenShare.svg';
import { ReactComponent as MuteParticipantIcon } from '../../assets/icons/icon_muteParticipant.svg';
import { ReactComponent as MinutesIcon } from '../../assets/icons/icon_minutes.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/icon_settings.svg';
import { ReactComponent as AddParticipantIcon } from '../../assets/icons/icon_add_user.svg';
import { ReactComponent as RemoveParticipantIcon } from '../../assets/icons/icon_remove_user.svg';
import { ReactComponent as RecordIcon } from '../../assets/icons/icon_record.svg';
import { ReactComponent as RequestSigningIcon } from '../../assets/icons/icon_request_signing.svg';
import { ReactComponent as AddAdviserIcon } from '../../assets/icons/icon_add_adviser.svg';
import { ReactComponent as TransferCallIcon } from '../../assets/icons/icon_transfer_call.svg';
import { showSettings } from '../deviceSettings/actions';
import { adiaPadTileSettings } from './adiaPadTileSettings';
import { Config } from '../../config/Config';

const supportedTiles = adiaPadTileSettings.SupportedTiles;
const tilesCategoriesOrder = [
    ADIAPAD_TILES_CATEGORIES.CONTROL,
    ADIAPAD_TILES_CATEGORIES.CONTENT,
    ADIAPAD_TILES_CATEGORIES.CUSTOM,
];

const iconSizeCorrection = -10;

function AdiaPad() {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.base.i18n.language);
    const screenShareOn = useSelector((state) => state.meetings.screenShareOn);
    const adiaPadTiles = useSelector((state) => state.meetings.adiaPadTiles);

    const serviceSettings = useSelector((state) => state.auth.serviceSettings);

    const meetingType = useSelector(
        (state) => state.meetings.meetingInfo?.type
    );

    const signingEnabled = useSelector(
        (state) =>
            state.auth.publicServiceInfo.nepatecSettings &&
            state.auth.publicServiceInfo.nepatecSettings.active &&
            state.meetings.meetingInfo &&
            (state.meetings.meetingInfo.type !== MEETING_TYPES.PODIUM ||
                state.meetings.meetingInfo.type !== MEETING_TYPES.WEBINAR)
    );
    const requestSignatureActive = useSelector(
        (state) =>
            state.meetings.requestSignatureAvailable &&
            state.meetings.meetingInfo &&
            state.meetings.meetingInfo.currentApp ===
                SA_APPLICATIONS.SHOWBOARD &&
            (state.meetings.ownApplicationId === null ||
                state.meetings.ownApplicationId === SA_APPLICATIONS.SHOWBOARD)
    );

    const recordingEnabled = useSelector(
        (state) =>
            (state.meetings.meetingInfo && state.meetings.meetingInfo.type) !==
                MEETING_TYPES.PODIUM &&
            (state.auth.publicServiceInfo.webRtcMode ===
                WEBRTC_MODES.LIVESWITCH_SFU ||
                ((state.auth.publicServiceInfo.webRtcMode ===
                    WEBRTC_MODES.LIVESWITCH_P2P ||
                    state.auth.publicServiceInfo.webRtcMode ===
                        WEBRTC_MODES.ADIA_P2P) &&
                    state.meetings.webRtcFlags.canRecordMedia)) &&
            (!state.auth.publicServiceInfo.meetingsSettings.hasOwnProperty(
                'enableRecording'
            ) ||
                state.auth.publicServiceInfo.meetingsSettings.enableRecording)
    );

    const canStopRecording = useSelector((state) =>
        state.auth.publicServiceInfo.webRtcMode ===
            WEBRTC_MODES.LIVESWITCH_P2P ||
        state.auth.publicServiceInfo.webRtcMode === WEBRTC_MODES.ADIA_P2P
            ? state.meetings.clientInfo && state.meetings.clientInfo.isRecording
            : state.meetings.meetingInfo &&
              state.meetings.meetingInfo.isRecording
    );

    const stopRecordingDisabled = useSelector((state) =>
        state.auth.publicServiceInfo.meetingsSettings &&
        state.auth.publicServiceInfo.meetingsSettings.enableAutoRecording
            ? !!state.meetings.meetingParticipants.find(
                  (participant) =>
                      participant.isAdviser &&
                      participant.info.roles.includes('autorecord')
              )
            : false
    );

    const screenShareEnabled = useSelector((state) => {
        // TODO: workaround for VZ requirement, remove when handled properly
        let screenShareEnabled = state.meetings.webRtcFlags.hasDisplayMedia;
        if (
            state.auth.service === 'VZ-CH-01' ||
            state.auth.service === 'DEV-VZ-CH-01'
        ) {
            screenShareEnabled = false;
            if (
                (state.deviceSettings.currentSettings.cam &&
                    (state.deviceSettings.currentSettings.cam.includes(
                        'AMX Acendo Vibe'
                    ) ||
                        state.deviceSettings.currentSettings.cam.includes(
                            'Jabra PanaCast 50'
                        ))) ||
                (state.deviceSettings.currentSettings.mic &&
                    (state.deviceSettings.currentSettings.mic.includes(
                        'AMX Acendo Vibe'
                    ) ||
                        state.deviceSettings.currentSettings.mic.includes(
                            'Jabra PanaCast 50'
                        )))
            ) {
                screenShareEnabled = true;
            }
        }
        return screenShareEnabled;
    });

    const audioVideoDisabled = useSelector(
        (state) =>
            Config.phoneConsultingAudioVideoDisabled &&
            state.meetings.meetingInfo &&
            state.meetings.meetingInfo.type === MEETING_TYPES.PHONE_CONSULTING
    );

    const saIFrameReady = useSelector((state) => state.meetings.saIFrameReady);

    const shownApp = useSelector(
        (state) =>
            state.meetings.ownApplicationId ||
            (state.meetings.meetingInfo &&
                state.meetings.meetingInfo.currentApp)
    );

    const showSharedApplicationApp = useCallback(
        (appId) => {
            dispatch(showSharedApplications());
            dispatch(switchOwnSharedApplication(appId));
        },
        [dispatch]
    );

    const disableAgenda = useSelector(
        (state) => state.auth.publicServiceInfo.meetingsSettings.disableAgenda
    );
    const disableMinutes = useSelector(
        (state) => state.auth.publicServiceInfo.meetingsSettings.disableMinutes
    );

    let currentCategoryId = 0;
    const tiles = adiaPadTiles.map((item, index) => {
        switch (item) {
            case supportedTiles.CALL_MANAGER_INVITE_ADVISER: {
                if (!serviceSettings.callManager) {
                    return null;
                }
                return (
                    <AdiaPadTile
                        title={getTranslatedString(
                            language,
                            'adiaPadAddAdviser'
                        )}
                        category={tilesCategoriesOrder[currentCategoryId]}
                        icon={<AddAdviserIcon />}
                        onClick={() => dispatch(showAddAdviserDialog())}
                        key={index}
                    />
                );
            }
            case supportedTiles.CALL_MANAGER_TRANSFER: {
                if (
                    !serviceSettings.callManager ||
                    meetingType !== MEETING_TYPES.CALL_MANAGER
                ) {
                    return null;
                }
                return (
                    <AdiaPadTile
                        title={getTranslatedString(
                            language,
                            'adiaPadTransferCall'
                        )}
                        category={tilesCategoriesOrder[currentCategoryId]}
                        icon={<TransferCallIcon />}
                        onClick={() => dispatch(showTransferCallDialog())}
                        key={index}
                    />
                );
            }
            case supportedTiles.START_STOP_RECORDING: {
                if (recordingEnabled) {
                    if (canStopRecording) {
                        return (
                            <AdiaPadTile
                                title={getTranslatedString(
                                    language,
                                    'adiaPadStopRecording'
                                )}
                                category={
                                    tilesCategoriesOrder[currentCategoryId]
                                }
                                icon={
                                    <RecordIcon style={{ fill: '#cc0000' }} />
                                }
                                disabled={stopRecordingDisabled}
                                onClick={() => dispatch(stopRecording())}
                                key={index}
                            />
                        );
                    } else {
                        return (
                            <AdiaPadTile
                                title={getTranslatedString(
                                    language,
                                    'adiaPadStartRecording'
                                )}
                                category={
                                    tilesCategoriesOrder[currentCategoryId]
                                }
                                icon={<RecordIcon />}
                                onClick={() => dispatch(startRecording())}
                                key={index}
                            />
                        );
                    }
                }
                return null;
            }
            case supportedTiles.MUTE_PARTICIPANT: {
                return (
                    <AdiaPadTile
                        title={getTranslatedString(
                            language,
                            'adiaPadMuteParticipant'
                        )}
                        category={tilesCategoriesOrder[currentCategoryId]}
                        icon={<MuteParticipantIcon />}
                        onClick={() => dispatch(showParticipantActions())}
                        key={index}
                    />
                );
            }
            case supportedTiles.ADD_PARTICIPANT: {
                return (
                    <AdiaPadTile
                        title={getTranslatedString(
                            language,
                            'adiaPadAddParticipant'
                        )}
                        category={tilesCategoriesOrder[currentCategoryId]}
                        icon={<AddParticipantIcon />}
                        onClick={() => dispatch(showInfoPanel())}
                        key={index}
                    />
                );
            }
            case supportedTiles.REMOVE_PARTICIPANT: {
                return (
                    <AdiaPadTile
                        title={getTranslatedString(
                            language,
                            'adiaPadRemoveParticipant'
                        )}
                        category={tilesCategoriesOrder[currentCategoryId]}
                        icon={<RemoveParticipantIcon />}
                        onClick={() => dispatch(showParticipantActions())}
                        key={index}
                    />
                );
            }
            case supportedTiles.SHOW_CAM_MIC_SETTINGS: {
                if (!audioVideoDisabled) {
                    return (
                        <AdiaPadTile
                            title={getTranslatedString(
                                language,
                                'adiaPadShowCamMicSettings'
                            )}
                            category={tilesCategoriesOrder[currentCategoryId]}
                            icon={
                                <SettingsIcon
                                    style={{
                                        maxHeight: 45 + iconSizeCorrection,
                                    }}
                                />
                            }
                            onClick={() => dispatch(showSettings())}
                            key={index}
                        />
                    );
                }
                return null;
            }
            case supportedTiles.SHOW_SHOWBOARD: {
                return (
                    <AdiaPadTile
                        title={getTranslatedString(
                            language,
                            'adiaPadShowShowboard'
                        )}
                        category={tilesCategoriesOrder[currentCategoryId]}
                        icon={<ShowboardIcon />}
                        disabled={
                            shownApp === SA_APPLICATIONS.SHOWBOARD ||
                            !saIFrameReady
                        }
                        onClick={() =>
                            showSharedApplicationApp(SA_APPLICATIONS.SHOWBOARD)
                        }
                        key={index}
                    />
                );
            }
            case supportedTiles.SHOW_AGENDA: {
                if (!disableAgenda) {
                    return (
                        <AdiaPadTile
                            title={getTranslatedString(
                                language,
                                'adiaPadShowAgenda'
                            )}
                            category={tilesCategoriesOrder[currentCategoryId]}
                            icon={
                                <AgendaIcon
                                    style={{
                                        maxHeight: 65 + iconSizeCorrection,
                                    }}
                                />
                            }
                            disabled={
                                shownApp === SA_APPLICATIONS.AGENDA ||
                                !saIFrameReady
                            }
                            onClick={() =>
                                showSharedApplicationApp(SA_APPLICATIONS.AGENDA)
                            }
                            key={index}
                        />
                    );
                }
                return null;
            }
            case supportedTiles.SHOW_MINUTES: {
                if (!disableMinutes) {
                    return (
                        <AdiaPadTile
                            title={getTranslatedString(
                                language,
                                'adiaPadShowMinutes'
                            )}
                            category={tilesCategoriesOrder[currentCategoryId]}
                            icon={<MinutesIcon />}
                            disabled={
                                shownApp === SA_APPLICATIONS.MINUTES ||
                                !saIFrameReady
                            }
                            onClick={() =>
                                showSharedApplicationApp(
                                    SA_APPLICATIONS.MINUTES
                                )
                            }
                            key={index}
                        />
                    );
                }
                return null;
            }
            case supportedTiles.START_STOP_SCREEN_SHARE: {
                if (screenShareEnabled) {
                    if (!screenShareOn) {
                        return (
                            <AdiaPadTile
                                title={getTranslatedString(
                                    language,
                                    'adiaPadStartScreenShare'
                                )}
                                category={
                                    tilesCategoriesOrder[currentCategoryId]
                                }
                                icon={
                                    <ScreenShareIcon
                                        style={{
                                            maxHeight: 45 + iconSizeCorrection,
                                        }}
                                    />
                                }
                                onClick={() =>
                                    dispatch(webRtcStartScreenShare())
                                }
                                key={index}
                            />
                        );
                    } else {
                        return (
                            <AdiaPadTile
                                title={getTranslatedString(
                                    language,
                                    'adiaPadStopScreenShare'
                                )}
                                category={
                                    tilesCategoriesOrder[currentCategoryId]
                                }
                                icon={
                                    <ScreenShareIcon
                                        style={{
                                            maxHeight: 45 + iconSizeCorrection,
                                        }}
                                    />
                                }
                                onClick={() =>
                                    dispatch(webRtcStopScreenShare())
                                }
                                key={index}
                            />
                        );
                    }
                }
                return null;
            }
            case supportedTiles.REQUEST_SIGNATURE: {
                if (signingEnabled) {
                    return (
                        <AdiaPadTile
                            title={getTranslatedString(
                                language,
                                'adiaPadRequestSignature'
                            )}
                            category={tilesCategoriesOrder[currentCategoryId]}
                            icon={
                                <RequestSigningIcon
                                    style={{
                                        maxHeight: 35 + iconSizeCorrection,
                                    }}
                                />
                            }
                            disabled={!requestSignatureActive}
                            onClick={() => dispatch(requestSignature())}
                            key={index}
                        />
                    );
                }
                return null;
            }
            case supportedTiles.SEPARATOR_1:
            case supportedTiles.SEPARATOR_2: {
                currentCategoryId++;
                return (
                    <AdiaPadTile
                        category={ADIAPAD_TILES_CATEGORIES.SEPARATOR}
                        key={index}
                    />
                );
            }
            default: {
                return null; // not supported
            }
        }
    });

    return (
        <div className="adia-pad">
            <div className="tile-container">{tiles}</div>
        </div>
    );
}

export default AdiaPad;
