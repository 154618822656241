import React from 'react';
import { connect } from 'react-redux';
import { TabView, TabPanel } from 'primereact/tabview';
import MeetingsManager from '../meetingsManager/MeetingsManager';
import { MEETING_TYPES } from '../../constants/constants';
import WaitingQueue from './WaitingQueue';
import AdviserList from './AdviserList';
import TranslatedString from '../base/i18n/TranslatedString';
import classNames from "classnames";
import {Config} from "../../config/Config";

export class CallManager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTabIndex: 0,
        };
    }

    render() {
        return (
            <div className={classNames('layout-content call-manager', {
                'no-breadcrumb': !Config.showBreadcrumb,
            })}>
                <TabView
                    activeIndex={this.state.activeTabIndex}
                    onTabChange={(e) =>
                        this.setState({ activeTabIndex: e.index })
                    }
                >
                    <TabPanel
                        header={<TranslatedString id="callManagerQueueTitle" />}
                    >
                        <WaitingQueue />
                    </TabPanel>
                    <TabPanel
                        header={
                            <TranslatedString id="callManagerClosedCallsTitle" />
                        }
                    >
                        <MeetingsManager type={MEETING_TYPES.CALL_MANAGER} />
                    </TabPanel>
                    <TabPanel
                        header={
                            <TranslatedString id="callManagerAdvisersTitle" />
                        }
                    >
                        <AdviserList />
                    </TabPanel>
                </TabView>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CallManager);
