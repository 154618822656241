import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

class AppSubmenu extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onMenuItemClick(event, item, index) {
        //avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        if (this.props.root && this.props.onRootItemClick) {
            this.props.onRootItemClick({
                originalEvent: event,
                item: item
            });
        }

        //execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }

        if (index === this.state.activeIndex)
            this.setState({ activeIndex: null });
        else if (item.items) this.setState({ activeIndex: index });

        if (this.props.onMenuItemClick) {
            this.props.onMenuItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    onMenuItemMouseEnter(index) {
        if (this.props.root && this.props.menuActive && this.isHorizontal()) {
            this.setState({ activeIndex: index });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.parentMenuItemActive === false) {
            return {
                activeIndex: null
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.isHorizontal() &&
            prevProps.menuActive &&
            !this.props.menuActive
        ) {
            this.setState({ activeIndex: null });
        }
        if (prevProps.pathname !== this.props.pathname) {
            this.setState({ activeIndex: null });
        }
    }

    isHorizontal() {
        return this.props.layoutMode === 'horizontal';
    }

    renderLinkContent(item, subItemSelected) {
        let submenuIcon = item.items && !subItemSelected && (
            <i className="material-icons layout-submenu-toggler">
                keyboard_arrow_down
            </i>
        );
        let badge = item.badge && (
            <span className="menuitem-badge">{item.badge}</span>
        );

        return (
            <React.Fragment>
                <i
                    className={classNames('material-icons', {
                        'menuitem-disabled': item.disabled
                    })}
                >
                    {item.icon}
                </i>
                <span className="menuitem-text">{item.label}</span>
                {submenuIcon}
                {badge}
            </React.Fragment>
        );
    }

    renderLink(item, i, subItemSelected) {
        let content = this.renderLinkContent(item, subItemSelected);

        if (item.to) {
            return (
                <NavLink
                    activeClassName="active-menuitem-routerlink"
                    to={item.to}
                    onClick={e => this.onMenuItemClick(e, item, i)}
                    exact
                    target={item.target}
                    onMouseEnter={e => this.onMenuItemMouseEnter(i)}
                    className={classNames(item.styleClass, {
                        'menuitem-disabled': item.disabled
                    })}
                >
                    {content}
                </NavLink>
            );
        } else {
            return (
                <a
                    className={classNames(
                        { ripplelink: !item.disabled },
                        item.styleClass,
                        { 'menuitem-disabled': item.disabled }
                    )}
                    href={item.url}
                    onClick={e => this.onMenuItemClick(e, item, i)}
                    target={item.target}
                    onMouseEnter={e => this.onMenuItemMouseEnter(i)}
                >
                    {content}
                </a>
            );
        }
    }

    render() {
        let items =
            this.props.items &&
            this.props.items.map((item, i) => {
                const subItemSelected =
                    item.items !== undefined &&
                    item.items.find(el => el.to === this.props.pathname) !==
                        undefined;
                const active =
                    this.state.activeIndex === i ||
                    item.to === this.props.pathname ||
                    subItemSelected;
                const openSubmenu = active && item.items !== undefined;
                const openSubmenuInactiveItem = openSubmenu && !subItemSelected;
                const styleClass = classNames(
                    item.badgeStyleClass,
                    { 'active-menuitem': active },
                    { 'open-submenu': openSubmenu },
                    { 'open-submenu-inactive-item': openSubmenuInactiveItem }
                );

                return (
                    <li className={styleClass} key={i}>
                        {this.renderLink(item, i, subItemSelected)}
                        <AppSubmenu
                            items={item.items}
                            onMenuItemClick={this.props.onMenuItemClick}
                            layoutMode={this.props.layoutMode}
                            menuActive={this.props.menuActive}
                            parentMenuItemActive={active}
                            pathname={this.props.pathname}
                        />
                    </li>
                );
            });

        return <ul className={this.props.className}>{items}</ul>;
    }
}

export class AppMenu extends Component {
    render() {
        return (
            <AppSubmenu
                items={this.props.model}
                className="layout-menu"
                menuActive={this.props.active}
                onMenuItemClick={this.props.onMenuItemClick}
                onRootItemClick={this.props.onRootMenuItemClick}
                root={true}
                layoutMode={this.props.layoutMode}
                parentMenuItemActive={true}
                pathname={this.props.pathname}
            />
        );
    }
}
