import React from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import {
    alcChangeActiveUser,
    alcSetSpeakerPermission,
    alcSetUserVisibility,
} from './actions';
import VideoListItem from './VideoListItem';
import {
    MEETING_TYPES,
    SCREEN_SHARING_MODES,
    SPEAKER_PERMISSION_STATE,
} from '../../constants/constants';

export function MeetingVideoList(props) {
    let videoItems = [];
    for (let i = 0; i < props.meetingParticipants.length; i++) {
        const meetingParticipant = props.meetingParticipants[i];
        if (
            !meetingParticipant.standalone &&
            meetingParticipant.id !== props.clientInfo.id &&
            ((props.meetingInfo.type !== MEETING_TYPES.WEBINAR &&
                props.meetingInfo.type !== MEETING_TYPES.PODIUM) ||
                meetingParticipant.isAdviser ||
                meetingParticipant.speakerPermissionState ===
                    SPEAKER_PERMISSION_STATE.GRANTED)
        ) {
            const isMainVideo = meetingParticipant.id === props.mainVideoId;
            const videoActive = meetingParticipant.deviceInfo.video;
            const screenShareActive = meetingParticipant.deviceInfo.screen;

            let videoView = undefined;
            let screenShareView = undefined;

            if (
                videoActive &&
                (!isMainVideo ||
                    (screenShareActive &&
                        meetingParticipant.userMode &&
                        meetingParticipant.userMode.screenSharing ===
                            SCREEN_SHARING_MODES.SCREEN))
            ) {
                videoView = props.videoViews.find(
                    (el) => el.userId === meetingParticipant.id
                );
            }
            if (screenShareActive) {
                screenShareView = props.screenShareViews.find(
                    (el) => el.userId === props.meetingParticipants[i].id
                );
            }
            videoItems.push(
                <VideoListItem
                    key={meetingParticipant.id}
                    userInfo={meetingParticipant}
                    isMainVideo={isMainVideo}
                    isSelf={false}
                    isCurrentActiveUser={
                        meetingParticipant.id ===
                        props.meetingInfo.currentActiveUser
                    }
                    isActiveSpeaker={
                        meetingParticipant.id === props.activeSpeakerId
                    }
                    videoView={videoView}
                    onChangeActiveUser={props.alcChangeActiveUser}
                    onSetUserVisibility={props.alcSetUserVisibility}
                    isRevokeable={
                        meetingParticipant.speakerPermissionState ===
                        SPEAKER_PERMISSION_STATE.GRANTED
                    }
                    onRevokeSpeakerPermission={() =>
                        props.alcSetSpeakerPermission(
                            meetingParticipant.id,
                            false
                        )
                    }
                    screenShareActive={screenShareActive}
                    screenShareView={screenShareView}
                    meetingType={props.meetingInfo.type}
                />
            );
        }
    }
    const isMainVideo = props.clientInfo.id === props.mainVideoId;
    const videoActive = props.clientInfo.deviceInfo.video;
    const screenShareActive = props.clientInfo.deviceInfo.screen;

    let ownVideoView = undefined;
    let ownScreenShareView = undefined;

    if (
        videoActive &&
        !props.settingsPanelShown &&
        (!isMainVideo ||
            (screenShareActive &&
                props.clientInfo.userMode &&
                props.clientInfo.userMode.screenSharing ===
                    SCREEN_SHARING_MODES.SCREEN))
    ) {
        ownVideoView = props.ownVideoView;
    }
    if (screenShareActive) {
        ownScreenShareView = props.ownScreenShareView;
    }

    return (
        <div
            className={
                'participant-list' +
                (props.controlsVisible ? ' controlsVisible' : '')
            }
        >
            <div className="remote-videos">
                <Scrollbars>{videoItems}</Scrollbars>
            </div>
            <div className="local-video">
                <VideoListItem
                    key={'self'}
                    userInfo={props.clientInfo}
                    isMainVideo={isMainVideo}
                    isSelf={true}
                    isCurrentActiveUser={
                        props.clientInfo.id ===
                        props.meetingInfo.currentActiveUser
                    }
                    isActiveSpeaker={
                        props.clientInfo.id === props.activeSpeakerId
                    }
                    videoView={ownVideoView}
                    onChangeActiveUser={props.alcChangeActiveUser}
                    onSetUserVisibility={props.alcSetUserVisibility}
                    isRevokeable={false}
                    screenShareActive={screenShareActive}
                    screenShareView={ownScreenShareView}
                    meetingType={props.meetingInfo.type}
                />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        meetingParticipants: state.meetings.meetingParticipants,
        meetingInfo: state.meetings.meetingInfo,
        activeSpeakerId: state.meetings.activeSpeakerId,
        videoViews: state.meetings.videoViews,
        screenShareViews: state.meetings.screenShareViews,
        ownVideoView: state.meetings.ownVideoView,
        ownScreenShareView: state.meetings.ownScreenShareView,
        clientInfo: state.meetings.clientInfo,
        mainVideoId: state.meetings.mainVideoId,
        controlsVisible: state.meetings.controlsVisible,
        settingsPanelShown: state.deviceSettings.settingsPanelShown,
    };
};

const mapDispatchToProps = {
    alcChangeActiveUser,
    alcSetSpeakerPermission,
    alcSetUserVisibility,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingVideoList);
