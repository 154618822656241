import * as types from './actionTypes';

export const initialState = {
    isLoading: false,
    meetings: [],
    errorMessage: undefined,
};

/**
 *
 * @param state
 * @param action
 * @returns new state
 */
export default function meetingsManager(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_MEETINGS:
            return {
                ...state,
                isLoading: true,
                errorMessage: undefined,
            };
        case types.REQUEST_MEETINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                meetings: action.meetings,
            };
        case types.REQUEST_MEETINGS_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.errorMessage,
            };
        default:
            return state;
    }
}
