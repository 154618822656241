import * as types from './actionTypes';
import {
    AL_APPLICATIONS,
    MEETING_TYPES,
    SA_APPLICATIONS,
    SPEAKER_PERMISSION_STATE,
    RESPONSIVE_MODES,
    ADMITTANCE_STATE,
} from '../../constants/constants';
import { adiaPadTileSettings } from '../adiaPad/adiaPadTileSettings';

export const initialState = {
    alcInitialized: false,
    alcAuthenticated: false,
    alcMeetingJoined: false,
    alcReconnecting: false,
    webRtcInitialized: false,
    webRtcFlags: {},
    webRtcMeetingJoined: false,
    webRtcReconnecting: false,
    clientInfo: null,
    meetingInfo: undefined,
    ownVideoView: undefined,
    ownScreenShareView: undefined,
    videoViews: [],
    screenShareViews: [],
    meetingParticipants: [],
    podiumParticipants: [],
    waitingRoom: [],
    mainVideoId: null,
    activeSpeakerId: null,
    screenShareOn: false,
    sharedApplicationsShown: false,
    ownApplicationId: null,
    chatMessagePending: false,
    documentPending: false,
    saAuthToken: undefined,
    controlsVisible: true,
    menuVisible: false,
    infoPanelVisible: false,
    participantActionsVisible: false,
    completeMeetingDialogVisible: false,
    leaveMeetingDialogVisible: false,
    fullscreenActive: false,
    serverTimeDiff: 0,
    saIFrameInitialized: false,
    saIFrameReady: false,
    recordingConfirmationDialogVisible: false,
    sidePanelIFrameInitialized: false,
    sidePanelIFrameReady: false,
    activeSidePanelApplicationId: null,
    lastActiveSidePanelApplicationId: null,
    streamChatMessagePending: false,
    streamSurveyPending: false,
    streamDocumentPending: false,
    participantRequestPending: false,
    speakerPermissionLoading: false,
    requestSignatureAvailable: false,
    adiaPadTiles: adiaPadTileSettings.getDefaults(),
    adiaPadEnabled: true,
    addAdviserDialogVisible: false,
    transferCallDialogVisible: false,
    transferCallError: null,
    transferCallInProgress: false,
    currentMeetingInvitations: {},
    sidePanelMenuVisible: false,
    confirmJoinDialogVisible: false,
};

export default function meetings(state = initialState, action) {
    switch (action.type) {
        case types.WEBRTC_INIT_SUCCESS: {
            return {
                ...state,
                webRtcInitialized: true,
                webRtcFlags: action.webRtcFlags,
            };
        }
        case types.ALC_INITIALIZED: {
            return {
                ...initialState,
                alcInitialized: true,
                alcMeetingJoined: false,
                webRtcInitialized: state.webRtcInitialized,
                webRtcMeetingJoined: state.webRtcMeetingJoined,
                webRtcFlags: state.webRtcFlags,
                adiaPadTiles: state.adiaPadTiles,
                adiaPadEnabled: state.adiaPadEnabled,
            };
        }
        case types.ALC_RECONNECTING: {
            return {
                ...state,
                alcReconnecting: true,
            };
        }
        case types.ALC_SESSION_RESTORED: {
            return {
                ...state,
                alcReconnecting: false,
            };
        }
        case types.ALC_JOIN_MEETING_SUCCESS: {
            let sharedApplicationsShown = state.sharedApplicationsShown;
            if (action.meetingInfo.currentApp) {
                sharedApplicationsShown = true;
            }

            let activeSidePanelApplicationId;

            if (action.responsiveMode < RESPONSIVE_MODES.MEDIUM) {
                activeSidePanelApplicationId = null;
            } else {
                switch (action.meetingInfo.type) {
                    case MEETING_TYPES.PODIUM: {
                        if (
                            action.meetingInfo.additionalMetadata &&
                            action.meetingInfo.additionalMetadata.hideChatTab
                        ) {
                            activeSidePanelApplicationId =
                                AL_APPLICATIONS.PARTICIPANTS;
                        } else {
                            activeSidePanelApplicationId =
                                AL_APPLICATIONS.PARTICIPANTS;
                        }
                        break;
                    }
                    case MEETING_TYPES.CALL_MANAGER:
                    case MEETING_TYPES.MEETING: {
                        activeSidePanelApplicationId = state.adiaPadEnabled
                            ? AL_APPLICATIONS.ADIA_PAD
                            : SA_APPLICATIONS.CHAT;
                        break;
                    }
                    default: {
                        activeSidePanelApplicationId = SA_APPLICATIONS.CHAT;
                    }
                }
            }

            return {
                ...state,
                alcMeetingJoined: true,
                meetingInfo: action.meetingInfo,
                sharedApplicationsShown,
                activeSidePanelApplicationId,
                lastActiveSidePanelApplicationId: activeSidePanelApplicationId,
                saAuthToken: action.saAuth ? action.saAuth.token : undefined,
                currentMeetingInvitations: {},
            };
        }
        case types.SET_ADIA_PAD_SETTINGS: {
            return {
                ...state,
                adiaPadTiles: action.tiles,
                adiaPadEnabled: action.tiles.length > 0,
            };
        }
        case types.ALC_CLIENT_INFO_UPDATE: {
            return {
                ...state,
                alcAuthenticated: action.clientInfo.isAdviser,
                clientInfo: action.clientInfo,
            };
        }
        case types.ALC_SET_SPEAKER_PERMISSION: {
            const podiumParticipants = state.podiumParticipants;
            // on declining immediately hide request in UI (before podiumParticipantUpdate)
            if (!action.permission) {
                const index = podiumParticipants.findIndex(
                    (el) => el.id === action.userId
                );
                if (index !== -1) {
                    podiumParticipants[index].speakerPermissionState =
                        SPEAKER_PERMISSION_STATE.NONE;
                }
            }
            return {
                ...state,
                podiumParticipants,
                speakerPermissionLoading: true,
            };
        }
        case types.ALC_INVITATION_UPDATE: {
            return {
                ...state,
                currentMeetingInvitations: {
                    ...state.currentMeetingInvitations,
                    [action.invitationInfo.toAdviser.id]: action.invitationInfo,
                },
            };
        }
        case types.ALC_SET_SPEAKER_PERMISSION_SUCCESS:
        case types.ALC_SET_SPEAKER_PERMISSION_FAILURE: {
            return {
                ...state,
                speakerPermissionLoading: false,
            };
        }
        case types.WEBRTC_JOIN_SUCCESS: {
            return {
                ...state,
                webRtcMeetingJoined: true,
                ownVideoView: action.ownVideoView,
            };
        }
        case types.WEBRTC_USERJOINED: {
            if (action.videoView.type === 'remote') {
                const videoViews = state.videoViews.slice();
                const previousIndex = videoViews.findIndex(
                    (el) => el.userId === action.videoView.userId
                );
                if (previousIndex !== -1) {
                    videoViews[previousIndex] = action.videoView;
                } else {
                    videoViews.push(action.videoView);
                }
                const mainVideoId = determineMainVideoId(
                    state.activeSpeakerId,
                    state.meetingParticipants,
                    videoViews,
                    state.ownVideoView,
                    state.meetingInfo
                );
                return {
                    ...state,
                    videoViews: videoViews,
                    mainVideoId,
                };
            } else if (action.videoView.type === 'screen') {
                const screenShareViews = state.screenShareViews.slice();
                const previousIndex = screenShareViews.findIndex(
                    (el) => el.userId === action.videoView.userId
                );
                if (previousIndex !== -1) {
                    screenShareViews[previousIndex] = action.videoView;
                } else {
                    screenShareViews.push(action.videoView);
                }
                /*
                const mainVideoId = determineMainVideoId(
                    state.activeSpeakerId,
                    state.meetingParticipants,
                    videoViews,
                    state.ownVideoView,
                    state.meetingInfo
                );
                 */
                return {
                    ...state,
                    screenShareViews: screenShareViews,
                    //mainVideoId
                };
            } else {
                return state;
            }
        }
        case types.WEBRTC_USERLEFT: {
            if (action.userType === 'remote') {
                let videoViews = state.videoViews.slice();
                const indexToDelete = videoViews.findIndex(
                    (el) => el.userId === action.userId
                );
                if (indexToDelete !== -1) {
                    videoViews.splice(indexToDelete, 1);
                }
                const mainVideoId = determineMainVideoId(
                    state.activeSpeakerId,
                    state.meetingParticipants,
                    videoViews,
                    state.ownVideoView,
                    state.meetingInfo
                );
                return {
                    ...state,
                    videoViews: videoViews,
                    mainVideoId,
                };
            } else if (action.userType === 'screen') {
                let screenShareViews = state.screenShareViews.slice();
                const indexToDelete = screenShareViews.findIndex(
                    (el) => el.userId === action.userId
                );
                if (indexToDelete !== -1) {
                    screenShareViews.splice(indexToDelete, 1);
                }
                /*
                const mainVideoId = determineMainVideoId(
                    state.activeSpeakerId,
                    state.meetingParticipants,
                    videoViews,
                    state.ownVideoView,
                    state.meetingInfo
                );
                 */
                return {
                    ...state,
                    screenShareViews: screenShareViews,
                    //mainVideoId
                };
            } else {
                return state;
            }
        }
        case types.WEBRTC_SPEAKER_CHANGED: {
            // ignore self
            if (action.userId === state.clientInfo.id) {
                return state;
            } else {
                const mainVideoId = determineMainVideoId(
                    action.userId,
                    state.meetingParticipants,
                    state.videoViews,
                    state.ownVideoView,
                    state.meetingInfo
                );
                return {
                    ...state,
                    activeSpeakerId: action.userId,
                    mainVideoId,
                };
            }
        }
        case types.WEBRTC_START_SCREENSHARE_SUCCESS: {
            return {
                ...state,
                ownScreenShareView: action.screenShareView,
            };
        }
        case types.WEBRTC_STOP_SCREENSHARE_SUCCESS: {
            return {
                ...state,
                ownScreenShareView: undefined,
            };
        }
        case types.WEBRTC_CLOSE: {
            return {
                ...state,
                webRtcInitialized: false,
                webRtcFlags: {},
            };
        }
        case types.WEBRTC_RECONNECTING: {
            return {
                ...state,
                webRtcReconnecting: true,
            };
        }
        case types.WEBRTC_RECONNECTING_SUCCESS: {
            return {
                ...state,
                webRtcReconnecting: false,
            };
        }
        case types.ALC_MEETING_PARTICIPANTS_UPDATE: {
            const adviser = [];
            const clients = [];
            action.meetingParticipants.forEach((el) => {
                if (el.isAdviser) {
                    adviser.push(el);
                } else {
                    clients.push(el);
                }
            });
            const meetingParticipants = adviser.concat(clients);

            const mainVideoId = determineMainVideoId(
                state.activeSpeakerId,
                meetingParticipants,
                state.videoViews,
                state.ownVideoView,
                state.meetingInfo
            );

            // participants tab not open and pending request
            const participantRequestPending = !!(
                state.activeSidePanelApplicationId !==
                    AL_APPLICATIONS.PARTICIPANTS &&
                action.meetingParticipants.find(
                    (el) =>
                        el.speakerPermissionState ===
                            SPEAKER_PERMISSION_STATE.REQUESTING || el.handRaised
                )
            );
            return {
                ...state,
                meetingParticipants,
                participantRequestPending:
                    state.participantRequestPending ||
                    participantRequestPending,
                mainVideoId,
            };
        }
        case types.ALC_PODIUM_PARTICIPANTS_UPDATE: {
            // participants tab not open and pending request
            const participantRequestPending = !!(
                state.activeSidePanelApplicationId !==
                    AL_APPLICATIONS.PARTICIPANTS &&
                action.podiumParticipants.find(
                    (el) =>
                        el.speakerPermissionState ===
                        SPEAKER_PERMISSION_STATE.REQUESTING
                )
            );
            return {
                ...state,
                podiumParticipants: action.podiumParticipants,
                participantRequestPending:
                    state.participantRequestPending ||
                    participantRequestPending,
            };
        }
        case types.ALC_MEETING_INFO_UPDATE: {
            const mainVideoId = determineMainVideoId(
                state.activeSpeakerId,
                state.meetingParticipants,
                state.videoViews,
                state.ownVideoView,
                action.meetingInfo
            );
            let sharedApplicationsShown = false;
            if (
                action.meetingInfo.currentApp ||
                (state.ownApplicationId && state.sharedApplicationsShown)
            ) {
                sharedApplicationsShown = true;
            }

            return {
                ...state,
                meetingInfo: action.meetingInfo,
                mainVideoId,
                sharedApplicationsShown,
            };
        }
        case types.ALC_WAITING_ROOM_UPDATE: {
            // participants tab not open and pending request
            const participantRequestPending = !!(
                state.activeSidePanelApplicationId !==
                    AL_APPLICATIONS.PARTICIPANTS &&
                action.waitingRoom.find(
                    (el) => el.admittanceState === ADMITTANCE_STATE.REQUESTED
                )
            );
            return {
                ...state,
                waitingRoom: action.waitingRoom,
                participantRequestPending:
                    state.participantRequestPending ||
                    participantRequestPending,
            };
        }
        case types.ALC_SET_CURRENT_APP: {
            let ownApplicationId = action.sharedApplicationId;
            if (!ownApplicationId) {
                ownApplicationId = state.ownApplicationId;
            }
            return {
                ...state,
                ownApplicationId,
            };
        }
        case types.ALC_UPDATE_SERVERTIME_DIFF: {
            return {
                ...state,
                serverTimeDiff: action.serverTimeDiff,
            };
        }
        case types.ALC_CLOSE: {
            return {
                ...state,
                alcInitialized: false,
            };
        }
        case types.JOIN_MEETING_SUCCESS: {
            const mainVideoId = determineMainVideoId(
                state.activeSpeakerId,
                state.meetingParticipants,
                state.videoViews,
                state.ownVideoView,
                state.meetingInfo
            );
            return {
                ...state,
                mainVideoId,
            };
        }
        case types.SA_SHOW: {
            return {
                ...state,
                sharedApplicationsShown: true,
                ownApplicationId: SA_APPLICATIONS.SHOWBOARD,
            };
        }
        case types.SA_SWITCH_OWN: {
            return {
                ...state,
                ownApplicationId: action.sharedApplicationId,
            };
        }
        case types.SA_HIDE: {
            return {
                ...state,
                sharedApplicationsShown: false,
                ownApplicationId: null,
            };
        }
        case types.SA_REQUEST_SIGNATURE_STATE: {
            return {
                ...state,
                requestSignatureAvailable: action.requestSignatureAvailable,
            };
        }
        case types.DOCUMENTS_NEW_UPLOAD: {
            if (
                state.activeSidePanelApplicationId === SA_APPLICATIONS.DOCUMENTS
            ) {
                return state;
            } else {
                return {
                    ...state,
                    documentPending: true,
                };
            }
        }
        case types.CHAT_NEW_MESSAGE: {
            if (state.activeSidePanelApplicationId === SA_APPLICATIONS.CHAT) {
                return state;
            } else {
                return {
                    ...state,
                    chatMessagePending: true,
                };
            }
        }
        case types.ALC_LEAVE_MEETING: {
            return {
                ...state,
                alcMeetingJoined: false,
                meetingInfo: undefined,
                meetingParticipants: [],
                podiumParticipants: [],
                waitingRoom: [],
            };
        }
        case types.ALC_MEETING_COMPLETED:
        case types.ALC_TRANSFERRED:
        case types.ALC_KICKED: {
            return {
                ...state,
                alcMeetingJoined: false,
                meetingInfo: undefined,
                meetingParticipants: [],
                podiumParticipants: [],
                waitingRoom: [],
                mainVideoId: null,
                activeSpeakerId: null,
                sharedApplicationsShown: false,
                ownApplicationId: null,
                chatMessagePending: false,
                documentPending: false,
                menuVisible: false,
                sidePanelMenuVisible: false,
                infoPanelVisible: false,
                recordingConfirmationDialogVisible: false,
                streamChatMessagePending: false,
                streamSurveyPending: false,
                streamDocumentPending: false,
                participantRequestPending: false,
                speakerPermissionLoading: false,
                requestSignatureAvailable: false,
            };
        }
        case types.WEBRTC_LEAVE:
        case types.WEBRTC_RECONNECTING_FAILURE:
        case types.WEBRTC_JOIN_FAILURE: {
            return {
                ...state,
                webRtcMeetingJoined: false,
                ownVideoView: undefined,
                videoViews: [],
                ownScreenShareView: undefined,
                screenShareViews: [],
                screenShareOn: false,
                webRtcReconnecting: false,
            };
        }
        case types.LEAVE_MEETING: {
            return {
                ...state,
                mainVideoId: null,
                activeSpeakerId: null,
                sharedApplicationsShown: false,
                ownApplicationId: null,
                chatMessagePending: false,
                documentPending: false,
                menuVisible: false,
                sidePanelMenuVisible: false,
                infoPanelVisible: false,
                participantActionsVisible: false,
                completeMeetingDialogVisible: false,
                leaveMeetingDialogVisible: false,
                recordingConfirmationDialogVisible: false,
                streamChatMessagePending: false,
                streamSurveyPending: false,
                streamDocumentPending: false,
                participantRequestPending: false,
                speakerPermissionLoading: false,
                requestSignatureAvailable: false,
            };
        }
        case types.CONTROLS_SHOW: {
            return {
                ...state,
                controlsVisible: true,
            };
        }
        case types.CONTROLS_HIDE: {
            return {
                ...state,
                controlsVisible: false,
                menuVisible: false,
                sidePanelMenuVisible: false,
                infoPanelVisible: false,
            };
        }
        case types.MENU_SHOW: {
            return {
                ...state,
                menuVisible: true,
                infoPanelVisible: false,
                sidePanelMenuVisible: false,
            };
        }
        case types.MENU_HIDE: {
            return {
                ...state,
                menuVisible: false,
            };
        }
        case types.SIDE_PANEL_MENU_SHOW: {
            return {
                ...state,
                sidePanelMenuVisible: true,
                infoPanelVisible: false,
                menuVisible: false,
            };
        }
        case types.SIDE_PANEL_MENU_HIDE: {
            return {
                ...state,
                sidePanelMenuVisible: false,
            };
        }
        case types.INFO_PANEL_SHOW: {
            return {
                ...state,
                infoPanelVisible: true,
                menuVisible: false,
                sidePanelMenuVisible: false,
            };
        }
        case types.INFO_PANEL_HIDE: {
            return {
                ...state,
                infoPanelVisible: false,
            };
        }
        case types.PARTICIPANT_ACTIONS_SHOW: {
            return {
                ...state,
                participantActionsVisible: true,
            };
        }
        case types.PARTICIPANT_ACTIONS_HIDE: {
            return {
                ...state,
                participantActionsVisible: false,
            };
        }
        case types.ADD_ADVISER_DIALOG_SHOW: {
            return {
                ...state,
                addAdviserDialogVisible: true,
            };
        }
        case types.ADD_ADVISER_DIALOG_HIDE: {
            return {
                ...state,
                addAdviserDialogVisible: false,
            };
        }
        case types.TRANSFER_CALL_DIALOG_SHOW: {
            return {
                ...state,
                transferCallDialogVisible: true,
                transferCallError: null,
            };
        }
        case types.TRANSFER_CALL_DIALOG_HIDE: {
            return {
                ...state,
                transferCallDialogVisible: false,
            };
        }
        case types.ALC_TRANSFER_CALL: {
            return {
                ...state,
                transferCallInProgress: true,
            };
        }
        case types.ALC_TRANSFER_CALL_FAILED: {
            return {
                ...state,
                transferCallDialogVisible: true,
                transferCallError: action.error,
                transferCallInProgress: false,
            };
        }
        case types.ALC_TRANSFER_CALL_SUCCESS: {
            return {
                ...state,
                transferCallDialogVisible: false,
                transferCallError: null,
                transferCallInProgress: false,
            };
        }
        case types.COMPLETE_MEETING_DIALOG_SHOW: {
            return {
                ...state,
                completeMeetingDialogVisible: true,
            };
        }
        case types.COMPLETE_MEETING_DIALOG_HIDE: {
            return {
                ...state,
                completeMeetingDialogVisible: false,
            };
        }
        case types.LEAVE_MEETING_DIALOG_SHOW: {
            return {
                ...state,
                leaveMeetingDialogVisible: true,
            };
        }
        case types.LEAVE_MEETING_DIALOG_HIDE: {
            return {
                ...state,
                leaveMeetingDialogVisible: false,
            };
        }
        case types.FULLSCREEN_CHANGE: {
            return {
                ...state,
                fullscreenActive: action.fullscreenActive,
            };
        }
        case types.SA_IFRAME_NEW_REF: {
            return {
                ...state,
                saIFrameReady: false,
                saIFrameInitialized: false,
            };
        }
        case types.SA_IFRAME_INIT_CONFIRMED: {
            return {
                ...state,
                saIFrameInitialized: true,
            };
        }
        case types.SA_IFRAME_READY: {
            return {
                ...state,
                saIFrameReady: true,
            };
        }
        case types.SIDE_PANEL_IFRAME_NEW_REF: {
            return {
                ...state,
                sidePanelIFrameReady: false,
                sidePanelIFrameInitialized: false,
            };
        }
        case types.SIDE_PANEL_IFRAME_INIT_CONFIRMED: {
            return {
                ...state,
                sidePanelIFrameInitialized: true,
            };
        }
        case types.SIDE_PANEL_IFRAME_READY: {
            return {
                ...state,
                sidePanelIFrameReady: true,
            };
        }
        case types.SIDE_PANEL_SHOW_APPLICATION: {
            switch (action.applicationId) {
                case SA_APPLICATIONS.STREAM_CHAT: {
                    return {
                        ...state,
                        activeSidePanelApplicationId: action.applicationId,
                        lastActiveSidePanelApplicationId: action.applicationId
                            ? action.applicationId
                            : state.lastActiveSidePanelApplicationId,
                        streamChatMessagePending: false,
                        chatMessagePending: false,
                    };
                }
                case SA_APPLICATIONS.SURVEY_MANAGER: {
                    return {
                        ...state,
                        activeSidePanelApplicationId: action.applicationId,
                        lastActiveSidePanelApplicationId: action.applicationId
                            ? action.applicationId
                            : state.lastActiveSidePanelApplicationId,
                        streamSurveyPending: false,
                    };
                }
                case SA_APPLICATIONS.DOCUMENTS_MANAGER: {
                    return {
                        ...state,
                        activeSidePanelApplicationId: action.applicationId,
                        lastActiveSidePanelApplicationId: action.applicationId
                            ? action.applicationId
                            : state.lastActiveSidePanelApplicationId,
                        streamDocumentPending: false,
                        documentPending: false,
                    };
                }
                case AL_APPLICATIONS.PARTICIPANTS: {
                    return {
                        ...state,
                        activeSidePanelApplicationId: action.applicationId,
                        lastActiveSidePanelApplicationId: action.applicationId
                            ? action.applicationId
                            : state.lastActiveSidePanelApplicationId,
                        participantRequestPending: false,
                    };
                }
                case SA_APPLICATIONS.CHAT: {
                    return {
                        ...state,
                        activeSidePanelApplicationId: action.applicationId,
                        lastActiveSidePanelApplicationId: action.applicationId
                            ? action.applicationId
                            : state.lastActiveSidePanelApplicationId,
                        chatMessagePending: false,
                        streamChatMessagePending: false,
                    };
                }
                case SA_APPLICATIONS.DOCUMENTS: {
                    return {
                        ...state,
                        activeSidePanelApplicationId: action.applicationId,
                        lastActiveSidePanelApplicationId: action.applicationId
                            ? action.applicationId
                            : state.lastActiveSidePanelApplicationId,
                        documentPending: false,
                        streamDocumentPending: false,
                    };
                }
                default: {
                    return {
                        ...state,
                        activeSidePanelApplicationId: action.applicationId,
                        lastActiveSidePanelApplicationId: action.applicationId
                            ? action.applicationId
                            : state.lastActiveSidePanelApplicationId,
                    };
                }
            }
        }
        case types.STREAM_CHAT_NEW_MESSAGE: {
            if (
                state.activeSidePanelApplicationId ===
                SA_APPLICATIONS.STREAM_CHAT
            ) {
                return state;
            } else {
                return {
                    ...state,
                    streamChatMessagePending: true,
                };
            }
        }
        case types.STREAM_CHAT_NEW_DOCUMENT: {
            if (
                state.activeSidePanelApplicationId ===
                SA_APPLICATIONS.DOCUMENTS_MANAGER
            ) {
                return state;
            } else {
                return {
                    ...state,
                    streamDocumentPending: true,
                };
            }
        }
        case types.STREAM_CHAT_NEW_SURVEY: {
            if (
                state.activeSidePanelApplicationId ===
                SA_APPLICATIONS.SURVEY_MANAGER
            ) {
                return state;
            } else {
                return {
                    ...state,
                    streamSurveyPending: true,
                };
            }
        }
        case types.RECORDING_CONFIRMATION_DIALOG_SHOW: {
            return {
                ...state,
                recordingConfirmationDialogVisible: true,
            };
        }
        case types.RECORDING_CONFIRMATION_DIALOG_HIDE: {
            return {
                ...state,
                recordingConfirmationDialogVisible: false,
            };
        }
        case types.CONFIRM_JOIN_DIALOG_SHOW: {
            return {
                ...state,
                confirmJoinDialogVisible: true,
            };
        }
        // TODO: temporary workaround for no user gesture issue
        case types.CONFIRM_JOIN_DIALOG_CONFIRM:
        case types.CONFIRM_JOIN_DIALOG_CANCEL: {
            return {
                ...state,
                confirmJoinDialogVisible: false,
            };
        }
        default:
            return state;
    }

    function determineMainVideoId(
        activeSpeakerId,
        meetingParticipants,
        videoViews,
        ownVideoView,
        meetingInfo
    ) {
        if (!meetingInfo) {
            return null;
        }
        // if shared application shown no video is big
        else if (meetingInfo.currentApp) {
            return null;
            // if currentActiveUser is set
        } else if (meetingInfo.currentActiveUser) {
            return meetingInfo.currentActiveUser;
            // else current speaker is big (if in meeting)
        } else if (
            activeSpeakerId &&
            meetingParticipants.find((el) => el.id === activeSpeakerId)
        ) {
            return activeSpeakerId;
            // ownVideo is big if no one else in meeting (excluding standalone and recorder users)
        } else if (
            ownVideoView &&
            !meetingParticipants.find((participant) => {
                return (
                    participant.id !== ownVideoView.userId &&
                    !participant.standalone &&
                    !participant.recorder &&
                    !(
                        meetingInfo.type === MEETING_TYPES.WEBINAR &&
                        !participant.speakerPermissionState ===
                            SPEAKER_PERMISSION_STATE.GRANTED
                    )
                );
            })
        ) {
            return ownVideoView.userId;
            // if nothing else applies just show the first webrtc user found
        } else {
            for (let i = 0; i < videoViews.length; i++) {
                if (
                    meetingParticipants.findIndex(
                        (el) =>
                            el.id === videoViews[i].userId &&
                            (el.isAdviser ||
                                meetingInfo.type !== MEETING_TYPES.WEBINAR ||
                                el.speakerPermissionState ===
                                    SPEAKER_PERMISSION_STATE.GRANTED)
                    ) !== -1
                ) {
                    return videoViews[i].userId;
                }
            }
        }
        // if no webrtc views select any user (not self, standalone or recorder)
        if (ownVideoView && meetingParticipants.length > 1) {
            const participant = meetingParticipants.find(
                (el) =>
                    el.id !== ownVideoView.userId &&
                    !el.recorder &&
                    !el.standalone &&
                    !(
                        meetingInfo.type === MEETING_TYPES.WEBINAR &&
                        !el.speakerPermissionState ===
                            SPEAKER_PERMISSION_STATE.GRANTED
                    )
            );
            if (participant) {
                return participant.id;
            }
        }
        return null;
    }
}
