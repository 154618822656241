import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
    sidePanelIFrameNewRef,
    sidePanelIFrameLoad,
    sidePanelShowApplication,
} from './actions';
import {
    AL_APPLICATIONS,
    KELDANO_APPLICATIONS,
} from '../../constants/constants';
import ParticipantList from './ParticipantList';
import AdiaPad from '../adiaPad/AdiaPad';
import TranslatedString from '../base/i18n/TranslatedString';

function SidePanel() {
    const dispatch = useDispatch();

    const iFrameRef = useRef(null);
    const setRef = useCallback(
        (node) => {
            iFrameRef.current = node;
            dispatch(sidePanelIFrameNewRef(iFrameRef));
        },
        [dispatch]
    );

    const applicationsUrl = useSelector(
        (state) => state.auth.publicServiceInfo.meetingsSettings.applicationsUrl
    );
    const sidePanelIFrameReady = useSelector(
        (state) => state.meetings.sidePanelIFrameReady
    );
    const saAuthToken = useSelector((state) => state.meetings.saAuthToken);

    const currentActiveSidePanelApplicationId = useSelector(
        (state) => state.meetings.activeSidePanelApplicationId
    );
    const lastActiveSidePanelApplicationId = useSelector(
        (state) => state.meetings.lastActiveSidePanelApplicationId
    );

    //const mobileLayout = responsiveMode < RESPONSIVE_MODES.FULL;
    const mobileLayout = false; // todo: is only quick fix for Mobile-View

    const activeSidePanelApplicationId = mobileLayout
        ? lastActiveSidePanelApplicationId
        : currentActiveSidePanelApplicationId;

    const sidePanelIFrameActive = !useSelector(
        (state) =>
            state.meetings.meetingInfo.additionalMetadata &&
            state.meetings.meetingInfo.additionalMetadata.hideChatTab &&
            state.meetings.meetingInfo.additionalMetadata.hideDocumentsTab &&
            state.meetings.meetingInfo.additionalMetadata.hidePollsTab
    );

    const iFrameUrl = useMemo(() => {
        const retUrl =
            applicationsUrl + '/' + KELDANO_APPLICATIONS.SHARED_APPLICATIONS;
        if (!saAuthToken) {
            return retUrl;
        } else {
            if (retUrl.includes('?')) {
                return retUrl + '&jws=' + saAuthToken;
            } else {
                return retUrl + '?jws=' + saAuthToken;
            }
        }
    }, [applicationsUrl, saAuthToken]);

    const sidePanelIFrameHidden =
        !(
            sidePanelIFrameReady &&
            activeSidePanelApplicationId !== AL_APPLICATIONS.PARTICIPANTS &&
            activeSidePanelApplicationId !== AL_APPLICATIONS.ADIA_PAD
        ) || activeSidePanelApplicationId === null;

    // side-panel-header
    return (
        <div
            className={classNames('side-panel', {
                darkBackground:
                    activeSidePanelApplicationId === AL_APPLICATIONS.ADIA_PAD,
            })}
        >
            <div className="side-panel-header">
                <TranslatedString
                    id={'appTitle-' + activeSidePanelApplicationId}
                />
                {!mobileLayout && (
                    <div
                        className="btnClose"
                        onClick={() => dispatch(sidePanelShowApplication(null))}
                    >
                        <i className="icon-close" />
                    </div>
                )}
            </div>
            <div className="side-panel-iframe-container">
                {sidePanelIFrameActive && (
                    <iframe
                        className={classNames('side-panel-iframe', {
                            hidden: sidePanelIFrameHidden,
                        })}
                        title="Side Panel"
                        src={iFrameUrl}
                        ref={setRef}
                        onLoad={(e) => dispatch(sidePanelIFrameLoad(e))}
                    />
                )}
                {activeSidePanelApplicationId ===
                    AL_APPLICATIONS.PARTICIPANTS && <ParticipantList />}
                {activeSidePanelApplicationId === AL_APPLICATIONS.ADIA_PAD && (
                    <AdiaPad />
                )}
            </div>
        </div>
    );
}

export default SidePanel;
