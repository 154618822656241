export const WEBRTC_SETTINGS_START_SUCCESS = 'WEBRTC_SETTINGS_START_SUCCESS';
export const WEBRTC_CHANGE_CAM = 'WEBRTC_CHANGE_CAM';
export const WEBRTC_CHANGE_CAM_SUCCESS = 'WEBRTC_CHANGE_CAM_SUCCESS';
export const WEBRTC_CHANGE_MIC = 'WEBRTC_CHANGE_MIC';
export const WEBRTC_CHANGE_MIC_SUCCESS = 'WEBRTC_CHANGE_MIC_SUCCESS';
export const WEBRTC_CHANGE_RES = 'WEBRTC_CHANGE_RES';
export const WEBRTC_CHANGE_RES_SUCCESS = 'WEBRTC_CHANGE_RES_SUCCESS';
export const WEBRTC_CHANGE_FPS = 'WEBRTC_CHANGE_FPS';
export const WEBRTC_CHANGE_FPS_SUCCESS = 'WEBRTC_CHANGE_FPS_SUCCESS';
export const WEBRTC_CHANGE_HQ_AUDIO = 'CHANGE_HQ_AUDIO';
export const WEBRTC_CHANGE_CHANGE_HQ_AUDIO_SUCCESS =
    'WEBRTC_CHANGE_CHANGE_HQ_AUDIO_SUCCESS';
export const WEBRTC_CHANGE_COMMUNICATION_MODE =
    'WEBRTC_CHANGE_COMMUNICATION_MODE';
export const WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS =
    'WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS';
export const WEBRTC_SETTINGS_ERROR = 'WEBRTC_SETTINGS_ERROR';
export const WEBRTC_MUTE_AUDIO = 'WEBRTC_MUTE_AUDIO';
export const WEBRTC_MUTE_VIDEO = 'WEBRTC_MUTE_VIDEO';
export const WEBRTC_SET_BACKGROUND_EFFECT = 'WEBRTC_SET_BACKGROUND_EFFECT';
export const WEBRTC_SET_BACKGROUND_EFFECT_SUCCESS =
    'WEBRTC_SET_BACKGROUND_EFFECT_SUCCESS';
export const SETTINGS_NEW_LOCAL_BG_IMAGE = 'SETTINGS_NEW_LOCAL_BG_IMAGE';
export const SETTINGS_SET_NOT_MIRROR_OWN = 'SETTINGS_SET_NOT_MIRROR_OWN';
export const WEBRTC_REFRESH_DEVICE_LISTS = 'WEBRTC_REFRESH_DEVICE_LISTS';
export const WEBRTC_REFRESH_DEVICE_LISTS_SUCCESS =
    'WEBRTC_REFRESH_DEVICE_LISTS_SUCCESS';
export const WEBRTC_APPLY_RECOVERY_SETTINGS = 'WEBRTC_APPLY_RECOVERY_SETTINGS';
export const WEBRTC_APPLY_RECOVERY_SETTINGS_SUCCESS =
    'WEBRTC_APPLY_RECOVERY_SETTINGS_SUCCESS';
export const SETTINGS_RECOVERY_START = 'SETTINGS_RECOVERY_START';
export const SETTINGS_RECOVERY_START_SUCCESS =
    'SETTINGS_RECOVERY_START_SUCCESS';
export const SETTINGS_RECOVERY_CHANGE_CAM = 'SETTINGS_RECOVERY_CHANGE_CAM';
export const SETTINGS_RECOVERY_CHANGE_MIC = 'SETTINGS_RECOVERY_CHANGE_MIC';
export const SETTINGS_RECOVERY_CHANGE_RES = 'SETTINGS_RECOVERY_CHANGE_RES';
export const SETTINGS_RECOVERY_CHANGE_COMMUNICATION_MODE =
    'SETTINGS_RECOVERY_CHANGE_COMMUNICATION_MODE';
export const SETTINGS_RECOVERY_CHANGE_COMMUNICATION_MODE_SUCCESS =
    'SETTINGS_RECOVERY_CHANGE_COMMUNICATION_MODE_SUCCESS';
export const SETTINGS_RECOVERY_CHANGE_COMMUNICATION_MODE_FAILURE =
    'SETTINGS_RECOVERY_CHANGE_COMMUNICATION_MODE_FAILURE';
export const SETTINGS_SHOW = 'SETTINGS_SHOW';
export const SETTINGS_HIDE = 'SETTINGS_HIDE';
