export const adiaLiveSettings = {
    Flags: {
        LOG_SESSIONS: 1,
        LOG_CHAT: 2,
        ADVISER_TRACKING: 4,
        EXPERIMENTAL_MODE: 8,
        MEETINGS: 16,
        CALL_MANAGER: 32,
        VIDEO_IDENT: 64,
        BRANCH: 128,
        KELDANO_API: 256,
        WEBINAR: 512,
        HIDE_ADVISERLIST: 1024,
        PHONE_CONSULTING: 2048,
        PODIUM: 4096,
    },

    flagsToSettings: function (flags) {
        const settings = {};

        settings.logSessions = !!(flags & this.Flags.LOG_SESSIONS);
        settings.logChat = !!(flags & this.Flags.LOG_CHAT);
        settings.adviserTracking = !!(flags & this.Flags.ADVISER_TRACKING);
        settings.experimentalMode = !!(flags & this.Flags.EXPERIMENTAL_MODE);
        settings.meetings = !!(flags & this.Flags.MEETINGS);
        settings.callManager = !!(flags & this.Flags.CALL_MANAGER);
        settings.videoIdent = !!(flags & this.Flags.VIDEO_IDENT);
        settings.branch = !!(flags & this.Flags.BRANCH);
        settings.keldanoApi = !!(flags & this.Flags.KELDANO_API);
        settings.webinar = !!(flags & this.Flags.WEBINAR);
        settings.hideAdviserList = !!(flags & this.Flags.HIDE_ADVISERLIST);
        settings.phoneConsulting = !!(flags & this.Flags.PHONE_CONSULTING);
        settings.podium = !!(flags & this.Flags.PODIUM);

        return settings;
    },
};
