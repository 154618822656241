import * as types from './actionTypes';
import { forceLogout, noServiceFound } from '../auth/actions';

import authHelper from '../base/util/authHelper';
import { convertMeetingProperties, handleError } from '../base/util/helpers';

export function alcAdviserListUpdate(adviserList) {
    return { type: types.ALC_ADVISER_LIST_UPDATE, adviserList };
}

function requestOwnMeetingsRequest() {
    return { type: types.REQUEST_OWN_MEETINGS };
}

function requestOwnMeetingsSuccess(meetings) {
    return { type: types.REQUEST_OWN_MEETINGS_SUCCESS, meetings };
}

function requestOwnMeetingsFailure(errorMessage) {
    return { type: types.REQUEST_OWN_MEETINGS_FAILURE, errorMessage };
}

export function requestOwnMeetings(range) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        const adviserId = getState().meetings.clientInfo.info._id;
        if (service) {
            dispatch(requestOwnMeetingsRequest());
            const response = await api.getMeetings(
                service,
                null,
                ['-date'],
                range,
                authHelper.getToken(),
                adviserId,
                7 // open and ongoing
            );
            if (!response.error) {
                convertMeetingProperties(response.meetings, true);
                dispatch(requestOwnMeetingsSuccess(response.meetings));
            } else {
                handleError(response.error, {
                    fatal: (errorMessage) =>
                        dispatch(forceLogout(errorMessage)),
                    nonFatal: (errorMessage) =>
                        dispatch(requestOwnMeetingsFailure(errorMessage)),
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

function getMaintenanceWindowsRequest() {
    return { type: types.GET_MAINTENANCE_WINDOWS };
}

function getMaintenanceWindowsSuccess(maintenanceWindows) {
    return { type: types.GET_MAINTENANCE_WINDOWS_SUCCESS, maintenanceWindows };
}

function getMaintenanceWindowsFailure(errorMessage) {
    return { type: types.GET_MAINTENANCE_WINDOWS_FAILURE, errorMessage };
}

export function getMaintenanceWindows() {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        if (service) {
            dispatch(getMaintenanceWindowsRequest());
            const response = await api.getMaintenanceWindows(
                service,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(
                    getMaintenanceWindowsSuccess(response.maintenanceWindows)
                );
            } else {
                handleError(response.error, {
                    fatal: (errorMessage) =>
                        dispatch(forceLogout(errorMessage)),
                    nonFatal: (errorMessage) =>
                        dispatch(getMaintenanceWindowsFailure(errorMessage)),
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}
