export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const RE_LOGIN_CHECK = 'RE_LOGIN_CHECK';
export const RE_LOGIN_CHECKED = 'RE_LOGIN_CHECKED';

export const RE_LOGIN_REQUEST = 'RE_LOGIN_REQUEST';
export const RE_LOGIN_SUCCESS = 'RE_LOGIN_SUCCESS';
export const RE_LOGIN_FAILURE = 'RE_LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';
export const FORCE_LOGOUT = 'FORCE_LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const NO_SERVICE = 'NO_SERVICE';
export const SELECT_SERVICE = 'SELECT_SERVICE';

export const PUBLIC_SERVICE_INFO_REQUEST = 'PUBLIC_SERVICE_INFO_REQUEST';
export const PUBLIC_SERVICE_INFO_SUCCESS = 'PUBLIC_SERVICE_INFO_SUCCESS';
export const PUBLIC_SERVICE_INFO_FAILURE = 'PUBLIC_SERVICE_INFO_FAILURE';

export const INITIALIZE_FAILURE = 'INITIALIZE_FAILURE';
