import { take, put, all, select, spawn } from 'redux-saga/effects';

import {
    JOIN_MEETING,
    JOIN_MEETING_STANDALONE,
    LEAVE_MEETING,
    ALC_JOIN_MEETING_SUCCESS,
    ALC_ERROR,
    WEBRTC_JOIN_SUCCESS,
    ALC_CLIENT_INFO_UPDATE,
    WEBRTC_START_LOCAL_MEDIA_SUCCESS,
    WEBRTC_START_LOCAL_MEDIA_FAILURE,
    WEBRTC_JOIN_FAILURE,
    WEBRTC_INIT_SUCCESS,
    ALC_LEAVE_MEETING_SUCCESS,
    ALC_KICKED,
    WEBRTC_RECONNECTING_FAILURE,
    ALC_MEETING_COMPLETED,
    START_RECORDING,
    WEBRTC_START_RECORDING_SUCCESS,
    WEBRTC_START_RECORDING_FAILURE,
    STOP_RECORDING,
    ALC_START_RECORDING_SUCCESS,
    START_RECORDING_SUCCESS,
    START_RECORDING_FAILURE,
    CONFIRM_JOIN_DIALOG_CONFIRM,
    CONFIRM_JOIN_DIALOG_CANCEL,
    ALC_MEETING_PARTICIPANTS_UPDATE,
    ALC_TRANSFERRED,
} from './actionTypes';
import {
    alcClose,
    alcJoinMeeting,
    alcJoinMeetingStandalone,
    alcLeaveMeeting,
    webRtcClose,
    webRtcJoin,
    webRtcLeave,
    joinMeetingSuccess,
    joinMeetingFailure,
    webRtcStartLocalMedia,
    showSharedApplications,
    webRtcStopLocalMedia,
    startRecording as startRecordingActionCreator,
    leaveMeeting as leaveMeetingActionCreator,
    alcStartRecording,
    webRtcStartRecording,
    alcStopRecording,
    webRtcStopRecording,
    showRecordingConfirmationDialog,
    startRecordingSuccess,
    startRecordingFailure,
    alcCompleteMeeting,
    showConfirmJoinDialog,
} from './actions';
import { FORCE_LOGOUT, LOGOUT } from '../auth/actionTypes';
import { MANAGER_IFRAME_READY } from '../meetingsManager/actionTypes';
import {
    showSettings,
    webRtcChangeCommunicationMode,
    webRtcMuteAudio,
    webRtcMuteVideo,
    webRtcSettingsError,
} from '../deviceSettings/actions';
import { showMessage } from '../base/messages/actions';
import {
    goToMeeting,
    managerIFrameSendCheckCompleted,
    openEditMeeting,
} from '../meetingsManager/actions';
import { push } from 'connected-react-router';
import {
    log,
    routeWithServiceId,
    webRtcErrorCodeToErrorId,
} from '../base/util/helpers';
import Routes from '../../constants/routes';
import { alcErrorCodeToErrorId } from '../base/util/helpers';
import {
    COMMUNICATION_MODES,
    MEETING_TYPES,
    WEBRTC_MODES,
} from '../../constants/constants';
import { WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS } from '../deviceSettings/actionTypes';
import { ALC_ACCEPT_CALL } from '../callManager/actionTypes';

const getIsAdviser = (state) => {
    if (state.meetings.clientInfo) {
        return state.meetings.clientInfo.isAdviser;
    } else return false;
};
const getNeedsAutoRecord = (state) => {
    const autoRecordingEnabled =
        state.auth.publicServiceInfo.meetingsSettings &&
        state.auth.publicServiceInfo.meetingsSettings.enableAutoRecording;
    return (
        autoRecordingEnabled &&
        state.auth.user &&
        state.auth.user.roles.includes('autorecord')
    );
};
const getServiceId = (state) => state.auth.service;
const getAlcMeetingJoined = (state) => state.meetings.alcMeetingJoined;
const getMeetingInfo = (state) => state.meetings.meetingInfo;
// TODO implement selector?
const getApplicationsUrl = (state) => {
    let applicationsUrl = null;
    if (
        state.auth.publicServiceInfo &&
        state.auth.publicServiceInfo.meetingsSettings
    ) {
        applicationsUrl =
            state.auth.publicServiceInfo.meetingsSettings.applicationsUrl;
    }
    return applicationsUrl;
};
const getWebRtcMeetingJoined = (state) => state.meetings.webRtcMeetingJoined;
const getAlcInitialized = (state) => state.meetings.alcInitialized;
const getWebRtcInitialized = (state) => state.meetings.webRtcInitialized;
const getCommunicationMode = (state) => state.deviceSettings.communicationMode;
const getClientState = (state) => state.meetings.clientInfo.state.name;
const getLastMeetingId = (state) => state.meetings.clientInfo.state.value;
const getWebRtcMode = (state) => state.auth.publicServiceInfo.webRtcMode;
const getRouterAction = (state) => state.router.action;
const getEnableCommModeMatching = (state) =>
    state.auth.publicServiceInfo.callManagerSettings &&
    state.auth.publicServiceInfo.callManagerSettings.enableCommModeMatching;
const getCallerCommMode = (state) => {
    if (
        state.callManager.connectingClientInfo &&
        state.callManager.connectingClientInfo.deviceInfo
    ) {
        const deviceInfo = state.callManager.connectingClientInfo.deviceInfo;
        if (deviceInfo.audio && deviceInfo.video) {
            return COMMUNICATION_MODES.BOTH;
        } else if (deviceInfo.audio) {
            return COMMUNICATION_MODES.AUDIO;
        }
    }
    return COMMUNICATION_MODES.NONE;
};

function* joinMeeting() {
    while (true) {
        const { meetingId } = yield take(JOIN_MEETING);
        log.debug('saga join meeting ' + meetingId);
        const isAdviser = yield select(getIsAdviser);
        // if not adviser when trying to join meeting, wait until server updates isAdviser
        if (!isAdviser) {
            while (true) {
                const { clientInfo } = yield take(ALC_CLIENT_INFO_UPDATE);
                if (clientInfo.isAdviser) {
                    break;
                }
            }
        }
        const webRtcInitialized = yield select(getWebRtcInitialized);
        // wait until webRtc layer is initialized
        if (!webRtcInitialized) {
            yield take(WEBRTC_INIT_SUCCESS);
        }
        // try to start local media first
        yield put(webRtcStartLocalMedia());
        let startLocalMediaAction = yield take([
            WEBRTC_START_LOCAL_MEDIA_SUCCESS,
            WEBRTC_START_LOCAL_MEDIA_FAILURE,
        ]);
        let webRtcErrorCode = null;
        // if startLocalMedia failed try with commMode none
        if (startLocalMediaAction.type === WEBRTC_START_LOCAL_MEDIA_FAILURE) {
            webRtcErrorCode = startLocalMediaAction.error.errorCode;
            yield put(webRtcChangeCommunicationMode(COMMUNICATION_MODES.NONE));
            yield take(WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS);
            yield put(webRtcStartLocalMedia());
            startLocalMediaAction = yield take([
                WEBRTC_START_LOCAL_MEDIA_SUCCESS,
                WEBRTC_START_LOCAL_MEDIA_FAILURE,
            ]);
        }
        if (startLocalMediaAction.type === WEBRTC_START_LOCAL_MEDIA_SUCCESS) {
            const communicationMode = yield select(getCommunicationMode);
            const enableCommModeMatching = yield select(
                getEnableCommModeMatching
            );
            // if local media could be started, join alc meeting
            yield put(
                alcJoinMeeting(
                    meetingId,
                    startLocalMediaAction.communicationMode
                )
            );
            while (true) {
                const alcJoinAction = yield take([
                    ALC_JOIN_MEETING_SUCCESS,
                    ALC_ERROR,
                ]);
                if (alcJoinAction.type === ALC_JOIN_MEETING_SUCCESS) {
                    // match comMode in callManager meeting (if enabled)
                    if (
                        enableCommModeMatching &&
                        alcJoinAction.meetingInfo.type ===
                            MEETING_TYPES.CALL_MANAGER
                    ) {
                        const participantsUpdateAction = yield take(
                            ALC_MEETING_PARTICIPANTS_UPDATE
                        );
                        const caller =
                            participantsUpdateAction.meetingParticipants.find(
                                (el) => el.isAdviser === false
                            );
                        if (caller && caller.deviceInfo) {
                            let callerCommMode = COMMUNICATION_MODES.NONE;
                            if (
                                caller.deviceInfo.audio &&
                                caller.deviceInfo.video
                            ) {
                                callerCommMode = COMMUNICATION_MODES.BOTH;
                            } else if (caller.deviceInfo.audio) {
                                callerCommMode = COMMUNICATION_MODES.AUDIO;
                            }

                            if (
                                communicationMode === COMMUNICATION_MODES.BOTH
                            ) {
                                if (
                                    callerCommMode === COMMUNICATION_MODES.AUDIO
                                ) {
                                    yield put(webRtcMuteVideo(true));
                                } else if (
                                    callerCommMode === COMMUNICATION_MODES.NONE
                                ) {
                                    yield put(webRtcMuteAudio(true));
                                    yield put(webRtcMuteVideo(true));
                                }
                            } else if (
                                communicationMode ===
                                    COMMUNICATION_MODES.AUDIO &&
                                callerCommMode === COMMUNICATION_MODES.NONE
                            ) {
                                yield put(webRtcMuteAudio(true));
                            }
                        }
                    }

                    // set communication mode to none when joining phoneConsulting
                    if (
                        alcJoinAction.meetingInfo.type ===
                        MEETING_TYPES.PHONE_CONSULTING
                    ) {
                        yield put(
                            webRtcChangeCommunicationMode(
                                COMMUNICATION_MODES.NONE
                            )
                        );
                        yield take(WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS);
                    }

                    // TODO: temporary workaround for no user gesture issue
                    const routerAction = yield select(getRouterAction);
                    if (
                        communicationMode === COMMUNICATION_MODES.NONE &&
                        alcJoinAction.meetingInfo.type !==
                            MEETING_TYPES.PHONE_CONSULTING &&
                        routerAction === 'POP'
                    ) {
                        yield put(showConfirmJoinDialog());
                        const confirmAction = yield take([
                            CONFIRM_JOIN_DIALOG_CONFIRM,
                            CONFIRM_JOIN_DIALOG_CANCEL,
                        ]);
                        if (confirmAction.type === CONFIRM_JOIN_DIALOG_CANCEL) {
                            const serviceId = yield select(getServiceId);
                            yield put(
                                push(
                                    routeWithServiceId(
                                        Routes.DASHBOARD,
                                        serviceId
                                    )
                                )
                            );
                            break;
                        }
                    }

                    // if alc meeting is joined, join webRtc meeting
                    yield put(
                        webRtcJoin(
                            alcJoinAction.joinOptions,
                            alcJoinAction.meetingInfo
                        )
                    );
                    const webRtcJoinAction = yield take([
                        WEBRTC_JOIN_SUCCESS,
                        WEBRTC_JOIN_FAILURE,
                    ]);
                    if (webRtcJoinAction.type === WEBRTC_JOIN_SUCCESS) {
                        const needsAutoRecord = yield select(
                            getNeedsAutoRecord
                        );
                        const meetingInfo = yield select(getMeetingInfo);
                        if (
                            needsAutoRecord &&
                            (!meetingInfo.isRecording ||
                                alcJoinAction.joinOptions.mode !==
                                    WEBRTC_MODES.LIVESWITCH_SFU)
                        ) {
                            log.debug(
                                'autorecord role detected and recording is not running yet, starting recording'
                            );
                            yield put(startRecordingActionCreator());
                            const startRecordingAction = yield take([
                                START_RECORDING_SUCCESS,
                                START_RECORDING_FAILURE,
                            ]);
                            if (
                                startRecordingAction.type ===
                                START_RECORDING_FAILURE
                            ) {
                                yield put(joinMeetingFailure());
                                yield put(
                                    leaveMeetingActionCreator(Routes.MEETINGS)
                                );
                                yield put(
                                    showMessage({
                                        contentId: 'autoRecordingError',
                                        type: 'error',
                                    })
                                );
                                break;
                            }
                        }
                        yield put(joinMeetingSuccess());
                        if (webRtcErrorCode) {
                            const communicationMode = yield select(
                                getCommunicationMode
                            );
                            yield put(
                                webRtcSettingsError(
                                    webRtcErrorCodeToErrorId(webRtcErrorCode)
                                )
                            );
                            yield put(showSettings(communicationMode));
                        }
                    } else {
                        yield put(joinMeetingFailure());
                        yield put(alcLeaveMeeting());
                        let errorCode;
                        if (webRtcJoinAction.error) {
                            errorCode = webRtcJoinAction.error.errorCode;
                        }
                        const serviceId = yield select(getServiceId);
                        yield put(
                            push(routeWithServiceId(Routes.MEETINGS, serviceId))
                        );
                        yield put(
                            showMessage({
                                contentId: webRtcErrorCodeToErrorId(errorCode),
                                type: 'error',
                                errorNo: errorCode,
                            })
                        );
                    }
                    break;
                } else {
                    if (alcJoinAction.context === 'joinMeeting') {
                        yield put(joinMeetingFailure());
                        yield put(webRtcStopLocalMedia());
                        const serviceId = yield select(getServiceId);
                        yield put(
                            push(routeWithServiceId(Routes.MEETINGS, serviceId))
                        );
                        yield put(
                            showMessage({
                                contentId: alcErrorCodeToErrorId(
                                    alcJoinAction.error.errorNo
                                ),
                                type: 'error',
                                errorNo: alcJoinAction.error.errorNo,
                            })
                        );
                        break;
                    }
                }
            }
        } else {
            // if local media couldn't be started
            const serviceId = yield select(getServiceId);
            yield put(push(routeWithServiceId(Routes.DASHBOARD, serviceId)));
            const communicationMode = yield select(getCommunicationMode);
            yield put(showSettings(communicationMode));
        }
    }
}

function* acceptCall() {
    while (true) {
        yield take(ALC_ACCEPT_CALL);
        log.debug('saga accept call');
        const webRtcInitialized = yield select(getWebRtcInitialized);
        // wait until webRtc layer is initialized
        if (!webRtcInitialized) {
            yield take(WEBRTC_INIT_SUCCESS);
        }
        const alcJoinAction = yield take([ALC_JOIN_MEETING_SUCCESS, ALC_ERROR]);
        if (alcJoinAction.type === ALC_JOIN_MEETING_SUCCESS) {
            yield put(goToMeeting(alcJoinAction.meetingInfo._id));
            // if alc meeting is joined, start webRtc localMedia
            yield put(webRtcStartLocalMedia());
            const startLocalMediaAction = yield take([
                WEBRTC_START_LOCAL_MEDIA_SUCCESS,
                WEBRTC_START_LOCAL_MEDIA_FAILURE,
            ]);
            // if webRtc fails try with commMode none
            let webRtcErrorCode = null;
            if (
                startLocalMediaAction.type === WEBRTC_START_LOCAL_MEDIA_FAILURE
            ) {
                webRtcErrorCode = startLocalMediaAction.error.errorCode;
                yield put(
                    webRtcChangeCommunicationMode(COMMUNICATION_MODES.NONE)
                );
                yield take(WEBRTC_CHANGE_COMMUNICATION_MODE_SUCCESS);
            }
            // match commMode of caller (if enabled)
            const enableCommModeMatching = yield select(
                getEnableCommModeMatching
            );
            if (enableCommModeMatching) {
                const communicationMode = yield select(getCommunicationMode);
                const callerCommMode = yield select(getCallerCommMode);
                if (communicationMode === COMMUNICATION_MODES.BOTH) {
                    if (callerCommMode === COMMUNICATION_MODES.AUDIO) {
                        yield put(webRtcMuteVideo(true));
                    } else if (callerCommMode === COMMUNICATION_MODES.NONE) {
                        yield put(webRtcMuteAudio(true));
                        yield put(webRtcMuteVideo(true));
                    }
                } else if (
                    communicationMode === COMMUNICATION_MODES.AUDIO &&
                    callerCommMode === COMMUNICATION_MODES.NONE
                ) {
                    yield put(webRtcMuteAudio(true));
                }
            }

            yield put(
                webRtcJoin(alcJoinAction.joinOptions, alcJoinAction.meetingInfo)
            );
            const webRtcJoinAction = yield take([
                WEBRTC_JOIN_SUCCESS,
                WEBRTC_JOIN_FAILURE,
            ]);
            if (webRtcJoinAction.type === WEBRTC_JOIN_SUCCESS) {
                const needsAutoRecord = yield select(getNeedsAutoRecord);
                const meetingInfo = yield select(getMeetingInfo);
                if (
                    needsAutoRecord &&
                    (!meetingInfo.isRecording ||
                        alcJoinAction.joinOptions.mode !==
                            WEBRTC_MODES.LIVESWITCH_SFU)
                ) {
                    log.debug(
                        'autorecord role detected and recording is not running yet, starting recording'
                    );
                    yield put(startRecordingActionCreator());
                    const startRecordingAction = yield take([
                        START_RECORDING_SUCCESS,
                        START_RECORDING_FAILURE,
                    ]);
                    if (startRecordingAction.type === START_RECORDING_FAILURE) {
                        yield put(joinMeetingFailure());
                        yield put(leaveMeetingActionCreator(Routes.MEETINGS));
                        yield put(
                            showMessage({
                                contentId: 'autoRecordingError',
                                type: 'error',
                            })
                        );
                        break;
                    }
                }
                yield put(joinMeetingSuccess());
                if (webRtcErrorCode) {
                    const communicationMode = yield select(
                        getCommunicationMode
                    );
                    yield put(
                        webRtcSettingsError(
                            webRtcErrorCodeToErrorId(webRtcErrorCode)
                        )
                    );
                    yield put(showSettings(communicationMode));
                }
            } else {
                yield put(joinMeetingFailure());
                // TODO complete meeting?
                yield put(alcCompleteMeeting());
                let errorCode;
                if (webRtcJoinAction.error) {
                    errorCode = webRtcJoinAction.error.errorCode;
                }
                const serviceId = yield select(getServiceId);
                yield put(push(routeWithServiceId(Routes.MEETINGS, serviceId)));
                yield put(
                    showMessage({
                        contentId: webRtcErrorCodeToErrorId(errorCode),
                        type: 'error',
                        errorNo: errorCode,
                    })
                );
            }
        } else {
            if (
                alcJoinAction.context === 'acceptCall' ||
                alcJoinAction.context === 'acceptCall'
            ) {
                yield put(joinMeetingFailure());
                yield put(webRtcStopLocalMedia());
                const serviceId = yield select(getServiceId);
                yield put(
                    push(routeWithServiceId(Routes.CALL_MANAGER, serviceId))
                );
                yield put(
                    showMessage({
                        contentId: alcErrorCodeToErrorId(
                            alcJoinAction.error.errorNo
                        ),
                        type: 'error',
                        errorNo: alcJoinAction.error.errorNo,
                    })
                );
            }
        }
    }
}

function* joinMeetingStandalone() {
    while (true) {
        const { meetingId } = yield take(JOIN_MEETING_STANDALONE);
        const isAdviser = yield select(getIsAdviser);
        // if not adviser when trying to join meeting, wait until server updates isAdviser
        if (!isAdviser) {
            while (true) {
                const { clientInfo } = yield take(ALC_CLIENT_INFO_UPDATE);
                if (clientInfo.isAdviser) {
                    break;
                }
            }
        }
        yield put(alcJoinMeetingStandalone(meetingId));
        while (true) {
            const action = yield take([ALC_JOIN_MEETING_SUCCESS, ALC_ERROR]);
            if (action.type === ALC_JOIN_MEETING_SUCCESS) {
                yield put(joinMeetingSuccess());
                yield put(showSharedApplications());
                break;
            } else {
                if (action.context === 'joinMeetingStandalone') {
                    yield put(joinMeetingFailure());
                    const serviceId = yield select(getServiceId);
                    yield put(
                        push(routeWithServiceId(Routes.DASHBOARD, serviceId))
                    );
                    yield put(
                        showMessage({
                            contentId: alcErrorCodeToErrorId(
                                action.error.errorNo
                            ),
                            type: 'error',
                            errorNo: action.error.errorNo,
                        })
                    );
                    break;
                }
            }
        }
    }
}

function* leaveMeeting() {
    while (true) {
        const leaveAction = yield take([
            LEAVE_MEETING,
            ALC_KICKED,
            ALC_MEETING_COMPLETED,
            WEBRTC_RECONNECTING_FAILURE,
            ALC_TRANSFERRED,
        ]);
        // leave webRtc meeting if joined
        const webRtcMeetingJoined = yield select(getWebRtcMeetingJoined);
        if (webRtcMeetingJoined) {
            yield put(webRtcLeave());
        }
        // leave alc meeting if joined
        const alcMeetingJoined = yield select(getAlcMeetingJoined);
        let meetingInfo;
        if (alcMeetingJoined) {
            meetingInfo = yield select(getMeetingInfo);
            yield put(alcLeaveMeeting());
            yield take(ALC_LEAVE_MEETING_SUCCESS);
        }
        const serviceId = yield select(getServiceId);
        const applicationsUrl = yield select(getApplicationsUrl);
        // redirect to meetings manager page
        if (leaveAction.redirectTo) {
            yield put(
                push(routeWithServiceId(leaveAction.redirectTo, serviceId))
            );
            // if meeting manager is set, send checkCompleted message
            if (meetingInfo && applicationsUrl) {
                // spawn so leaveMeeting saga is not blocked if iFrame never loads
                yield spawn(checkCompleted, meetingInfo);
            }
        }
        if (leaveAction.type === ALC_KICKED) {
            yield put(push(routeWithServiceId(Routes.DASHBOARD, serviceId)));
            yield put(
                showMessage({
                    contentId: 'msgKick',
                    type: 'info',
                })
            );
        } else if (leaveAction.type === ALC_TRANSFERRED) {
            yield put(push(routeWithServiceId(Routes.CALL_MANAGER, serviceId)));
            yield put(
                showMessage({
                    contentId: 'msgTransferred',
                    type: 'info',
                })
            );
        } else if (leaveAction.type === ALC_MEETING_COMPLETED) {
            if (leaveAction.initiatedBySelf && applicationsUrl) {
                if (leaveAction.meetingType === MEETING_TYPES.CALL_MANAGER) {
                    yield put(
                        push(routeWithServiceId(Routes.CALL_MANAGER, serviceId))
                    );
                } else {
                    // if initiated completeMeeting and meeting manager is set, send openEditMeeting message
                    const meetingId = yield select(getLastMeetingId);
                    yield put(
                        openEditMeeting(meetingId, leaveAction.meetingType)
                    );
                }
            } else {
                yield put(
                    push(routeWithServiceId(Routes.DASHBOARD, serviceId))
                );
            }
            if (leaveAction.meetingType !== MEETING_TYPES.CALL_MANAGER) {
                yield put(
                    showMessage({
                        contentId: 'msgMeetingCompleted',
                        type: 'info',
                    })
                );
            }
        } else if (leaveAction.type === WEBRTC_RECONNECTING_FAILURE) {
            yield put(push(routeWithServiceId(Routes.DASHBOARD, serviceId)));
            yield put(
                showMessage({
                    contentId: 'alcErrorMeetingConnectionLost',
                    type: 'warn',
                })
            );
        }
    }
}

function* checkCompleted(meetingInfo) {
    // skip if another meeting is joined
    const action = yield take([MANAGER_IFRAME_READY, ALC_JOIN_MEETING_SUCCESS]);
    if (action.type === MANAGER_IFRAME_READY) {
        const clientState = yield select(getClientState);
        if (clientState !== 'leftMeeting' && clientState !== 'busy') {
            yield take(ALC_LEAVE_MEETING_SUCCESS);
        }
        yield put(managerIFrameSendCheckCompleted(meetingInfo));
    }
}

function* leaveMeetingOnLogout() {
    while (true) {
        yield take([LOGOUT, FORCE_LOGOUT]);
        const webRtcMeetingJoined = yield select(getWebRtcMeetingJoined);
        if (webRtcMeetingJoined) {
            yield put(webRtcLeave());
        }
        const alcMeetingJoined = yield select(getAlcMeetingJoined);
        if (alcMeetingJoined) {
            yield put(alcLeaveMeeting());
        }
        const webRtcInitialized = yield select(getWebRtcInitialized);
        if (webRtcInitialized) {
            yield put(webRtcClose());
        }
        const alcInitialized = yield select(getAlcInitialized);
        if (alcInitialized) {
            yield put(alcClose());
        }
    }
}

function* startRecording() {
    while (true) {
        yield take(START_RECORDING);
        yield put(alcStartRecording());
        while (true) {
            const alcRecordingAction = yield take([
                ALC_START_RECORDING_SUCCESS,
                ALC_ERROR,
            ]);
            if (alcRecordingAction.type === ALC_START_RECORDING_SUCCESS) {
                const webRtcMode = yield select(getWebRtcMode);
                if (
                    webRtcMode === WEBRTC_MODES.LIVESWITCH_P2P ||
                    webRtcMode === WEBRTC_MODES.ADIA_P2P
                ) {
                    yield put(webRtcStartRecording());
                    const startRecordingAction = yield take([
                        WEBRTC_START_RECORDING_SUCCESS,
                        WEBRTC_START_RECORDING_FAILURE,
                    ]);
                    if (
                        startRecordingAction.type ===
                        WEBRTC_START_RECORDING_FAILURE
                    ) {
                        log.error(startRecordingAction.error);
                        yield put(
                            showMessage({
                                contentId: webRtcErrorCodeToErrorId(
                                    startRecordingAction.error.errorCode
                                ),
                                type: 'error',
                                errorNo: startRecordingAction.error.errorCode,
                            })
                        );
                        yield put(alcStopRecording());
                        yield put(startRecordingFailure());
                        break;
                    }
                }
                yield put(startRecordingSuccess());
                break;
            } else if (
                alcRecordingAction.type === ALC_ERROR &&
                alcRecordingAction.context === 'startRecording'
            ) {
                if (alcRecordingAction.error.errorNo === 40105) {
                    yield put(showRecordingConfirmationDialog());
                }
                yield put(startRecordingFailure());
                break;
            }
        }
    }
}

function* stopRecording() {
    while (true) {
        yield take(STOP_RECORDING);
        const webRtcMode = yield select(getWebRtcMode);
        if (webRtcMode === WEBRTC_MODES.LIVESWITCH_SFU) {
            yield put(alcStopRecording());
        } else if (
            webRtcMode === WEBRTC_MODES.LIVESWITCH_P2P ||
            webRtcMode === WEBRTC_MODES.ADIA_P2P
        ) {
            yield put(webRtcStopRecording());
            yield put(alcStopRecording());
        }
    }
}

export function* meetingsSagas() {
    yield all([
        joinMeeting(),
        joinMeetingStandalone(),
        acceptCall(),
        leaveMeeting(),
        leaveMeetingOnLogout(),
        startRecording(),
        stopRecording(),
    ]);
}
