import * as types from './actionTypes';

export function requestRecordings() {
    return { type: types.REQUEST_RECORDINGS };
}

export function requestRecordingsSuccess(recordings) {
    return { type: types.REQUEST_RECORDINGS_SUCCESS, recordings };
}

export function requestRecordingsFailure(errorId) {
    return { type: types.REQUEST_RECORDINGS_FAILURE, errorId };
}

export function deleteRecording(meetingId) {
    return { type: types.DELETE_RECORDING, meetingId };
}

export function downloadRecording(meetingId) {
    return { type: types.DOWNLOAD_RECORDING, meetingId };
}
