import { LOCATION_CHANGE } from 'connected-react-router';
import throttle from 'lodash/throttle';

import {
    SA_IFRAME_NEW_REF,
    SA_IFRAME_LOAD,
    SA_SHOW,
    SA_SWITCH_OWN,
    SA_HIDE,
    SA_IFRAME_READY,
    ALC_JOIN_MEETING_SUCCESS,
    ALC_MEETING_INFO_UPDATE,
    SA_IFRAME_INIT_CONFIRMED,
    SIDE_PANEL_IFRAME_NEW_REF,
    SIDE_PANEL_IFRAME_LOAD,
    SIDE_PANEL_IFRAME_INIT_CONFIRMED,
    SIDE_PANEL_SHOW_APPLICATION,
    SIDE_PANEL_IFRAME_READY,
    SA_REQUEST_SIGNATURE,
} from '../features/meetings/actionTypes';
import {
    MANAGER_IFRAME_NEW_REF,
    MANAGER_IFRAME_LOAD,
    MANAGER_IFRAME_READY,
    MANAGER_IFRAME_SEND_CHECK_COMPLETED,
    MANAGER_IFRAME_SEND_OPEN_EDIT,
} from '../features/meetingsManager/actionTypes';
import {
    DOC_MANAGER_IFRAME_NEW_REF,
    DOC_MANAGER_IFRAME_LOAD,
    DOC_MANAGER_IFRAME_READY,
} from '../features/documentsManager/actionTypes';
import { CHANGE_LANGUAGE } from '../features/base/i18n/actionTypes';
import {
    sharedApplicationsIFrameReady,
    documentsNewUpload,
    chatNewMessage,
    sharedApplicationsIFrameInitConfirmed,
    sidePanelIFrameInitConfirmed,
    sidePanelIFrameReady,
    streamChatNewMessage,
    streamChatNewDocument,
    streamChatNewSurvey,
    updateRequestSignatureState,
} from '../features/meetings/actions';
import {
    managerIFrameReady,
    goToMeeting,
} from '../features/meetingsManager/actions';
import { docManagerIFrameReady } from '../features/documentsManager/actions';
import {
    SA_NAMESPACE,
    SA_IDENTIFIERS,
    SA_APPLICATIONS,
    IFRAME_COMMANDS,
    IFRAME_INIT_INTERVAL,
    AL_APPLICATIONS,
} from '../constants/constants';
import { log, routeWithServiceId } from '../features/base/util/helpers';
import Routes from '../constants/routes';
import { GET_MAINTENANCE_WINDOWS_SUCCESS } from '../features/dashboard/actionTypes';

export const createIFrameMiddleware = () => {
    let saIFrame = null;
    let managerIFrame = null;
    let docManagerIFrame = null;
    let sidePanelIFrame = null;

    let saAuth = null;

    let saInitInterval = null;
    let managerInitInterval = null;
    let docManagerInitInterval = null;
    let sidePanelInitInterval = null;

    function sendMessageToSAIFrame(msg) {
        if (saIFrame && saIFrame.contentWindow) {
            msg.namespace = SA_NAMESPACE;
            msg.identifier = SA_IDENTIFIERS.SHARED_APPLICATIONS;
            saIFrame.contentWindow.postMessage(msg, '*');
            log.debug(msg);
        }
    }

    function sendSaInitMessage(language) {
        if (saAuth) {
            sendMessageToSAIFrame({
                cmd: IFRAME_COMMANDS.INIT,
                language: language,
                participantInfo: saAuth.participantInfo,
                meetingInfo: saAuth.meetingInfo,
                token: saAuth.token,
            });
        }
    }

    function sendMessageToSidePanelIFrame(msg) {
        if (sidePanelIFrame && sidePanelIFrame.contentWindow) {
            msg.namespace = SA_NAMESPACE;
            msg.identifier = SA_IDENTIFIERS.SIDE_PANEL;
            sidePanelIFrame.contentWindow.postMessage(msg, '*');
            log.debug(msg);
        }
    }

    function sendSidePanelInitMessage(language, chatId) {
        if (saAuth) {
            sendMessageToSidePanelIFrame({
                cmd: IFRAME_COMMANDS.INIT,
                language: language,
                participantInfo: saAuth.participantInfo,
                meetingInfo: saAuth.meetingInfo,
                token: saAuth.token,
                chatId,
            });
        }
    }

    function sendMessageToManagerIFrame(msg) {
        if (managerIFrame && managerIFrame.contentWindow) {
            msg.namespace = SA_NAMESPACE;
            msg.identifier = SA_IDENTIFIERS.MANAGER;
            managerIFrame.contentWindow.postMessage(msg, '*');
            log.debug(msg);
        }
    }

    function sendManagerInitMessage(language) {
        sendMessageToManagerIFrame({
            cmd: IFRAME_COMMANDS.INIT,
            language: language,
        });
    }

    function sendMessageToDocManagerIFrame(msg) {
        if (docManagerIFrame && docManagerIFrame.contentWindow) {
            msg.namespace = SA_NAMESPACE;
            msg.identifier = SA_IDENTIFIERS.DOC_MANAGER;
            docManagerIFrame.contentWindow.postMessage(msg, '*');
            log.debug(msg);
        }
    }

    function sendDocManagerInitMessage(language) {
        sendMessageToDocManagerIFrame({
            cmd: IFRAME_COMMANDS.INIT,
            language: language,
        });
    }

    const saMessageEventHandler = (store) => (event) => {
        if (
            event.data &&
            event.data.namespace === SA_NAMESPACE &&
            event.data.identifier === SA_IDENTIFIERS.SHARED_APPLICATIONS
        ) {
            log.debug(event.data);
            switch (event.data.cmd) {
                case IFRAME_COMMANDS.INIT_CONFIRMED: {
                    store.dispatch(sharedApplicationsIFrameInitConfirmed());
                    break;
                }
                case IFRAME_COMMANDS.READY: {
                    store.dispatch(sharedApplicationsIFrameReady());
                    break;
                }
                case IFRAME_COMMANDS.REQUEST_SIGNATURE_STATE: {
                    store.dispatch(
                        updateRequestSignatureState(event.data.hasSignatures)
                    );
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

    const sidePanelMessageEventHandler = (store) => (event) => {
        if (
            event.data &&
            event.data.namespace === SA_NAMESPACE &&
            event.data.identifier === SA_IDENTIFIERS.SIDE_PANEL
        ) {
            log.debug(event.data);
            switch (event.data.cmd) {
                case IFRAME_COMMANDS.INIT_CONFIRMED: {
                    store.dispatch(sidePanelIFrameInitConfirmed());
                    break;
                }
                case IFRAME_COMMANDS.READY: {
                    store.dispatch(sidePanelIFrameReady());
                    break;
                }
                case IFRAME_COMMANDS.CHAT_NEW_MESSAGE: {
                    store.dispatch(streamChatNewMessage());
                    store.dispatch(chatNewMessage());
                    break;
                }
                case IFRAME_COMMANDS.DOCUMENTS_NEW_UPLOAD: {
                    store.dispatch(streamChatNewDocument());
                    store.dispatch(documentsNewUpload());
                    break;
                }
                case IFRAME_COMMANDS.NEW_SURVEY: {
                    store.dispatch(streamChatNewSurvey());
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

    const managerMessageEventHandler = (store) => (event) => {
        if (
            event.data &&
            event.data.namespace === SA_NAMESPACE &&
            event.data.identifier === SA_IDENTIFIERS.MANAGER
        ) {
            log.debug(event.data);
            switch (event.data.cmd) {
                case IFRAME_COMMANDS.READY: {
                    store.dispatch(managerIFrameReady());
                    if (
                        store.getState().dashboard.maintenanceWindows.length > 0
                    ) {
                        sendMessageToManagerIFrame({
                            cmd: IFRAME_COMMANDS.MAINTENANCE_WINDOWS,
                            maintenanceWindows:
                                store.getState().dashboard.maintenanceWindows,
                        });
                    }
                    break;
                }
                case IFRAME_COMMANDS.JOIN_MEETING: {
                    if (event.data.meetingId) {
                        store.dispatch(goToMeeting(event.data.meetingId));
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

    const docManagerMessageEventHandler = (store) => (event) => {
        if (
            event.data &&
            event.data.namespace === SA_NAMESPACE &&
            event.data.identifier === SA_IDENTIFIERS.DOC_MANAGER
        ) {
            log.debug(event.data);
            switch (event.data.cmd) {
                case IFRAME_COMMANDS.READY: {
                    store.dispatch(docManagerIFrameReady());
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

    const hasEditMode = function (state, applicationId) {
        return (
            state.meetings.clientInfo.isAdviser ||
            (state.meetings.clientInfo.editPermissions &&
                state.meetings.clientInfo.editPermissions.includes(
                    applicationId
                ))
        );
    };

    // window listeners
    const keydownListener = (e) => {
        if (e.key === 'Shift') {
            sendMessageToSAIFrame({
                cmd: IFRAME_COMMANDS.WINDOW_EVENT,
                type: 'keydown',
                key: 'Shift',
            });
        }
    };
    const keydownListenerThrottled = throttle(keydownListener, 500, {
        trailing: false,
    });

    const keyupListener = (e) => {
        if (e.key === 'Shift') {
            sendMessageToSAIFrame({
                cmd: IFRAME_COMMANDS.WINDOW_EVENT,
                type: 'keyup',
                key: 'Shift',
            });
        }
    };

    const focusListener = (e) => {
        sendMessageToSAIFrame({
            cmd: IFRAME_COMMANDS.WINDOW_EVENT,
            type: 'focus',
        });
    };

    const blurListener = (e) => {
        sendMessageToSAIFrame({
            cmd: IFRAME_COMMANDS.WINDOW_EVENT,
            type: 'blur',
            key: 'Shift',
        });
    };

    const initSaWindowListener = () => {
        window.addEventListener('keydown', keydownListenerThrottled);
        window.addEventListener('keyup', keyupListener);
        window.addEventListener('focus', focusListener);
        window.addEventListener('blur', blurListener);
    };

    const resetSaWindowListener = () => {
        window.removeEventListener('keydown', keydownListenerThrottled);
        window.removeEventListener('keyup', keyupListener);
        window.removeEventListener('focus', focusListener);
        window.removeEventListener('blur', blurListener);
    };

    let saEventHandlerRef;
    let sidePanelEventHandlerRef;
    let managerEventHandlerRef;
    let docManagerEventHandlerRef;

    return (store) => (next) => (action) => {
        const state = store.getState();

        switch (action.type) {
            /* Shared Applications main iFrame */
            case SA_IFRAME_NEW_REF:
                if (action.iFrameRef && action.iFrameRef.current) {
                    saIFrame = action.iFrameRef.current;
                } else {
                    saIFrame = null;
                }
                if (saInitInterval) {
                    clearInterval(saInitInterval);
                    saInitInterval = null;
                }
                if (saEventHandlerRef) {
                    window.removeEventListener('message', saEventHandlerRef);
                    resetSaWindowListener();
                    saEventHandlerRef = null;
                }
                return next(action);
            case SA_IFRAME_LOAD:
                if (saEventHandlerRef) {
                    window.removeEventListener('message', saEventHandlerRef);
                    resetSaWindowListener();
                }
                saEventHandlerRef = saMessageEventHandler(store);
                window.addEventListener('message', saEventHandlerRef);
                initSaWindowListener();
                if (saInitInterval) {
                    clearInterval(saInitInterval);
                }
                saInitInterval = setInterval(
                    () => sendSaInitMessage(state.base.i18n.language),
                    IFRAME_INIT_INTERVAL
                );
                return next(action);
            case SA_IFRAME_INIT_CONFIRMED: {
                clearInterval(saInitInterval);
                saInitInterval = null;
                return next(action);
            }
            case SA_IFRAME_READY: {
                if (state.meetings.ownApplicationId) {
                    sendMessageToSAIFrame({
                        cmd: IFRAME_COMMANDS.SHOW,
                        application: state.meetings.ownApplicationId,
                        editMode: hasEditMode(
                            state,
                            state.meetings.ownApplicationId
                        ),
                        language: state.base.i18n.language,
                    });
                } else if (state.meetings.meetingInfo.currentApp) {
                    sendMessageToSAIFrame({
                        cmd: IFRAME_COMMANDS.SHOW,
                        application: state.meetings.meetingInfo.currentApp,
                        editMode: hasEditMode(
                            state,
                            state.meetings.meetingInfo.currentApp
                        ),
                        language: state.base.i18n.language,
                    });
                }
                sendMessageToSAIFrame({
                    cmd: IFRAME_COMMANDS.CURRENT_APP,
                    currentApp: state.meetings.meetingInfo.currentApp,
                });
                return next(action);
            }
            case ALC_JOIN_MEETING_SUCCESS: {
                saAuth = action.saAuth;
                return next(action);
            }
            case ALC_MEETING_INFO_UPDATE: {
                if (state.meetings.saIFrameReady) {
                    if (
                        action.meetingInfo.currentApp &&
                        !state.meetings.ownApplicationId
                    ) {
                        sendMessageToSAIFrame({
                            cmd: IFRAME_COMMANDS.SHOW,
                            application: action.meetingInfo.currentApp,
                            editMode: hasEditMode(
                                state,
                                action.meetingInfo.currentApp
                            ),
                            language: state.base.i18n.language,
                        });
                    } else if (
                        !action.meetingInfo.currentApp &&
                        !state.meetings.ownApplicationId
                    ) {
                        sendMessageToSAIFrame({
                            cmd: IFRAME_COMMANDS.HIDE,
                        });
                    }
                    if (
                        action.meetingInfo.currentApp !==
                        state.meetings.meetingInfo.currentApp
                    ) {
                        sendMessageToSAIFrame({
                            cmd: IFRAME_COMMANDS.CURRENT_APP,
                            currentApp: action.meetingInfo.currentApp,
                        });
                    }
                }
                if (
                    state.meetings.sidePanelIFrameReady &&
                    action.meetingInfo.participants.length !==
                        state.meetings.meetingInfo.participants.length
                ) {
                    sendMessageToSidePanelIFrame({
                        cmd: IFRAME_COMMANDS.NEW_PARTICIPANT_JOINED,
                    });
                }
                return next(action);
            }
            case SA_SHOW: {
                sendMessageToSAIFrame({
                    cmd: IFRAME_COMMANDS.SHOW,
                    application: SA_APPLICATIONS.SHOWBOARD,
                    editMode: hasEditMode(state, SA_APPLICATIONS.SHOWBOARD),
                    language: state.base.i18n.language,
                });
                return next(action);
            }
            case SA_SWITCH_OWN: {
                sendMessageToSAIFrame({
                    cmd: IFRAME_COMMANDS.SHOW,
                    application: action.sharedApplicationId,
                    editMode: hasEditMode(state, action.sharedApplicationId),
                    language: state.base.i18n.language,
                });
                return next(action);
            }
            case SA_HIDE: {
                sendMessageToSAIFrame({
                    cmd: IFRAME_COMMANDS.HIDE,
                });
                return next(action);
            }
            case SA_REQUEST_SIGNATURE: {
                sendMessageToSAIFrame({
                    cmd: IFRAME_COMMANDS.REQUEST_SIGNATURE,
                });
                return next(action);
            }

            /* SidePanel iFrame */
            case SIDE_PANEL_IFRAME_NEW_REF: {
                if (action.iFrameRef && action.iFrameRef.current) {
                    sidePanelIFrame = action.iFrameRef.current;
                } else {
                    sidePanelIFrame = null;
                }
                if (sidePanelInitInterval) {
                    clearInterval(sidePanelInitInterval);
                    sidePanelInitInterval = null;
                }
                if (sidePanelEventHandlerRef) {
                    window.removeEventListener(
                        'message',
                        sidePanelEventHandlerRef
                    );
                    sidePanelEventHandlerRef = null;
                }
                return next(action);
            }
            case SIDE_PANEL_IFRAME_LOAD: {
                if (sidePanelEventHandlerRef) {
                    window.removeEventListener(
                        'message',
                        sidePanelEventHandlerRef
                    );
                }
                sidePanelEventHandlerRef = sidePanelMessageEventHandler(store);
                window.addEventListener('message', sidePanelEventHandlerRef);
                if (sidePanelInitInterval) {
                    clearInterval(sidePanelInitInterval);
                }
                sidePanelInitInterval = setInterval(
                    () =>
                        sendSidePanelInitMessage(
                            state.base.i18n.language,
                            state.meetings.meetingInfo.chatId
                        ),
                    IFRAME_INIT_INTERVAL
                );
                return next(action);
            }
            case SIDE_PANEL_IFRAME_INIT_CONFIRMED: {
                clearInterval(sidePanelInitInterval);
                sidePanelInitInterval = null;
                return next(action);
            }

            /* single frame handling */
            case SIDE_PANEL_SHOW_APPLICATION: {
                if (
                    state.meetings.activeSidePanelApplicationId !==
                    action.applicationId
                ) {
                    if (
                        action.applicationId === AL_APPLICATIONS.PARTICIPANTS ||
                        action.applicationId === AL_APPLICATIONS.ADIA_PAD ||
                        action.applicationId === null
                    ) {
                        sendMessageToSidePanelIFrame({
                            cmd: IFRAME_COMMANDS.HIDE,
                        });
                    } else {
                        sendMessageToSidePanelIFrame({
                            cmd: IFRAME_COMMANDS.SHOW,
                            application: action.applicationId,
                            language: state.base.i18n.language,
                        });
                    }
                }
                return next(action);
            }
            case SIDE_PANEL_IFRAME_READY: {
                if (
                    state.meetings.activeSidePanelApplicationId &&
                    state.meetings.activeSidePanelApplicationId !==
                        AL_APPLICATIONS.PARTICIPANTS &&
                    state.meetings.activeSidePanelApplicationId !==
                        AL_APPLICATIONS.ADIA_PAD
                ) {
                    sendMessageToSidePanelIFrame({
                        cmd: IFRAME_COMMANDS.SHOW,
                        application:
                            state.meetings.activeSidePanelApplicationId,
                        language: state.base.i18n.language,
                    });
                }
                return next(action);
            }

            /* Meeting Manager iFrame */
            case MANAGER_IFRAME_NEW_REF: {
                if (action.iFrameRef && action.iFrameRef.current) {
                    managerIFrame = action.iFrameRef.current;
                } else {
                    managerIFrame = null;
                }
                if (managerInitInterval) {
                    clearInterval(managerInitInterval);
                    managerInitInterval = null;
                }
                if (managerEventHandlerRef) {
                    window.removeEventListener(
                        'message',
                        managerEventHandlerRef
                    );
                    managerEventHandlerRef = null;
                }
                return next(action);
            }
            case MANAGER_IFRAME_LOAD: {
                if (managerEventHandlerRef) {
                    window.removeEventListener(
                        'message',
                        managerEventHandlerRef
                    );
                }
                managerEventHandlerRef = managerMessageEventHandler(store);
                window.addEventListener('message', managerEventHandlerRef);
                if (managerInitInterval) {
                    clearInterval(managerInitInterval);
                }
                managerInitInterval = setInterval(
                    () => sendManagerInitMessage(state.base.i18n.language),
                    IFRAME_INIT_INTERVAL
                );
                return next(action);
            }
            case MANAGER_IFRAME_READY: {
                clearInterval(managerInitInterval);
                managerInitInterval = null;
                return next(action);
            }
            case MANAGER_IFRAME_SEND_CHECK_COMPLETED: {
                sendMessageToManagerIFrame({
                    cmd: IFRAME_COMMANDS.CHECK_COMPLETED,
                    meetingInfo: action.meetingInfo,
                    language: state.base.i18n.language,
                });
                return next(action);
            }
            case MANAGER_IFRAME_SEND_OPEN_EDIT: {
                sendMessageToManagerIFrame({
                    cmd: IFRAME_COMMANDS.OPEN_EDIT,
                    meetingId: action.meetingId,
                    language: state.base.i18n.language,
                });
                return next(action);
            }
            case GET_MAINTENANCE_WINDOWS_SUCCESS: {
                if (
                    action.maintenanceWindows &&
                    action.maintenanceWindows.length > 0
                ) {
                    sendMessageToManagerIFrame({
                        cmd: IFRAME_COMMANDS.MAINTENANCE_WINDOWS,
                        maintenanceWindows: action.maintenanceWindows,
                    });
                }
                return next(action);
            }

            /* Documents Manager iFrame */
            case DOC_MANAGER_IFRAME_NEW_REF: {
                if (action.iFrameRef && action.iFrameRef.current) {
                    docManagerIFrame = action.iFrameRef.current;
                } else {
                    docManagerIFrame = null;
                }
                if (docManagerInitInterval) {
                    clearInterval(docManagerInitInterval);
                    docManagerInitInterval = null;
                }
                if (docManagerEventHandlerRef) {
                    window.removeEventListener(
                        'message',
                        docManagerEventHandlerRef
                    );
                    docManagerEventHandlerRef = null;
                }
                return next(action);
            }
            case DOC_MANAGER_IFRAME_LOAD: {
                if (docManagerEventHandlerRef) {
                    window.removeEventListener(
                        'message',
                        docManagerEventHandlerRef
                    );
                }
                docManagerEventHandlerRef =
                    docManagerMessageEventHandler(store);
                window.addEventListener('message', docManagerEventHandlerRef);
                if (docManagerInitInterval) {
                    clearInterval(docManagerInitInterval);
                }
                docManagerInitInterval = setInterval(
                    () => sendDocManagerInitMessage(state.base.i18n.language),
                    IFRAME_INIT_INTERVAL
                );
                return next(action);
            }
            case DOC_MANAGER_IFRAME_READY: {
                clearInterval(docManagerInitInterval);
                docManagerInitInterval = null;
                return next(action);
            }

            /* General */
            case CHANGE_LANGUAGE: {
                // TODO: language switch during meeting disabled for now
                /*
                if (state.meetings.alcMeetingJoined) {
                    let applicationId = '';
                    if (state.meetings && state.meetings.ownApplicationId) {
                        applicationId = state.meetings.ownApplicationId;
                    } else if (state.meetings && state.meetings.meetingInfo && state.meetings.meetingInfo.currentApp) {
                        applicationId = state.meetings.meetingInfo.currentApp;
                    }
                    sendMessageToSAIFrame({
                        cmd: IFRAME_COMMANDS.SHOW,
                        application: applicationId,
                        editMode: hasEditMode(state, applicationId),
                        language: action.language
                    });
                } else {
                 */
                sendMessageToManagerIFrame({
                    cmd: IFRAME_COMMANDS.SHOW,
                    language: action.language,
                });
                sendMessageToDocManagerIFrame({
                    cmd: IFRAME_COMMANDS.SHOW,
                    language: action.language,
                });
                //}

                return next(action);
            }
            case LOCATION_CHANGE: {
                if (
                    action.payload.location.pathname ===
                        routeWithServiceId(
                            Routes.MEETINGS,
                            state.auth.service
                        ) &&
                    state.auth.publicServiceInfo &&
                    state.auth.publicServiceInfo.meetingsSettings
                        .applicationsUrl &&
                    state.router.location.pathname ===
                        routeWithServiceId(Routes.MEETINGS, state.auth.service)
                ) {
                    sendMessageToManagerIFrame({
                        cmd: IFRAME_COMMANDS.GO_TO_OVERVIEW,
                        language: state.base.i18n.language,
                    });
                } else if (
                    action.payload.location.pathname ===
                        routeWithServiceId(
                            Routes.WEBINARS,
                            state.auth.service
                        ) &&
                    state.auth.publicServiceInfo &&
                    state.auth.publicServiceInfo.meetingsSettings
                        .applicationsUrl &&
                    state.router.location.pathname ===
                        routeWithServiceId(Routes.WEBINARS, state.auth.service)
                ) {
                    sendMessageToManagerIFrame({
                        cmd: IFRAME_COMMANDS.GO_TO_OVERVIEW,
                        language: state.base.i18n.language,
                    });
                } else if (
                    action.payload.location.pathname ===
                        routeWithServiceId(
                            Routes.PHONE_CONSULTATION,
                            state.auth.service
                        ) &&
                    state.auth.publicServiceInfo &&
                    state.auth.publicServiceInfo.meetingsSettings
                        .applicationsUrl &&
                    state.router.location.pathname ===
                        routeWithServiceId(
                            Routes.PHONE_CONSULTATION,
                            state.auth.service
                        )
                ) {
                    sendMessageToManagerIFrame({
                        cmd: IFRAME_COMMANDS.GO_TO_OVERVIEW,
                        language: state.base.i18n.language,
                    });
                } else if (
                    action.payload.location.pathname ===
                        routeWithServiceId(
                            Routes.PODIUMS,
                            state.auth.service
                        ) &&
                    state.auth.publicServiceInfo &&
                    state.auth.publicServiceInfo.meetingsSettings
                        .applicationsUrl &&
                    state.router.location.pathname ===
                        routeWithServiceId(Routes.PODIUMS, state.auth.service)
                ) {
                    sendMessageToManagerIFrame({
                        cmd: IFRAME_COMMANDS.GO_TO_OVERVIEW,
                        language: state.base.i18n.language,
                    });
                }
                return next(action);
            }
            default:
                return next(action);
        }
    };
};
