import * as types from './actionTypes';

export const initialState = {
    serviceState: undefined,
    queueList: [],
    connectingClientInfo: null,
    invitationInfo: null,
    openCalls: {},
};

export default function callManager(state = initialState, action) {
    switch (action.type) {
        case types.ALC_SERVICE_STATE_UPDATE: {
            return {
                ...state,
                serviceState: action.serviceState,
            };
        }
        case types.ALC_QUEUE_LIST_UPDATE: {
            return {
                ...state,
                queueList: action.queueList,
            };
        }
        case types.ALC_CONNECTING_CLIENT_INFO: {
            return {
                ...state,
                connectingClientInfo: action.connectingClientInfo,
            };
        }
        case types.ALC_OPEN_CALLS_UPDATE: {
            return {
                ...state,
                openCalls: action.openCalls,
            };
        }
        case types.ALC_INVITATION_INFO: {
            return {
                ...state,
                invitationInfo: action.invitationInfo
            }
        }
        default:
            return state;
    }
}
