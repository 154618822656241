import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import { alcSetBusy, alcSetReady } from './actions';

import {ADVISER_STATE_NAMES} from "../../constants/constants";
import {SelectButton} from "primereact/selectbutton";
import {getTranslatedString} from "../base/i18n/translations";

const btnShowStates = [ADVISER_STATE_NAMES.READY, ADVISER_STATE_NAMES.BUSY];

function CallManagerStatus(){
    const dispatch = useDispatch();

    const adviserStateName = useSelector(state => state.meetings.clientInfo && state.meetings.clientInfo.state.name);
    const stateButtonDisabled = !btnShowStates.includes(adviserStateName);
    const language = useSelector(state => state.base.i18n.language);

    const toggleState = useCallback(()=>{
        if (adviserStateName === ADVISER_STATE_NAMES.READY){
            dispatch(alcSetBusy());
        } else {
            dispatch(alcSetReady(null));
        }
    }, [dispatch, adviserStateName]);

    return (
        <div className="adviser-state-widget">
            <div className={classNames('adviser-state-widget-btnReady', adviserStateName)}>
                <SelectButton
                    value={adviserStateName}
                    className="p-button-secondary"
                    disabled={stateButtonDisabled}
                    options={[
                        {
                            value: ADVISER_STATE_NAMES.READY,
                            label: getTranslatedString(language, 'callState-'+ADVISER_STATE_NAMES.READY),
                        },
                        {
                            value: ADVISER_STATE_NAMES.BUSY,
                            label: getTranslatedString(language, 'callState-'+ADVISER_STATE_NAMES.BUSY),
                        }
                    ]}
                    onChange={toggleState}
                />
            </div>
        </div>
    )
}
export default CallManagerStatus;
