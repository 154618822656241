const routes = {
    HOME: '/',
    SERVICE_LOGIN: '/:serviceId',
    DASHBOARD: '/:serviceId',
    MEETINGS: '/:serviceId/meetings',
    MEETINGS_EXECUTE: '/:serviceId/meetings/:id',
    MEETINGS_EXECUTE_STANDALONE: '/:serviceId/meetings/standalone/:id',
    WEBINARS: '/:serviceId/webinars',
    PODIUMS: '/:serviceId/podiums',
    PHONE_CONSULTATION: '/:serviceId/phoneConsultation/',
    DEFAULT_DOCUMENTS: '/:serviceId/defaultDocuments',
    DEFAULT_AGENDA: '/:serviceId/defaultAgenda',
    DEFAULT_MINUTES: '/:serviceId/defaultMinutes',
    SIGNATURE_TEMPLATES: '/:serviceId/signatureTemplates',
    RECORDINGS: '/:serviceId/recordings',
    CALL_MANAGER: '/:serviceId/callManager',
};
export default routes;
