import React, { Component } from 'react';
import classNames from 'classnames';

import { logout } from '../auth/actions';
import { changeLanguage } from '../base/i18n/actions';
import { showEditAccount } from '../base/editAccount/actions';
import {connect, useSelector} from 'react-redux';
import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString, languages } from '../base/i18n/translations';
import { hideSettings, showSettings } from '../deviceSettings/actions';
import { Config } from '../../config/Config';
import CallManagerStatus from "../callManager/CallManagerStatus";

function MobileMenuButton() {
    const adviserStateName = useSelector(state => state.meetings.clientInfo && state.meetings.clientInfo.state.name);

    return (
        <i className={classNames("material-icons", {'adviser-state-color-mobile': adviserStateName==='ready' || adviserStateName === 'busy'}, adviserStateName)}>&#xE853;</i>
    );
}

export class AppTopbar extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handleLanguageSelectChange = this.handleLanguageSelectChange.bind(
            this
        );
    }

    handleLanguageSelectChange(e) {
        this.props.changeLanguage(e.target.value);
    }

    onTopbarItemClick(event, item) {
        if (this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item,
            });
        }
    }

    renderLanguageButtons() {
        const languageButtons = [];
        Object.keys(languages).forEach((language) => {
            languageButtons.push(
                <button
                    key={languages[language]}
                    className="p-link"
                    onClick={() =>
                        this.props.changeLanguage(languages[language])
                    }
                >
                    {getTranslatedString(
                        languages[language],
                        languages[language]
                    )}
                    {' - '}
                    {getTranslatedString(
                        this.props.language,
                        languages[language]
                    )}
                </button>
            );
        });

        return languageButtons;
    }

    render() {
        let topbarClass = classNames('topbar-menu fadeInDown', {
            'topbar-menu-active': this.props.topbarMenuActive,
        });

        return (
            <div className="layout-topbar">
                {/*<img alt="logo" src={Config.mainLogo} className="mobile-logo" />*/}

                <button
                    className="p-link menu-btn"
                    onClick={this.props.onMenuButtonClick}
                >
                    <i className="material-icons">&#xE5D2;</i>
                </button>

                <button
                    className="p-link topbar-menu-btn"
                    onClick={this.props.onTopbarMobileMenuButtonClick}
                >
                    <MobileMenuButton />
                </button>

                <div className="layout-topbar-menu-wrapper">
                    <ul className={topbarClass}>

                        <li
                            className={classNames({
                                'active-topmenuitem':
                                    this.props.activeTopbarItem === 'profile',
                            })}
                        >
                            <button
                                className="p-link"
                                onClick={(e) =>
                                    this.onTopbarItemClick(e, 'profile')
                                }
                            >
                                <i className="topbar-icon material-icons">
                                    account_circle
                                </i>
                                <span className="topbar-item-name profile-name">
                                    {this.props.user &&
                                        this.props.user.username}
                                </span>
                            </button>
                            <ul className="fadeInDown">
                                <li role="menuitem">
                                    <button
                                        className="p-link"
                                        onClick={() =>
                                            this.props.showSettings()
                                        }
                                    >
                                        <i className="material-icons">
                                            settings
                                        </i>
                                        <span>
                                            <TranslatedString id="deviceSettings" />
                                        </span>
                                    </button>
                                </li>
                                {this.props.user &&
                                    !this.props.user.ldap &&
                                    !Config.editAccountDisabled && (
                                        <li role="menuitem">
                                            <button
                                                className="p-link"
                                                onClick={
                                                    this.props.showEditAccount
                                                }
                                            >
                                                <i className="material-icons">
                                                    account_circle
                                                </i>
                                                <span>
                                                    <TranslatedString id="editAccount" />
                                                </span>
                                            </button>
                                        </li>
                                    )}
                                <li role="menuitem">
                                    <button
                                        className="p-link"
                                        onClick={this.props.logout}
                                    >
                                        <i className="material-icons">
                                            exit_to_app
                                        </i>
                                        <span>
                                            <TranslatedString id="signOut" />
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                        <li
                            className={classNames({
                                'active-topmenuitem':
                                    this.props.activeTopbarItem === 'lan',
                            })}
                        >
                            <button
                                className="p-link"
                                onClick={(e) =>
                                    this.onTopbarItemClick(e, 'lan')
                                }
                            >
                                <span className="topbar-desktop-dropdown">
                                    <span className="topbar-item-name profile-name">
                                        {this.props.language}
                                    </span>
                                    <i className="topbar-icon material-icons topbar-desktop-dropdown-arrow">
                                        arrow_drop_down
                                    </i>
                                </span>
                                <i className="topbar-icon mobile-only material-icons">
                                    language
                                </i>
                                <span className="topbar-item-name">
                                    <TranslatedString id="language" />
                                </span>
                            </button>
                            <ul className="fadeInDown">
                                <li role="menuitem">
                                    {this.renderLanguageButtons()}
                                </li>
                            </ul>
                        </li>

                        {this.props.callManagerEnabled && <li className="active-topmenuitem">
                            <CallManagerStatus />
                        </li>}
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.base.i18n.language,
        user: state.auth.user,
        settingsPanelShown: state.deviceSettings.settingsPanelShown,
        callManagerEnabled: state.auth.serviceSettings.callManager
    };
};

const mapDispatchToProps = {
    logout,
    changeLanguage,
    showSettings,
    hideSettings,
    showEditAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppTopbar);
