import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { MEETING_TYPES, RESPONSIVE_MODES } from '../../constants/constants';
import OnAirStateIndicator from './OnAirStateIndicator';
import RecordingIndicator from './RecordingIndicator';

export function MeetingIndicators(props) {
    return (
        <div
            className={classNames('meeting-indicators-container', {
                'controls-visible':
                    props.controlsVisible &&
                    props.responsiveMode > RESPONSIVE_MODES.MEDIUM,
            })}
        >
            {props.meetingType === MEETING_TYPES.PODIUM && (
                <OnAirStateIndicator />
            )}
            <RecordingIndicator />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        meetingType:
            state.meetings.meetingInfo && state.meetings.meetingInfo.type,

        controlsVisible: state.meetings.controlsVisible,
        responsiveMode: state.base.common.responsiveMode,
    };
};

export default connect(mapStateToProps, undefined)(MeetingIndicators);
