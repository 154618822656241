export const adiaPadTileSettings = {
    SupportedTiles: {
        CALL_MANAGER_INVITE_ADVISER: 'callManagerInviteAdviser',
        CALL_MANAGER_TRANSFER: 'callManagerTransfer',
        START_STOP_RECORDING: 'startStopRecording',
        MUTE_PARTICIPANT: 'muteParticipant',
        ADD_PARTICIPANT: 'addParticipant',
        REMOVE_PARTICIPANT: 'removeParticipant',
        SHOW_CAM_MIC_SETTINGS: 'showCamMicSettings',
        SEPARATOR_1: 'separator1',
        SHOW_SHOWBOARD: 'showShowboard',
        SHOW_AGENDA: 'showAgenda',
        SHOW_MINUTES: 'showMinutes',
        REQUEST_SIGNATURE: 'requestSignature',
        START_STOP_SCREEN_SHARE: 'startStopScreenShare',
        SEPARATOR_2: 'separator2',
    },

    getDefaults: function () {
        return [
            'callManagerInviteAdviser',
            'callManagerTransfer',
            'startStopRecording',
            'muteParticipant',
            'addParticipant',
            'removeParticipant',
            'showCamMicSettings',
            'separator1',
            'showShowboard',
            'showAgenda',
            'showMinutes',
            'requestSignature',
            'startStopScreenShare',
        ];
    },
};
