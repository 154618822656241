import * as types from './actionTypes';

export const initialState = {
    responsiveMode: 0,
    externalConfigLoaded: false,
};

export default function common(state = initialState, action) {
    switch (action.type) {
        case types.RESPONSIVE_MODE_CHANGE: {
            return {
                ...state,
                responsiveMode: action.responsiveMode,
            };
        }
        case types.EXTERNAL_CONFIG_LOADED: {
            return {
                ...state,
                externalConfigLoaded: action.externalConfigLoaded,
            };
        }
        default:
            return state;
    }
}
