import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Message } from 'primereact/message';

import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';

import { alcTransferCall, hideTransferCallDialog } from './actions';

function TransferCallInputs({ inpValuesRef }) {
    const [topicId, setTopicId] = useState('all');
    const [message, setMessage] = useState('');
    const serviceStateTopics = useSelector(
        (state) => state.callManager.serviceState.topics
    );
    const meetingParticipants = useSelector(
        (state) => state.meetings.meetingParticipants
    );

    const participantsOptions = useMemo(() => {
        const ret = [];
        for (let i = 0; i < meetingParticipants.length; i++) {
            const participant = meetingParticipants[i];
            if (!participant.isAdviser) {
                ret.push({
                    label: participant.info.name,
                    value: participant.id,
                });
            }
        }
        return ret;
    }, [meetingParticipants]);
    const [participant, setParticipant] = useState(
        participantsOptions.length > 0 ? participantsOptions[0].value : null
    );

    useEffect(() => {
        if (inpValuesRef) {
            inpValuesRef.current = {
                topicId,
                message,
                participant,
            };
        }
    }, [topicId, message, participant, inpValuesRef]);

    return (
        <React.Fragment>
            <div className="p-field p-grid">
                <label
                    htmlFor="inpParticipant"
                    className="p-col-fixed"
                    style={{ width: '100px' }}
                >
                    <TranslatedString id="participant" />
                </label>
                <div className="p-col">
                    <Dropdown
                        style={{ minWidth: 140 }}
                        inputId="inpParticipant"
                        value={participant}
                        options={participantsOptions}
                        onChange={(e) => setParticipant(e.value)}
                    />
                </div>
            </div>
            <div className="p-field p-grid">
                <label
                    htmlFor="inpTopicId"
                    className="p-col-fixed"
                    style={{ width: '100px' }}
                >
                    <TranslatedString id="topic" />
                </label>
                <div className="p-col">
                    <Dropdown
                        style={{ minWidth: 140 }}
                        inputId="inpTopicId"
                        value={topicId}
                        options={serviceStateTopics}
                        optionLabel="topicName"
                        optionValue="topicId"
                        filter={true}
                        onChange={(e) => setTopicId(e.value)}
                    />
                </div>
            </div>
            <div className="p-field p-grid">
                <label
                    htmlFor="inpMessage"
                    className="p-col-fixed"
                    style={{ width: '100px' }}
                >
                    <TranslatedString id="comment" />
                </label>
                <div className="p-col">
                    <InputTextarea
                        rows={3}
                        cols={25}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

function TransferCallDialog() {
    const dispatch = useDispatch();
    const [inputError, setInputError] = useState('');
    const transferCallDialogVisible = useSelector(
        (state) => state.meetings.transferCallDialogVisible
    );
    const transferCallInProgress = useSelector(
        (state) => state.meetings.transferCallInProgress
    );
    const transferCallError = useSelector(
        (state) => state.meetings.transferCallError
    );
    const language = useSelector((state) => state.base.i18n.language);
    const inputValueRef = useRef(null);

    const handleClose = useCallback(() => {
        dispatch(hideTransferCallDialog());
    }, [dispatch]);

    useEffect(() => {
        if (!transferCallDialogVisible) {
            setInputError('');
        }
    }, [transferCallDialogVisible, setInputError]);

    const handleTransferCall = useCallback(() => {
        if (
            !inputValueRef.current ||
            !inputValueRef.current.topicId ||
            !inputValueRef.current.participant
        ) {
            setInputError('input');
        } else {
            setInputError('');
            dispatch(
                alcTransferCall(
                    inputValueRef.current.participant,
                    inputValueRef.current.topicId,
                    inputValueRef.current.message
                )
            );
        }
    }, [inputValueRef, setInputError, dispatch]);

    const transferError = inputError || (transferCallError ? 'server' : '');

    return (
        <Dialog
            className="transfer-call-dialog"
            header={<TranslatedString id="adiaPadTransferCall" />}
            footer={
                <div>
                    <Button
                        label={getTranslatedString(language, 'close')}
                        className="p-button-secondary"
                        icon="pi pi-times"
                        onClick={handleClose}
                    />
                    <Button
                        label={getTranslatedString(
                            language,
                            'adiaPadTransferCall'
                        )}
                        className="p-highlight"
                        icon="pi pi-md-phone-forwarded"
                        disabled={transferCallInProgress}
                        onClick={handleTransferCall}
                    />
                </div>
            }
            visible={transferCallDialogVisible}
            onHide={handleClose}
            baseZIndex={999999}
            focusOnShow={false}
        >
            <div className="p-grid form-group">
                <div className="p-col-12">
                    {transferCallDialogVisible && (
                        <React.Fragment>
                            {transferError && (
                                <Message
                                    style={{ marginBottom: 5 }}
                                    severity="error"
                                    text={getTranslatedString(
                                        language,
                                        'msgTransferCallError_' + transferError
                                    )}
                                />
                            )}
                            <TransferCallInputs inpValuesRef={inputValueRef} />
                        </React.Fragment>
                    )}
                </div>
            </div>
        </Dialog>
    );
}

export default TransferCallDialog;
