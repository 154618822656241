import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Message } from 'primereact/message';
import classNames from 'classnames';

import TranslatedString from '../base/i18n/TranslatedString';

import {
    BACKGROUND_EFFECTS,
    COMMUNICATION_MODES,
    MAX_LOCAL_BG_IMAGE_SIZE,
    RESOLUTION_LIST,
    FPS_LIST,
    RESPONSIVE_MODES,
} from '../../constants/constants';
import DeviceSettingsAudioLevel from './DeviceSettingsAudioLevel';
import {
    hideSettings,
    settingsRecoveryChangeCam,
    settingsRecoveryChangeCommunicationMode,
    settingsRecoveryChangeMic,
    settingsRecoveryChangeRes,
    webRtcApplyRecoverySettings,
    webRtcChangeCam,
    webRtcChangeCommunicationMode,
    webRtcChangeMic,
    webRtcChangeRes,
    webRtcChangeFps,
    webRtcChangeHqAudio,
    webRtcRefreshDeviceLists,
    webRtcSetBackgroundEffect,
    settingsSetNotMirrorOwn,
    settingsNewLocalBgImage,
} from './actions';
import { getTranslatedString } from '../base/i18n/translations';
import { toBase64 } from '../base/util/helpers';
import { showMessage } from '../base/messages/actions';
import { Panel } from 'primereact/panel';

export class DeviceSettings extends Component {
    constructor(props) {
        super(props);

        this.videoContainerRef = React.createRef();
        this.handleCommunicationModeChange =
            this.handleCommunicationModeChange.bind(this);
        this.handleCamChange = this.handleCamChange.bind(this);
        this.handleMicChange = this.handleMicChange.bind(this);
        this.handleResChange = this.handleResChange.bind(this);
        this.handleFpsChange = this.handleFpsChange.bind(this);
        this.handleHqAudioChange = this.handleHqAudioChange.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.handleImageUpload = this.handleImageUpload.bind(this);

        this.fileInputElement = null;
    }

    componentDidMount() {
        if (this.props.view) {
            this.videoContainerRef.current.appendChild(this.props.view);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.view !== this.props.view) {
            if (
                prevProps.view &&
                this.videoContainerRef.current.contains(prevProps.view)
            ) {
                this.videoContainerRef.current.removeChild(prevProps.view);
            }
            if (this.props.view) {
                this.videoContainerRef.current.appendChild(this.props.view);
            }
        }
    }

    componentWillUnmount() {
        if (
            this.props.view &&
            this.videoContainerRef.current.contains(this.props.view)
        ) {
            this.videoContainerRef.current.removeChild(this.props.view);
        }
    }

    handleCommunicationModeChange(e) {
        if (e.value) {
            if (!this.props.recoveryMode) {
                this.props.webRtcChangeCommunicationMode(e.value);
            } else {
                this.props.settingsRecoveryChangeCommunicationMode(e.value);
            }
        }
    }

    handleCamChange(e) {
        if (e.value) {
            if (!this.props.recoveryMode) {
                this.props.webRtcChangeCam(e.value);
            } else {
                this.props.settingsRecoveryChangeCam(e.value);
            }
        }
    }

    handleMicChange(e) {
        if (e.value) {
            if (!this.props.recoveryMode) {
                this.props.webRtcChangeMic(e.value);
            } else {
                this.props.settingsRecoveryChangeMic(e.value);
            }
        }
    }

    handleResChange(e) {
        if (e.value) {
            if (!this.props.recoveryMode) {
                this.props.webRtcChangeRes(e.value);
            } else {
                this.props.settingsRecoveryChangeRes(e.value);
            }
        }
    }

    handleFpsChange(e) {
        if (e.value) {
            this.props.webRtcChangeFps(e.value);
        }
    }

    handleHqAudioChange(e) {
        this.props.webRtcChangeHqAudio(!!e.checked);
    }

    handleRefresh(e) {
        if (this.props.recoveryMode) {
            this.props.webRtcRefreshDeviceLists(
                this.props.recoveryCommunicationMode
            );
        } else {
            this.props.webRtcRefreshDeviceLists(this.props.communicationMode);
        }
    }

    handleImageUpload(e) {
        if (!e.target.files[0]) {
            return;
        }
        if (e.target.files[0].size > MAX_LOCAL_BG_IMAGE_SIZE) {
            this.props.showMessage({
                contentId: 'fileSizeToBig',
                type: 'error',
            });
        } else if (!e.target.files[0].type.includes('image')) {
            this.props.showMessage({
                contentId: 'invalidFileType',
                type: 'error',
            });
        } else {
            toBase64(e.target.files[0]).then((result) => {
                this.props.settingsNewLocalBgImage(
                    e.target.files[0].name,
                    result
                );
                this.props.webRtcSetBackgroundEffect(
                    BACKGROUND_EFFECTS.LOCAL_IMAGE,
                    result
                );
            });
        }
    }

    render() {
        const communicationMode = !this.props.recoveryMode
            ? this.props.communicationMode
            : this.props.recoveryCommunicationMode;
        const deviceSettings = !this.props.recoveryMode
            ? this.props.currentSettings
            : this.props.recoverySettings;

        let showCamSettings = false;
        let showMicSettings = false;

        switch (communicationMode) {
            case COMMUNICATION_MODES.BOTH: {
                showCamSettings = showMicSettings = true;
                break;
            }
            case COMMUNICATION_MODES.VIDEO: {
                showCamSettings = true;
                break;
            }
            case COMMUNICATION_MODES.AUDIO: {
                showMicSettings = true;
                break;
            }
            case COMMUNICATION_MODES.NONE:
            default: {
                showCamSettings = showMicSettings = false;
            }
        }

        let camDeviceOptions, micDeviceOptions, resolutionOptions, fpsOptions;

        if (showCamSettings && this.props.deviceLists.camDeviceList) {
            camDeviceOptions = this.props.deviceLists.camDeviceList.map(
                (el) => {
                    return { label: el, value: el };
                }
            );
        }
        if (showMicSettings && this.props.deviceLists.micDeviceList) {
            micDeviceOptions = this.props.deviceLists.micDeviceList.map(
                (el) => {
                    return { label: el, value: el };
                }
            );
        }

        if (showCamSettings) {
            resolutionOptions = RESOLUTION_LIST.map((el) => {
                return { label: el, value: el };
            });

            if (
                deviceSettings.res &&
                !RESOLUTION_LIST.includes(deviceSettings.res)
            ) {
                resolutionOptions.unshift({
                    label: deviceSettings.res,
                    value: deviceSettings.res,
                });
            }

            fpsOptions = FPS_LIST.map((el) => {
                return { label: el, value: el };
            });

            if (deviceSettings.fps && !FPS_LIST.includes(deviceSettings.fps)) {
                fpsOptions.unshift({
                    label: deviceSettings.fps,
                    value: deviceSettings.fps,
                });
            }
        }

        const footer = !this.props.recoveryMode ? (
            <Button
                label={getTranslatedString(this.props.language, 'close')}
                className="p-button-secondary"
                onClick={this.props.hideSettings}
                disabled={this.props.loading}
            />
        ) : (
            <Button
                label={getTranslatedString(this.props.language, 'confirm')}
                className="p-highlight"
                onClick={this.props.webRtcApplyRecoverySettings}
                disabled={
                    (communicationMode === COMMUNICATION_MODES.AUDIO &&
                        !deviceSettings.mic) ||
                    (communicationMode === COMMUNICATION_MODES.BOTH &&
                        (!deviceSettings.cam ||
                            !deviceSettings.res ||
                            !deviceSettings.mic))
                }
            />
        );

        const backgroundEffectOptions = [
            {
                label: getTranslatedString(
                    this.props.language,
                    'backgroundEffectNone'
                ),
                value: BACKGROUND_EFFECTS.NONE,
            },
            {
                label: getTranslatedString(
                    this.props.language,
                    'backgroundEffectBlur'
                ),
                value: BACKGROUND_EFFECTS.BLUR,
            },
            {
                label: getTranslatedString(
                    this.props.language,
                    'backgroundEffectStrongBlur'
                ),
                value: BACKGROUND_EFFECTS.STRONG_BLUR,
            },
        ];

        if (this.props.defaultBackgroundImage) {
            backgroundEffectOptions.push({
                label: getTranslatedString(
                    this.props.language,
                    'backgroundEffectImage'
                ),
                value: BACKGROUND_EFFECTS.IMAGE,
            });
        }
        if (!this.props.disableLocalBgImage && this.props.localBgImageName) {
            backgroundEffectOptions.push({
                label: <TranslatedString id={'localImage'} />,
                value: BACKGROUND_EFFECTS.LOCAL_IMAGE,
            });
        }

        return (
            <Dialog
                className="settings-panel"
                header={<TranslatedString id={'deviceSettings'} />}
                footer={footer}
                visible={this.props.settingsPanelShown}
                onHide={this.props.hideSettings}
                baseZIndex={999999}
                closable={!this.props.loading && !this.props.recoveryMode}
                focusOnShow={false}
                style={
                    this.props.responsiveMode < RESPONSIVE_MODES.MEDIUM
                        ? { width: '90%' }
                        : null
                }
            >
                {this.props.errorId && (
                    <div className="p-col-12" style={{ marginLeft: '-4px' }}>
                        <Message
                            severity="warn"
                            text={getTranslatedString(
                                this.props.language,
                                this.props.errorId
                            )}
                        />
                    </div>
                )}
                <div className="p-col-12">
                    <SelectButton
                        value={communicationMode}
                        options={[
                            {
                                label: getTranslatedString(
                                    this.props.language,
                                    'comModeNone'
                                ),
                                value: COMMUNICATION_MODES.NONE,
                            },
                            {
                                label: getTranslatedString(
                                    this.props.language,
                                    'comModeAudio'
                                ),
                                value: COMMUNICATION_MODES.AUDIO,
                            },
                            {
                                label: getTranslatedString(
                                    this.props.language,
                                    'comModeBoth'
                                ),
                                value: COMMUNICATION_MODES.BOTH,
                            },
                        ]}
                        onChange={this.handleCommunicationModeChange}
                        disabled={this.props.loading}
                    />
                </div>
                <div className="p-col-12">
                    <div
                        className={classNames('settings-panel-video', {
                            'no-video':
                                !showCamSettings || this.props.recoveryMode,
                            'not-mirror-own': this.props.notMirrorOwn,
                        })}
                        ref={this.videoContainerRef}
                    />
                </div>
                <div className="p-grid form-group">
                    {communicationMode !== COMMUNICATION_MODES.NONE && (
                        <div className="p-col-12">
                            <Button
                                label={getTranslatedString(
                                    this.props.language,
                                    'refresh'
                                )}
                                className="p-button-secondary"
                                icon="pi pi-md-refresh"
                                onClick={this.handleRefresh}
                                disabled={this.props.loading}
                            />
                        </div>
                    )}
                    {showCamSettings && (
                        <React.Fragment>
                            <div className="p-col-12">
                                <Dropdown
                                    className="form-input"
                                    value={deviceSettings.cam}
                                    options={camDeviceOptions}
                                    onChange={this.handleCamChange}
                                    disabled={
                                        this.props.loading ||
                                        camDeviceOptions.length === 0
                                    }
                                    scrollHeight={'150px'}
                                />
                                <label className="dropdown-label-float">
                                    <TranslatedString id="cam" />
                                </label>
                            </div>
                            <div className="p-col-12">
                                <Dropdown
                                    className="form-input"
                                    value={deviceSettings.res}
                                    options={resolutionOptions}
                                    onChange={this.handleResChange}
                                    disabled={
                                        this.props.loading ||
                                        camDeviceOptions.length === 0 ||
                                        !deviceSettings.cam
                                    }
                                    scrollHeight={'150px'}
                                />
                                <label className="dropdown-label-float">
                                    <TranslatedString id="resolution" />
                                </label>
                            </div>
                            {this.props.backgroundEffectsSupported && (
                                <React.Fragment>
                                    <div className="p-col-12">
                                        <Dropdown
                                            className="form-input"
                                            value={this.props.backgroundEffect}
                                            options={backgroundEffectOptions}
                                            onChange={(e) => {
                                                let image;
                                                if (
                                                    e.value ===
                                                    BACKGROUND_EFFECTS.IMAGE
                                                ) {
                                                    image =
                                                        this.props
                                                            .defaultBackgroundImage;
                                                }
                                                this.props.webRtcSetBackgroundEffect(
                                                    e.value,
                                                    image
                                                );
                                            }}
                                            scrollHeight={'150px'}
                                            disabled={this.props.loading}
                                        />
                                        <label className="dropdown-label-float">
                                            <TranslatedString id="backgroundEffect" />
                                        </label>
                                    </div>
                                    {!this.props.disableLocalBgImage && (
                                        <div
                                            className="p-col-12"
                                            style={{ paddingTop: '0' }}
                                        >
                                            <span
                                                style={{ marginRight: '10px' }}
                                            >
                                                <TranslatedString
                                                    id={'uploadImage'}
                                                />
                                            </span>
                                            <Button
                                                icon={'pi pi-md-file-upload'}
                                                onClick={() =>
                                                    this.fileInputElement.click()
                                                }
                                                disabled={this.props.loading}
                                            />
                                            <span
                                                style={{ marginLeft: '10px' }}
                                            >
                                                {this.props.localBgImageName
                                                    ? '(' +
                                                      this.props
                                                          .localBgImageName +
                                                      ')'
                                                    : ''}
                                            </span>
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={(input) =>
                                            (this.fileInputElement = input)
                                        }
                                        onChange={this.handleImageUpload}
                                        style={{ display: 'none' }}
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                    {showMicSettings && (
                        <React.Fragment>
                            <div className="p-col-12">
                                <Dropdown
                                    className="form-input"
                                    value={deviceSettings.mic}
                                    options={micDeviceOptions}
                                    onChange={this.handleMicChange}
                                    disabled={
                                        this.props.loading ||
                                        micDeviceOptions.length === 0
                                    }
                                    scrollHeight={'150px'}
                                />
                                <label className="dropdown-label-float">
                                    <TranslatedString id="mic" />
                                </label>
                            </div>
                            <div
                                className="p-col-12"
                                style={{ paddingBottom: '30px' }}
                            >
                                {!this.props.recoveryMode ? (
                                    <DeviceSettingsAudioLevel />
                                ) : (
                                    <div
                                        className="p-col-12"
                                        style={{ paddingBottom: '16px' }}
                                    />
                                )}
                            </div>
                        </React.Fragment>
                    )}
                    {!this.props.recoveryMode &&
                        (showMicSettings || showCamSettings) && (
                            <div className="p-col-12">
                                <Panel
                                    header={
                                        <TranslatedString
                                            id={'advancedSettings'}
                                        />
                                    }
                                    toggleable={true}
                                    collapsed={true}
                                >
                                    {showMicSettings && (
                                        <div className="p-col-12">
                                            <Checkbox
                                                inputId="hqAudio"
                                                onChange={
                                                    this.handleHqAudioChange
                                                }
                                                checked={deviceSettings.hqAudio}
                                                disabled={this.props.loading}
                                            />
                                            <label htmlFor="hqAudio">
                                                <TranslatedString
                                                    id={'hqAudio'}
                                                />
                                            </label>
                                        </div>
                                    )}
                                    {showCamSettings && (
                                        <React.Fragment>
                                            <div
                                                className="p-col-12"
                                                style={{
                                                    marginTop: '10px',
                                                }}
                                            >
                                                <Dropdown
                                                    className="form-input"
                                                    value={deviceSettings.fps}
                                                    options={fpsOptions}
                                                    onChange={
                                                        this.handleFpsChange
                                                    }
                                                    disabled={
                                                        this.props.loading ||
                                                        camDeviceOptions.length ===
                                                            0 ||
                                                        !deviceSettings.cam
                                                    }
                                                    scrollHeight={'150px'}
                                                />
                                                <label className="dropdown-label-float">
                                                    <TranslatedString
                                                        id={'fps'}
                                                    />
                                                </label>
                                            </div>
                                            <div
                                                className="p-col-12"
                                                style={{
                                                    marginBottom: '25px',
                                                }}
                                            >
                                                <Checkbox
                                                    inputId="notMirrorOwn"
                                                    onChange={(e) =>
                                                        this.props.settingsSetNotMirrorOwn(
                                                            e.checked
                                                        )
                                                    }
                                                    checked={
                                                        this.props.notMirrorOwn
                                                    }
                                                    disabled={
                                                        this.props.loading
                                                    }
                                                />
                                                <label htmlFor="notMirrorOwn">
                                                    <TranslatedString
                                                        id={'notMirrorOwn'}
                                                    />
                                                </label>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </Panel>
                            </div>
                        )}
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        deviceLists: state.deviceSettings.deviceLists,
        currentSettings: state.deviceSettings.currentSettings,
        communicationMode: state.deviceSettings.communicationMode,
        view: state.deviceSettings.view,
        settingsPanelShown: state.deviceSettings.settingsPanelShown,
        errorId: state.deviceSettings.errorId,
        loading: state.deviceSettings.loading,
        recoveryMode: state.deviceSettings.recoveryMode,
        recoverySettings: state.deviceSettings.recoverySettings,
        recoveryCommunicationMode:
            state.deviceSettings.recoveryCommunicationMode,
        backgroundEffectsSupported:
            state.deviceSettings.backgroundEffectsSupported,
        backgroundEffect: state.deviceSettings.backgroundEffect,
        defaultBackgroundImage:
            (state.auth.publicServiceInfo &&
                state.auth.publicServiceInfo.defaultBackgroundImage) ||
            null,
        disableLocalBgImage:
            (state.auth.publicServiceInfo &&
                state.auth.publicServiceInfo.disableLocalBgImage) ||
            null,
        language: state.base.i18n.language,
        responsiveMode: state.base.common.responsiveMode,
        notMirrorOwn: state.deviceSettings.notMirrorOwn,
        localBgImageName: state.deviceSettings.localBgImageName,
    };
};

const mapDispatchToProps = {
    hideSettings,
    webRtcChangeCam,
    webRtcChangeMic,
    webRtcChangeRes,
    webRtcChangeFps,
    webRtcChangeHqAudio,
    webRtcChangeCommunicationMode,
    webRtcRefreshDeviceLists,
    webRtcApplyRecoverySettings,
    settingsRecoveryChangeCam,
    settingsRecoveryChangeMic,
    settingsRecoveryChangeRes,
    settingsRecoveryChangeCommunicationMode,
    webRtcSetBackgroundEffect,
    settingsSetNotMirrorOwn,
    settingsNewLocalBgImage,
    showMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSettings);
