import {
    ADVISER_STATE_NAMES,
    AL_APPLICATIONS,
    SA_APPLICATIONS,
} from '../../../../constants/constants';

export const english = {
    // General
    language: 'Language',
    de: 'German',
    en: 'English',
    fr: 'French',
    it: 'Italian',

    applicationName: 'Unblu Meet - Adviser',

    save: 'Save',
    cancel: 'Cancel',
    confirm: 'Confirm',
    close: 'Close',
    join: 'Join',
    edit: 'Edit',
    refresh: 'Refresh',
    connect: 'Connect',
    reject: 'Reject',

    areYouSure: 'Are You Sure?',

    username: 'Username',
    password: 'Password',
    serviceId: 'Service-ID',
    captcha: 'Captcha',
    signIn: 'Sign In',
    signOut: 'Logout',

    dashboard: 'Dashboard',
    meetings: 'Meetings',
    phoneConsulting: 'Phone Consulting',
    templates: 'Templates',
    defaultDocuments: 'Default Documents',
    defaultAgenda: 'Agenda template',
    defaultMinutes: 'Minutes template',
    signatureTemplates: 'Signature templates',

    reconnectingHeader: 'Reconnecting',
    reconnectingMessage: 'Lost Connection to Server. Trying to reconnect...',

    pathDoesNotExist: 'This page does not exist!',

    // Account

    account: 'Account',
    nepatecAccount: 'Nepatec Account',
    editAccount: 'Edit Account',
    name: 'Name',
    lastName: 'Last Name',
    firstName: 'First Name',
    email: 'E-mail',
    newPassword: 'New Password',
    newPasswordConfirmation: 'Confirmation',
    status: 'Status',
    active: 'Active',

    invalidEmail: 'Invalid e-mail',
    invalidPassword: 'The password must be at least 8 characters long.',
    editAccountSuccess: 'Your account has been updated.',
    editAccountFailure: "Your account couldn't be updated.",
    editPasswordSuccess: 'Your password has been updated.',
    editPasswordFailure: "Your password couldn't be updated.",
    editPasswordCurrentWrong: 'Invalid existing password.',
    editPasswordDoesNotMatch: "Password confirmation doesn't match.",
    userKeycloakConflict:
        'A user with this username and/or e-mail already exists.',
    getNepatecFailure: 'Failed to get user.',

    // Dashboard

    adviserOnline: 'Adviser Online',
    ownOpenMeetings: 'Your open Meetings',

    // Device Settings

    deviceSettings: 'Settings',
    cam: 'Camera',
    mic: 'Microphone',
    resolution: 'Resolution',
    comModeNone: 'Chat only',
    comModeAudio: 'Audio',
    comModeBoth: 'Audio + Video',
    backgroundEffect: 'Video Background Effect',
    backgroundEffectNone: 'None',
    backgroundEffectBlur: 'Blur',
    backgroundEffectStrongBlur: 'Blur (strong)',
    backgroundEffectImage: 'Image',
    notMirrorOwn: "Don't mirror own video locally",
    localImage: 'Local Image',
    uploadImage: 'Upload Image',
    advancedSettings: 'Advanced Settings',
    hqAudio: 'Studio Audio',
    fps: 'FPS',

    // Meeting

    agenda: 'Agenda',
    showboard: 'Showboard',
    minutes: 'Minutes',
    documents: 'Documents',
    chat: 'Chat',
    surveys: 'Surveys',
    show: 'Show',
    editModePermissions: 'Edit Mode Permissions',

    startFullscreen: 'Start Full Screen',
    stopFullscreen: 'Exit Full Screen',
    startScreenShare: 'Share your Screen',
    stopScreenShare: 'Stop sharing your Screen',
    leaveMeeting: 'Leave Meeting',
    muteMic: 'Mute Microphone',
    unmuteMic: 'Unmute Microphone',
    activateMic: 'Activate Microphone',
    startCam: 'Start Camera',
    stopCam: 'Stop Camera',
    activateCam: 'Activate Camera',
    meetingInfo: 'Meeting Info',
    sharedApplications: 'Shared Applications',
    menu: 'Menu',

    startRecording: 'Start Recording',
    stopRecording: 'Stop Recording',
    muteParticipant: 'Mute Participant',
    kickParticipant: 'Kick Participant',
    msgRemoteMute: 'You have been muted by an Adviser',
    msgKick: 'You have been removed from the meeting by an Adviser',
    msgTransferred: 'Call has been transferred',
    msgTransferCallError_input: 'Please select topic and participant.',
    msgTransferCallError_server: 'Failed to transfer call.',
    msgNewWaitingUser: 'Someone wants to enter the meeting.',

    participantActions: 'Mute / Kick Participant',
    noParticipants: 'No Participants in Meeting',
    mute: 'Mute',
    kick: 'Kick',

    msgReallyLeaveMeeting: 'Do you really want to leave the meeting?',
    completeMeeting: 'Complete Meeting',
    completeMeetingConfirmation: 'Do you really want to complete this meeting?',
    msgMeetingCompleted: 'The Meeting was completed',
    startCapturingClient: 'Start Meeting Capturing',
    stopCapturingClient: 'Stop Meeting Capturing',
    startBroadcasting: 'Start Broadcast',
    stopBroadcasting: 'Stop Broadcast',
    broadcastOnline: 'BROADCAST LIVE',
    broadcastOffline: 'BROADCAST OFFLINE',
    share: 'Share',
    standaloneApplications: 'Standalone Applications',
    link: 'Link',
    participantLink: 'Participant Link',

    currentActiveUser: 'Focused Participant',
    handRaised: 'Hand raised',
    waitingRoom: 'Waiting room',

    bitrate: 'Bitrate',
    framerate: 'Framerate',
    packetLoss: 'Packet loss',

    // Meeting Manager Standard

    meetingTableDate: 'Date',
    meetingTableTitle: 'Title',
    meetingTableDescription: 'Description',
    meetingTableParticipants: 'Participants',

    // Webinars
    webinars: 'Webinars',
    participants: 'Participants',
    participant: 'Participant',
    speakerPermissions: 'Speaking Permissions',
    grantedSpeakerPermission: 'Granted Speaking Permission',
    requestedSpeakerPermission: 'Requested Speaking Permission',
    leaveWebinar: 'Leave Webinar',

    // Podium
    podiums: 'Podiums',

    // Call Manager
    callManager: 'Call Manager',

    // Recordings
    recordings: 'Recordings',
    download: 'Download',
    deleteRecording: 'Delete Recording',
    deleteRecordingConfirmation: 'Do you really want to delete this recording?',
    unsavedRecordings:
        'Recordings found. Please download them now and then delete them from the list.',
    noRecordings: 'No recordings found',
    recordingsNotice:
        'Due to browser storage limits, please keep the download list as short as possible by downloading and then deleting recordings.',
    markedForDelete: 'Will be deleted in {{hours}} hours',
    markedForDelete1Hour: 'Will be deleted in less than an hour',
    needsDownload: 'Please download',
    confirmRecordingTitle: 'Requesting user permission',
    confirmRecordingMessage:
        'Asking participants whether they will allow recording. Please wait for confirmation.',
    confirmRecordingResultAsk: 'Asking participants',
    confirmRecordingResultRejected: 'Rejected',
    confirmRecordingResultConfirmed: 'Confirmed',
    restartConfirmation: 'Request Confirmation again',

    // adHoc
    meeting: 'Meeting',
    phoneConsultation: 'Phone consultation',
    meetingId: 'Meeting-ID',
    consultationId: 'Consultation-ID',
    newMeeting: 'New meeting',
    newPhoneConsultation: 'New phone consultation',

    topics: 'Topics',
    topic: 'Topic',
    state: 'State',
    action: 'Action',
    select: 'Select',

    allTopicsName: 'All topics',

    incomingCall: 'Incoming Call',
    joiningCall: 'Joining Call',
    addAdviserBtn: 'Add',
    cancelInvitationBtn: 'Cancel invitation',
    noAdvisers: 'No advisers available',
    adviserInvitationMessage:
        '{{adviserName}} wants you to join meeting "{{meetingTitle}}".',
    adviserTransferMessage: 'This call was transferred by "{{adviserName}}"',
    invitationComment: 'Message',
    comment: 'Message',

    callManagerQueueTitle: 'Queue',
    callManagerClosedCallsTitle: 'Closed Calls',
    callManagerAdvisersTitle: 'Advisers',
    callManagerEmptyQueueMessage: 'Queue empty',

    ['appTitle-' + SA_APPLICATIONS.DOCUMENTS]: 'Documents',
    ['appTitle-' + SA_APPLICATIONS.CHAT]: 'Chat',
    ['appTitle-' + SA_APPLICATIONS.DOCUMENTS_MANAGER]: 'Documents',
    ['appTitle-' + SA_APPLICATIONS.STREAM_CHAT]: 'Chat',
    ['appTitle-' + SA_APPLICATIONS.SURVEY_MANAGER]: 'Surveys',
    ['appTitle-' + AL_APPLICATIONS.ADIA_PAD]: 'Control Panel',
    ['appTitle-' + AL_APPLICATIONS.PARTICIPANTS]: 'Participants',

    callManagerStateTitle: 'Call Manager State',

    ['callState-' + ADVISER_STATE_NAMES.BUSY]: 'Busy',
    ['callState-' + ADVISER_STATE_NAMES.READY]: 'Ready',
    ['callState-' + ADVISER_STATE_NAMES.CONNECTING]: 'Connecting',
    ['callState-' + ADVISER_STATE_NAMES.CONNECTED]: 'Connected',
    ['callState-' + ADVISER_STATE_NAMES.JOINED_MEETING]: 'Connected',
    'callState-waiting': 'Waiting',

    ['changeStateBtn-' + ADVISER_STATE_NAMES.BUSY]: 'Set busy',
    ['changeStateBtn-' + ADVISER_STATE_NAMES.READY]: 'Set ready',

    openCallsTitle: 'Open Calls',
    openCallsMessage: 'You currently have open calls',

    // adiaPad
    adiaPadName: 'Pad',
    adiaPadAddAdviser: 'Add Adviser',
    adiaPadTransferCall: 'Transfer call',
    adiaPadShowShowboard: 'Open Showboard',
    adiaPadShowAgenda: 'Open Agenda',
    adiaPadShowMinutes: 'Open Minutes',
    adiaPadShowCamMicSettings: 'Device Settings',
    adiaPadMuteParticipant: 'Mute Participant',
    adiaPadStartScreenShare: 'Share your Screen',
    adiaPadStopScreenShare: 'Stop sharing your Screen',
    adiaPadRequestSignature: 'Request Signature',
    adiaPadAddParticipant: 'Add Participant',
    adiaPadRemoveParticipant: 'Remove Participant',
    adiaPadStartRecording: 'Start Recording',
    adiaPadStopRecording: 'Stop Recording',

    // Errors

    connectionError: 'Connection to server failed.',
    serverError: 'Oops! Something went wrong.',
    accessDenied: 'Access denied!',
    notAuthorized: 'Not authorized!',
    authInvalidIp: 'Invalid IP.',
    authInvalidUser: 'Invalid user.',
    authInvalidCaptcha: 'Invalid captcha.',
    authInvalidCredentials: 'Invalid credentials.',
    serviceNotFound: 'This service does not exist.',
    invalidRoleError:
        "Only users with the 'adviser' role are allowed to use the Adviser application.",
    autoRecordingError:
        "Automatic recording couldn't be started, you're not allowed to join this meeting without recording.",
    invalidFileType: 'Invalid file type',
    fileSizeToBig: 'File size too big',
    meetingCreateError: 'Failed to create meeting.',
    meetingLimitError:
        'No further appointments are possible at this time (maximum capacity reached). Please try later.',

    alcErrorDefault: 'alc Error',
    alcErrorMeetingDoesNotExist:
        'This meeting has been completed or does not exist.',
    alcErrorMeetingConnectionLost:
        'Lost connection to Meeting Server. Please try again.',
    alcErrorMeetingFull:
        'Maximum number of Participants reached for this Meeting.',
    alcErrorWebinarAdviserFull:
        'Maximum number of Advisers reached for this Webinar.',
    alcErrorAdviserIdMismatch:
        'This Meeting-Invitation was not issued for your User Account.',
    alcErrorExclusiveNotInvited:
        'You are not invited to this exclusive Meeting',

    webRtcErrorDefault: 'WebRTC Error',
    webRtcErrorNotFound:
        'No camera / microphone found. Please make sure that your camera / microphone is plugged in and click "Refresh".',
    webRtcErrorNotAllowed:
        'Please allow access to Devices in your Browser. Activate Audio / Audio + Video afterwards.',
    webRtcErrorDeviceInUse:
        'The camera is being used by another application. Please close that application or select a different camera.',
    webRtcErrorFailedAllocateSource: "Your camera couldn't be accessed.",
    webRtcErrorOverconstrained:
        'Your saved Camera / Microphone could not be found. Please check that your Camera / Microphone is plugged in and click Refresh or select a different Device.',
    webRtcErrorConnectionTimeout: 'Timeout trying to connect to meeting server',
    webRtcErrorRecordingStart: 'Could not start recording.',
    webRtcErrorRecordingStream: "A stream can't be recorded.",
    webRtcErrorGetRecordings: 'Recordings could not be retrieved.',
    webRctErrorBackgroundEffect: "Background effect couldn't be applied.",

    webRtcBrowserNotSupported:
        'Your Browser is not supported. Please use a recent version of Chrome or Firefox.',
    webRtcBrowserOutOfDate:
        'Your {{browser}} Version is ouf of date and is no longer supported. Please update your Browser.',
    webRtcBrowserNonSafariIos:
        'Unfortunately {{browser}} is not supported on iOS. Please try a recent version of Safari.',
    webRtcBrowserLegacyEdge:
        "You're using Edge Legacy. This version is out of date and is no longer supported. We suggest using the new Edge which you can download {{link}}.",
    webRtcBrowserIe:
        'Internet Explorer is not supported. We suggest using the new Edge which you can download {{link}}.',
};
