import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';

import { logout } from './actions';

import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';

import { Config } from '../../config/Config';
import { getBrowserCheckMessage } from '../base/util/helpers';

export class InitErrorPage extends Component {
    render() {
        let errorText;
        if (this.props.initErrorId) {
            if (this.props.initErrorNo === 51) {
                errorText = getBrowserCheckMessage(
                    this.props.language,
                    this.props.initErrorData
                );
            } else {
                errorText = getTranslatedString(
                    this.props.language,
                    this.props.initErrorId
                );
                if (
                    (this.props.initErrorId === 'webRtcErrorDefault' ||
                        this.props.initErrorId === 'alcErrorDefault') &&
                    this.props.initErrorNo
                ) {
                    errorText += ' (' + this.props.initErrorNo + ')';
                }
            }
        }

        return (
            <div>
                <div className="login-body">
                    <div className="login-panel p-fluid">
                        <div className="login-panel-header">
                            <img src={Config.loginLogo} alt="Logo" />
                        </div>
                        <div className="login-panel-content">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <h1>
                                        <TranslatedString
                                            id={'applicationName'}
                                        />
                                    </h1>
                                </div>

                                <div className="p-col-12">
                                    <Message severity="warn" text={errorText} />
                                </div>

                                <div className="p-col-12">
                                    <Button
                                        label={getTranslatedString(
                                            this.props.language,
                                            'signOut'
                                        )}
                                        icon="pi-md-exit-to-app"
                                        onClick={this.props.logout}
                                        disabled={this.props.isLoggingIn}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        initErrorId: state.auth.initErrorId,
        initErrorNo: state.auth.initErrorNo,
        initErrorData: state.auth.initErrorData,
        language: state.base.i18n.language,
    };
};

const mapDispatchToProps = {
    logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(InitErrorPage);
