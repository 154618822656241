import React, { Component } from 'react';
import VideoContainer from './VideoContainer';

export default class CombiModeView extends Component {
    render() {
        return (
            <div className="combi-container">
                <div className="combi-video">
                    <VideoContainer videoView={this.props.videoView} />
                </div>
                <div className="combi-screen">
                    <VideoContainer videoView={this.props.screenShareView} />
                </div>
            </div>
        );
    }
}
