import * as types from './actionTypes';

import { hasLanguage } from './translations';
import authHelper from '../util/authHelper';
import { forceLogout } from '../../auth/actions';
import { handleError, log, storeToSessionStorage } from '../util/helpers';

export function changeLanguage(language) {
    return async (dispatch, getState, api) => {
        // check if this language is available
        if (hasLanguage(language)) {
            dispatch({ type: types.CHANGE_LANGUAGE, language });

            const user = authHelper.getUser();
            storeToSessionStorage(
                'user',
                JSON.stringify({ ...user, locale: language })
            );

            const service = getState().auth.service;
            const adviserId = authHelper.getUser()._id;
            if (service) {
                const response = await api.updateUser(
                    service,
                    adviserId,
                    { locale: language },
                    authHelper.getToken()
                );
                if (!response.error) {
                } else {
                    handleError(response.error, {
                        fatal: errorMessage =>
                            dispatch(forceLogout(errorMessage)),
                        nonFatal: errorMessage => log.debug(errorMessage)
                    });
                }
            }
        }
    };
}
