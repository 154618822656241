import * as types from './actionTypes';

export const initialState = {
    editAccountVisible: false,
    isLoading: false,
    accountMessage: undefined,
    passwordMessage: undefined,
    nepatecMessage: undefined,
    nepatecCredentials: {
        username: '',
        active: undefined,
    },
};

export default function editAccount(state = initialState, action) {
    switch (action.type) {
        case types.EDIT_ACCOUNT_SHOW: {
            return {
                ...state,
                editAccountVisible: true,
                accountMessage: undefined,
                passwordMessage: undefined,
            };
        }
        case types.EDIT_ACCOUNT_HIDE: {
            return {
                ...state,
                editAccountVisible: false,
                accountMessage: undefined,
                passwordMessage: undefined,
            };
        }
        case types.EDIT_ACCOUNT_REQUEST:
        case types.EDIT_PASSWORD_REQUEST:
        case types.GET_NEPATEC_REQUEST:
        case types.EDIT_NEPATEC_REQUEST: {
            return {
                ...state,
                isLoading: true,
                accountMessage: undefined,
                passwordMessage: undefined,
                nepatecMessage: undefined,
            };
        }
        case types.EDIT_ACCOUNT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                accountMessage: action.message,
            };
        }
        case types.EDIT_PASSWORD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                passwordMessage: action.message,
            };
        }
        case types.GET_NEPATEC_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                nepatecCredentials: action.nepatecCredentials,
            };
        }
        case types.EDIT_NEPATEC_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                nepatecCredentials: action.nepatecCredentials,
                nepatecMessage: action.message,
            };
        }
        case types.EDIT_ACCOUNT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                accountMessage: action.message,
            };
        }
        case types.EDIT_PASSWORD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                passwordMessage: action.message,
            };
        }
        case types.GET_NEPATEC_FAILURE:
        case types.EDIT_NEPATEC_FAILURE: {
            return {
                ...state,
                isLoading: false,
                nepatecCredentials: {
                    username: '',
                    active: undefined,
                },
                nepatecMessage: action.message,
            };
        }
        default:
            return state;
    }
}
