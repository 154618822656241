import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';

import {
    sharedApplicationsIFrameNewRef,
    sharedApplicationsIFrameLoad,
    switchOwnSharedApplication,
    alcSetCurrentApp,
    alcSetEditPermissions,
    hideSharedApplications,
} from './actions';
import { logout } from '../auth/actions';
import { getTranslatedString } from '../base/i18n/translations';
import {
    AL_APPLICATIONS,
    KELDANO_APPLICATIONS,
    MEETING_TYPES,
    SA_APPLICATIONS,
    SHOWBOARD_TOOLBAR_HEIGHT,
} from '../../constants/constants';
import { getActiveSharedApplications } from '../base/util/helpers';
import ParticipantList from './ParticipantList';

export class SharedApplicationsPanel extends Component {
    constructor(props) {
        super(props);

        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleShowClick = this.handleShowClick.bind(this);
        this.handleEditModeChange = this.handleEditModeChange.bind(this);
        this.handleResize = this.handleResize.bind(this);

        this.containerRef = React.createRef();
        this.headerRef = React.createRef();
        this.iFrameRef = React.createRef();

        this.state = {
            bodyHeight: 0,
            bodyWidth: 0,
            reducedMenu: false,
        };

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.props.sharedApplicationsIFrameNewRef(this.iFrameRef);

        /* content sizing */
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.sharedApplicationsShown !==
                this.props.sharedApplicationsShown ||
            prevProps.controlsVisible !== this.props.controlsVisible ||
            prevProps.saIFrameReady !== this.props.saIFrameReady ||
            prevProps.activeSidePanelApplicationId !==
                this.props.activeSidePanelApplicationId ||
            prevProps.meetingParticipants.length !==
                this.props.meetingParticipants.length
        ) {
            this.handleResize();
        }
    }

    componentWillUnmount() {
        this.props.sharedApplicationsIFrameNewRef(null);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        if (this.props.sharedApplicationsShown || this.props.standalone) {
            const mainContainerRect =
                this.containerRef.current.getBoundingClientRect();
            const headerContainerRect = this.headerRef.current
                ? this.headerRef.current.getBoundingClientRect()
                : { height: 0, width: mainContainerRect.width };

            const showboardToolbarHeight = this.props.singleApp
                ? 0
                : SHOWBOARD_TOOLBAR_HEIGHT;

            let bodyTargetHeight =
                mainContainerRect.height - headerContainerRect.height;
            let bodyTargetWidth =
                ((bodyTargetHeight - showboardToolbarHeight) / 9) * 16;
            if (bodyTargetWidth > mainContainerRect.width) {
                bodyTargetWidth = mainContainerRect.width;
                bodyTargetHeight =
                    (bodyTargetWidth / 16) * 9 + showboardToolbarHeight;
            }
            if (bodyTargetWidth <= 445) {
                bodyTargetWidth = 445;
                bodyTargetHeight =
                    (bodyTargetWidth / 16) * 9 + showboardToolbarHeight;
            }

            const reducedMenu =
                (this.props.standalone && bodyTargetWidth < 1200) ||
                bodyTargetWidth < 720;

            this.setState({
                bodyHeight: bodyTargetHeight,
                bodyWidth: bodyTargetWidth,
                reducedMenu,
            });
        }
    }

    handleTabChange(e) {
        this.props.switchOwnSharedApplication(e.value.appId);
    }

    handleEditModeChange(e) {
        const currentShownApp = this.props.ownApplicationId
            ? this.props.ownApplicationId
            : this.props.meetingInfo.currentApp;
        this.props.meetingParticipants.forEach((participant) => {
            if (!participant.isAdviser) {
                const hasEditMode = participant.editPermissions
                    ? participant.editPermissions.includes(currentShownApp)
                    : false;
                const shouldHaveEditMode = e.value.includes(participant.id);
                // if participant currently has edit permission for currently shown app, and isn't in new list, remove it
                if (hasEditMode && !shouldHaveEditMode) {
                    const newEditPermissions = [...participant.editPermissions];
                    const index = newEditPermissions.indexOf(currentShownApp);
                    if (index !== -1) {
                        newEditPermissions.splice(index, 1);
                    }
                    this.props.alcSetEditPermissions(
                        participant.id,
                        newEditPermissions
                    );
                    // if participant doesn't have edit permission but is in new list, add it
                } else if (!hasEditMode && shouldHaveEditMode) {
                    const newEditPermissions = participant.editPermissions
                        ? [...participant.editPermissions]
                        : [];
                    newEditPermissions.push(currentShownApp);
                    this.props.alcSetEditPermissions(
                        participant.id,
                        newEditPermissions
                    );
                }
            }
        });
    }

    handleShowClick() {
        // if currentApp active and currently on current app panel -> disable show mode
        if (this.isShowActiveOnCurrentPanel()) {
            this.props.alcSetCurrentApp('');
        } else {
            this.props.alcSetCurrentApp(this.props.ownApplicationId);
        }
    }

    isShowActiveOnCurrentPanel() {
        return (
            this.props.meetingInfo.currentApp &&
            (!this.props.ownApplicationId ||
                this.props.meetingInfo.currentApp ===
                    this.props.ownApplicationId)
        );
    }

    render() {
        let sharedApplicationsUrl =
            this.props.applicationsUrl +
            '/' +
            KELDANO_APPLICATIONS.SHARED_APPLICATIONS;
        if (this.props.saAuthToken) {
            if (sharedApplicationsUrl.indexOf('?') > -1) {
                sharedApplicationsUrl += '&jws=' + this.props.saAuthToken;
            } else {
                sharedApplicationsUrl += '?jws=' + this.props.saAuthToken;
            }
        }

        if (this.props.standalone && this.props.singleApp) {
            return (
                <div
                    className="sa-panel-container standalone"
                    ref={this.containerRef}
                >
                    <div className="sa-panel">
                        <div
                            className="sa-panel-body"
                            style={{
                                height: this.state.bodyHeight,
                                width: this.state.bodyWidth,
                            }}
                        >
                            <iframe
                                className="shared-applications-iframe"
                                title="Shared Applications"
                                src={sharedApplicationsUrl}
                                ref={this.iFrameRef}
                                onLoad={this.props.sharedApplicationsIFrameLoad}
                                allow={'display-capture'}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        const showboardItem = {
            label: !this.state.reducedMenu
                ? getTranslatedString(this.props.language, 'showboard')
                : '',
            icon: 'pi-md-laptop',
            appId: SA_APPLICATIONS.SHOWBOARD,
            className: classNames({
                'active-sa-tab':
                    this.props.meetingInfo.currentApp ===
                    SA_APPLICATIONS.SHOWBOARD,
            }),
        };

        const agendaItem = {
            label: !this.state.reducedMenu
                ? getTranslatedString(this.props.language, 'agenda')
                : '',
            icon: 'pi-md-format-list-bulleted',
            appId: SA_APPLICATIONS.AGENDA,
            className: classNames({
                'active-sa-tab':
                    this.props.meetingInfo.currentApp ===
                    SA_APPLICATIONS.AGENDA,
            }),
        };

        const minutesItem = {
            label: !this.state.reducedMenu
                ? getTranslatedString(this.props.language, 'minutes')
                : '',
            icon: 'pi-md-speaker-notes',
            appId: SA_APPLICATIONS.MINUTES,
            className: classNames({
                'active-sa-tab':
                    this.props.meetingInfo.currentApp ===
                    SA_APPLICATIONS.MINUTES,
            }),
        };

        const documentsItem = {
            label: !this.state.reducedMenu
                ? getTranslatedString(this.props.language, 'documents')
                : '',
            icon: 'pi-md-insert-drive-file',
            appId:
                this.props.meetingInfo.type !== MEETING_TYPES.PODIUM
                    ? SA_APPLICATIONS.DOCUMENTS
                    : SA_APPLICATIONS.DOCUMENTS_MANAGER,
        };

        const chatItem = {
            label: !this.state.reducedMenu
                ? getTranslatedString(this.props.language, 'chat')
                : '',
            icon: 'pi-md-chat-bubble-outline',
            appId:
                this.props.meetingInfo.type !== MEETING_TYPES.PODIUM
                    ? SA_APPLICATIONS.CHAT
                    : SA_APPLICATIONS.STREAM_CHAT,
        };

        const surveysItem = {
            label: !this.state.reducedMenu
                ? getTranslatedString(this.props.language, 'surveys')
                : '',
            icon: 'pi-md-poll',
            appId: SA_APPLICATIONS.SURVEY_MANAGER,
        };

        const participantsItem = {
            label: !this.state.reducedMenu
                ? getTranslatedString(
                      this.props.language,
                      'appTitle-' + AL_APPLICATIONS.PARTICIPANTS
                  )
                : '',
            icon: 'pi-md-people',
            appId: AL_APPLICATIONS.PARTICIPANTS,
        };

        const items = [];
        const activeSharedApplications = getActiveSharedApplications(
            this.props.meetingInfo,
            this.props.publicServiceInfo,
            this.props.standalone
        );

        activeSharedApplications.forEach((application) => {
            switch (application) {
                case SA_APPLICATIONS.SHOWBOARD: {
                    items.push(showboardItem);
                    break;
                }
                case SA_APPLICATIONS.AGENDA: {
                    items.push(agendaItem);
                    break;
                }
                case SA_APPLICATIONS.MINUTES: {
                    items.push(minutesItem);
                    break;
                }
                case SA_APPLICATIONS.DOCUMENTS:
                case SA_APPLICATIONS.DOCUMENTS_MANAGER: {
                    items.push(documentsItem);
                    break;
                }
                case SA_APPLICATIONS.CHAT:
                case SA_APPLICATIONS.STREAM_CHAT: {
                    items.push(chatItem);
                    break;
                }
                case SA_APPLICATIONS.SURVEY_MANAGER: {
                    items.push(surveysItem);
                    break;
                }
                case AL_APPLICATIONS.PARTICIPANTS: {
                    items.push(participantsItem);
                    break;
                }
                default: {
                    break;
                }
            }
        });

        const currentShownApp = this.props.ownApplicationId
            ? this.props.ownApplicationId
            : this.props.meetingInfo.currentApp;

        let activeItem = null;
        switch (currentShownApp) {
            case SA_APPLICATIONS.SHOWBOARD: {
                activeItem = showboardItem;
                break;
            }
            case SA_APPLICATIONS.AGENDA: {
                activeItem = agendaItem;
                break;
            }
            case SA_APPLICATIONS.MINUTES: {
                activeItem = minutesItem;
                break;
            }
            case SA_APPLICATIONS.DOCUMENTS:
            case SA_APPLICATIONS.DOCUMENTS_MANAGER: {
                activeItem = documentsItem;
                break;
            }
            case SA_APPLICATIONS.CHAT:
            case SA_APPLICATIONS.STREAM_CHAT: {
                activeItem = chatItem;
                break;
            }
            case SA_APPLICATIONS.SURVEY_MANAGER: {
                activeItem = surveysItem;
                break;
            }
            case AL_APPLICATIONS.PARTICIPANTS: {
                activeItem = participantsItem;
                break;
            }
            default: {
                activeItem = showboardItem;
                break;
            }
        }

        // get options {label; name, value: id} for each participant who isn't an adviser
        // get all non adviser participants who have edit mode on currently shown application
        const { editModeOptions, editModeValue } =
            this.props.meetingParticipants.reduce(
                (result, currentElement) => {
                    if (!currentElement.isAdviser && !currentElement.recorder) {
                        if (currentElement.info.name && currentElement.id) {
                            result.editModeOptions.push({
                                label: currentElement.info.name,
                                value: currentElement.id,
                            });

                            if (
                                currentElement.editPermissions &&
                                currentElement.editPermissions.includes(
                                    currentShownApp
                                )
                            ) {
                                result.editModeValue.push(currentElement.id);
                            }
                        }
                    }
                    return result;
                },
                { editModeOptions: [], editModeValue: [] }
            );

        let closeIcon = null;
        if (this.props.meetingInfo && this.props.meetingInfo.currentApp) {
            closeIcon = <i className="pi-md-close close-icon disabled" />;
        } else {
            closeIcon = (
                <i
                    className="pi-md-close close-icon clickable"
                    onClick={this.props.hideSharedApplications}
                />
            );
        }

        const isShareableApplication =
            currentShownApp === SA_APPLICATIONS.SHOWBOARD ||
            currentShownApp === SA_APPLICATIONS.AGENDA ||
            currentShownApp === SA_APPLICATIONS.MINUTES;

        const iFrameHidden = currentShownApp === AL_APPLICATIONS.PARTICIPANTS;

        return (
            <div
                className={classNames({
                    'sa-panel-container': true,
                    hidden: !(
                        this.props.saIFrameReady &&
                        (this.props.sharedApplicationsShown ||
                            this.props.standalone)
                    ),
                    controlsVisible: this.props.controlsVisible,
                    standalone: this.props.standalone,
                })}
                ref={this.containerRef}
            >
                <div className="sa-panel">
                    <div className="sa-panel-header" ref={this.headerRef}>
                        <TabMenu
                            model={items}
                            activeItem={activeItem}
                            onTabChange={this.handleTabChange}
                        />
                        <div className="sa-panel-controls">
                            {isShareableApplication && (
                                <React.Fragment>
                                    <Button
                                        className={classNames({
                                            'p-highlight':
                                                this.isShowActiveOnCurrentPanel(),
                                        })}
                                        label={getTranslatedString(
                                            this.props.language,
                                            'show'
                                        )}
                                        icon="pi-md-remove-red-eye"
                                        onClick={this.handleShowClick}
                                    />
                                    <MultiSelect
                                        value={editModeValue}
                                        options={editModeOptions}
                                        onChange={this.handleEditModeChange}
                                        filter={false}
                                        placeholder={
                                            <React.Fragment>
                                                <i className="pi-md-edit" />
                                                {!this.state.reducedMenu &&
                                                    getTranslatedString(
                                                        this.props.language,
                                                        'editModePermissions'
                                                    )}
                                            </React.Fragment>
                                        }
                                        fixedPlaceholder={true}
                                        disabled={editModeOptions.length === 0}
                                        className={'edit-mode-select'}
                                    />
                                </React.Fragment>
                            )}
                            {!this.props.standalone && closeIcon}
                            {this.props.standalone && (
                                <i
                                    className="pi-md-exit-to-app clickable"
                                    onClick={this.props.logout}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className="sa-panel-body"
                        style={{
                            height: this.state.bodyHeight,
                            width: this.state.bodyWidth,
                        }}
                    >
                        <iframe
                            className="shared-applications-iframe"
                            title="Shared Applications "
                            src={sharedApplicationsUrl}
                            ref={this.iFrameRef}
                            onLoad={this.props.sharedApplicationsIFrameLoad}
                            allow={'display-capture'}
                            hidden={iFrameHidden}
                        />
                        {iFrameHidden &&
                            currentShownApp ===
                                AL_APPLICATIONS.PARTICIPANTS && (
                                <div style={{ height: this.state.bodyHeight }}>
                                    <ParticipantList />
                                </div>
                            )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sharedApplicationsShown: state.meetings.sharedApplicationsShown,
        applicationsUrl:
            state.auth.publicServiceInfo.meetingsSettings.applicationsUrl,
        ownApplicationId: state.meetings.ownApplicationId,
        meetingInfo: state.meetings.meetingInfo,
        meetingParticipants: state.meetings.meetingParticipants,
        saAuthToken: state.meetings.saAuthToken,
        controlsVisible: state.meetings.controlsVisible,
        saIFrameReady: state.meetings.saIFrameReady,
        language: state.base.i18n.language,
        activeSidePanelApplicationId:
            state.meetings.activeSidePanelApplicationId,
        publicServiceInfo: state.auth.publicServiceInfo,
    };
};

const mapDispatchToProps = {
    sharedApplicationsIFrameNewRef,
    sharedApplicationsIFrameLoad,
    switchOwnSharedApplication,
    alcSetCurrentApp,
    alcSetEditPermissions,
    hideSharedApplications,
    logout,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SharedApplicationsPanel);
