import React, { Component } from 'react';
import classNames from 'classnames';

import ConnectionIndicator from './ConnectionIndicator';
import AudioLevelIndicator from './AudioLevelIndicator';
import { Button } from 'primereact/button';
import VideoContainer from './VideoContainer';
import { MEETING_TYPES, SCREEN_SHARING_MODES } from '../../constants/constants';
import CombiModeView from './CombiModeView';

export default class VideoListItem extends Component {
    constructor(props) {
        super(props);
        this.handleChangeActiveUser = this.handleChangeActiveUser.bind(this);
        this.handleRevokeClick = this.handleRevokeClick.bind(this);
        this.handleToggleUserVisibility =
            this.handleToggleUserVisibility.bind(this);
    }

    handleChangeActiveUser() {
        if (this.props.isCurrentActiveUser) {
            this.props.onChangeActiveUser(null);
        } else {
            this.props.onChangeActiveUser(this.props.userInfo.id);
        }
    }

    handleToggleUserVisibility(e) {
        e.stopPropagation();
        const isVisible = !this.props.userInfo.isVisible === false;
        this.props.onSetUserVisibility(this.props.userInfo.id, !isVisible);
    }

    handleRevokeClick(e) {
        e.stopPropagation();
        this.props.onRevokeSpeakerPermission();
    }

    render() {
        const hasVideo = !!(this.props.videoView && this.props.videoView.view);
        let videoElement;

        if (
            !this.props.isMainVideo &&
            this.props.screenShareActive &&
            this.props.userInfo.userMode &&
            this.props.userInfo.userMode.screenSharing !==
                SCREEN_SHARING_MODES.VIDEO
        ) {
            if (
                this.props.userInfo.userMode.screenSharing ===
                    SCREEN_SHARING_MODES.COMBI &&
                hasVideo
            ) {
                videoElement = (
                    <CombiModeView
                        videoView={this.props.videoView}
                        screenShareView={this.props.screenShareView}
                    />
                );
            } else {
                videoElement = (
                    <VideoContainer videoView={this.props.screenShareView} />
                );
            }
        } else if (
            hasVideo &&
            (!this.props.isMainVideo ||
                (this.props.screenShareActive &&
                    this.props.userInfo.userMode &&
                    this.props.userInfo.userMode.screenSharing ===
                        SCREEN_SHARING_MODES.SCREEN))
        ) {
            videoElement = <VideoContainer videoView={this.props.videoView} />;
        } else {
            let placeholder;
            if (this.props.isMainVideo) {
                if (this.props.isCurrentActiveUser) {
                    placeholder = (
                        <div className="video-list-icon">
                            <i className="icon-open_in_new" />
                        </div>
                    );
                } else if (this.props.isActiveSpeaker) {
                    placeholder = (
                        <div className="video-list-icon">
                            <i className="icon-open_in_new" />
                        </div>
                    );
                } else {
                    placeholder = (
                        <div className="video-list-icon">
                            <i className="icon-open_in_new" />
                        </div>
                    );
                }
            } else {
                let nameParts;
                if (this.props.userInfo.info.name) {
                    nameParts = this.props.userInfo.info.name.split(' ');
                }
                let initials = '';
                if (nameParts) {
                    if (nameParts.length > 1 && nameParts[1] !== '') {
                        initials =
                            nameParts[0].substr(0, 1) +
                            nameParts[1].substr(0, 1);
                    } else {
                        initials = nameParts[0].substr(0, 2);
                    }
                }
                placeholder = (
                    <div className="video-list-icon">
                        <div className="name-initial">{initials}</div>
                    </div>
                );
            }
            videoElement = placeholder;
        }

        return (
            <div
                className={classNames('video-list-box', {
                    'video-box-active': this.props.isCurrentActiveUser,
                })}
                onClick={this.handleChangeActiveUser}
            >
                <div className="participant-name-wrapper">
                    <div className="participant-name">
                        {this.props.userInfo.info.name}
                    </div>
                </div>
                <ConnectionIndicator
                    userInfo={this.props.userInfo}
                    videoActive={this.props.userInfo.deviceInfo.video}
                    isSelf={this.props.isSelf}
                />
                {videoElement}
                <AudioLevelIndicator userId={this.props.userInfo.id} />
                {!this.props.userInfo.deviceInfo.audio && (
                    <div className="muted-icon">
                        <i className="icon-mic-disabled" />
                    </div>
                )}
                {this.props.isRevokeable && (
                    <Button
                        className="p-button-secondary revoke-button"
                        icon="pi pi-times"
                        onClick={this.handleRevokeClick}
                    />
                )}
                {this.props.meetingType === MEETING_TYPES.PODIUM && (
                    <div
                        className={classNames('visibility-icon', {
                            'show-always':
                                this.props.userInfo.isVisible === false,
                        })}
                    >
                        <i
                            className={classNames({
                                'icon-visibility':
                                    !this.props.userInfo.isVisible === false,
                                'icon-visibility-off':
                                    this.props.userInfo.isVisible === false,
                            })}
                            onClick={this.handleToggleUserVisibility}
                        />
                    </div>
                )}
            </div>
        );
    }
}
