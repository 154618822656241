import { push } from 'connected-react-router';

import * as types from './actionTypes';
import Routes from '../../constants/routes';
import {
    log,
    removeFromSessionStorage,
    routeWithServiceId,
    storeToLocalStorage,
    storeToSessionStorage,
} from '../base/util/helpers';
import authHelper from '../base/util/authHelper';
import { ERRORS } from '../../constants/constants';

export function login(loginData) {
    return { type: types.LOGIN_REQUEST, loginData };
}

export function loginSuccess(user, serviceId) {
    return { type: types.LOGIN_SUCCESS, user, serviceId };
}

export function loginFailure(errorMessage, captcha) {
    return { type: types.LOGIN_FAILURE, errorMessage, captcha };
}

/*
 * thunk
 */
export function serviceLogin(loginData) {
    return async (dispatch, getState, api) => {
        const serviceId = loginData.serviceId;
        const response = await api.serviceLogin(loginData);

        if (response.user) {
            if (response.user.roles.includes('adviser')) {
                storeToSessionStorage('token', response.token);
                storeToSessionStorage('user', JSON.stringify(response.user));
                storeToLocalStorage('serviceId', serviceId);

                dispatch(
                    loginSuccess(
                        response.user,
                        serviceId,
                        getState().publicService
                    )
                );

                //redirect to selected path or default path
                const path = getState().router.location.pathname;
                if (
                    path === routeWithServiceId(Routes.SERVICE_LOGIN, serviceId)
                ) {
                    dispatch(
                        push(routeWithServiceId(Routes.DASHBOARD, serviceId))
                    );
                }
            } else {
                dispatch(loginFailure(ERRORS.INVALID_ROLE_ERROR));
            }
        } else {
            dispatch(loginFailure(response.error, response.captcha));
        }
    };
}

/*
 * thunk
 */
export function keycloakLogin(user, serviceId) {
    return async (dispatch, getState, api) => {
        storeToSessionStorage('token', 'keycloak');
        storeToSessionStorage('user', JSON.stringify(user));
        storeToLocalStorage('serviceId', serviceId);

        if (user && user.roles && user.roles.includes('adviser')) {
            dispatch(loginSuccess(user, serviceId));

            //redirect to selected path or default path
            const path = getState().router.location.pathname;
            if (path === routeWithServiceId(Routes.SERVICE_LOGIN, serviceId)) {
                dispatch(push(routeWithServiceId(Routes.DASHBOARD, serviceId)));
            }
        } else {
            dispatch(loginFailure(ERRORS.INVALID_ROLE_ERROR));
            dispatch(logout());
        }
    };
}

/*
 * updateToken thunk
 */
export function updateToken(currentToken, serviceId) {
    return async (dispatch, getState, api) => {
        log.debug('[updateToken]');

        const response = await api.updateServiceToken(serviceId, currentToken);
        if (!response.error) {
            storeToSessionStorage('token', response.token);
        } else {
            dispatch(forceLogout(response.error));
        }
    };
}

/*
 * action creators
 */
export function checkReLogin() {
    return { type: types.RE_LOGIN_CHECK };
}

export function checkReLoginDone() {
    return { type: types.RE_LOGIN_CHECKED };
}

export function reLogin(user, serviceId, keycloak) {
    return { type: types.RE_LOGIN_REQUEST, user, serviceId, keycloak };
}

function reLoginSuccess(user, serviceId) {
    return { type: types.RE_LOGIN_SUCCESS, user, serviceId };
}

function reLoginFailure(errorMessage) {
    return { type: types.RE_LOGIN_FAILURE, errorMessage };
}

/*
 * thunk
 */
export function reLoginService(user, serviceId) {
    return async (dispatch, getState, api) => {
        if (user && user.roles && user.roles.includes('adviser')) {
            //request public service info
            await dispatch(requestPublicServiceInfo(serviceId));

            const response = await api.updateServiceToken(
                serviceId,
                authHelper.getToken(true)
            );
            if (!response.error) {
                storeToSessionStorage('token', response.token);
                dispatch(reLoginSuccess(user, serviceId));
            } else {
                dispatch(reLoginFailure());
            }
        } else {
            dispatch(reLoginFailure(ERRORS.INVALID_ROLE_ERROR));
        }
    };
}

/*
 * keycloakReLogin thunk
 */
export function keycloakReLogin() {
    return async (dispatch) => {
        removeFromSessionStorage('token');

        dispatch(checkReLoginDone());
    };
}

export function logout() {
    return { type: types.LOGOUT };
}

export function forceLogout(errorMessage) {
    return { type: types.FORCE_LOGOUT, errorMessage };
}

export function logoutSuccess() {
    return { type: types.LOGOUT_SUCCESS };
}

export function noServiceFound() {
    return { type: types.NO_SERVICE };
}

export function selectService(serviceId) {
    return { type: types.SELECT_SERVICE, serviceId };
}

/*
 * action creators
 */
function getPublicServiceInfoRequest() {
    return { type: types.PUBLIC_SERVICE_INFO_REQUEST };
}

function getPublicServiceInfoSuccess(serviceId, publicServiceInfo) {
    return {
        type: types.PUBLIC_SERVICE_INFO_SUCCESS,
        serviceId,
        publicServiceInfo,
    };
}

function getPublicServiceInfoFailure(errorMessage) {
    return { type: types.PUBLIC_SERVICE_INFO_FAILURE, errorMessage };
}

/*
 * thunk
 */
export function requestPublicServiceInfo(serviceId, keycloakLogin) {
    return async (dispatch, getState, api) => {
        dispatch(getPublicServiceInfoRequest());

        const response = await api.getPublicServiceInfo(serviceId);
        if (!response.error) {
            dispatch(
                getPublicServiceInfoSuccess(
                    serviceId,
                    response.publicServiceInfo
                )
            );

            // login immediately with keycloak if authMethod is 'keycloak' and a 'keycloakLogin' method is defined
            if (
                keycloakLogin &&
                response.publicServiceInfo &&
                response.publicServiceInfo.authMethod === 'keycloak' &&
                response.publicServiceInfo.keycloakSettings
            ) {
                dispatch(keycloakLogin);
            }
        } else {
            //redirect to HOME route if there is some error; this will trigger the serviceId input page again
            dispatch(push(Routes.HOME));
            dispatch(getPublicServiceInfoFailure(response.error));
        }
    };
}

export function initializeFailure(errorId, errorNo, errorData) {
    return { type: types.INITIALIZE_FAILURE, errorId, errorNo, errorData };
}
