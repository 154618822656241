import {
    ALC_MEETING_INFO_UPDATE,
    WEBRTC_CLOSE,
    WEBRTC_INIT,
    WEBRTC_JOIN,
    WEBRTC_LEAVE,
    WEBRTC_START_LOCAL_MEDIA,
    WEBRTC_START_RECORDING,
    WEBRTC_START_SCREENSHARE,
    WEBRTC_STOP_LOCAL_MEDIA,
    WEBRTC_STOP_RECORDING,
    WEBRTC_STOP_SCREENSHARE,
} from '../features/meetings/actionTypes';
import {
    webRtcInitFailure,
    webRtcInitSuccess,
    webRtcJoinFailure,
    webRtcJoinSuccess,
    webRtcReconnecting,
    webRtcReconnectingFailure,
    webRtcReconnectingSuccess,
    webRtcSpeakerChanged,
    webRtcStartLocalMediaFailure,
    webRtcStartLocalMediaSuccess,
    webRtcStartRecordingFailure,
    webRtcStartRecordingSuccess,
    webRtcStartScreenShareSuccess,
    webRtcStopScreenShareSuccess,
    webRtcUserJoined,
    webRtcUserLeft,
} from '../features/meetings/actions';
import WebRtcLayer from '@adialive/webrtc-layer';
import {
    SETTINGS_HIDE,
    SETTINGS_RECOVERY_CHANGE_COMMUNICATION_MODE,
    SETTINGS_RECOVERY_START,
    SETTINGS_SET_NOT_MIRROR_OWN,
    SETTINGS_SHOW,
    WEBRTC_APPLY_RECOVERY_SETTINGS,
    WEBRTC_CHANGE_CAM,
    WEBRTC_CHANGE_COMMUNICATION_MODE,
    WEBRTC_CHANGE_MIC,
    WEBRTC_CHANGE_RES,
    WEBRTC_MUTE_AUDIO,
    WEBRTC_MUTE_VIDEO,
    WEBRTC_REFRESH_DEVICE_LISTS,
    WEBRTC_SET_BACKGROUND_EFFECT,
    SETTINGS_NEW_LOCAL_BG_IMAGE,
    WEBRTC_CHANGE_FPS,
    WEBRTC_CHANGE_HQ_AUDIO,
} from '../features/deviceSettings/actionTypes';
import {
    settingsRecoveryChangeCam,
    settingsRecoveryChangeCommunicationModeFailure,
    settingsRecoveryChangeCommunicationModeSuccess,
    settingsRecoveryChangeMic,
    settingsRecoveryStart,
    settingsRecoveryStartSuccess,
    webRtcApplyRecoverySettingsSuccess,
    webRtcChangeCamSuccess,
    webRtcChangeCommunicationModeSuccess,
    webRtcChangeFpsSuccess,
    webRtcChangeHqAudioSuccess,
    webRtcChangeMicSuccess,
    webRtcChangeResSuccess,
    webRtcRefreshDeviceListsSuccess,
    webRtcSetBackgroundEffectSuccess,
    webRtcSettingsError,
    webRtcSettingsStartSuccess,
} from '../features/deviceSettings/actions';
import {
    BACKGROUND_EFFECTS,
    COMMUNICATION_MODES,
    DEFAULT_RESOLUTION,
    WEBRTC_MODES,
} from '../constants/constants';
import { audioLevelMap, statsMap } from '../features/base/util/webRtcStats';
import {
    log,
    logging,
    readFromLocalStorage,
    storeToLocalStorage,
    webRtcErrorCodeToErrorId,
    webRtcLogging,
} from '../features/base/util/helpers';
import { showMessage } from '../features/base/messages/actions';
import {
    DELETE_RECORDING,
    DOWNLOAD_RECORDING,
    REQUEST_RECORDINGS,
} from '../features/recordings/actionTypes';
import {
    requestRecordingsFailure,
    requestRecordingsSuccess,
} from '../features/recordings/actions';
import { Config } from '../config/Config';

// TODO: adjust webrtc-layer to match with adviser format, adjust this function
const convertAndStoreBgEffect = (state, bgEffect) => {
    const imageSrc =
        (state.auth.publicServiceInfo &&
            state.auth.publicServiceInfo.defaultBackgroundImage) ||
        null;
    const currentBgEffect = state.deviceSettings.backgroundEffect;
    let newSettings;
    if (bgEffect === imageSrc) {
        newSettings = {
            backgroundEffect: BACKGROUND_EFFECTS.IMAGE,
            imageSrc: imageSrc,
        };
    } else if (
        bgEffect === BACKGROUND_EFFECTS.BLUR ||
        bgEffect === BACKGROUND_EFFECTS.STRONG_BLUR
    ) {
        newSettings = {
            backgroundEffect: bgEffect,
            imageSrc: undefined,
        };
    } else if (bgEffect && bgEffect.startsWith('data:image')) {
        newSettings = {
            backgroundEffect: BACKGROUND_EFFECTS.LOCAL_IMAGE,
            imageSrc: undefined,
        };
    } else {
        newSettings = {
            backgroundEffect: BACKGROUND_EFFECTS.NONE,
            imageSrc: undefined,
        };
    }
    if (currentBgEffect !== newSettings.backgroundEffect) {
        log.debug('background effect stored:', newSettings);
        storeToLocalStorage(
            'videoBackgroundSettings',
            JSON.stringify(newSettings)
        );
    }
    return newSettings.backgroundEffect;
};

export const createWebRtcLayerMiddleware = () => {
    let webRtcLayer;

    return (store) => (next) => (action) => {
        switch (action.type) {
            case WEBRTC_INIT: {
                let deviceSettings;

                try {
                    deviceSettings = JSON.parse(
                        readFromLocalStorage('deviceSettings')
                    );
                } catch (err) {
                    deviceSettings = null;
                }
                if (
                    !deviceSettings ||
                    !deviceSettings.cam ||
                    !deviceSettings.mic ||
                    !deviceSettings.res
                ) {
                    deviceSettings = {
                        cam:
                            deviceSettings && deviceSettings.cam
                                ? deviceSettings.cam
                                : 'default',
                        mic:
                            deviceSettings && deviceSettings.mic
                                ? deviceSettings.mic
                                : 'default',
                        res:
                            deviceSettings && deviceSettings.res
                                ? deviceSettings.res
                                : DEFAULT_RESOLUTION,
                    };
                }

                let videoBackgroundSettings, backgroundEffect, localImage;
                const backgroundEffectsSupported =
                    !!WebRtcLayer.canRunBgEffects();
                log.debug(
                    'background effects supported:',
                    backgroundEffectsSupported
                );
                if (backgroundEffectsSupported) {
                    try {
                        videoBackgroundSettings = JSON.parse(
                            readFromLocalStorage('videoBackgroundSettings')
                        );
                    } catch (err) {
                        videoBackgroundSettings = null;
                        log.debug(err);
                    }
                    try {
                        localImage = JSON.parse(
                            readFromLocalStorage('localBgImage')
                        );
                    } catch (err) {
                        log.debug(err);
                    }
                    if (
                        !(
                            videoBackgroundSettings &&
                            Object.values(BACKGROUND_EFFECTS).includes(
                                videoBackgroundSettings.backgroundEffect
                            )
                        )
                    ) {
                        videoBackgroundSettings = {
                            backgroundEffect: BACKGROUND_EFFECTS.NONE,
                        };
                    } else if (
                        videoBackgroundSettings.backgroundEffect ===
                        BACKGROUND_EFFECTS.IMAGE
                    ) {
                        const defaultBackgroundImage =
                            (store.getState().auth.publicServiceInfo &&
                                store.getState().auth.publicServiceInfo
                                    .defaultBackgroundImage) ||
                            null;
                        if (
                            videoBackgroundSettings.imageSrc !==
                            defaultBackgroundImage
                        ) {
                            // default background image changed compared to last time
                            videoBackgroundSettings.backgroundEffect =
                                BACKGROUND_EFFECTS.NONE;
                        }
                    }
                    backgroundEffect =
                        videoBackgroundSettings.backgroundEffect ===
                        BACKGROUND_EFFECTS.IMAGE
                            ? videoBackgroundSettings.imageSrc
                            : videoBackgroundSettings.backgroundEffect;
                    if (
                        videoBackgroundSettings.backgroundEffect ===
                        BACKGROUND_EFFECTS.LOCAL_IMAGE
                    ) {
                        if (
                            localImage &&
                            localImage.data &&
                            store.getState().auth.publicServiceInfo &&
                            !store.getState().auth.publicServiceInfo
                                .disableLocalBgImage
                        ) {
                            backgroundEffect = localImage.data;
                        } else {
                            backgroundEffect = BACKGROUND_EFFECTS.NONE;
                        }
                    }
                    log.debug('initial background effect:', backgroundEffect);
                }

                let communicationMode =
                    readFromLocalStorage('communicationMode');
                if (
                    !Object.values(COMMUNICATION_MODES).includes(
                        communicationMode
                    )
                ) {
                    communicationMode = Config.defaultCommunicationMode;
                }

                let notMirrorOwn = false;
                try {
                    notMirrorOwn = JSON.parse(
                        readFromLocalStorage('notMirrorOwn')
                    );
                } catch (e) {
                    log.debug(e);
                }

                // Safari 15 Hotfix - Video not sent with simulcast active
                const browserInfo = WebRtcLayer.getBrowserInfo();
                log.debug(browserInfo);
                let useSimulcast = action.useSimulcast;
                if (
                    action.useSimulcast &&
                    (browserInfo.browserName === 'Safari' ||
                        browserInfo.browserName === 'Mobile Safari') &&
                    isFinite(browserInfo.browserVersion) &&
                    Number(browserInfo.browserVersion) >= 15.0 &&
                    Number(browserInfo.browserVersion) < 15.6
                ) {
                    log.debug('turning off simulcast');
                    useSimulcast = false;
                }

                const urlSearchParams = new URLSearchParams(
                    window.location.search
                );
                const disableWebsocketForLS =
                    urlSearchParams.get('disableWebsocketForLS') === 'true';

                const options = {
                    mode: action.webRtcMode,
                    camSettings: deviceSettings,
                    communicationMode: communicationMode,
                    maxAudioBitrate: action.webRtcMaxAudioBitrate,
                    maxVideoBitrate: action.webRtcMaxVideoBitrate,
                    useSimulcast: useSimulcast,
                    tflitePath: new URL(
                        process.env.PUBLIC_URL,
                        window.location.origin
                    ).toString(),
                    bgEffect: backgroundEffect
                        ? backgroundEffect
                        : BACKGROUND_EFFECTS.NONE,

                    onError: (error) => {
                        log.error(error);
                        store.dispatch(
                            showMessage({
                                contentId: webRtcErrorCodeToErrorId(
                                    error.errorCode
                                ),
                                type: 'error',
                                errorNo: error.errorCode,
                            })
                        );
                    },
                    onNotification: (notification) => {
                        log.debug(notification);
                        switch (notification.number) {
                            case 11: {
                                store.dispatch(webRtcStopScreenShareSuccess());
                                break;
                            }
                            case 12: {
                                store.dispatch(webRtcReconnecting());
                                break;
                            }
                            case 13: {
                                store.dispatch(webRtcReconnectingSuccess());
                                break;
                            }
                            case 14: {
                                store.dispatch(webRtcReconnectingFailure());
                                break;
                            }
                            default:
                                break;
                        }
                    },
                    onUserJoined: (videoView) =>
                        store.dispatch(webRtcUserJoined(videoView)),
                    onUserLeft: (userLeftMsg) =>
                        store.dispatch(
                            webRtcUserLeft(userLeftMsg.userId, userLeftMsg.type)
                        ),
                    onStatsReceived: (msg) => {
                        // ignore screenshare stats
                        if (msg.type === 'remote' || msg.type === 'local') {
                            statsMap.set(msg.userId, msg.stats);
                        }
                    },
                    onAudioLevel: (msg) =>
                        audioLevelMap.set(msg.userId, msg.audioLevel),
                    onSpeakerDetected: (userId) =>
                        store.dispatch(webRtcSpeakerChanged(userId)),
                    enableLogging: logging,
                    enableWebRtcLogging: webRtcLogging,
                    disableWebsocketForLS,
                };
                log.debug('init webrtc-layer', options);
                webRtcLayer = new WebRtcLayer(options);
                webRtcLayer
                    .init()
                    .then((initResponse) => {
                        log.debug(initResponse);
                        store.dispatch(
                            webRtcInitSuccess(
                                initResponse,
                                communicationMode,
                                backgroundEffectsSupported,
                                videoBackgroundSettings
                                    ? videoBackgroundSettings.backgroundEffect
                                    : BACKGROUND_EFFECTS.NONE,
                                notMirrorOwn,
                                localImage ? localImage.name : null
                            )
                        );
                    })
                    .catch((error) => {
                        log.error(error);
                        store.dispatch(webRtcInitFailure(error));
                    });
                return next(action);
            }
            case WEBRTC_START_LOCAL_MEDIA: {
                webRtcLayer
                    .startLocalMedia()
                    .then((response) => {
                        log.debug(response);
                        const backgroundEffect = convertAndStoreBgEffect(
                            store.getState(),
                            response.currentSettings.bgEffect
                        );
                        store.dispatch(
                            webRtcStartLocalMediaSuccess(
                                response.communicationMode,
                                response.currentSettings,
                                backgroundEffect
                            )
                        );
                    })
                    .catch((err) => {
                        log.error(err);
                        store.dispatch(webRtcStartLocalMediaFailure(err));
                    });
                return next(action);
            }
            case WEBRTC_STOP_LOCAL_MEDIA: {
                webRtcLayer.stopLocalMedia();
                return next(action);
            }
            case WEBRTC_JOIN: {
                webRtcLayer
                    .join(action.joinOptions, action.meetingInfo)
                    .then((joinResponse) => {
                        log.debug(joinResponse);
                        const ownVideoView = {
                            userId: joinResponse.userId,
                            view: joinResponse.view,
                        };
                        const backgroundEffect = convertAndStoreBgEffect(
                            store.getState(),
                            joinResponse.currentSettings.bgEffect
                        );
                        store.dispatch(
                            webRtcJoinSuccess(
                                ownVideoView,
                                joinResponse.communicationMode,
                                joinResponse.currentSettings,
                                backgroundEffect
                            )
                        );
                    })
                    .catch((err) => {
                        log.error(err);
                        store.dispatch(webRtcJoinFailure(err));
                    });
                return next(action);
            }
            case WEBRTC_LEAVE: {
                webRtcLayer.leave();
                return next(action);
            }
            case SETTINGS_SHOW: {
                webRtcLayer
                    .startSettingsMode({
                        onAudioLevel: (audioLevel) => {
                            audioLevelMap.set('preview', audioLevel);
                        },
                    })
                    .then((startSettingsResponse) => {
                        log.debug(startSettingsResponse);
                        storeToLocalStorage(
                            'communicationMode',
                            startSettingsResponse.communicationMode
                        );
                        const backgroundEffect = convertAndStoreBgEffect(
                            store.getState(),
                            startSettingsResponse.currentSettings.bgEffect
                        );
                        store.dispatch(
                            webRtcSettingsStartSuccess(
                                startSettingsResponse.currentSettings,
                                startSettingsResponse.view,
                                startSettingsResponse.micDeviceList,
                                startSettingsResponse.camDeviceList,
                                startSettingsResponse.communicationMode,
                                backgroundEffect
                            )
                        );
                    })
                    .catch((err) => {
                        log.error(err);
                        store.dispatch(
                            settingsRecoveryStart(
                                store.getState().deviceSettings
                                    .communicationMode,
                                err.errorCode
                            )
                        );
                    });
                return next(action);
            }
            case SETTINGS_HIDE: {
                webRtcLayer.stopSettingsMode();
                return next(action);
            }
            case WEBRTC_CHANGE_CAM: {
                webRtcLayer
                    .setDevice('video', action.cam)
                    .then((newSettings) => {
                        log.debug(newSettings);
                        storeToLocalStorage(
                            'deviceSettings',
                            JSON.stringify(newSettings)
                        );
                        store.dispatch(webRtcChangeCamSuccess(newSettings));
                    })
                    .catch((err) => {
                        log.error(err);
                        // if cam in use, ls switches to black stream -> recovery mode
                        if (err.errorCode === 172) {
                            store.dispatch(
                                settingsRecoveryStart(
                                    COMMUNICATION_MODES.BOTH,
                                    err.errorCode
                                )
                            );
                        } else {
                            store.dispatch(
                                webRtcSettingsError(
                                    webRtcErrorCodeToErrorId(err.errorCode)
                                )
                            );
                        }
                    });
                return next(action);
            }
            case WEBRTC_CHANGE_MIC: {
                webRtcLayer
                    .setDevice('audio', action.mic)
                    .then((newSettings) => {
                        log.debug(newSettings);
                        storeToLocalStorage(
                            'deviceSettings',
                            JSON.stringify(newSettings)
                        );
                        store.dispatch(webRtcChangeMicSuccess(newSettings));
                    })
                    .catch((err) => {
                        log.error(err);
                        store.dispatch(
                            webRtcSettingsError(
                                webRtcErrorCodeToErrorId(err.errorCode)
                            )
                        );
                    });
                return next(action);
            }
            case WEBRTC_CHANGE_RES: {
                webRtcLayer
                    .setResolution(action.res)
                    .then((newSettings) => {
                        log.debug(newSettings);
                        storeToLocalStorage(
                            'deviceSettings',
                            JSON.stringify(newSettings)
                        );
                        store.dispatch(webRtcChangeResSuccess(newSettings));
                    })
                    .catch((err) => {
                        log.error(err);
                        store.dispatch(
                            webRtcSettingsError(
                                webRtcErrorCodeToErrorId(err.errorCode)
                            )
                        );
                    });
                return next(action);
            }
            case WEBRTC_CHANGE_FPS: {
                webRtcLayer
                    .setFrameRate(action.fps)
                    .then((newSettings) => {
                        log.debug(newSettings);
                        storeToLocalStorage(
                            'deviceSettings',
                            JSON.stringify(newSettings)
                        );
                        store.dispatch(webRtcChangeFpsSuccess(newSettings));
                    })
                    .catch((err) => {
                        log.error(err);
                        store.dispatch(
                            webRtcSettingsError(
                                webRtcErrorCodeToErrorId(err.errorCode)
                            )
                        );
                    });
                return next(action);
            }
            case WEBRTC_CHANGE_HQ_AUDIO: {
                webRtcLayer
                    .setHqAudio(action.hqAudio)
                    .then((newSettings) => {
                        log.debug(newSettings);
                        storeToLocalStorage(
                            'deviceSettings',
                            JSON.stringify(newSettings)
                        );
                        store.dispatch(webRtcChangeHqAudioSuccess(newSettings));
                    })
                    .catch((err) => {
                        log.error(err);
                        store.dispatch(
                            webRtcSettingsError(
                                webRtcErrorCodeToErrorId(err.errorCode)
                            )
                        );
                    });
                return next(action);
            }
            case WEBRTC_CHANGE_COMMUNICATION_MODE: {
                webRtcLayer
                    .setCommunicationMode(action.communicationMode)
                    .then((changeComModeResponse) => {
                        log.debug(changeComModeResponse);
                        storeToLocalStorage(
                            'communicationMode',
                            changeComModeResponse.communicationMode
                        );
                        storeToLocalStorage(
                            'deviceSettings',
                            JSON.stringify(
                                changeComModeResponse.currentSettings
                            )
                        );
                        const backgroundEffect = convertAndStoreBgEffect(
                            store.getState(),
                            changeComModeResponse.currentSettings.bgEffect
                        );
                        store.dispatch(
                            webRtcChangeCommunicationModeSuccess(
                                changeComModeResponse.currentSettings,
                                changeComModeResponse.view,
                                changeComModeResponse.micDeviceList,
                                changeComModeResponse.camDeviceList,
                                changeComModeResponse.communicationMode,
                                backgroundEffect
                            )
                        );
                    })
                    .catch((err) => {
                        log.error(err);
                        store.dispatch(
                            settingsRecoveryStart(
                                action.communicationMode,
                                err.errorCode
                            )
                        );
                    });
                return next(action);
            }
            case WEBRTC_REFRESH_DEVICE_LISTS: {
                webRtcLayer
                    .getDeviceLists(action.communicationMode)
                    .then((response) => {
                        log.debug(response);
                        if (
                            response.audio.length === 0 &&
                            action.communicationMode ===
                                COMMUNICATION_MODES.AUDIO
                        ) {
                            if (store.getState().deviceSettings.recoveryMode) {
                                store.dispatch(settingsRecoveryChangeMic(null));
                                store.dispatch(
                                    webRtcRefreshDeviceListsSuccess(
                                        response.audio,
                                        response.video,
                                        webRtcErrorCodeToErrorId(11)
                                    )
                                );
                            } else {
                                store.dispatch(
                                    settingsRecoveryStart(
                                        action.communicationMode,
                                        11
                                    )
                                );
                            }
                        } else if (
                            (response.audio.length === 0 ||
                                response.video.length === 0) &&
                            action.communicationMode ===
                                COMMUNICATION_MODES.BOTH
                        ) {
                            if (store.getState().deviceSettings.recoveryMode) {
                                if (response.audio.length === 0) {
                                    store.dispatch(
                                        settingsRecoveryChangeMic(null)
                                    );
                                }
                                if (response.video.length === 0) {
                                    store.dispatch(
                                        settingsRecoveryChangeCam(null)
                                    );
                                }
                                store.dispatch(
                                    webRtcRefreshDeviceListsSuccess(
                                        response.audio,
                                        response.video,
                                        webRtcErrorCodeToErrorId(11)
                                    )
                                );
                            } else {
                                store.dispatch(
                                    settingsRecoveryStart(
                                        action.communicationMode,
                                        11
                                    )
                                );
                            }
                        } else {
                            store.dispatch(
                                webRtcRefreshDeviceListsSuccess(
                                    response.audio,
                                    response.video
                                )
                            );
                        }
                    })
                    .catch((err) => {
                        log.error(err);
                        store.dispatch(
                            settingsRecoveryStart(
                                action.communicationMode,
                                err.errorCode
                            )
                        );
                    });
                return next(action);
            }
            case SETTINGS_RECOVERY_START: {
                const { communicationMode, errorCode } = action;
                webRtcLayer
                    .getDeviceLists(communicationMode)
                    .then((response) => {
                        log.debug(response);
                        store.dispatch(
                            settingsRecoveryStartSuccess(
                                communicationMode,
                                response.audio,
                                response.video,
                                webRtcErrorCodeToErrorId(errorCode)
                            )
                        );
                    })
                    .catch((err) => {
                        log.error(err);
                        store.dispatch(
                            settingsRecoveryStartSuccess(
                                COMMUNICATION_MODES.NONE,
                                [],
                                [],
                                webRtcErrorCodeToErrorId(err.errorCode)
                            )
                        );
                    });
                return next(action);
            }
            case SETTINGS_RECOVERY_CHANGE_COMMUNICATION_MODE: {
                const { communicationMode } = action;
                webRtcLayer
                    .getDeviceLists(communicationMode)
                    .then((response) => {
                        log.debug(response);
                        store.dispatch(
                            settingsRecoveryChangeCommunicationModeSuccess(
                                communicationMode,
                                response.audio,
                                response.video
                            )
                        );
                    })
                    .catch((err) => {
                        log.error(err);
                        store.dispatch(
                            settingsRecoveryChangeCommunicationModeFailure(
                                webRtcErrorCodeToErrorId(err.errorCode)
                            )
                        );
                    });
                return next(action);
            }
            case WEBRTC_APPLY_RECOVERY_SETTINGS: {
                webRtcLayer
                    .applySettings({
                        communicationMode:
                            store.getState().deviceSettings
                                .recoveryCommunicationMode,
                        camSettings:
                            store.getState().deviceSettings.recoverySettings,
                    })
                    .then((applySettingsResponse) => {
                        log.debug(applySettingsResponse);
                        storeToLocalStorage(
                            'communicationMode',
                            applySettingsResponse.communicationMode
                        );
                        storeToLocalStorage(
                            'deviceSettings',
                            JSON.stringify(
                                applySettingsResponse.currentSettings
                            )
                        );
                        const backgroundEffect = convertAndStoreBgEffect(
                            store.getState(),
                            applySettingsResponse.currentSettings.bgEffect
                        );
                        store.dispatch(
                            webRtcApplyRecoverySettingsSuccess(
                                applySettingsResponse.currentSettings,
                                applySettingsResponse.view,
                                applySettingsResponse.micDeviceList,
                                applySettingsResponse.camDeviceList,
                                applySettingsResponse.communicationMode,
                                backgroundEffect
                            )
                        );
                    })
                    .catch((err) => {
                        log.error(err);
                        store.dispatch(
                            webRtcSettingsError(
                                webRtcErrorCodeToErrorId(err.errorCode)
                            )
                        );
                    });
                return next(action);
            }
            case WEBRTC_MUTE_AUDIO: {
                webRtcLayer.muteAudio(action.shouldMute);
                return next(action);
            }
            case WEBRTC_MUTE_VIDEO: {
                webRtcLayer.muteVideo(action.shouldMute);
                return next(action);
            }
            case WEBRTC_START_SCREENSHARE: {
                webRtcLayer
                    .startScreenshare()
                    .then((screenShareView) =>
                        store.dispatch(
                            webRtcStartScreenShareSuccess(screenShareView)
                        )
                    )
                    .catch((err) => log.error(err));
                return next(action);
            }
            case WEBRTC_STOP_SCREENSHARE: {
                webRtcLayer
                    .stopScreenshare()
                    .then(() => store.dispatch(webRtcStopScreenShareSuccess()))
                    .catch((err) => log.error(err));
                return next(action);
            }
            case WEBRTC_SET_BACKGROUND_EFFECT: {
                let setBackgroundEffect;
                switch (action.backgroundEffect) {
                    case BACKGROUND_EFFECTS.BLUR:
                        setBackgroundEffect = webRtcLayer.setBgEffect(
                            BACKGROUND_EFFECTS.BLUR
                        );
                        break;

                    case BACKGROUND_EFFECTS.STRONG_BLUR:
                        setBackgroundEffect = webRtcLayer.setBgEffect(
                            BACKGROUND_EFFECTS.STRONG_BLUR
                        );
                        break;

                    case BACKGROUND_EFFECTS.IMAGE:
                        setBackgroundEffect = webRtcLayer.setBgEffect(
                            action.imageSrc
                        );
                        break;

                    case BACKGROUND_EFFECTS.LOCAL_IMAGE:
                        let localImage;
                        try {
                            localImage = JSON.parse(
                                readFromLocalStorage('localBgImage')
                            );
                        } catch (err) {
                            log.debug(err);
                        }
                        if (localImage && localImage.data) {
                            setBackgroundEffect = webRtcLayer.setBgEffect(
                                localImage.data
                            );
                        } else {
                            setBackgroundEffect = webRtcLayer.setBgEffect(
                                BACKGROUND_EFFECTS.NONE
                            );
                        }
                        break;
                    default:
                        setBackgroundEffect = webRtcLayer.setBgEffect(
                            BACKGROUND_EFFECTS.NONE
                        );
                }

                setBackgroundEffect
                    .then((res) => {
                        const backgroundEffect = convertAndStoreBgEffect(
                            store.getState(),
                            res.bgEffect
                        );
                        store.dispatch(
                            webRtcSetBackgroundEffectSuccess(backgroundEffect)
                        );
                    })
                    .catch((err) => {
                        log.debug('set background effect - error:', err);
                        webRtcLayer.setBgEffect(BACKGROUND_EFFECTS.NONE);
                    });

                return next(action);
            }
            case SETTINGS_NEW_LOCAL_BG_IMAGE: {
                storeToLocalStorage(
                    'localBgImage',
                    JSON.stringify({
                        name: action.name,
                        data: action.data,
                    })
                );
                return next(action);
            }
            case WEBRTC_CLOSE: {
                if (webRtcLayer) {
                    webRtcLayer = null;
                }
                return next(action);
            }
            case WEBRTC_START_RECORDING: {
                if (
                    webRtcLayer &&
                    (store.getState().auth.publicServiceInfo.webRtcMode ===
                        WEBRTC_MODES.LIVESWITCH_P2P ||
                        store.getState().auth.publicServiceInfo.webRtcMode ===
                            WEBRTC_MODES.ADIA_P2P)
                ) {
                    webRtcLayer
                        .startRecording()
                        .then((response) => {
                            store.dispatch(webRtcStartRecordingSuccess());
                            webRtcLayer.updateMeetingInfo(
                                store.getState().meetings.meetingInfo
                            );
                            log.debug(response);
                        })
                        .catch((err) => {
                            store.dispatch(webRtcStartRecordingFailure(err));
                            log.error(err);
                        });
                }
                return next(action);
            }
            case WEBRTC_STOP_RECORDING: {
                if (
                    webRtcLayer &&
                    (store.getState().auth.publicServiceInfo.webRtcMode ===
                        WEBRTC_MODES.LIVESWITCH_P2P ||
                        store.getState().auth.publicServiceInfo.webRtcMode ===
                            WEBRTC_MODES.ADIA_P2P)
                ) {
                    try {
                        webRtcLayer.stopRecording();
                    } catch (err) {
                        log.error(err);
                    }
                }
                return next(action);
            }
            case REQUEST_RECORDINGS: {
                if (webRtcLayer) {
                    webRtcLayer
                        .getRecordingList()
                        .then((recordings) => {
                            store.dispatch(
                                requestRecordingsSuccess(recordings)
                            );
                            log.debug(recordings);
                        })
                        .catch((err) => {
                            store.dispatch(
                                requestRecordingsFailure(
                                    'webRtcErrorGetRecordings'
                                )
                            );
                            log.error(err);
                        });
                }
                return next(action);
            }
            case DOWNLOAD_RECORDING: {
                if (webRtcLayer) {
                    webRtcLayer.downloadRecording(action.meetingId);
                }
                return next(action);
            }
            case DELETE_RECORDING: {
                if (webRtcLayer) {
                    webRtcLayer.deleteRecording(action.meetingId);
                }
                return next(action);
            }
            case ALC_MEETING_INFO_UPDATE: {
                // update webRtcLayer with meetingInfo if recording in p2p mode
                if (
                    webRtcLayer &&
                    (store.getState().auth.publicServiceInfo.webRtcMode ===
                        WEBRTC_MODES.LIVESWITCH_P2P ||
                        store.getState().auth.publicServiceInfo.webRtcMode ===
                            WEBRTC_MODES.ADIA_P2P) &&
                    store.getState().meetings.clientInfo.isRecording
                ) {
                    webRtcLayer.updateMeetingInfo(action.meetingInfo);
                }
                return next(action);
            }
            case SETTINGS_SET_NOT_MIRROR_OWN: {
                storeToLocalStorage('notMirrorOwn', action.notMirrorOwn);
                return next(action);
            }
            default:
                return next(action);
        }
    };
};
