import React from 'react';
import { connect } from 'react-redux';

import { getTranslatedString } from './translations';

function TranslatedString({ language, id, replacements }) {
    return (
        <React.Fragment>
            {getTranslatedString(language, id, replacements)}
        </React.Fragment>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        language: state.base.i18n.language,
        id: ownProps.id,
    };
};

export default connect(mapStateToProps, undefined)(TranslatedString);
