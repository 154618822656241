import React from 'react';
import { connect } from 'react-redux';

import MeetingsManagerExternal from './MeetingsManagerExternal';
import MeetingsManagerStandard from './MeetingsManagerStandard';
import { MEETING_TYPES, KELDANO_APPLICATIONS } from '../../constants/constants';

export function MeetingsManager(props) {
    if (props.applicationsUrl) {
        let managerUrl;
        switch (props.type) {
            case MEETING_TYPES.MEETING: {
                managerUrl =
                    props.applicationsUrl +
                    '/' +
                    KELDANO_APPLICATIONS.MEETING_MANAGER;
                break;
            }
            case MEETING_TYPES.WEBINAR: {
                managerUrl =
                    props.applicationsUrl +
                    '/' +
                    KELDANO_APPLICATIONS.WEBINAR_MANAGER;
                break;
            }
            case MEETING_TYPES.PHONE_CONSULTING: {
                managerUrl =
                    props.applicationsUrl +
                    '/' +
                    KELDANO_APPLICATIONS.PHONE_CONSULTING_MANAGER;
                break;
            }
            case MEETING_TYPES.PODIUM: {
                managerUrl =
                    props.applicationsUrl +
                    '/' +
                    KELDANO_APPLICATIONS.PODIUM_MANAGER;
                break;
            }
            case MEETING_TYPES.CALL_MANAGER: {
                managerUrl =
                    props.applicationsUrl +
                    '/' +
                    KELDANO_APPLICATIONS.CALL_MANAGER;
                break;
            }
            default: {
                managerUrl =
                    props.applicationsUrl +
                    '/' +
                    KELDANO_APPLICATIONS.MEETING_MANAGER;
            }
        }
        return <MeetingsManagerExternal managerUrl={managerUrl} />;
    } else {
        return <MeetingsManagerStandard type={props.type} />;
    }
}

const mapStateToProps = (state) => {
    let applicationsUrl = null;
    if (
        state.auth.publicServiceInfo &&
        state.auth.publicServiceInfo.meetingsSettings
    ) {
        applicationsUrl =
            state.auth.publicServiceInfo.meetingsSettings.applicationsUrl;
    }

    return {
        applicationsUrl,
    };
};

export default connect(mapStateToProps, undefined)(MeetingsManager);
