import msgFx from '../../../assets/audio/msg.mp3';
import ringingFx from '../../../assets/audio/ringring.mp3';

let msgPlayer = null;
let ringingPlayer = null;

const adiaLiveFx = {
    playMsg: () => {
        if (!msgPlayer) {
            msgPlayer = new Audio(msgFx);
            msgPlayer.volume = 0.2;
            msgPlayer.addEventListener('canplay', () => {
                msgPlayer.play();
            });
        } else {
            msgPlayer.currentTime = 0;
            msgPlayer.play();
        }
    },
    startRinging: () => {
        if (!ringingPlayer) {
            ringingPlayer = new Audio(ringingFx);
            ringingPlayer.volume = 0.8;
            ringingPlayer.loop = true;
            ringingPlayer.addEventListener('canplay', () => {
                ringingPlayer.play();
            });
        } else {
            ringingPlayer.currentTime = 0;
            ringingPlayer.play();
        }
    },
    stopRinging: () => {
        if (ringingPlayer) {
            ringingPlayer.pause();
        }
    },
};

export default adiaLiveFx;
