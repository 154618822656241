import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { docManagerIFrameLoad, docManagerIFrameNewRef } from './actions';
import { Config } from '../../config/Config';

export class DocumentsManager extends React.Component {
    constructor(props) {
        super(props);
        this.iFrameRef = React.createRef();
    }

    componentDidMount() {
        this.props.docManagerIFrameNewRef(this.iFrameRef);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    componentWillUnmount() {
        this.props.docManagerIFrameNewRef(null);
    }

    render() {
        if (this.props.applicationsUrl) {
            const managerUrl =
                this.props.applicationsUrl + '/' + this.props.application;

            return (
                <iframe
                    className={classNames('meetings-manager-iframe', {
                        'no-breadcrumb': !Config.showBreadcrumb,
                    })}
                    title="Documents Manager"
                    src={managerUrl}
                    allowFullScreen
                    ref={this.iFrameRef}
                    onLoad={this.props.docManagerIFrameLoad}
                />
            );
        }
    }
}

const mapStateToProps = (state) => {
    let applicationsUrl = null;
    if (
        state.auth.publicServiceInfo &&
        state.auth.publicServiceInfo.meetingsSettings
    ) {
        applicationsUrl =
            state.auth.publicServiceInfo.meetingsSettings.applicationsUrl;
    }

    return {
        applicationsUrl,
    };
};

const mapDispatchToProps = {
    docManagerIFrameLoad,
    docManagerIFrameNewRef,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsManager);
