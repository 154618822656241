import React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../constants/routes';
import { routeWithServiceId } from '../base/util/helpers';
import { connect } from 'react-redux';

export function AppBreadcrumb(props) {
    return (
        <div className="layout-breadcrumb">
            <ul className="breadcrumb">
                <Link
                    to={routeWithServiceId(routes.DASHBOARD, props.serviceId)}
                >
                    <li>
                        <button className="p-link">
                            <i className="material-icons">home</i>
                        </button>
                    </li>
                </Link>
                <li>{props.pathname}</li>
            </ul>
            {/*<CallManagerStatus />*/}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        serviceId: state.auth.service,
    };
};

export default connect(mapStateToProps, undefined)(AppBreadcrumb);
