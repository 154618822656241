import { take, put, all, select } from 'redux-saga/effects';
import { LOGIN_SUCCESS, RE_LOGIN_SUCCESS } from './actionTypes';
import {
    ALC_INITIALIZED,
    ALC_CLIENT_INFO_UPDATE,
    ALC_RECONNECTING,
    ALC_SESSION_RESTORED,
    WEBRTC_INIT_SUCCESS,
    WEBRTC_INIT_FAILURE,
} from '../meetings/actionTypes';
import {
    alcInit,
    alcAdviserAuth,
    webRtcInit,
    webRtcLeave,
} from '../meetings/actions';
import authHelper from '../base/util/authHelper';
import { push } from 'connected-react-router';
import {
    routeWithServiceId,
    webRtcErrorCodeToErrorId,
} from '../base/util/helpers';
import Routes from '../../constants/routes';
import { showMessage } from '../base/messages/actions';
import { initializeFailure, logout } from './actions';
import { getMaintenanceWindows } from '../dashboard/actions';
import { EXTERNAL_CONFIG_LOADED } from '../base/common/actionTypes';
import { Config } from '../../config/Config';

const getPublicServiceInfo = (state) => state.auth.publicServiceInfo;

function* initialize() {
    if (Config.externalConfig) {
        yield take(EXTERNAL_CONFIG_LOADED);
    }
    while (true) {
        const { serviceId } = yield take([LOGIN_SUCCESS, RE_LOGIN_SUCCESS]);
        const publicServiceInfo = yield select(getPublicServiceInfo);
        yield put(
            webRtcInit(
                publicServiceInfo.webRtcMode,
                publicServiceInfo.webRtcMaxAudioBitrate,
                publicServiceInfo.webRtcMaxVideoBitrate,
                publicServiceInfo.useSimulcast
            )
        );
        const initAction = yield take([
            WEBRTC_INIT_SUCCESS,
            WEBRTC_INIT_FAILURE,
        ]);
        if (initAction.type === WEBRTC_INIT_FAILURE) {
            if (initAction.error.errorCode === 51) {
                yield put(
                    initializeFailure(
                        webRtcErrorCodeToErrorId(initAction.error.errorCode),
                        initAction.error.errorCode,
                        initAction.error.errorData
                    )
                );
            } else {
                yield put(
                    initializeFailure(
                        webRtcErrorCodeToErrorId(initAction.error.errorCode),
                        initAction.error.errorCode
                    )
                );
            }
        } else {
            yield put(alcInit(serviceId));
            // TODO: handle alc initialize error
            yield take(ALC_INITIALIZED);
            yield put(alcAdviserAuth(authHelper.getToken()));
            yield take(ALC_CLIENT_INFO_UPDATE);
            yield put(getMaintenanceWindows());
        }
    }
}

const getServiceId = (state) => state.auth.service;
const getAlcMeetingJoined = (state) => state.meetings.alcMeetingJoined;
const getWebRtcMeetingJoined = (state) => state.meetings.webRtcMeetingJoined;
const getIsStandalone = (state) => state.meetings.clientInfo.standalone;

function* reconnecting() {
    while (true) {
        yield take(ALC_RECONNECTING);
        const alcMeetingJoined = yield select(getAlcMeetingJoined);
        const isStandalone = yield select(getIsStandalone);
        const action = yield take([ALC_SESSION_RESTORED, ALC_INITIALIZED]);
        if (action.type === ALC_INITIALIZED) {
            if (isStandalone) {
                yield put(logout());
            } else {
                yield put(alcAdviserAuth(authHelper.getToken()));
                const webRtcMeetingJoined = yield select(
                    getWebRtcMeetingJoined
                );
                if (webRtcMeetingJoined) {
                    yield put(webRtcLeave());
                }
                if (alcMeetingJoined) {
                    const serviceId = yield select(getServiceId);
                    yield put(
                        push(routeWithServiceId(Routes.DASHBOARD, serviceId))
                    );
                    yield put(
                        showMessage({
                            contentId: 'alcErrorMeetingConnectionLost',
                            type: 'warn',
                        })
                    );
                }
            }
        }
    }
}

export function* authSagas() {
    yield all([initialize(), reconnecting()]);
}
