import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu } from 'primereact/menu';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser,
    faChalkboard,
    faChalkboardTeacher,
} from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as SideBarIcon } from '../../assets/icons/icon_sidebar.svg';

import { Config } from '../../config/Config';

import {
    hideSharedApplications,
    hideMenu,
    hideSidePanelMenu,
    hideInfoPanel,
    leaveMeeting,
    showSharedApplications,
    showMenu,
    showSidePanelMenu,
    showInfoPanel,
    showParticipantActions,
    showCompleteMeetingDialog,
    showLeaveMeetingDialog,
    startRecording,
    stopRecording,
    webRtcStartScreenShare,
    webRtcStopScreenShare,
    fullscreenToggle,
    startBroadcasting,
    stopBroadcasting,
    startCapturingClient,
    stopCapturingClient,
    alcUpdateUserMode,
    alcCompleteMeeting,
} from './actions';
import {
    hideSettings,
    showSettings,
    webRtcMuteAudio,
    webRtcMuteVideo,
} from '../deviceSettings/actions';
import MeetingInfoPanel from './MeetingInfoPanel';
import TranslatedString from '../base/i18n/TranslatedString';
import {
    COMMUNICATION_MODES,
    MEETING_TYPES,
    RESPONSIVE_MODES,
    SCREEN_SHARING_MODES,
    TOOLTIP_TOUCH_DELAY,
    WEBRTC_MODES,
} from '../../constants/constants';
import { fullscreenEnabled } from '../base/util/helpers';
import Routes from '../../constants/routes';
import { SelectButton } from 'primereact/selectbutton';
import SidePanelTabBar from './SidePanelTabBar';
import MeetingToolbarButton from './MeetingToolbarButton';
import MeetingTime from './MeetingTime';

export class MeetingToolbar extends Component {
    constructor(props) {
        super(props);

        this.menuBtnRef = React.createRef();
        this.toolBarRef = React.createRef();

        this.handleLanguageSelectChange =
            this.handleLanguageSelectChange.bind(this);
        this.handleSAButtonClick = this.handleSAButtonClick.bind(this);
        this.handleActivateCamera = this.handleActivateCamera.bind(this);
        this.handleActivateMic = this.handleActivateMic.bind(this);
        this.handleLeaveMeeting = this.handleLeaveMeeting.bind(this);
        this.handleScreenShareModeChange =
            this.handleScreenShareModeChange.bind(this);
    }

    handleLanguageSelectChange(e) {
        this.props.changeLanguage(e.target.value);
    }

    handleSAButtonClick() {
        if (this.props.saIFrameReady) {
            if (this.props.sharedApplicationsShown) {
                if (!this.props.currentApp) {
                    this.props.hideSharedApplications();
                }
            } else {
                this.props.showSharedApplications();
            }
        }
    }

    handleActivateCamera() {
        if (
            this.props.communicationMode === COMMUNICATION_MODES.AUDIO ||
            this.props.communicationMode === COMMUNICATION_MODES.NONE
        ) {
            this.props.showSettings();
        } else {
            this.props.webRtcMuteVideo(false);
        }
    }

    handleActivateMic() {
        if (this.props.communicationMode === COMMUNICATION_MODES.NONE) {
            this.props.showSettings();
        } else {
            this.props.webRtcMuteAudio(false);
        }
    }

    handleLeaveMeeting() {
        if (
            this.props.completeMeetingDialogOnLeave &&
            this.props.canCompleteMeeting &&
            this.props.meetingType !== MEETING_TYPES.CALL_MANAGER
        ) {
            this.props.showLeaveMeetingDialog();
        } else {
            if (this.props.meetingType === MEETING_TYPES.WEBINAR) {
                this.props.leaveMeeting(Routes.WEBINARS);
            } else if (
                this.props.meetingType === MEETING_TYPES.PHONE_CONSULTING
            ) {
                this.props.leaveMeeting(Routes.PHONE_CONSULTATION);
            } else if (this.props.meetingType === MEETING_TYPES.PODIUM) {
                this.props.leaveMeeting(Routes.PODIUMS);
            } else if (this.props.meetingType === MEETING_TYPES.CALL_MANAGER) {
                // leave meeting if another adviser is present, otherwise complete meeting
                if (
                    this.props.meetingParticipants &&
                    this.props.meetingParticipants.find((el) => {
                        return (
                            el.isAdviser && el.id !== this.props.clientInfo.id
                        );
                    })
                ) {
                    this.props.leaveMeeting(Routes.CALL_MANAGER);
                } else {
                    this.props.alcCompleteMeeting();
                }
            } else {
                this.props.leaveMeeting(Routes.MEETINGS);
            }
        }
    }

    handleScreenShareModeChange(e) {
        if (e.value) {
            this.props.alcUpdateUserMode({
                screenSharing: e.value,
            });
        }
    }

    render() {
        /*const responsiveMenuActive =
            this.props.responsiveMode < RESPONSIVE_MODES.MEDIUM;*/
        const responsiveMenuActive = true;

        const micInactive =
            this.props.communicationMode === COMMUNICATION_MODES.NONE;

        //const mobileLayout = this.props.responsiveMode < RESPONSIVE_MODES.FULL;
        //const mobileLayout = false; // todo: is only quick fix for Mobile-View

        let audioMuteButton = null;
        if (!this.props.audioVideoDisabled) {
            if (this.props.audioMuted || micInactive) {
                audioMuteButton = (
                    <MeetingToolbarButton
                        label={
                            micInactive ? (
                                <TranslatedString id="activateMic" />
                            ) : (
                                <TranslatedString id="unmuteMic" />
                            )
                        }
                        icon="icon-mic-disabled"
                        toggled={false}
                        onToggle={() => this.handleActivateMic()}
                    />
                );
            } else {
                audioMuteButton = (
                    <MeetingToolbarButton
                        label={<TranslatedString id="muteMic" />}
                        icon="icon-microphone"
                        toggled={false}
                        onToggle={() => this.props.webRtcMuteAudio(true)}
                    />
                );
            }
        }

        const leaveMeetingButton = (
            <div aria-label="Leave the call" className="toolbox-button">
                <div className="toolbox-icon">
                    <Tippy
                        content={
                            <TranslatedString
                                id={
                                    this.props.meetingType ===
                                        MEETING_TYPES.WEBINAR ||
                                    this.props.meetingType ===
                                        MEETING_TYPES.PODIUM
                                        ? 'leaveWebinar'
                                        : 'leaveMeeting'
                                }
                            />
                        }
                        arrow
                        touch={['hold', TOOLTIP_TOUCH_DELAY]}
                    >
                        <i
                            className="icon-hangup"
                            onClick={this.handleLeaveMeeting}
                        />
                    </Tippy>
                </div>
            </div>
        );

        const cameraInactive =
            this.props.communicationMode === COMMUNICATION_MODES.AUDIO ||
            this.props.communicationMode === COMMUNICATION_MODES.NONE;

        let videoMuteButton = null;
        if (!this.props.audioVideoDisabled) {
            if (this.props.videoMuted || cameraInactive) {
                videoMuteButton = (
                    <MeetingToolbarButton
                        label={
                            cameraInactive ? (
                                <TranslatedString id="activateCam" />
                            ) : (
                                <TranslatedString id="startCam" />
                            )
                        }
                        icon="icon-camera-disabled"
                        toggled={false}
                        onToggle={() => this.handleActivateCamera()}
                    />
                );
            } else {
                videoMuteButton = (
                    <MeetingToolbarButton
                        label={<TranslatedString id="stopCam" />}
                        icon="icon-camera"
                        toggled={false}
                        onToggle={() => this.props.webRtcMuteVideo(true)}
                    />
                );
            }
        }

        let menuItems = [];

        let recordingMenuItem;
        if (this.props.recordingEnabled) {
            if (this.props.canStopRecording) {
                recordingMenuItem = {
                    label: <TranslatedString id="stopRecording" />,
                    icon: 'pi pi-fw pi-md-fiber-manual-record',
                    className: 'recording-on',
                    disabled: this.props.stopRecordingDisabled,
                    command: () => {
                        this.props.stopRecording();
                        this.props.hideMenu();
                    },
                };
            } else {
                recordingMenuItem = {
                    label: <TranslatedString id="startRecording" />,
                    icon: 'pi pi-fw pi-md-fiber-manual-record',
                    command: () => {
                        this.props.startRecording();
                        this.props.hideMenu();
                    },
                };
            }

            menuItems.push(recordingMenuItem);
        }

        menuItems.push({
            label: <TranslatedString id="participantActions" />,
            icon: 'pi pi-fw pi-md-mic-off',
            command: () => {
                this.props.showParticipantActions();
                this.props.hideMenu();
            },
        });

        let capturingClientMenuItem, broadcastingMenuItem;
        if (this.props.meetingType === MEETING_TYPES.PODIUM) {
            menuItems.push({ separator: true });

            if (this.props.isCapturing) {
                capturingClientMenuItem = {
                    label: <TranslatedString id="stopCapturingClient" />,
                    icon: 'pi pi-fw pi-md-fiber-manual-record',
                    className: 'recording-on',
                    command: () => {
                        this.props.stopCapturingClient();
                        this.props.hideMenu();
                    },
                    disabled: this.props.isOnline,
                };
            } else {
                capturingClientMenuItem = {
                    label: <TranslatedString id="startCapturingClient" />,
                    icon: 'pi pi-fw pi-md-fiber-manual-record',
                    command: () => {
                        this.props.startCapturingClient();
                        this.props.hideMenu();
                    },
                };
            }
            menuItems.push(capturingClientMenuItem);

            if (this.props.isOnline) {
                broadcastingMenuItem = {
                    label: <TranslatedString id="stopBroadcasting" />,
                    icon: 'pi pi-fw pi-md-fiber-manual-record',
                    className: 'stream-online',
                    command: () => {
                        this.props.stopBroadcasting();
                        this.props.hideMenu();
                    },
                };
            } else {
                broadcastingMenuItem = {
                    label: <TranslatedString id="startBroadcasting" />,
                    icon: 'pi pi-fw pi-md-fiber-manual-record',
                    className: 'stream-offline',
                    command: () => {
                        this.props.startBroadcasting();
                        this.props.hideMenu();
                    },
                    disabled: !this.props.isCapturing,
                };
            }
            menuItems.push(broadcastingMenuItem);
        }

        if (responsiveMenuActive) {
            menuItems.push({ separator: true });

            if (fullscreenEnabled) {
                if (this.props.fullscreenActive) {
                    menuItems.push({
                        label: <TranslatedString id="stopFullscreen" />,
                        icon: 'pi pi-fw pi-md-fullscreen-exit',
                        command: () => {
                            this.props.fullscreenToggle();
                            this.props.hideMenu();
                        },
                    });
                } else {
                    menuItems.push({
                        label: <TranslatedString id="startFullscreen" />,
                        icon: 'pi pi-fw pi-md-fullscreen',
                        command: () => {
                            this.props.fullscreenToggle();
                            this.props.hideMenu();
                        },
                    });
                }
            }
            menuItems.push({
                label: <TranslatedString id="meetingInfo" />,
                icon: 'pi pi-fw pi-md-info-outline',
                command: this.props.infoPanelVisible
                    ? this.props.hideInfoPanel
                    : this.props.showInfoPanel,
            });
            if (this.props.applicationsUrl) {
                menuItems.push({
                    label: <TranslatedString id="sharedApplications" />,
                    icon: 'pi pi-fw pi-md-view-quilt',
                    command: () => {
                        this.handleSAButtonClick();
                        this.props.hideMenu();
                    },
                    disabled:
                        !this.props.saIFrameReady || this.props.currentApp,
                });
            }
            if (!this.props.audioVideoDisabled) {
                menuItems.push({
                    label: <TranslatedString id="deviceSettings" />,
                    icon: 'pi pi-fw pi-md-settings',
                    command: () => {
                        this.props.showSettings();
                        this.props.hideMenu();
                    },
                });
            }
        }

        menuItems = menuItems.concat([
            {
                separator: true,
            },
            {
                label: <TranslatedString id="completeMeeting" />,
                icon: 'pi pi-fw pi-md-check-circle',
                command: () => {
                    this.props.showCompleteMeetingDialog();
                    this.props.hideMenu();
                },
                disabled: !this.props.canCompleteMeeting,
            },
        ]);

        const screenShareButton = this.props.screenShareEnabled ? (
            <div aria-label="Toggle screenshare" className="toolbox-button">
                <div className="toolbox-icon">
                    {this.props.ownScreenShareView ? (
                        <Tippy
                            content={<TranslatedString id="stopScreenShare" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className="icon-share-desktop toggled "
                                onClick={this.props.webRtcStopScreenShare}
                            />
                        </Tippy>
                    ) : (
                        <Tippy
                            content={<TranslatedString id="startScreenShare" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className="icon-share-desktop"
                                onClick={this.props.webRtcStartScreenShare}
                            />
                        </Tippy>
                    )}
                </div>
            </div>
        ) : null;
        const screenShareModeSelect =
            this.props.ownScreenShareView && !Config.combiModeDisabled ? (
                <div
                    aria-label="Select screenshare mode"
                    className="toolbox-select-button"
                >
                    <SelectButton
                        value={this.props.screenSharingMode}
                        // Label value set as workaround for PrimeReact bug
                        options={[
                            {
                                icon: 'fa-user',
                                value: SCREEN_SHARING_MODES.VIDEO,
                                label: '1',
                            },
                            {
                                icon: 'fa-chalkboard-teacher',
                                value: SCREEN_SHARING_MODES.COMBI,
                                label: '2',
                            },
                            {
                                icon: 'fa-chalkboard',
                                value: SCREEN_SHARING_MODES.SCREEN,
                                label: '3',
                            },
                        ]}
                        itemTemplate={(option) => {
                            let icon;
                            switch (option.icon) {
                                case 'fa-user':
                                    icon = faUser;
                                    break;
                                case 'fa-chalkboard-teacher':
                                    icon = faChalkboardTeacher;
                                    break;
                                case 'fa-chalkboard':
                                default:
                                    icon = faChalkboard;
                                    break;
                            }
                            return <FontAwesomeIcon icon={icon} />;
                        }}
                        onChange={this.handleScreenShareModeChange}
                    />
                </div>
            ) : null;

        const menuButton = (
            <div
                aria-label="More"
                className="toolbox-button"
                ref={this.menuBtnRef}
            >
                <div className="toolbox-icon">
                    {this.props.menuVisible ? (
                        <i
                            className="icon-thumb-menu toggled"
                            onClick={this.props.hideMenu}
                        />
                    ) : (
                        <Tippy
                            content={<TranslatedString id="menu" />}
                            arrow
                            touch={['hold', TOOLTIP_TOUCH_DELAY]}
                        >
                            <i
                                className="icon-thumb-menu"
                                onClick={this.props.showMenu}
                            />
                        </Tippy>
                    )}
                </div>
            </div>
        );

        //const sidePanelBarActive = !mobileLayout && this.props.activeSidePanelApplicationId === null;
        const sidePanelBarActive = true; // todo: is only quick fix for Mobile-View

        let menuRightPos = 0;
        if (
            this.props.menuVisible &&
            this.menuBtnRef.current &&
            this.toolBarRef.current
        ) {
            const menuButtonRightPos =
                this.menuBtnRef.current.getBoundingClientRect().right;
            const containerWidth =
                this.toolBarRef.current.getBoundingClientRect().width;

            menuRightPos = Math.min(
                containerWidth - menuButtonRightPos - 100,
                containerWidth - 205
            );
        }

        const showTitle = this.props.responsiveMode >= RESPONSIVE_MODES.BIG;
        const putMainControlsLeft =
            this.props.responsiveMode < RESPONSIVE_MODES.BIG;
        const sidePanelButtonInMenu =
            this.props.responsiveMode < RESPONSIVE_MODES.MEDIUM;

        return (
            <div
                className={classNames('meeting-toolbar', {
                    visible: this.props.controlsVisible,
                    'side-panel-bar-active': sidePanelBarActive,
                })}
                ref={this.toolBarRef}
            >
                <div className="toolbox-background" />
                <div
                    className="toolbox-menu-container"
                    style={{ right: menuRightPos }}
                >
                    {this.props.menuVisible && <Menu model={menuItems} />}
                </div>
                {this.props.sidePanelMenuVisible && (
                    <div
                        className="toolbox-menu-container"
                        style={{ right: 10 }}
                    >
                        <SidePanelTabBar asMenu={true} />
                    </div>
                )}
                {this.props.infoPanelVisible && <MeetingInfoPanel />}
                <div className="toolbox-content">
                    {!putMainControlsLeft && (
                        <React.Fragment>
                            <div className="button-group-left">
                                <div className="title-time">
                                    {this.props.meetingInfo.startTime && (
                                        <MeetingTime
                                            startTime={
                                                this.props.meetingInfo.startTime
                                            }
                                        />
                                    )}
                                    {this.props.meetingInfo.startTime &&
                                        showTitle && (
                                            <React.Fragment>
                                                &nbsp;|&nbsp;
                                            </React.Fragment>
                                        )}
                                    {showTitle && (
                                        <span className="toolbar-title">
                                            {this.props.meetingInfo.title}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="button-group-center">
                                {audioMuteButton}
                                {videoMuteButton}
                                {screenShareButton}
                                {screenShareModeSelect}
                                {menuButton}
                                {leaveMeetingButton}
                            </div>
                            <div className="button-group-right">
                                {sidePanelBarActive && <SidePanelTabBar />}
                            </div>
                        </React.Fragment>
                    )}

                    {putMainControlsLeft && (
                        <React.Fragment>
                            <div className="button-group-half">
                                {audioMuteButton}
                                {videoMuteButton}
                                {screenShareButton}
                                {screenShareModeSelect}
                                {menuButton}
                                {leaveMeetingButton}
                            </div>
                            <div className="button-group-half-right">
                                {sidePanelButtonInMenu && (
                                    <MeetingToolbarButton
                                        toggled={
                                            this.props.sidePanelMenuVisible
                                        }
                                        label={<TranslatedString id="menu" />}
                                        icon={<SideBarIcon />}
                                        appId="sidePanelMenu"
                                        onToggle={(appId) => {
                                            if (appId) {
                                                this.props.showSidePanelMenu();
                                            } else {
                                                this.props.hideSidePanelMenu();
                                            }
                                        }}
                                        badge={
                                            this.props.showSidePanelBadge
                                                ? 'N'
                                                : ''
                                        }
                                    />
                                )}
                                {!sidePanelButtonInMenu && <SidePanelTabBar />}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // TODO: workaround for VZ requirement, remove when handled properly
    let screenShareEnabled = state.meetings.webRtcFlags.hasDisplayMedia;
    if (
        state.auth.service === 'VZ-CH-01' ||
        state.auth.service === 'DEV-VZ-CH-01'
    ) {
        screenShareEnabled = false;
        if (
            (state.deviceSettings.currentSettings.cam &&
                (state.deviceSettings.currentSettings.cam.includes(
                    'AMX Acendo Vibe'
                ) ||
                    state.deviceSettings.currentSettings.cam.includes(
                        'Jabra PanaCast 50'
                    ))) ||
            (state.deviceSettings.currentSettings.mic &&
                (state.deviceSettings.currentSettings.mic.includes(
                    'AMX Acendo Vibe'
                ) ||
                    state.deviceSettings.currentSettings.mic.includes(
                        'Jabra PanaCast 50'
                    )))
        ) {
            screenShareEnabled = true;
        }
    }

    const meetingType =
        state.meetings.meetingInfo && state.meetings.meetingInfo.type;

    const canCompleteMeeting = state.meetings.meetingInfo
        ? state.meetings.meetingInfo.hasSessions ||
          state.meetings.meetingInfo.state === 'ongoing'
        : false;

    const recordingEnabled =
        meetingType !== MEETING_TYPES.PODIUM &&
        (state.auth.publicServiceInfo.webRtcMode ===
            WEBRTC_MODES.LIVESWITCH_SFU ||
            ((state.auth.publicServiceInfo.webRtcMode ===
                WEBRTC_MODES.LIVESWITCH_P2P ||
                state.auth.publicServiceInfo.webRtcMode ===
                    WEBRTC_MODES.ADIA_P2P) &&
                state.meetings.webRtcFlags.canRecordMedia)) &&
        (!state.auth.publicServiceInfo.meetingsSettings.hasOwnProperty(
            'enableRecording'
        ) ||
            state.auth.publicServiceInfo.meetingsSettings.enableRecording);

    let canStopRecording = false;
    if (
        state.auth.publicServiceInfo.webRtcMode ===
            WEBRTC_MODES.LIVESWITCH_P2P ||
        state.auth.publicServiceInfo.webRtcMode === WEBRTC_MODES.ADIA_P2P
    ) {
        if (
            state.meetings.clientInfo &&
            state.meetings.clientInfo.isRecording
        ) {
            canStopRecording = true;
        }
    } else {
        canStopRecording = state.meetings.meetingInfo
            ? state.meetings.meetingInfo.isRecording
            : false;
    }

    let stopRecordingDisabled = false;
    if (
        state.auth.publicServiceInfo.meetingsSettings &&
        state.auth.publicServiceInfo.meetingsSettings.enableAutoRecording
    ) {
        if (
            state.meetings.meetingParticipants.find(
                (participant) =>
                    participant.isAdviser &&
                    participant.info.roles.includes('autorecord')
            )
        ) {
            stopRecordingDisabled = true;
        }
    }

    const showSidePanelBadge =
        state.meetings.participantRequestPending ||
        state.meetings.streamChatMessagePending ||
        state.meetings.streamSurveyPending ||
        state.meetings.streamDocumentPending ||
        state.meetings.chatMessagePending ||
        state.meetings.documentPending;

    return {
        language: state.base.i18n.language,
        currentApp: state.meetings.meetingInfo
            ? state.meetings.meetingInfo.currentApp
            : null,
        meetingType,
        meetingInfo: state.meetings.meetingInfo,
        meetingParticipants: state.meetings.meetingParticipants,
        clientInfo: state.meetings.clientInfo,
        audioMuted: state.deviceSettings.currentSettings.audioMuted,
        videoMuted: state.deviceSettings.currentSettings.videoMuted,
        ownScreenShareView: state.meetings.ownScreenShareView,
        sharedApplicationsShown: state.meetings.sharedApplicationsShown,
        settingsPanelShown: state.deviceSettings.settingsPanelShown,
        controlsVisible: state.meetings.controlsVisible,
        menuVisible: state.meetings.menuVisible,
        sidePanelMenuVisible: state.meetings.sidePanelMenuVisible,
        infoPanelVisible: state.meetings.infoPanelVisible,
        communicationMode: state.deviceSettings.communicationMode,
        applicationsUrl:
            state.auth.publicServiceInfo.meetingsSettings.applicationsUrl,
        screenShareEnabled,
        responsiveMode: state.base.common.responsiveMode,
        fullscreenActive: state.meetings.fullscreenActive,
        sidePanelIFrameReady: state.meetings.sidePanelIFrameReady,
        saIFrameReady: state.meetings.saIFrameReady,
        activeSidePanelApplicationId:
            state.meetings.activeSidePanelApplicationId,
        recordingEnabled,
        canCompleteMeeting,
        canStopRecording,
        stopRecordingDisabled,
        isCapturing:
            state.meetings.meetingInfo &&
            state.meetings.meetingInfo.isCapturing,
        isOnline:
            state.meetings.meetingInfo && state.meetings.meetingInfo.isOnline,
        audioVideoDisabled:
            Config.phoneConsultingAudioVideoDisabled &&
            state.meetings.meetingInfo &&
            state.meetings.meetingInfo.type === MEETING_TYPES.PHONE_CONSULTING,
        screenSharingMode:
            state.meetings.clientInfo &&
            state.meetings.clientInfo.userMode &&
            state.meetings.clientInfo.userMode.screenSharing,
        showSidePanelBadge,
        completeMeetingDialogOnLeave:
            state.auth.publicServiceInfo.meetingsSettings
                .completeMeetingDialogOnLeave,
    };
};

const mapDispatchToProps = {
    hideSettings,
    showSettings,
    hideSharedApplications,
    hideMenu,
    hideInfoPanel,
    leaveMeeting,
    showSharedApplications,
    showMenu,
    showInfoPanel,
    showParticipantActions,
    showCompleteMeetingDialog,
    showLeaveMeetingDialog,
    startRecording,
    stopRecording,
    webRtcMuteAudio,
    webRtcMuteVideo,
    webRtcStartScreenShare,
    webRtcStopScreenShare,
    fullscreenToggle,
    startCapturingClient,
    stopCapturingClient,
    startBroadcasting,
    stopBroadcasting,
    alcUpdateUserMode,
    alcCompleteMeeting,
    showSidePanelMenu,
    hideSidePanelMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingToolbar);
