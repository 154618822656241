import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import dateFormat from 'dateformat';
import { FullCalendar } from 'primereact/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import deLocale from '@fullcalendar/core/locales/de';
import enGbLocale from '@fullcalendar/core/locales/en-gb';
import frLocale from '@fullcalendar/core/locales/fr';
import itLocale from '@fullcalendar/core/locales/it';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';

import { requestOwnMeetings } from './actions';
import { goToMeeting, openEditMeeting } from '../meetingsManager/actions';
import { getTranslatedString } from '../base/i18n/translations';
import { ContextMenu } from 'primereact/contextmenu';
import { adiaLiveSettings } from '../base/util/adiaLiveSettings';
import { MEETING_TYPES, RESPONSIVE_MODES } from '../../constants/constants';

class Dashboard extends Component {
    componentDidMount() {
        this.props.requestOwnMeetings();
    }

    render() {
        let editMenuItem = null;
        const adviserData = [];
        if (this.props.adviserList) {
            Object.entries(this.props.adviserList).forEach(([key, value]) => {
                if (!value.standalone) {
                    adviserData.push({ name: value.info.name });
                }
            });
        }
        if (adviserData.length === 0) {
            adviserData.push({ name: '' });
        }

        let calendarData = [];
        this.props.ownMeetingsData.forEach((meeting) => {
            let meetingObj = {
                id: meeting.id,
                title: meeting.title,
                start: meeting.dateObj,
                end: meeting.endDateObj,
                state: meeting.state,
                meetingType: meeting.type,
                authenticatedParticipantLink:
                    meeting.authenticatedParticipantLink,
            };
            if (meeting.state === 'ongoing') {
                meetingObj.textColor = '#ffffff';
                meetingObj.backgroundColor = 'darkgreen';
                meetingObj.borderColor = 'orange';
            }
            calendarData.push(meetingObj);
        });

        let fullcalendarOptions = {
            defaultDate: dateFormat(new Date(), 'yyyy-mm-dd'),
            titleRangeSeparator: ' \u2013 ',
            plugins: [
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
            ],
            defaultView: 'listWeek',
            header: {
                left: 'prev,next today',
                center:
                    this.props.responsiveMode <= RESPONSIVE_MODES.SMALL
                        ? ''
                        : 'title',
                right:
                    this.props.responsiveMode <= RESPONSIVE_MODES.SMALL
                        ? ''
                        : 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
            },
            footer: {
                center:
                    this.props.responsiveMode <= RESPONSIVE_MODES.SMALL
                        ? 'dayGridMonth,timeGridWeek,listWeek'
                        : '',
            },
            editable: false,
            locales: [deLocale, enGbLocale, frLocale, itLocale],
            locale:
                this.props.language === 'en' ? 'en-gb' : this.props.language,
            eventClick: (info) => {
                this.currentId = info.event.id;
                this.currentType = info.event.extendedProps.meetingType;
                this.currentAuthenticatedParticipantLink =
                    info.event.extendedProps.authenticatedParticipantLink;

                // todo: solve this with proper state handling or React references
                if (editMenuItem) {
                    editMenuItem.disabled =
                        !!info.event.extendedProps.authenticatedParticipantLink;
                }
                this.cm.show(info.jsEvent);
            },
        };

        const menuItems = [
            {
                label: getTranslatedString(this.props.language, 'join'),
                icon: 'pi-md-video-library',
                command: () => {
                    if (this.currentAuthenticatedParticipantLink) {
                        window.open(
                            this.currentAuthenticatedParticipantLink,
                            '_blank'
                        );
                    } else {
                        this.props.goToMeeting(this.currentId);
                    }
                },
            },
        ];

        if (this.props.applicationsUrl) {
            editMenuItem = {
                label: getTranslatedString(this.props.language, 'edit'),
                icon: 'pi-md-edit',
                command: () =>
                    this.props.openEditMeeting(
                        this.currentId,
                        this.currentType
                    ),
            };
            menuItems.push(editMenuItem);
        }

        return (
            <div className="layout-content">
                <ContextMenu model={menuItems} ref={(el) => (this.cm = el)} />
                <div className="p-grid dashboard">
                    <div className="p-col-12 p-xl-9">
                        <Card
                            title={
                                getTranslatedString(
                                    this.props.language,
                                    'ownOpenMeetings'
                                ) +
                                ': ' +
                                calendarData.length
                            }
                        >
                            <FullCalendar
                                events={calendarData}
                                options={fullcalendarOptions}
                            />
                        </Card>
                    </div>
                    {!this.props.hideAdviserList && (
                        <div className="p-col-12 p-lg-6 p-xl-3 p-offset-0">
                            <Card
                                title={
                                    getTranslatedString(
                                        this.props.language,
                                        'adviserOnline'
                                    ) +
                                    ': ' +
                                    adviserData.length
                                }
                            >
                                <DataTable
                                    value={adviserData}
                                    header={null}
                                    rows={15}
                                    paginator={true}
                                    paginatorPosition={'top'}
                                    alwaysShowPaginator={false}
                                >
                                    <Column
                                        field={'name'}
                                        header={null}
                                        headerStyle={{ display: 'none' }}
                                    />
                                </DataTable>
                            </Card>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let ownMeetingsData = [];

    state.dashboard.ownMeetings.forEach((meeting) => {
        if (
            meeting.date &&
            (meeting.state === 'open' || meeting.state === 'ongoing') &&
            meeting.type !== MEETING_TYPES.CALL_MANAGER
        ) {
            const adviserId = state.meetings?.clientInfo?.info._id;
            const isAuthenticatedParticipant = meeting.participants.filter(
                (item) => {
                    return (
                        adviserId === item.adviserId &&
                        item.isAuthenticatedParticipant === true
                    );
                }
            );
            const newMeetingDataElement = {
                id: meeting._id,
                title: meeting.title,
                date: dateFormat(meeting.date, 'dd.mm.yyyy, HH:MM'),
                dateObj: meeting.date,
                state: meeting.state,
                endDateObj: new Date(
                    meeting.date.getTime() +
                        meeting.durationInMinutes * 60 * 1000
                ),
                durationInMinutes: meeting.durationInMinutes,
                type: meeting.type,
                authenticatedParticipantLink:
                    isAuthenticatedParticipant.length > 0
                        ? isAuthenticatedParticipant[0].link
                        : '',
            };
            ownMeetingsData.push(newMeetingDataElement);
        }
    });

    let applicationsUrl = null;
    if (
        state.auth.publicServiceInfo &&
        state.auth.publicServiceInfo.meetingsSettings
    ) {
        applicationsUrl =
            state.auth.publicServiceInfo.meetingsSettings.applicationsUrl;
    }

    let hideAdviserList = true;
    const flags = state.auth.publicServiceInfo
        ? state.auth.publicServiceInfo.flags
        : undefined;
    if (flags) {
        hideAdviserList =
            adiaLiveSettings.flagsToSettings(flags).hideAdviserList;
    }

    return {
        adviserList: state.dashboard.adviserList,
        ownMeetingsData,
        language: state.base.i18n.language,
        applicationsUrl,
        hideAdviserList,
        responsiveMode: state.base.common.responsiveMode,
    };
};

const mapDispatchToProps = {
    requestOwnMeetings,
    goToMeeting,
    openEditMeeting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
