import React from 'react';
import { connect } from 'react-redux';
import { Panel } from 'primereact/panel';

import {
    alcSetSpeakerPermission,
    alcSetHandRaised,
    alcChangeActiveUser,
    alcSetAdmittance,
} from './actions';
import { Button } from 'primereact/button';
import TranslatedString from '../base/i18n/TranslatedString';
import {
    ADMITTANCE_STATE,
    MEETING_TYPES,
    SPEAKER_PERMISSION_STATE,
} from '../../constants/constants';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';

export function ParticipantList(props) {
    const participants =
        props.meetingType === MEETING_TYPES.PODIUM
            ? props.podiumParticipants
            : props.meetingParticipants;
    let participantItems = [];
    let raisedHandItems = [];
    let speakerItems = [];
    let currentSpeakerIds = [];
    let speakerCount = 0;

    if (
        props.meetingType === MEETING_TYPES.PODIUM ||
        props.meetingType === MEETING_TYPES.WEBINAR
    ) {
        props.meetingParticipants.forEach((participant) => {
            if (
                participant.speakerPermissionState ===
                SPEAKER_PERMISSION_STATE.GRANTED
            ) {
                speakerItems.push(
                    <div className="p-col-12" key={participant.id}>
                        <Button
                            className="p-button-secondary"
                            icon="pi pi-md-cancel"
                            onClick={() =>
                                props.alcSetSpeakerPermission(
                                    participant.id,
                                    false
                                )
                            }
                        />
                        {'  '}
                        {participant.info.name}
                    </div>
                );
                currentSpeakerIds.push(participant.id);
                speakerCount++;
            }
        });
        participants.forEach((participant) => {
            // all participants
            if (
                !participant.isAdviser &&
                !participant.standalone &&
                !participant.recorder
            ) {
                participantItems.push(
                    <div className="p-col-12" key={participant.id}>
                        {participant.info.name}
                    </div>
                );
            }
            if (
                participant.speakerPermissionState ===
                    SPEAKER_PERMISSION_STATE.REQUESTING &&
                !currentSpeakerIds.includes(participant.id)
            ) {
                raisedHandItems.push(
                    <div className="p-col-12" key={participant.id}>
                        <Button
                            className="p-button-secondary"
                            icon="pi pi-md-check"
                            onClick={() =>
                                props.alcSetSpeakerPermission(
                                    participant.id,
                                    true
                                )
                            }
                            disabled={
                                speakerCount >= props.maxSpeakerParticipants ||
                                props.speakerPermissionLoading
                            }
                        />{' '}
                        <Button
                            className="p-button-secondary"
                            icon="pi pi-md-cancel"
                            onClick={() =>
                                props.alcSetSpeakerPermission(
                                    participant.id,
                                    false
                                )
                            }
                            disabled={props.speakerPermissionLoading}
                        />
                        {'  '}
                        {participant.info.name}
                    </div>
                );
            } else if (
                participant.speakerPermissionState ===
                    SPEAKER_PERMISSION_STATE.GRANTED_PREPARING &&
                !currentSpeakerIds.includes(participant.id)
            ) {
                speakerItems.push(
                    <div className="p-col-12" key={participant.id}>
                        <Button
                            className="p-button-secondary"
                            icon="pi pi-md-cached"
                            onClick={() => {}}
                            disabled
                        />
                        {'  '}
                        {participant.info.name}
                    </div>
                );
                speakerCount++;
            }
        });

        return (
            <div className="participant-tab">
                <ScrollPanel className="participant-tab-scroll-panel">
                    {(speakerItems.length > 0 ||
                        raisedHandItems.length > 0) && (
                        <Panel
                            header={
                                <TranslatedString id={'speakerPermissions'} />
                            }
                            className={'participant-requesting-panel'}
                        >
                            {speakerItems.length > 0 && (
                                <React.Fragment>
                                    <h4>
                                        <TranslatedString
                                            id={'grantedSpeakerPermission'}
                                        />
                                        {'  (' +
                                            speakerCount +
                                            '/' +
                                            props.maxSpeakerParticipants +
                                            ')'}
                                    </h4>
                                    <div className="p-grid">{speakerItems}</div>
                                </React.Fragment>
                            )}
                            {raisedHandItems.length > 0 && (
                                <React.Fragment>
                                    <h4>
                                        <TranslatedString
                                            id={'requestedSpeakerPermission'}
                                        />
                                    </h4>
                                    <div className="p-grid">
                                        {raisedHandItems}
                                    </div>
                                </React.Fragment>
                            )}
                        </Panel>
                    )}
                    {participantItems.length > 0 && (
                        <Panel
                            header={
                                <span>
                                    <TranslatedString id={'participants'} />
                                    {' (' + participantItems.length + ')'}
                                </span>
                            }
                            className={'participant-list-panel'}
                        >
                            <div className="p-grid">{participantItems}</div>
                        </Panel>
                    )}
                </ScrollPanel>
            </div>
        );
    } else {
        let raisedHandParticipants = [];
        participants.forEach((participant) => {
            // all participants
            if (!participant.standalone && !participant.recorder) {
                participantItems.push(
                    <div className="p-col-12" key={participant.id}>
                        {participant.info.name}
                    </div>
                );
            }
            if (participant.handRaised) {
                raisedHandParticipants.push(participant);
            }
        });
        raisedHandParticipants.sort((a, b) =>
            a.handRaisedTimestamp > b.handRaisedTimestamp ? 1 : -1
        );
        raisedHandParticipants.forEach((participant) => {
            raisedHandItems.push(
                <div className="p-col-12" key={participant.id}>
                    <Button
                        className="p-button-secondary"
                        icon="pi pi-md-check"
                        onClick={() => {
                            props.alcChangeActiveUser(participant.id);
                            props.alcSetHandRaised(participant.id, false);
                        }}
                    />{' '}
                    <Button
                        className="p-button-secondary"
                        icon="pi pi-md-cancel"
                        onClick={() =>
                            props.alcSetHandRaised(participant.id, false)
                        }
                    />
                    {'  '}
                    {participant.info.name}
                </div>
            );
        });

        const waitingRoomItems = [];
        if (props.waitingRoom.length > 0) {
            props.waitingRoom.forEach((waitingRoomUser) => {
                if (
                    waitingRoomUser.admittanceState ===
                    ADMITTANCE_STATE.REQUESTED
                ) {
                    waitingRoomItems.push(
                        <div
                            className="p-col-12"
                            key={waitingRoomUser.clientId}
                        >
                            <Button
                                className="p-button-secondary"
                                icon="pi pi-md-check"
                                onClick={() =>
                                    props.alcSetAdmittance(
                                        waitingRoomUser.clientId,
                                        true
                                    )
                                }
                            />{' '}
                            <Button
                                className="p-button-secondary"
                                icon="pi pi-md-cancel"
                                onClick={() =>
                                    props.alcSetAdmittance(
                                        waitingRoomUser.clientId,
                                        false
                                    )
                                }
                            />
                            {'  '}
                            {waitingRoomUser.name}
                        </div>
                    );
                }
            });
        }

        const currentActiveUser = participants.find(
            (el) => el.id === props.currentActiveUser
        );

        return (
            <div className="participant-tab">
                <ScrollPanel className="participant-tab-scroll-panel">
                    {waitingRoomItems.length > 0 && (
                        <Panel
                            header={<TranslatedString id={'waitingRoom'} />}
                            className={'participant-requesting-panel'}
                        >
                            <div className="p-grid">{waitingRoomItems}</div>
                        </Panel>
                    )}
                    {currentActiveUser && (
                        <Panel
                            header={
                                <TranslatedString id={'currentActiveUser'} />
                            }
                            className={'participant-requesting-panel'}
                        >
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <Button
                                        className="p-button-secondary"
                                        icon="pi pi-md-cancel"
                                        onClick={() => {
                                            props.alcChangeActiveUser(null);
                                        }}
                                    />
                                    {'  '}
                                    {currentActiveUser.info.name}
                                </div>
                            </div>
                        </Panel>
                    )}
                    {raisedHandItems.length > 0 && (
                        <Panel
                            header={<TranslatedString id={'handRaised'} />}
                            className={'participant-requesting-panel'}
                        >
                            <div className="p-grid">{raisedHandItems}</div>
                        </Panel>
                    )}
                    {participantItems.length > 0 && (
                        <Panel
                            header={
                                <span>
                                    <TranslatedString id={'participants'} />
                                    {' (' + participantItems.length + ')'}
                                </span>
                            }
                            className={'participant-list-panel'}
                        >
                            <div className="p-grid">{participantItems}</div>
                        </Panel>
                    )}
                </ScrollPanel>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const meetingType =
        state.meetings.meetingInfo && state.meetings.meetingInfo.type;
    const maxSpeakerParticipants =
        meetingType === MEETING_TYPES.PODIUM
            ? state.auth.publicServiceInfo.podiumSettings &&
              state.auth.publicServiceInfo.podiumSettings.maxSpeakerParticipants
            : state.auth.publicServiceInfo.webinarSettings &&
              state.auth.publicServiceInfo.webinarSettings
                  .maxSpeakerParticipants;

    return {
        meetingType,
        meetingParticipants: state.meetings.meetingParticipants,
        podiumParticipants: state.meetings.podiumParticipants,
        waitingRoom: state.meetings.waitingRoom,
        clientInfo: state.meetings.clientInfo,
        maxSpeakerParticipants,
        speakerPermissionLoading: state.meetings.speakerPermissionLoading,
        currentActiveUser: state.meetings.meetingInfo.currentActiveUser,
    };
};

const mapDispatchToProps = {
    alcSetSpeakerPermission,
    alcSetHandRaised,
    alcChangeActiveUser,
    alcSetAdmittance,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantList);
