import * as types from './actionTypes';
import { forceLogout, noServiceFound } from '../../auth/actions';
import { handleError } from '../util/helpers';
import authHelper from '../util/authHelper';

export function showEditAccount() {
    return { type: types.EDIT_ACCOUNT_SHOW };
}

export function hideEditAccount() {
    return { type: types.EDIT_ACCOUNT_HIDE };
}

function editAccountRequest() {
    return { type: types.EDIT_ACCOUNT_REQUEST };
}

function editAccountSuccess(user, message) {
    return { type: types.EDIT_ACCOUNT_SUCCESS, user, message };
}

function editAccountFailure(message) {
    return { type: types.EDIT_ACCOUNT_FAILURE, message };
}

export function editAccount(userData) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        const userId = getState().auth.user._id;
        if (service) {
            dispatch(editAccountRequest());

            const response = await api.updateUser(
                service,
                userId,
                userData,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(
                    editAccountSuccess(response.user, {
                        type: 'success',
                        messageId: 'editAccountSuccess',
                    })
                );
            } else {
                handleError(response.error, {
                    fatal: (error) => dispatch(forceLogout(error)),
                    nonFatal: (error) => {
                        let messageId = 'editAccountFailure';
                        if (error.errorNo === 40950) {
                            messageId = 'userKeycloakConflict';
                        }
                        dispatch(
                            editAccountFailure({
                                type: 'error',
                                messageId,
                            })
                        );
                    },
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

function editPasswordRequest() {
    return { type: types.EDIT_PASSWORD_REQUEST };
}

function editPasswordSuccess(message) {
    return { type: types.EDIT_PASSWORD_SUCCESS, message };
}

function editPasswordFailure(message) {
    return { type: types.EDIT_PASSWORD_FAILURE, message };
}

export function editPassword(passwordData) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        const userId = getState().auth.user._id;
        if (service) {
            dispatch(editPasswordRequest());

            if (
                passwordData.newPassword !==
                passwordData.newPasswordConfirmation
            ) {
                dispatch(
                    editPasswordFailure({
                        type: 'warn',
                        messageId: 'editPasswordDoesNotMatch',
                    })
                );
            } else {
                const response = await api.updatePassword(
                    service,
                    userId,
                    {
                        oldPassword: passwordData.currentPassword,
                        newPassword: passwordData.newPassword,
                    },
                    authHelper.getToken()
                );
                if (!response.error) {
                    dispatch(
                        editPasswordSuccess({
                            type: 'success',
                            messageId: 'editPasswordSuccess',
                        })
                    );
                } else {
                    if (response.error.status === 401) {
                        dispatch(
                            editPasswordFailure({
                                type: 'warn',
                                messageId: 'editPasswordCurrentWrong',
                            })
                        );
                    } else {
                        dispatch(
                            editPasswordFailure({
                                type: 'error',
                                messageId: 'editPasswordFailure',
                            })
                        );
                    }
                }
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

function getNepatecRequest() {
    return { type: types.GET_NEPATEC_REQUEST };
}

function getNepatecSuccess(nepatecCredentials) {
    return { type: types.GET_NEPATEC_SUCCESS, nepatecCredentials };
}

function getNepatecFailure(message) {
    return { type: types.GET_NEPATEC_FAILURE, message };
}

export function getNepatec() {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        const userId = getState().auth.user._id;
        if (service) {
            dispatch(getNepatecRequest());

            const response = await api.getNepatecCredentials(
                service,
                userId,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(getNepatecSuccess(response.nepatecCredentials));
            } else {
                handleError(response.error, {
                    fatal: (error) => dispatch(forceLogout(error)),
                    nonFatal: (error) => {
                        let messageId = 'getNepatecFailure';
                        dispatch(
                            getNepatecFailure({
                                type: 'error',
                                messageId,
                            })
                        );
                    },
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}

function editNepatecRequest() {
    return { type: types.EDIT_NEPATEC_REQUEST };
}

function editNepatecSuccess(nepatecCredentials, message) {
    return { type: types.EDIT_NEPATEC_SUCCESS, nepatecCredentials, message };
}

function editNepatecFailure(message) {
    return { type: types.EDIT_NEPATEC_FAILURE, message };
}

export function editNepatec(data) {
    return async (dispatch, getState, api) => {
        const service = getState().auth.service;
        const userId = getState().auth.user._id;
        if (service) {
            dispatch(editNepatecRequest());

            const response = await api.setNepatecCredentials(
                service,
                userId,
                data,
                authHelper.getToken()
            );
            if (!response.error) {
                dispatch(
                    editNepatecSuccess(response.nepatecCredentials, {
                        type: 'success',
                        messageId: 'editAccountSuccess',
                    })
                );
            } else {
                handleError(response.error, {
                    fatal: (error) => dispatch(forceLogout(error)),
                    nonFatal: (error) => {
                        let messageId = 'editAccountFailure';
                        dispatch(
                            editNepatecFailure({
                                type: 'error',
                                messageId,
                            })
                        );
                    },
                });
            }
        } else {
            dispatch(noServiceFound());
        }
    };
}
