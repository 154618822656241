import * as types from './actionTypes';

export function alcServiceStateUpdate(serviceState) {
    return { type: types.ALC_SERVICE_STATE_UPDATE, serviceState };
}

export function alcQueueListUpdate(queueList) {
    return { type: types.ALC_QUEUE_LIST_UPDATE, queueList };
}

export function alcConnectingClientInfo(connectingClientInfo) {
    return { type: types.ALC_CONNECTING_CLIENT_INFO, connectingClientInfo };
}

export function alcOpenCallsUpdate(openCalls) {
    return { type: types.ALC_OPEN_CALLS_UPDATE, openCalls };
}

export function alcSetReady(clientId) {
    return { type: types.ALC_SET_READY, clientId };
}

export function alcSetBusy() {
    return { type: types.ALC_SET_BUSY };
}

export function alcAcceptCall() {
    return { type: types.ALC_ACCEPT_CALL };
}

export function alcRejectCall() {
    return { type: types.ALC_REJECT_CALL };
}

export function alcInvitationInfo(invitationInfo) {
    return { type: types.ALC_INVITATION_INFO, invitationInfo };
}
