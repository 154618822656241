import React, { Component } from 'react';

import errorImage from '../../../serenity/sass/images/exception/404.svg';
import TranslatedString from '../i18n/TranslatedString';

export default class Error404 extends Component {
    render() {
        return (
            <div className="exception-body error">
                <div className="exception-panel">
                    <div className="exception-code">
                        <img src={errorImage} alt="404 error" />
                    </div>

                    <div className="exception-detail">
                        <div className="exception-icon">
                            <i className="material-icons">close</i>
                        </div>
                        <h1>
                            <TranslatedString id={'pathDoesNotExist'} />
                        </h1>
                    </div>
                </div>
            </div>
        );
    }
}
