import {
    ADVISER_STATE_NAMES,
    AL_APPLICATIONS,
    SA_APPLICATIONS,
} from '../../../../constants/constants';

export const german = {
    // General
    language: 'Sprache',
    de: 'Deutsch',
    en: 'Englisch',
    fr: 'Französich',
    it: 'Italienisch',

    applicationName: 'Unblu Meet - Adviser',

    save: 'Speichern',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    close: 'Schliessen',
    join: 'Teilnehmen',
    edit: 'Bearbeiten',
    refresh: 'Aktualisieren',
    connect: 'Verbinden',
    reject: 'Ablehnen',

    areYouSure: 'Sind Sie sicher?',

    username: 'Benutzername',
    password: 'Passwort',
    serviceId: 'Service-ID',
    captcha: 'Captcha',
    signIn: 'Anmelden',
    signOut: 'Abmelden',

    dashboard: 'Dashboard',
    meetings: 'Meetings',
    phoneConsulting: 'Telefonberatung',
    templates: 'Vorlagen',
    defaultDocuments: 'Standarddokumente',
    defaultAgenda: 'Agendavorlage',
    defaultMinutes: 'Protokollvorlage',
    signatureTemplates: 'Unterschriftsvorlagen',

    reconnectingHeader: 'Verbindung verloren',
    reconnectingMessage:
        'Verbindung zum Server verloren. Verbindung wird wiederhergestellt...',

    pathDoesNotExist: 'Diese Seite existiert nicht!',

    // Account

    account: 'Benutzerkonto',
    nepatecAccount: 'Nepatec Benutzerkonto',
    editAccount: 'Benutzerkonto bearbeiten',
    name: 'Name',
    lastName: 'Nachname',
    firstName: 'Vorname',
    email: 'E-Mail',
    newPassword: 'Neues Passwort',
    newPasswordConfirmation: 'Passwort bestätigen',
    status: 'Status',
    active: 'Aktiv',

    invalidEmail: 'Ungültige E-Mail Adresse',
    invalidPassword: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
    editAccountSuccess: 'Ihr Benutzerkonto wurde aktualisiert.',
    editAccountFailure: 'Ihr Benutzerkonto konnte nicht aktualisiert werden.',
    editPasswordSuccess: 'Ihr Passwort wurde aktualisiert.',
    editPasswordFailure: 'Ihr Password konnte nicht aktualisiert werden.',
    editPasswordCurrentWrong: 'Das aktuelle Passwort ist ungültig.',
    editPasswordDoesNotMatch: 'Die Passwortbestätigung ist nicht identisch.',
    userKeycloakConflict:
        'Ein Benutzer mit diesem Benutzernamen und/oder dieser E-Mail existiert bereits.',
    getNepatecFailure: 'Benutzer konnte nicht abgerufen werden.',

    // Dashboard

    adviserOnline: 'Berater Online',
    ownOpenMeetings: 'Ihre offenen Meetings',

    // Device Settings

    deviceSettings: 'Einstellungen',
    cam: 'Kamera',
    mic: 'Mikrofon',
    resolution: 'Auflösung',
    comModeNone: 'Nur Chat',
    comModeAudio: 'Audio',
    comModeBoth: 'Audio + Video',
    backgroundEffect: 'Videohintergrundeffekt',
    backgroundEffectNone: 'Kein',
    backgroundEffectBlur: 'Weichzeichnen',
    backgroundEffectStrongBlur: 'Weichzeichnen (stark)',
    backgroundEffectImage: 'Bild',
    notMirrorOwn: 'Eigenes Video lokal nicht spiegeln',
    localImage: 'Eigenes Bild',
    uploadImage: 'Bild hochladen',
    advancedSettings: 'Erweiterte Einstellungen',
    hqAudio: 'Studio Audio',
    fps: 'FPS',

    // Meeting

    agenda: 'Agenda',
    showboard: 'Showboard',
    minutes: 'Protokoll',
    documents: 'Dokumente',
    chat: 'Chat',
    surveys: 'Umfragen',
    show: 'Zeigen',
    editModePermissions: 'Edit Berechtigungen',

    startFullscreen: 'Vollbildmodus starten',
    stopFullscreen: 'Vollbildmodus beenden',
    startScreenShare: 'Bildschirm teilen',
    stopScreenShare: 'Bildschirm teilen beenden',
    leaveMeeting: 'Meeting verlassen',
    muteMic: 'Mikrofon stummschalten',
    unmuteMic: 'Mikrofon lautschalten',
    activateMic: 'Mikrofon aktivieren',
    startCam: 'Kamera einschalten',
    stopCam: 'Kamera ausschalten',
    activateCam: 'Kamera aktivieren',
    meetingInfo: 'Meeting Info',
    sharedApplications: 'Shared Applications',
    menu: 'Menu',

    startRecording: 'Aufzeichnung starten',
    stopRecording: 'Aufzeichnung beenden',
    muteParticipant: 'Teilnehmer stummschalten',
    kickParticipant: 'Teilnehmer entfernen',
    msgRemoteMute: 'Sie wurden durch einen Berater stummgeschaltet.',
    msgKick: 'Sie wurden durch einen Berater aus dem Meeting entfernt.',
    msgTransferred: 'Anruf wurde weitergeleitet.',
    msgTransferCallError_input: 'Bitte Thema und Teilnehmer auswählen.',
    msgTransferCallError_server: 'Anruf konnte nicht weitergeleitet werden.',
    msgNewWaitingUser: 'Jemand möchte das Meeting betreten.',

    participantActions: 'Teilnehmer stummschalten / entfernen',
    noParticipants: 'Keine weiteren Teilnehmer im Meeting',
    mute: 'Stummschalten',
    kick: 'Entfernen',
    startCapturingClient: 'Meeting-Capturing starten',
    stopCapturingClient: 'Meeting-Capturing beenden',
    startBroadcasting: 'Sendung starten',
    stopBroadcasting: 'Sendung beenden',
    broadcastOnline: 'SENDUNG LIVE',
    broadcastOffline: 'SENDUNG OFFLINE',
    msgReallyLeaveMeeting: 'Wollen Sie das Meeting wirklich verlassen?',
    completeMeeting: 'Meeting abschliessen',
    completeMeetingConfirmation:
        'Wollen Sie dieses Meeting wirklich abschliessen?',
    msgMeetingCompleted: 'Das Meeting wurde abgeschlossen.',

    share: 'Teilen',
    standaloneApplications: 'Standalone Applications',
    link: 'Link',
    participantLink: 'Teilnehmer-Link',

    currentActiveUser: 'Teilnehmer im Fokus',
    handRaised: 'Hand gehoben',
    waitingRoom: 'Warteraum',

    bitrate: 'Bitrate',
    framerate: 'Framerate',
    packetLoss: 'Packet loss',

    // Meeting Manager Standard

    meetingTableDate: 'Datum',
    meetingTableTitle: 'Titel',
    meetingTableDescription: 'Beschreibung',
    meetingTableParticipants: 'Teilnehmer',

    // Webinars
    webinars: 'Webinare',
    participants: 'Teilnehmer',
    participant: 'Teilnehmer',
    speakerPermissions: 'Sprecherlaubnis',
    grantedSpeakerPermission: 'Sprecherlaubnis erteilt',
    requestedSpeakerPermission: 'Sprecherlaubnis angefragt',
    leaveWebinar: 'Webinar verlassen',

    // Podium
    podiums: 'Podien',

    // Call Manager
    callManager: 'Call Manager',

    // Recordings
    recordings: 'Aufzeichnungen',
    download: 'Herunterladen',
    deleteRecording: 'Aufzeichnung löschen',
    deleteRecordingConfirmation:
        'Wollen Sie diese Aufzeichnung wirklich löschen?',
    unsavedRecordings:
        'Es stehen neue Aufzeichnungen zum Download bereit. Bitte herunterladen und aus der Liste löschen.',
    noRecordings: 'Keine Aufzeichnungen vorhanden',
    recordingsNotice:
        'Wegen Browser-Speicher Limitierungen sollten Sie diese Liste so klein wie möglich halten und Aufzeichnungen nach dem herunterladen aus der Liste löschen.',
    markedForDelete: 'Wird in {{hours}} Stunden gelöscht',
    markedForDelete1Hour: 'Wird in weniger als einer Stunde gelöscht',
    needsDownload: 'Bitte herunterladen',
    confirmRecordingTitle: 'Bestätigung für Aufzeichnung abwarten',
    confirmRecordingMessage:
        'Die Teilnehmer werden gerade gefragt, ob die Aufzeichnung gestartet werden darf. Bitte warten Sie bis die Bestätigung durch die Teilnehmer erfolgt ist.',
    confirmRecordingResultAsk: 'Teilnehmer wird gefragt',
    confirmRecordingResultRejected: 'Abgelehnt',
    confirmRecordingResultConfirmed: 'Bestätigt',
    restartConfirmation: 'Bestätigung erneut anfordern',

    // adHoc
    meeting: 'Meeting',
    phoneConsultation: 'Telefonberatung',
    meetingId: 'Meeting-ID',
    consultationId: 'Telefonberatungs-ID',
    newMeeting: 'Neues Meeting',
    newPhoneConsultation: 'Neue Telefonberatung',

    callManagerQueueTitle: 'Warteliste',
    callManagerClosedCallsTitle: 'Beendete Anrufe',
    callManagerAdvisersTitle: 'Berater',
    callManagerEmptyQueueMessage: 'Warteliste leer',

    topics: 'Themen',
    topic: 'Thema',
    state: 'Status',
    action: 'Aktion',
    select: 'Auswählen',

    allTopicsName: 'Alle Themen',

    incomingCall: 'Anruf Eingang',
    joiningCall: 'Verbindungsaufbau',
    addAdviserBtn: 'Hinzufügen',
    cancelInvitationBtn: 'Einladung abbrechen',
    noAdvisers: 'Aktuell sind keine Berater verfügbar.',
    adviserInvitationMessage:
        '{{adviserName}} möchte Sie zum Meeting "{{meetingTitle}}" hinzufügen.',
    adviserTransferMessage:
        'Dieser Anruf wurde durch "{{adviserName}}" weitergeleitet.',
    invitationComment: 'Mitteilung',
    comment: 'Mitteilung',

    ['appTitle-' + SA_APPLICATIONS.DOCUMENTS]: 'Dokumente',
    ['appTitle-' + SA_APPLICATIONS.CHAT]: 'Chat',
    ['appTitle-' + SA_APPLICATIONS.DOCUMENTS_MANAGER]: 'Dokumente',
    ['appTitle-' + SA_APPLICATIONS.STREAM_CHAT]: 'Chat',
    ['appTitle-' + SA_APPLICATIONS.SURVEY_MANAGER]: 'Umfragen',
    ['appTitle-' + AL_APPLICATIONS.ADIA_PAD]: 'Beratungs-Pad',
    ['appTitle-' + AL_APPLICATIONS.PARTICIPANTS]: 'Teilnehmer',

    callManagerStateTitle: 'Call Manager Status',

    ['callState-' + ADVISER_STATE_NAMES.BUSY]: 'Besetzt',
    ['callState-' + ADVISER_STATE_NAMES.READY]: 'Bereit',
    ['callState-' + ADVISER_STATE_NAMES.CONNECTING]: 'Wird verbunden',
    ['callState-' + ADVISER_STATE_NAMES.CONNECTED]: 'Verbunden',
    ['callState-' + ADVISER_STATE_NAMES.JOINED_MEETING]: 'Verbunden',
    'callState-waiting': 'Wartet',

    ['changeStateBtn-' + ADVISER_STATE_NAMES.BUSY]: 'Freigeben',
    ['changeStateBtn-' + ADVISER_STATE_NAMES.READY]: 'Besetzen',

    openCallsTitle: 'Offene Anrufe',
    openCallsMessage: 'Sie haben offene Anrufe',

    // adiaPad
    adiaPadName: 'Pad',
    adiaPadAddAdviser: 'Berater hinzufügen',
    adiaPadTransferCall: 'Anruf weiterleiten',
    adiaPadShowShowboard: 'Showboard öffnen',
    adiaPadShowAgenda: 'Agenda öffnen',
    adiaPadShowMinutes: 'Protokoll öffnen',
    adiaPadShowCamMicSettings: 'Kamera/Mic einstellen',
    adiaPadMuteParticipant: 'Teilnehmer stummschalten',
    adiaPadStartScreenShare: 'Bildschirm teilen',
    adiaPadStopScreenShare: 'Bildschirm teilen stoppen',
    adiaPadRequestSignature: 'Unterschrift anfordern',
    adiaPadAddParticipant: 'Teilnehmer hinzufügen',
    adiaPadRemoveParticipant: 'Teilnehmer entfernen',
    adiaPadStartRecording: 'Aufzeichnung starten',
    adiaPadStopRecording: 'Aufzeichnung stoppen',

    // Errors

    connectionError: 'Verbindung zum Server ist fehlgeschlagen.',
    serverError: 'Hoppla! Ein Fehler ist aufgetreten.',
    accessDenied: 'Zugriff verweigert!',
    notAuthorized: 'Keine Berechtigung!',
    authInvalidIp: 'Ungültige IP.',
    authInvalidUser: 'Ungültiger Benutzer.',
    authInvalidCaptcha: 'Ungültiges Captcha.',
    authInvalidCredentials: 'Ungültige Logindaten.',
    serviceNotFound: 'Dieser Service existiert nicht.',
    invalidRoleError:
        "Nur User mit der 'Adviser' Rolle können die Adviser Applikation benutzen.",
    autoRecordingError:
        'Die automatische Aufzeichnung konnte nicht gestartet werden. Ohne laufende Aufzeichnung dürfen Sie dieses Meeting nicht betreten.',
    invalidFileType: 'Ungültiger Dateityp',
    fileSizeToBig: 'Die Datei ist zu gross',
    meetingCreateError: 'Meeting konnte nicht erstellt werden.',
    meetingLimitError:
        'Zur Zeit sind keine weiteren Termine möglich (max. Kapazität erreicht). Bitte versuchen Sie es später.',

    alcErrorDefault: 'alc Fehler',
    alcErrorMeetingDoesNotExist:
        'Das Meeting wurde bereits abgeschlossen oder existiert nicht.',
    alcErrorMeetingConnectionLost:
        'Verbindung zum Meeting-Server verloren. Bitte versuchen Sie es erneut.',
    alcErrorMeetingFull:
        'Die maximale Anzahl Teilnehmer im Meeting ist überschritten.',
    alcErrorWebinarAdviserFull:
        'Die maximale Anzahl Moderatoren im Webinar ist überschritten.',
    alcErrorAdviserIdMismatch:
        'Der verwendete Einladungslink wurde nicht für Ihren Benutzer ausgestellt.',
    alcErrorExclusiveNotInvited:
        'Sie wurden nicht für dieses exklusive Meeting eingeladen',

    webRtcErrorDefault: 'WebRTC Fehler',
    webRtcErrorNotFound:
        'Keine Kamera / kein Mikrofon gefunden. Bitte überprüfen Sie, dass Ihre Kamera / Ihr Mikrofon angeschlossen ist und klicken Sie Aktualisieren.',
    webRtcErrorNotAllowed:
        'Bitte erlauben Sie in den Browsereinstellungen den Zugriff auf Ihr Mikrofon / Ihre Kamera. Aktivieren Sie danach Audio / Audio + Video.',
    webRtcErrorDeviceInUse:
        'Ihre Kamera wird von einer anderen Applikation verwendet. Bitte schliessen Sie diese Applikation oder wählen Sie eine andere Kamera.',
    webRtcErrorFailedAllocateSource:
        'Es konnte nicht auf Ihre Kamera zugegriffen werden.',
    webRtcErrorOverconstrained:
        'Ihre gespeicherte Kamera / Ihr gespeichertes Mikrofon konnte nicht gefunden werden. Bitte überprüfen Sie, dass Ihre Kamera / Ihr Mikrofon angeschlossen ist und klicken Sie Aktualisieren oder wählen Sie ein anderes Gerät.',
    webRtcErrorConnectionTimeout:
        'Zeitüberschreitung beim Verbinden zum Meeting Server',
    webRtcErrorRecordingStart: 'Aufzeichnung konnte nicht gestartet werden.',
    webRtcErrorRecordingStream: 'Ein Stream kann nicht aufgezeichnet werden.',
    webRtcErrorGetRecordings:
        'Es konnten keine Aufzeichnungen abgerufen werden.',
    webRctErrorBackgroundEffect:
        'Hintergrund-Effekt konnte nicht aktiviert werden.',

    webRtcBrowserNotSupported:
        'Ihr Browser wird nicht unterstützt. Bitte verwenden Sie eine aktuelle Version von Chrome oder Firefox.',
    webRtcBrowserOutOfDate:
        'Ihre Version von {{browser}} ist veraltet und wird nicht mehr unterstützt. Bitte aktualisieren Sie Ihren Browser.',
    webRtcBrowserNonSafariIos:
        '{{browser}} wird auf iOS leider nicht unterstützt. Bitte verwenden Sie eine aktuelle Version von Safari.',
    webRtcBrowserLegacyEdge:
        'Sie verwenden Edge Legacy. Diese Version von Edge ist veraltet und wird nicht mehr unterstüzt. Wir empfehlen Ihnen den neuen Edge zu verwenden, welchen Sie {{link}} herunterladen können.',
    webRtcBrowserIe:
        'Internet Explorer wird nicht unterstützt. Wir empfehlen Ihnen den neuen Edge zu verwenden, welchen Sie {{link}} herunterladen können.',
};
