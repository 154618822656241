import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import TranslatedString from '../base/i18n/TranslatedString';
import { getTranslatedString } from '../base/i18n/translations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPhone
} from '@fortawesome/free-solid-svg-icons';

import {
    hideAddAdviserDialog,
    alcInviteAdviser, alcCancelAdviserInvitation
} from './actions';
import {InputTextarea} from "primereact/inputtextarea";

const invitationStateToIcon = {
    'pending': <FontAwesomeIcon icon={faPhone} className="state-pending" spin={true} />,
    'accepted': <i title="accepted" className="pi pi-check state-accepted"/>,
    'canceled-timeout': <i title="timeout" className="pi pi-times state-canceled"/>,
    'canceled-toAdviserHangUp': <i title="adviser hangUp" className="pi pi-times state-canceled"/>,
    'canceled-serviceClose': <i title="serviceClose" className="pi pi-times state-canceled"/>,
    'canceled-fromAdviserCancel': <i title="canceled by caller" className="pi pi-times state-canceled"/>
}

function CommentInput({initValue, disabled, valueRef, placeholder}){
    const [value, setValue] = useState(initValue);

    useEffect(()=>{
        valueRef.current = initValue;
    }, [valueRef, initValue]);

    const onChange = useCallback((e)=>{
        valueRef.current = e.target.value;
        setValue(e.target.value);
    }, [setValue, valueRef]);

    if (disabled) {
        return <div className="comment-output">{initValue}</div>;
    }

    return (
        <InputTextarea placeholder={placeholder} rows={1} cols={30} value={value} onChange={onChange} autoResize={true} />
    );
}

function AddAdviserRow({adviser, meetingInvitation}) {
    const dispatch = useDispatch();
    const language = useSelector(state => state.base.i18n.language);
    const inputValueRef = useRef(null);

    const doAdviserInvite = useCallback(()=>{
        dispatch(alcInviteAdviser(adviser.id, inputValueRef.current?inputValueRef.current:''));
    }, [inputValueRef, dispatch, adviser]);

    return (
        <tr>
            <td>
                {meetingInvitation && invitationStateToIcon[meetingInvitation.state]}
            </td>
            <td>{adviser.info.name}</td>
            <td><CommentInput placeholder={getTranslatedString(language,'invitationComment')} valueRef={inputValueRef} initValue={meetingInvitation?meetingInvitation.comment:''} disabled={adviser.state.name !== 'ready'} /></td>
            <td>
                {adviser.state.name === 'ready' && <Button
                    label={getTranslatedString(
                        language,
                        'addAdviserBtn'
                    )}
                    icon="pi pi-md-add-circle-outline"
                    onClick={doAdviserInvite}
                />}
                {meetingInvitation && meetingInvitation.state === 'pending' && <Button
                    label={getTranslatedString(
                        language,
                        'cancelInvitationBtn'
                    )}
                    icon="pi pi-md-cancel"
                    onClick={()=>{dispatch(alcCancelAdviserInvitation(meetingInvitation.id))}}
                />}
            </td>
        </tr>
    )
}

function AddAdviserSelector(){
    const adviserList = useSelector(state => state.dashboard.adviserList);
    const clientId = useSelector(state => state.meetings.clientInfo?.id);
    const currentMeetingInvitations = useSelector(state => state.meetings.currentMeetingInvitations);

    const advisers = useMemo(()=>{

        let advisers = [];

        for(let props in adviserList){
            const adviser = adviserList[props];
            const meetingInvitation = currentMeetingInvitations[adviser.id];
            if ((adviser.id !== clientId && adviser.state.name === 'ready') || meetingInvitation) {
                advisers.push((
                    <AddAdviserRow key={adviser.id} adviser={adviser} meetingInvitation={meetingInvitation} />
                ));
            }
        }

        return advisers;
    }, [adviserList, clientId, currentMeetingInvitations]);

    if (advisers.length === 0){
        return (
            <TranslatedString id="noAdvisers" />
        );
    }

    return (
            <table>
                <tbody>
                {advisers}
                </tbody>
            </table>
        );
}

function AddAdviserDialog(){
    const dispatch = useDispatch();
    const addAdviserDialogVisible = useSelector(state => state.meetings.addAdviserDialogVisible);
    const language = useSelector(state => state.base.i18n.language);

    const handleClose = useCallback(()=>{
        dispatch(hideAddAdviserDialog());
    }, [dispatch]);

    return (
        <Dialog
            className="add-adviser-dialog"
            header={<TranslatedString id="adiaPadAddAdviser" />}
            footer={<div>
                <Button
                    label={getTranslatedString(
                        language,
                        'close'
                    )}
                    className="p-button-secondary"
                    onClick={handleClose}
                />
            </div>}
            visible={addAdviserDialogVisible}
            onHide={handleClose}
            baseZIndex={999999}
            focusOnShow={false}
        >
            <div className="p-grid form-group">
                <div className="p-col-12 adviser-selector-container">
                    {addAdviserDialogVisible && <AddAdviserSelector />}
                </div>
            </div>
        </Dialog>
    );
}

export default AddAdviserDialog;
