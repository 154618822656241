import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TranslatedString from '../base/i18n/TranslatedString';
import { Message } from 'primereact/message';
import { alcSetReady } from './actions';
import { getTranslatedString } from '../base/i18n/translations';

const ROWS = 25;

export class WaitingQueue extends React.Component {
    render() {
        const queueTableEntries = this.props.queueList.map((item, key) => {
            const selectButton =
                item.state.name === 'waiting' ? (
                    <Button
                        label={getTranslatedString(
                            this.props.language,
                            'select'
                        )}
                        icon="pi-md-video-library"
                        onClick={() => this.props.alcSetReady(item.id)}
                    />
                ) : null;

            return {
                name: item.info.name,
                state: getTranslatedString(
                    this.props.language,
                    'callState-' + item.state.name
                ),
                topicId: item.info._topicsNamesFormated,
                action: selectButton,
            };
        });

        if (queueTableEntries.length === 0) {
            return (
                <Message
                    severity="info"
                    text={getTranslatedString(
                        this.props.language,
                        'callManagerEmptyQueueMessage'
                    )}
                />
            );
        }

        return (
            <DataTable
                autoLayout={true}
                responsive={true}
                value={queueTableEntries}
                rows={ROWS}
                paginator={true}
                paginatorPosition={'top'}
                alwaysShowPaginator={false}
            >
                <Column
                    className="column date-col"
                    field="name"
                    header={<TranslatedString id={'name'} />}
                />
                <Column
                    className="column participants-col"
                    field="topicId"
                    header={<TranslatedString id={'topics'} />}
                />
                <Column
                    className="column participants-col"
                    field="state"
                    header={<TranslatedString id={'state'} />}
                />
                <Column
                    className="column join-button-col"
                    field="action"
                    header={<TranslatedString id={'action'} />}
                />
            </DataTable>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queueList: state.callManager.queueList,
        language: state.base.i18n.language,
    };
};

const mapDispatchToProps = {
    alcSetReady,
};

export default connect(mapStateToProps, mapDispatchToProps)(WaitingQueue);
