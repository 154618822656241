import { serviceUrlToAPIUrl } from '../features/base/util/helpers';
import { Config } from '../config/Config';
import { ERRORS } from '../constants/constants';
const getApiUrl = (serviceUrl) => serviceUrlToAPIUrl(serviceUrl);

const al5ApiService = {
    /** LOGIN: user authorisation **/
    serviceLogin: async (loginData) => {
        const url =
            getApiUrl(Config.serviceUrl) +
            '/login/service/' +
            loginData.serviceId;

        let response = {
            user: undefined,
            token: undefined,
            service: undefined,
            error: undefined,
            captcha: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(loginData.credentials),
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.token = responseJson.token;
                    response.user = responseJson.user;
                } else {
                    response.error = responseJson;
                    response.captcha = responseJson.captcha;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /** PUBLIC: public routes (no authentication needed) **/
    getPublicServiceInfo: async (serviceId) => {
        const url =
            getApiUrl(Config.serviceUrl) + '/public/services/' + serviceId;

        let response = {
            publicServiceInfo: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'GET',
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.publicServiceInfo = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    updateServiceToken: async (serviceId, token) => {
        const url =
            getApiUrl(Config.serviceUrl) +
            '/api/v1/token/' +
            serviceId +
            '/update';

        let response = {
            token: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token,
                },
                method: 'GET',
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.token = responseJson.token;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    /** MEETINGS: meeting administration **/
    getMeetings: async (
        serviceId,
        fields,
        sort,
        range,
        token,
        adviserId,
        stateId
    ) => {
        let params = '';

        if (fields && fields.length) {
            params += '?fields=';
            params += fields.join('%2C');
        }
        if (sort && sort.length) {
            params += (params ? '&' : '?') + 'sort=';
            params += sort.join('%2C');
        }
        if (adviserId) {
            params +=
                (params ? '&' : '?') + 'participants.adviserId=' + adviserId;
        }
        if (stateId) {
            params += (params ? '&' : '?') + 'state=' + stateId;
        }

        const url =
            getApiUrl(Config.serviceUrl) +
            '/api/v1/meetings/' +
            serviceId +
            params;
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': token,
        };

        let currentEndIndex = 0;
        let endIndex = Number.MAX_SAFE_INTEGER;

        if (
            range &&
            range.hasOwnProperty('startIndex') &&
            range.hasOwnProperty('endIndex')
        ) {
            headers['Range'] =
                'items=' + range.startIndex + '-' + range.endIndex;
            endIndex = range.endIndex;
        }

        let response = {
            meetings: [],
            error: undefined,
        };

        while (currentEndIndex < endIndex) {
            try {
                const apiResponse = await fetch(url, {
                    headers: headers,
                    method: 'GET',
                });
                const contentRange = apiResponse.headers.get('Content-Range');
                const matches = contentRange
                    ? contentRange.match(/(\d+)-(\d+)\/(\d+|\*)/)
                    : null;
                if (matches) {
                    const fetchedEndIndex = parseInt(matches[2], 10);
                    const fetchedLength =
                        matches[3] === '*' ? null : parseInt(matches[3], 10);
                    currentEndIndex = fetchedEndIndex;
                    if (endIndex > fetchedLength - 1) {
                        endIndex = fetchedLength - 1;
                    }
                    headers['Range'] =
                        'items=' + (fetchedEndIndex + 1) + '-' + endIndex;
                } else {
                    currentEndIndex = endIndex;
                }
                try {
                    const responseJson = await apiResponse.json();
                    if (
                        apiResponse.status === 200 ||
                        apiResponse.status === 206
                    ) {
                        response.meetings =
                            response.meetings.concat(responseJson);
                    } else {
                        response.error = responseJson;
                        break;
                    }
                } catch {
                    response.error = ERRORS.PARSING_ERROR;
                    break;
                }
            } catch {
                response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
                break;
            }
        }
        return response;
    },

    updateUser: async (serviceId, userId, userData, token) => {
        const url =
            getApiUrl(Config.serviceUrl) +
            '/api/v1/users/' +
            serviceId +
            '/' +
            userId;

        let response = {
            user: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token,
                },
                method: 'PUT',
                body: JSON.stringify(userData),
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.user = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    updatePassword: async (serviceId, userId, passwordData, token) => {
        const url =
            getApiUrl(Config.serviceUrl) +
            '/api/v1/users/' +
            serviceId +
            '/' +
            userId +
            '/password';

        let response = {
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token,
                },
                method: 'PUT',
                body: JSON.stringify(passwordData),
            });
            try {
                if (apiResponse.status !== 200) {
                    response.error = await apiResponse.json();
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    createMeeting: async (serviceId, meetingData, token) => {
        const url =
            getApiUrl(Config.serviceUrl) + '/api/v1/meetings/' + serviceId;

        let response = {
            meeting: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token,
                },
                method: 'POST',
                body: JSON.stringify(meetingData),
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.meeting = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    getMeeting: async (serviceId, meetingId, token) => {
        const url =
            getApiUrl(Config.serviceUrl) +
            '/api/v1/meetings/' +
            serviceId +
            '/' +
            meetingId;

        let response = {
            meeting: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token,
                },
                method: 'GET',
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.meeting = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    getNepatecCredentials: async (serviceId, userId, token) => {
        const url =
            getApiUrl(Config.serviceUrl) +
            '/api/v1/users/' +
            serviceId +
            '/' +
            userId +
            '/nepatecCredentials';

        let response = {
            nepatecCredentials: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token,
                },
                method: 'GET',
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.nepatecCredentials = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    setNepatecCredentials: async (serviceId, userId, data, token) => {
        const url =
            getApiUrl(Config.serviceUrl) +
            '/api/v1/users/' +
            serviceId +
            '/' +
            userId +
            '/nepatecCredentials';

        let response = {
            nepatecCredentials: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Token': token,
                },
                method: 'PUT',
                body: JSON.stringify(data),
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200) {
                    response.nepatecCredentials = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },

    getMaintenanceWindows: async (serviceId, token) => {
        const url =
            getApiUrl(Config.serviceUrl) +
            '/api/v1/meetings/' +
            serviceId +
            '/maintenance-windows';
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Access-Token': token,
        };

        let response = {
            maintenanceWindows: undefined,
            error: undefined,
        };

        try {
            const apiResponse = await fetch(url, {
                headers: headers,
                method: 'GET',
            });
            try {
                const responseJson = await apiResponse.json();
                if (apiResponse.status === 200 || apiResponse.status === 206) {
                    response.maintenanceWindows = responseJson;
                } else {
                    response.error = responseJson;
                }
            } catch {
                response.error = ERRORS.PARSING_ERROR;
            }
        } catch {
            response.error = ERRORS.SERVER_UNREACHABLE_ERROR;
        }
        return response;
    },
};

export default al5ApiService;
