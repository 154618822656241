import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter, matchPath } from 'react-router';
import throttle from 'lodash/throttle';
import { Dialog } from 'primereact/dialog';
import Helmet from 'react-helmet';

import SerenityLayout from './features/layout/SerenityLayout';
import './serenity/ripple';

import Routes from './constants/routes';
import {
    KELDANO_APPLICATIONS,
    MEETING_TYPES,
    RESPONSIVE_MODES,
    WEBRTC_MODES,
} from './constants/constants';
import LoginPage from './features/auth/LoginPage';
import ServiceInputPage from './features/auth/ServiceInputPage';
import InitErrorPage from './features/auth/InitErrorPage';
import Meeting from './features/meetings/Meeting';
import MeetingStandalone from './features/meetings/MeetingStandalone';
import Dashboard from './features/dashboard/Dashboard';
import Error404 from './features/base/error404/Error404';
import TranslatedString from './features/base/i18n/TranslatedString';
import DeviceSettings from './features/deviceSettings/DeviceSettings';
import { checkReLogin } from './features/auth/actions';
import { routeWithServiceId } from './features/base/util/helpers';
import { LoadingScreen } from './features/base/loadingScreen/LoadingScreen';
import MeetingsManager from './features/meetingsManager/MeetingsManager';
import Recordings from './features/recordings/Recordings';
import { adiaLiveSettings } from './features/base/util/adiaLiveSettings';
import CustomGrowl from './features/base/messages/CustomGrowl';
import EditAccountDialog from './features/base/editAccount/EditAccountDialog';
import {
    changeResponsiveMode,
    externalConfigLoaded,
} from './features/base/common/actions';
import { Config } from './config/Config';
import DocumentsManager from './features/documentsManager/DocumentsManager';
import CallManager from './features/callManager/CallManager';
import AcceptCallDialog from './features/callManager/AcceptCallDialog';
import OpenCallsDialog from './features/callManager/OpenCallsDialog';

class Adviser extends Component {
    constructor(props) {
        super(props);

        this.handleWindowResizeEvent = this.handleWindowResizeEvent.bind(this);
        this.handleWindowResizeEventThrottled = throttle(
            this.handleWindowResizeEvent,
            100
        );
    }

    componentDidMount() {
        if (Config.externalConfig) {
            const script = document.createElement('script');
            script.async = true;
            script.src = Config.externalConfigSrc;
            document.head.appendChild(script);
            script.onload = () => {
                for (const [key, value] of Object.entries(window.Config)) {
                    Config[key] = value;
                }
                this.props.externalConfigLoaded(true);
                this.props.checkReLogin();
            };
        } else {
            this.props.checkReLogin();
        }

        window.addEventListener(
            'resize',
            this.handleWindowResizeEventThrottled
        );
        this.handleWindowResizeEvent();
    }

    componentWillUnmount() {
        window.removeEventListener(
            'resize',
            this.handleWindowResizeEventThrottled
        );
    }

    handleWindowResizeEvent() {
        let newWidth = document.body.clientWidth;
        let newResponsiveMode = 0;
        if (newWidth < RESPONSIVE_MODES.MEDIUM) {
            newResponsiveMode = RESPONSIVE_MODES.SMALL;
        } else if (newWidth < RESPONSIVE_MODES.BIG) {
            newResponsiveMode = RESPONSIVE_MODES.MEDIUM;
        } else if (newWidth < RESPONSIVE_MODES.FULL) {
            newResponsiveMode = RESPONSIVE_MODES.BIG;
        } else {
            newResponsiveMode = RESPONSIVE_MODES.FULL;
        }

        if (this.props.responsiveMode !== newResponsiveMode) {
            this.props.changeResponsiveMode(newResponsiveMode);
        }
    }

    render() {
        if (Config.externalConfig && !this.props.externalConfigLoaded) {
            return <LoadingScreen />;
        }

        let content;
        if (this.props.reLoginChecked && this.props.authenticated) {
            if (this.props.initFailed) {
                content = <InitErrorPage />;
            } else {
                content = this.renderContent();
            }
        } else {
            if (this.props.reLoginChecked && !this.props.authenticated) {
                content = this.renderLogin();
            } else {
                content = <LoadingScreen />;
            }
        }
        return (
            <React.Fragment>
                <Helmet>
                    <title>{Config.documentTitle}</title>
                    <link rel="shortcut icon" href={Config.favicon} />
                </Helmet>
                {content}
            </React.Fragment>
        );
    }

    renderLogin() {
        return (
            <Switch>
                {/*redirect all service routes to the service login  //TODO generate automatically */}
                <Route
                    path={[
                        Routes.SERVICE_LOGIN,
                        Routes.DASHBOARD,
                        Routes.MEETINGS,
                        Routes.MEETINGS_EXECUTE,
                        Routes.MEETINGS_EXECUTE_STANDALONE,
                        Routes.WEBINARS,
                        Routes.PHONE_CONSULTATION,
                        Routes.PODIUMS,
                        Routes.RECORDINGS,
                        Routes.DEFAULT_DOCUMENTS,
                        Routes.DEFAULT_AGENDA,
                        Routes.DEFAULT_MINUTES,
                        Routes.SIGNATURE_TEMPLATES,
                        Routes.CALL_MANAGER,
                    ]}
                    exact
                    render={(props) => (
                        <LoginPage serviceId={props.match.params.serviceId} />
                    )}
                />
                {/*redirect the home route and all other unknown routes to the service input page*/}
                <Route path={Routes.HOME} exact component={ServiceInputPage} />
                <Route render={() => <Redirect to={Routes.HOME} />} />
            </Switch>
        );
    }

    renderContent() {
        const serviceSettings = adiaLiveSettings.flagsToSettings(
            this.props.flags
        );

        const defaultDocumentsEnabled = this.props.hasManagerRole;

        const signatureTemplatesEnabled =
            this.props.nepatecTemplatesActive && this.props.applicationsUrl;

        const menuModel = [
            {
                label: <TranslatedString id={'dashboard'} />,
                icon: 'dashboard',
                to: routeWithServiceId(Routes.DASHBOARD, this.props.service),
            },
        ];

        if (serviceSettings.meetings) {
            menuModel.push({
                label: <TranslatedString id={'meetings'} />,
                icon: 'people',
                to: routeWithServiceId(Routes.MEETINGS, this.props.service),
            });
        }

        if (serviceSettings.webinar) {
            menuModel.push({
                label: <TranslatedString id={'webinars'} />,
                icon: 'school',
                to: routeWithServiceId(Routes.WEBINARS, this.props.service),
            });
        }

        if (serviceSettings.phoneConsulting) {
            menuModel.push({
                label: <TranslatedString id={'phoneConsulting'} />,
                icon: 'perm_phone_msg',
                to: routeWithServiceId(
                    Routes.PHONE_CONSULTATION,
                    this.props.service
                ),
            });
        }

        if (serviceSettings.podium) {
            menuModel.push({
                label: <TranslatedString id={'podiums'} />,
                icon: 'live_tv',
                to: routeWithServiceId(Routes.PODIUMS, this.props.service),
            });
        }

        if (serviceSettings.callManager) {
            menuModel.push({
                label: <TranslatedString id={'callManager'} />,
                icon: 'view_list',
                to: routeWithServiceId(Routes.CALL_MANAGER, this.props.service),
            });
        }

        if (defaultDocumentsEnabled || signatureTemplatesEnabled) {
            const documentsMenuItems = [];
            if (defaultDocumentsEnabled) {
                documentsMenuItems.push({
                    label: <TranslatedString id={'defaultDocuments'} />,
                    icon: 'description',
                    to: routeWithServiceId(
                        Routes.DEFAULT_DOCUMENTS,
                        this.props.service
                    ),
                });
                if (!this.props.disableAgenda) {
                    documentsMenuItems.push({
                        label: <TranslatedString id={'defaultAgenda'} />,
                        icon: 'format_list_bulleted',
                        to: routeWithServiceId(
                            Routes.DEFAULT_AGENDA,
                            this.props.service
                        ),
                    });
                }
                if (!this.props.disableMinutes) {
                    documentsMenuItems.push({
                        label: <TranslatedString id={'defaultMinutes'} />,
                        icon: 'speaker_notes',
                        to: routeWithServiceId(
                            Routes.DEFAULT_MINUTES,
                            this.props.service
                        ),
                    });
                }
            }
            if (signatureTemplatesEnabled) {
                documentsMenuItems.push({
                    label: <TranslatedString id={'signatureTemplates'} />,
                    icon: 'edit',
                    to: routeWithServiceId(
                        Routes.SIGNATURE_TEMPLATES,
                        this.props.service
                    ),
                });
            }
            menuModel.push({
                label: <TranslatedString id={'templates'} />,
                icon: 'description',
                items: documentsMenuItems,
            });
        }

        if (this.props.localRecordingEnabled) {
            menuModel.push({
                label: <TranslatedString id={'recordings'} />,
                icon: 'video_library',
                to: routeWithServiceId(Routes.RECORDINGS, this.props.service),
            });
        }

        const dashboardRoute = (
            <Route
                path={Routes.DASHBOARD}
                exact
                render={() => {
                    return (
                        <SerenityLayout
                            menuModel={menuModel}
                            content={<Dashboard />}
                            showTopbar={true}
                            showBreadcrumb={Config.showBreadcrumb}
                            showFooter={false}
                        />
                    );
                }}
            />
        );

        const meetingsManagerRoute = serviceSettings.meetings ? (
            <Route
                path={Routes.MEETINGS}
                exact
                render={() => {
                    return (
                        <SerenityLayout
                            menuModel={menuModel}
                            content={
                                <MeetingsManager type={MEETING_TYPES.MEETING} />
                            }
                            showTopbar={true}
                            showBreadcrumb={Config.showBreadcrumb}
                            showFooter={false}
                        />
                    );
                }}
            />
        ) : null;

        const meetingsExecuteRoute = (
            <Route
                path={Routes.MEETINGS_EXECUTE}
                exact
                render={(props) => {
                    return <Meeting {...props} />;
                }}
            />
        );

        const meetingsExecuteStandaloneRoute = (
            <Route
                path={Routes.MEETINGS_EXECUTE_STANDALONE}
                exact
                render={(props) => {
                    return <MeetingStandalone {...props} />;
                }}
            />
        );

        const webinarsManagerRoute = serviceSettings.webinar ? (
            <Route
                path={Routes.WEBINARS}
                exact
                render={() => {
                    return (
                        <SerenityLayout
                            menuModel={menuModel}
                            content={
                                <MeetingsManager type={MEETING_TYPES.WEBINAR} />
                            }
                            showTopbar={true}
                            showBreadcrumb={Config.showBreadcrumb}
                            showFooter={false}
                        />
                    );
                }}
            />
        ) : null;

        const phoneConsultingManagerRoute = serviceSettings.phoneConsulting ? (
            <Route
                path={Routes.PHONE_CONSULTATION}
                exact
                render={() => {
                    return (
                        <SerenityLayout
                            menuModel={menuModel}
                            content={
                                <MeetingsManager
                                    type={MEETING_TYPES.PHONE_CONSULTING}
                                />
                            }
                            showTopbar={true}
                            showBreadcrumb={Config.showBreadcrumb}
                            showFooter={false}
                        />
                    );
                }}
            />
        ) : null;

        const podiumManagerRoute = serviceSettings.podium ? (
            <Route
                path={Routes.PODIUMS}
                exact
                render={() => {
                    return (
                        <SerenityLayout
                            menuModel={menuModel}
                            content={
                                <MeetingsManager type={MEETING_TYPES.PODIUM} />
                            }
                            showTopbar={true}
                            showBreadcrumb={Config.showBreadcrumb}
                            showFooter={false}
                        />
                    );
                }}
            />
        ) : null;

        const callManagerRoute = serviceSettings.callManager ? (
            <Route
                path={Routes.CALL_MANAGER}
                exact
                render={() => {
                    return (
                        <SerenityLayout
                            menuModel={menuModel}
                            content={<CallManager />}
                            showTopbar={true}
                            showBreadcrumb={Config.showBreadcrumb}
                            showFooter={false}
                        />
                    );
                }}
            />
        ) : null;

        const defaultDocumentsRoute = defaultDocumentsEnabled ? (
            <Route
                path={Routes.DEFAULT_DOCUMENTS}
                exact
                render={(props) => {
                    return (
                        <SerenityLayout
                            menuModel={menuModel}
                            content={
                                <DocumentsManager
                                    application={
                                        KELDANO_APPLICATIONS.DEFAULT_DOCUMENTS
                                    }
                                />
                            }
                            showTopbar={true}
                            showBreadcrumb={Config.showBreadcrumb}
                            showFooter={false}
                        />
                    );
                }}
            />
        ) : null;

        const defaultAgendaRoute =
            defaultDocumentsEnabled && !this.props.disableAgenda ? (
                <Route
                    path={Routes.DEFAULT_AGENDA}
                    exact
                    render={(props) => {
                        return (
                            <SerenityLayout
                                menuModel={menuModel}
                                content={
                                    <DocumentsManager
                                        application={
                                            KELDANO_APPLICATIONS.DEFAULT_AGENDA
                                        }
                                    />
                                }
                                showTopbar={true}
                                showBreadcrumb={Config.showBreadcrumb}
                                showFooter={false}
                            />
                        );
                    }}
                />
            ) : null;

        const defaultMinutesRoute =
            defaultDocumentsEnabled && !this.props.disableMinutes ? (
                <Route
                    path={Routes.DEFAULT_MINUTES}
                    exact
                    render={(props) => {
                        return (
                            <SerenityLayout
                                menuModel={menuModel}
                                content={
                                    <DocumentsManager
                                        application={
                                            KELDANO_APPLICATIONS.DEFAULT_MINUTES
                                        }
                                    />
                                }
                                showTopbar={true}
                                showBreadcrumb={Config.showBreadcrumb}
                                showFooter={false}
                            />
                        );
                    }}
                />
            ) : null;

        const signatureTemplatesRoute = signatureTemplatesEnabled ? (
            <Route
                path={Routes.SIGNATURE_TEMPLATES}
                exact
                render={(props) => {
                    return (
                        <SerenityLayout
                            menuModel={menuModel}
                            content={
                                <DocumentsManager
                                    application={
                                        KELDANO_APPLICATIONS.SIGNATURE_TEMPLATES
                                    }
                                />
                            }
                            showTopbar={true}
                            showBreadcrumb={Config.showBreadcrumb}
                            showFooter={false}
                        />
                    );
                }}
            />
        ) : null;

        const recordingsRoute = this.props.localRecordingEnabled ? (
            <Route
                path={Routes.RECORDINGS}
                exact
                render={(props) => {
                    return (
                        <SerenityLayout
                            menuModel={menuModel}
                            content={
                                this.props.webRtcInitialized ? (
                                    <Recordings />
                                ) : (
                                    <LoadingScreen />
                                )
                            }
                            showTopbar={true}
                            showBreadcrumb={Config.showBreadcrumb}
                            showFooter={false}
                        />
                    );
                }}
            />
        ) : null;

        const reconnectingDialog = (
            <Dialog
                header={<TranslatedString id="reconnectingHeader" />}
                visible={
                    this.props.alcReconnecting || this.props.webRtcReconnecting
                }
                closable={false}
                onHide={() => {}}
                baseZIndex={999999}
            >
                <div className="p-grid">
                    <div className="p-col-12">
                        <TranslatedString id="reconnectingMessage" />
                    </div>
                    <div className="p-col-12 reconnecting-spinner-container">
                        <LoadingScreen />
                    </div>
                </div>
            </Dialog>
        );

        // topbar is hidden if route is on meetings_execute(_standalone) path
        const topbarHidden =
            !!matchPath(this.props.pathname, Routes.MEETINGS_EXECUTE) ||
            !!matchPath(
                this.props.pathname,
                Routes.MEETINGS_EXECUTE_STANDALONE
            );

        return (
            <React.Fragment>
                {this.props.alcInitialized &&
                this.props.alcAuthenticated &&
                this.props.webRtcInitialized ? (
                    <React.Fragment>
                        <Switch>
                            {dashboardRoute}
                            {meetingsManagerRoute}
                            {webinarsManagerRoute}
                            {podiumManagerRoute}
                            {phoneConsultingManagerRoute}
                            {callManagerRoute}
                            {meetingsExecuteRoute}
                            {meetingsExecuteStandaloneRoute}
                            {defaultDocumentsRoute}
                            {defaultAgendaRoute}
                            {defaultMinutesRoute}
                            {signatureTemplatesRoute}
                            {recordingsRoute}
                            <Route render={() => <Error404 />} />
                        </Switch>
                        {this.props.settingsPanelShown && <DeviceSettings />}
                        {reconnectingDialog}
                        <EditAccountDialog />
                        {serviceSettings.callManager && (
                            <React.Fragment>
                                <AcceptCallDialog />
                                <OpenCallsDialog />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : (
                    <LoadingScreen />
                )}
                <CustomGrowl topbarHidden={topbarHidden} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const webRtcMode = state.auth.publicServiceInfo
        ? state.auth.publicServiceInfo.webRtcMode
        : undefined;
    const localRecordingEnabled =
        (webRtcMode === WEBRTC_MODES.LIVESWITCH_P2P ||
            webRtcMode === WEBRTC_MODES.ADIA_P2P) &&
        state.meetings.webRtcFlags &&
        state.meetings.webRtcFlags.canRecordMedia &&
        state.auth.publicServiceInfo &&
        (!state.auth.publicServiceInfo.meetingsSettings.hasOwnProperty(
            'enableRecording'
        ) ||
            state.auth.publicServiceInfo.meetingsSettings.enableRecording);
    let applicationsUrl = null;
    if (
        state.auth.publicServiceInfo &&
        state.auth.publicServiceInfo.meetingsSettings
    ) {
        applicationsUrl =
            state.auth.publicServiceInfo.meetingsSettings.applicationsUrl;
    }

    return {
        flags: state.auth.publicServiceInfo
            ? state.auth.publicServiceInfo.flags
            : null,
        reLoginChecked: state.auth.reLoginChecked,
        authenticated: state.auth.authenticated,
        service: state.auth.service,
        alcInitialized: state.meetings.alcInitialized,
        alcAuthenticated: state.meetings.alcAuthenticated,
        alcReconnecting: state.meetings.alcReconnecting,
        meetingType:
            state.meetings.meetingInfo && state.meetings.meetingInfo.type,
        webRtcInitialized: state.meetings.webRtcInitialized,
        webRtcReconnecting: state.meetings.webRtcReconnecting,
        settingsPanelShown: state.deviceSettings.settingsPanelShown,
        responsiveMode: state.base.common.responsiveMode,
        pathname: ownProps.location.pathname,
        localRecordingEnabled,
        initFailed: state.auth.initFailed,
        applicationsUrl,
        hasManagerRole:
            state.auth.user && state.auth.user.roles.includes('manager'),
        nepatecTemplatesActive:
            state.auth.publicServiceInfo &&
            state.auth.publicServiceInfo.nepatecSettings &&
            state.auth.publicServiceInfo.nepatecSettings.active &&
            state.auth.publicServiceInfo.nepatecSettings.signatureTemplates,
        disableAgenda:
            state.auth.publicServiceInfo &&
            state.auth.publicServiceInfo.meetingsSettings &&
            state.auth.publicServiceInfo.meetingsSettings.disableAgenda,
        disableMinutes:
            state.auth.publicServiceInfo &&
            state.auth.publicServiceInfo.meetingsSettings &&
            state.auth.publicServiceInfo.meetingsSettings.disableMinutes,
        externalConfigLoaded: state.base.common.externalConfigLoaded,
    };
};

const mapDispatchToProps = {
    checkReLogin,
    changeResponsiveMode,
    externalConfigLoaded,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Adviser)
);
