import loginLogo from '../assets/images/unblu_meet.svg';
import mainLogo from '../assets/images/unblu_meet.svg';
import favicon from '../assets/favicons/favicon_unblumeet.ico';
import { COMMUNICATION_MODES } from '../constants/constants';

export const defaultConfig = {
    externalConfig: false,
    externalConfigSrc: undefined,
    loginLogo,
    mainLogo,
    favicon,
    documentTitle: 'Unblu Meet - Adviser',
    defaultCommunicationMode: COMMUNICATION_MODES.BOTH,
    showBreadcrumb: false,
    editAccountDisabled: false,
    phoneConsultingAudioVideoDisabled: false,
    combiModeDisabled: false,
};
