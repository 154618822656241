import * as types from './actionTypes';

export const initialState = {
    isLoading: false,
    recordingList: [],
    errorId: undefined
};

export default function recordings(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_RECORDINGS:
            return {
                ...state,
                isLoading: true,
                errorMessage: undefined
            };
        case types.REQUEST_RECORDINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                recordingList: action.recordings
            };
        case types.REQUEST_RECORDINGS_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorId: action.errorId
            };
        default:
            return state;
    }
}
