import React from 'react';
import classnames from 'classnames';

function AdiaPadTile({ title, category, onClick, icon, disabled }) {
    return (
        <div
            onClick={disabled ? null : onClick}
            className={classnames('tile', category, { disabled })}
        >
            {category !== 'separator' && (
                <React.Fragment>
                    {icon && <div className="tile-icon">{icon}</div>}
                    <div className="tile-title">{title}</div>
                </React.Fragment>
            )}
        </div>
    )
}

export default AdiaPadTile;
